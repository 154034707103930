//
// todo make everything camelCase. NEW CODE MUST BE IN CamelCase ONLY

enum Actions {
    // sidebar
    TOGGLE_COMPACT_SIDEBAR = 'toggleCompactSidebar',
    CHANGE_MODE = 'changeMode',

    // customer care
    SET_CURRENT_SUBSCRIBER_ID = 'setCurrentSubscriberId',
    UPDATE_SUBSCRIBER_EMAIL = 'updateSubscriberEmail',
    UPDATE_ACCOUNT_EMAIL = 'updateAccountEmail',

    // config
    LOAD_COLOR_CONFIG = 'loadColorConfig',
    SAVE_COLOR_CONFIG = 'saveColorConfig',

    // customer care suite
    LOAD_LAYOUT_SECTION_CONFIGS = 'loadLayoutSectionConfigs',
    LOAD_LAYOUT_SECTION_CONFIGS_BY_PAGE = 'loadLayoutSectionConfigsByPage',
    SAVE_LAYOUT_SECTION_CONFIGS = 'saveLayoutSectionConfigs',
    SAVE_LAYOUT_SECTION_CONFIGS_BY_PAGE = 'saveLayoutSectionConfigsByPage',
    LOAD_ASSOCIATED_UM_ENTITIES = 'loadAssociatedUserManagementEntities',
    LOAD_ORGANIZATION = 'loadOrganization',
    LOAD_USER = 'loadUser',
    LOAD_ACCOUNT = 'loadAccount',
    LOAD_SUBSCRIBER = 'loadSubscriber',
    GENERATE_CODE = 'generateCode',
    VERIFY_CODE = 'verifyCode',

    // orders
    LOAD_ALL_ORDERS = 'loadAllOrders',
    LOAD_ALL_OEORDERS_ACCOUNT = 'loadAllOEOrdersAccount',
    LOAD_ALL_OMDDEVEDGEORDERS_ACCOUNT = 'loadAllOMDevEdgeOrdersAccount',
    REPLACE_ORDER = 'replaceOrder',
    CANCEL_REPLACE_ORDER = 'cancelReplaceOrder',
    RESET_ORDERS = 'resetOrders',
    GET_DELIVERIES = 'getDeliveries',
    RESET_DELIVERIES = 'resetDeliveries',

    // filters
    REQUEST_FILTERS_LIST = 'requestFiltersList',

    // PRODUCT CATALOG
    // TEMPLATES
    PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS = 'PCrequestTemplatesByTypeAndIds',
    PC_REQUEST_ALL_TEMPLATES = 'PCrequestAllTemplates',
    // ENTITIES
    PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS = 'PCrequestEntitiesByTypeAndIds',

    // CEP
    LOAD_FILTER_DEFINITIONS = 'loadFilterDefinitions',
    LOAD_TRIGGER_DEFINITIONS = 'loadTriggerDefinitionsV2',
    FETCH_SEGMENTS = 'fetchSegmentsV2',
    FETCH_SEGMENT = 'fetchSegment',
    DELETE_SEGMENT = 'deleteSegment',
    UPDATE_SEGMENT_OPERATIONAL_STATUS = 'updateSegmentOperationalStatus',
    DOWNLOAD_SEGMENT_DATA = 'downloadSegmentData',
    FETCH_CAMPAIGN_CARRIER_APPS = 'fetchCampaignCarrierApps',
    FETCH_CAMPAIGN_PRIORITIES = 'fetchCampaignPriorities',
    FETCH_CAMPAIGN_CATEGORIES = 'fetchCampaignCategories',
    FETCH_ALL_CAMPAIGNS = 'fetchAllCampaigns',
    FETCH_SEGMENT_STATISTICS = 'fetchSegmentStatistics',
    FETCH_TEMPLATE_HELPERS = 'fetchTemplateHelpers',
    LOAD_QUIET_HOURS = 'loadQuietHours',

    // CEP User groups
    FETCH_STATIC_FILTER_COUNT_STATS = 'fetchStaticFilterCountStats',

    // CEP Events
    LOAD_EVENT_REQUIRED_PROPERTIES = 'loadRequiredPropertyFields',
    LOAD_EVENT_PROPERTY_TYPES = 'loadEventPropertyTypes',
    LOAD_ALL_EVENTS = 'loadAllEvents',
    DELETE_EVENT = 'deleteEvent',
    LOAD_EVENT_DATA_SOURCES = 'loadEventDataSources',
    LOAD_ENTITY_TRACKER_DATA = 'loadEntityTrackerData',

    // CEP Events configs
    LOAD_ORD_CONFIGS = 'loadReportsConfigs',
    LOAD_ORD_CONFIGS_SETTINGS = 'loadReportsConfigsSettings',
    DELETE_ORD_CONFIG = 'deleteOrdConfig',
    LOAD_REDSHIFT_REQUIRED_PROPERTIES = 'loadRedshiftRequiredPropertyFields',
    LOAD_REDSHIFT_CONFIGS = 'loadRedshiftConfigs',
    LOAD_REDSHIFT_CONFIGS_SETTINGS = 'loadRedshiftConfigsSettings',
    DELETE_REDSHIFT_CONFIG = 'deleteRedshiftConfigs',
    UPDATE_REDSHIFT_CONFIG_STATE = 'updateRedshiftConfigState',
    LOAD_KAFKA_SINK_CONFIGS = 'loadKafkaConfigs',
    LOAD_KAFKA_SINK_CONFIGS_SETTINGS = 'loadKafkaConfigsSettings',
    DELETE_KAFKA_SINK_CONFIG = 'deleteKafkaConfigs',
    UPDATE_KAFKA_SINK_CONFIG_STATE = 'updateKafkaConfigState',
    LOAD_API_INVOKER_SINK_CONFIGS = 'loadApiInvokerConfigs',
    LOAD_EVENT_COMPOSITION_CONFIGS = 'loadEventCompositionConfigs',
    LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS = 'loadApiInvokerSinkConfigsSettings',
    LOAD_EVENT_COMPOSITION_CONFIGS_SETTINGS = 'loadApiEventCompositionConfigsSettings',
    DELETE_API_INVOKER_SINK_CONFIG = 'deleteApiInvokerSinkConfig',
    DELETE_EVENT_COMPOSITION_CONFIG = 'deleteEventCompositionConfig',
    UPDATE_API_INVOKER_SINK_CONFIG_STATE = 'updateApiInvokerConfigState',
    LOAD_SINK_FORMATTERS = 'loadSinkFormatters',
    LOAD_SINK_TYPE_CASTERS = 'loadSinkTypeCasters',
    LOAD_REDSHIFT_SINK_TYPE_CASTERS = 'loadRedshiftSinkTypeCasters',
    LOAD_REDSHIFT_FORMATTERS = 'loadRedshiftFormatters',
    LOAD_JSON_PATH_OPTIONS = 'loadJsonPathOptions',

    // Users
    LOAD_ALL_USERS = 'loadAllUsers',

    // Roles
    LOAD_ROLE = 'loadRole',

    GET_USER_PERMISSIONS = 'getUserPermissions',

    // Charging
    REQUEST_POLICY_COUNTERS = 'requestPolicyCounters',
    REQUEST_POLICY_COUNTER_BY_VERSION = 'requestPolicyCounterByVeersion',
    REQUEST_SETTING_AFFECTED_BY_POLICY_COUNTER = 'requestSettingAffectedByPolicyCounter',
    REQUEST_POLICY_RULES = 'requestPolicyRules',
    REQUEST_POLICY_RULE_BY_VERSION = 'requestPolicyRuleByVersion',
    REQUEST_CHARGING_SPECIFICATIONS = 'requestChargingSpecifications',
    REQUEST_CHARGING_SPECIFICATION_BY_VERSION = 'requestChargingSpecificationByVersion',
    REQUEST_CHARGING_SPECIFICATIONS_GROUPS = 'requestChargingSpecificationsGroups',
    REQUEST_RATING_GROUPS = 'requestRatingGroups',
    REQUEST_RATING_GROUP_BY_VERSION = 'requestRatingGroupByVersion',
    REQUEST_USAGE_COUNTERS = 'requestUsageCounters',
    REQUEST_USAGE_COUNTER_BY_VERSION = 'requestUsageCounterByVersion',
    REQUEST_CONDITION_PARAMETERS = 'requestConditionParameters',
    REQUEST_CONDITION_PARAMETER_BY_VERSION = 'requestConditionParameterByVersion',
    REQUEST_WALLET_COUNTERS = 'requestWalletCounters',
    REQUEST_WALLET_COUNTER_BY_VERSION = 'requestWalletCounterByVersion',
    REQUEST_CONTENT_TYPES = 'requestContentTypes',
    REQUEST_CONTENT_TYPE_BY_VERSION = 'requestContentTypeByVersion',
    REQUEST_TARIFF_SPECIFICATIONS = 'requestTariffSpecifications',
    REQUEST_TARIFF_SPECIFICATION_BY_VERSION = 'requestTariffSpecificationByVeersion',
    REQUEST_TARIFF_SPECIFICATION_GROUPS = 'requestTariffSpecificationGroups',

    // Content Hosting
    REQUEST_ITEMS_BY_TYPE = 'requestItemsByType',
    REQUEST_ALL_ITEMS = 'requestAllItems',

    // ESIM
    REQUEST_ESIM_DATA = 'requestEsimData',

    // PROMOTIONS
    REQUEST_PROMOTIONS = 'requestPromotions',

    // ORCHESTRATION ENGINE
    REQUEST_OE_PLANS = 'requestOEPlans',
    REQUEST_OE_EXECUTIONS = 'requestOEExecutions',
    REQUEST_OE_EXECUTION = 'requestOEExecution',
    REQUEST_OE_TEMPLATES = 'requestOETemplates',
    REQUEST_OE_MAPPED_EXECUTIONS = 'requestOEMappedExecutions',
    REQUEST_OE_PAUSED_EXECUTIONS = 'requestOEPausedExecutions',
    REQUEST_OE_BATCH_ACTIONS = 'requestOEbatchActions',

    // REWARDS
    REQUEST_REWARDS_ENTITIES_BY_TYPE = 'requestRewardsEntitiesByType',

    // OPERATORS
    SetLanguages = 'setLanguages',

    // Documents
    REQUEST_DOCUMENTS = 'requestDocuments',
    REQUEST_DOCUMENT_TEMPLATES = 'requestDocumentTemplates',
    REQUEST_DOCUMENT_ASSETS = 'requestDocumentAssets',

    // Dataflows
    REQUEST_DATAFLOW_TEMPLATES = 'requestDataflowTemplates',
    REQUEST_DATAFLOW_TEMPLATE_UI_MODEL = 'requestDataflowTemplateUiModel',

    // Authorization
    REQUEST_MEMBERS_NOTIFICATION_IDENTIFIERS = 'requestMembersNotificationIdentifiers',
    SET_AUTH_2FA_SUCCESSFUL_STATE = 'setAuth2faSuccessfulState',

    // Shared file locations
    FETCH_SHARED_FILE_LOCATIONS = 'fetchSharedFileLocations',
}

export enum Mutations {
    // core store
    TOGGLE_COMPACT_SIDEBAR = 'toggleCompactSidebar',
    CHANGE_MODE = 'changeMode',

    // Entity Mutation
    SET_AFFECTED_ENTITIES = 'setAffectedEntities',
    TOGGLE_SHOW_MODAL = 'toggleShowModal',

    // customer care
    SET_SUBSCRIBER_INFO = 'setSubscriberInfo',
    setSubscriberStateHistory = 'setSubscriberStateHistory',
    CLEAR_SUBSCRIBERS_INFO = 'clearSubscribersInfo',
    SET_ACCOUNT_INFO = 'setAccountInfo',
    SET_CURRENT_SUBSCRIBER_ID = 'setCurrentSubscriberId',
    SET_SUBSCRIBER_DETAILED_INFO = 'setSubscriberDetailedInfo',
    UPDATE_SUBSCRIBER_INFO = 'updateSubscriberInfo',
    UPDATE_ACCOUNT_INFO = 'updateAccountInfo',

    // config
    SET_COLOR_CONFIG = 'setColorConfig',

    // customer care suite
    SET_LAYOUT_SECTION_CONFIGS = 'setLayoutSectionConfigs',
    SET_ASSOCIATED_UM_ENTITIES = 'setAssociatedUserManagementEntities',
    SET_UM_ENTITIES_HIERARCHY = 'setUmEntitiesHierarchy',
    SET_ORGANIZATION_DATA = 'setOrganizationData',
    SET_USER_DATA = 'setUserData',
    SET_ACCOUNT_DATA = 'setAccountData',
    SET_SUBSCRIBER_DATA = 'setSubscriberData',
    SET_USER_AUTHENTICATION_STATE = 'setUserAuthenticationState',

    // orders
    SET_ORDERS = 'setOrders',
    SET_ORDERS_API_RESPONSE = 'setOrdersApiResponse',
    SET_DELIVERIES = 'setDeliveries',
    SET_DELIVERIES_API_RESPONSE = 'setDeliveriesApiResponse',

    // filters
    SET_FILTERS_LIST = 'setFiltersList',
    SET_ALL_FILTERS = 'setAllFilters',

    // PRODUCT CATALOG
    // TEMPLATES
    PC_SET_TEMPLATES = 'PCsetTemplates',
    PC_SET_TEMPLATES_BY_IDS = 'PCsetTemplatesByIds',
    PC_SET_TEMPLATES_API_RESPONSE = 'PCsetTemplatesApiResponse',
    PC_SET_ALL_TEMPLATES_API_RESPONSE = 'PCsetAllTemplatesApiResponse',

    // ENTITIES
    PC_SET_ENTITIES = 'PCsetEntitites',
    PC_SET_ENTITIES_API_RESPONSE = 'PCsetEntititesApiResponse',
    PC_SET_BULK_ENTITY_IDS = 'PCsetBulkEntityIds',
    PC_SET_BULK_EDIT_CHANGE_DATA = 'PCsetBulkEditChangeData',
    PC_SET_BULK_EDIT_CHANGE_COMPLETED_STATUS = 'PCsetBulkEditChangeCompletedStatus',

    // CEP
    SET_FILTER_DEFINITIONS_BY_ID = 'setFilterDefinitionsById',
    SET_TRIGGER_DEFINITIONS_BY_ID = 'setTriggerDefinitionsById',
    SET_CACHED_SEGMENTS = 'setCachedSegments',
    SET_SEGMENT_STATISTICS = 'setSegmentStatistics',
    SET_RAW_SEGMENTS = 'setRawSegments',
    SET_OPERATOR_STATS = 'setOperatorStats',
    SET_TOTAL_USERS = 'setTotalUsers',
    SET_CACHED_CAMPAIGN = 'setCachedCampaign',
    SET_ALL_CACHED_CAMPAIGNS = 'setAllCachedCampaigns',
    SET_RAW_CAMPAIGNS = 'setRawCampaigns',
    SET_CAMPAIGN_CARRIER_APPS = 'setCampaignCarrierApps',
    SET_CAMPAIGN_PRIORITIES = 'setCampaignPriorities',
    SET_CAMPAIGN_CATEGORIES = 'setCampaignCategories',
    SET_RAW_CAMPAIGN_CATEGORIES = 'setRawCampaignCategories',
    SET_TEMPLATE_HELPERS = 'setTemplateHelpers',
    SET_QUIET_HOURS = 'setQuietHours',

    // CEP user groups

    SET_STATIC_FILTERS_COUNT_STATS = 'setStaticFiltersCountStats',

    // CEP Events
    SET_EVENT_REQUIRED_PROPERTIES = 'setRequiredPropertyFields',
    SET_EVENT_PROPERTY_TYPES = 'setEventPropertyTypes',
    SET_ALL_EVENTS = 'setAllEvents',
    SET_ALL_EVENTS_API_RESPONSE = 'setAllEventsApiResponse',
    SET_EVENT_DATA_SOURCES = 'setEventDataSources',
    SET_ENTITY_TRACKER_DATA = 'setEntityTrackerData',
    SET_EUT_DATA_LOADING_FLAG = 'setEUTDataLoadingFlag',

    // CEP Events configs
    SET_ORD_CONFIGS = 'setReportsConfigs',
    SET_ORD_CONFIGS_API_RESPONSE = 'setReportsConfigsApiResponse',
    SET_ORD_CONFIGS_SETTINGS = 'setReportsConfigsSettings',
    SET_REDSHIFT_CONFIGS = 'setRedshiftConfigs',
    SET_REDSHIFT_CONFIGS_API_RESPONSE = 'setRedshiftConfigsApiResponse',
    SET_REDSHIFT_CONFIGS_SETTINGS = 'setRedshiftConfigsSettings',
    SET_REDSHIFT_REQUIRED_PROPERTIES = 'setRedshiftRequiredPropertyFields',
    SET_KAFKA_SINK_CONFIGS = 'setKafkaConfigs',
    SET_KAFKA_SINK_CONFIGS_API_RESPONSE = 'setKafkaConfigsApiResponse',
    SET_KAFKA_SINK_CONFIGS_SETTINGS = 'setKafkaConfigsSettings',
    SET_API_INVOKER_SINK_CONFIGS = 'setApiInvokerSinkConfigs',
    SET_EVENT_COMPOSITION_CONFIGS = 'setEventCompositionConfigs',
    SET_API_INVOKER_SINK_CONFIGS_API_RESPONSE = 'setApiInvokerSinkConfigsApiResponse',
    SET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE = 'setEventCompositionConfigsApiResponse',
    SET_API_INVOKER_SINK_CONFIGS_SETTINGS = 'setApiInvokerSinkConfigsSettings',
    SET_EVENT_COMPOSITION_CONFIGS_SETTINGS = 'setEventCompositionConfigsSettings',
    SET_SINK_FORMATTERS = 'setSinkFormatters',
    SET_SINK_TYPE_CASTERS = 'setSinkTypeCasters',
    SET_REDSHIFT_SINK_TYPE_CASTERS = 'setRedshiftSinkTypeCasters',
    SET_REDSHIFT_FORMATTERS = 'setRedshiftFormatters',
    SET_JSON_PATH_OPTIONS = 'setJsonPathOptions',

    // Users
    SET_USERS = 'setUsers',

    // Roles
    SET_ROLE = 'setRole',

    // Charging
    SET_POLICY_COUNTERS = 'setPolicyCounters',
    SET_POLICY_COUNTERS_API_RESPONSE = 'setPolicyCountersApiResponse',
    SET_POLICY_COUNTER_BY_VERSION = 'setPolicyCounterByVersion',
    SET_POLICY_RULES = 'setPolicyRules',
    SET_POLICY_RULES_API_RESPONSE = 'setPolicyRulesApiResponse',
    SET_POLICY_RULE_BY_VERSION = 'setPolicyRuleByVersion',
    SET_AFFECTED_BY_POLICY_COUNTER = 'setAffectedByPolicyCounter',
    SET_CHARGING_SPECIFICATIONS = 'setChargingSpecifications',
    SET_CHARGING_SPECIFICATIONS_API_RESPONSE = 'setChargingSpecificationsApiResponse',
    SET_CHARGING_SPECIFICATION_BY_VERSION = 'setChargingSpecificationByVersion',
    SET_CHARGING_SPECIFICATIONS_GROUPS = 'setChargingSpecificationsGroups',
    SET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE = 'setChargingSpecificationsGroupsApiResponse',
    SET_RATING_GROUPS = 'setRatingGroups',
    SET_RATING_GROUPS_API_RESPONSE = 'setRatingGroupsApiResponse',
    SET_USAGE_COUNTERS = 'setUsageCounters',
    SET_RATING_GROUP_BY_VERSION = 'setRatingGroupByVersion',
    SET_USAGE_COUNTERS_API_RESPONSE = 'setUsageCountersApiResponse',
    SET_USAGE_COUNTER_BY_VERSION = 'setUsageCounterByVersion',
    SET_CONDITION_PARAMETERS = 'setConditionParameters',
    SET_CONDITION_PARAMETERS_API_RESPONSE = 'setConditionParametersApiResponse',
    SET_CONDITION_PARAMETER_BY_VERSION = 'setConditionParameterByVersion',
    SET_WALLET_COUNTERS = 'setWalletCounters',
    SET_WALLET_COUNTERS_API_RESPONSE = 'setWalletCountersApiResponse',
    SET_WALLET_COUNTER_BY_VERSION = 'setWalletCounterByVersion',
    SET_CONTENT_TYPES = 'setContentTypes',
    SET_CONTENT_TYPES_API_RESPONSE = 'setContentTypesApiResponse',
    SET_CONTENT_TYPE_BY_VERSION = 'setContentTypeByVersion',
    SET_TARIFF_SPECIFICATIONS = 'setTariffSpecifications',
    SET_TARIFF_SPECIFICATIONS_API_RESPONSE = 'setTariffSpecificationsApiResponse',
    SET_TARIFF_SPECIFICATION_BY_VERSION = 'setTariffSpecificationByVersion',
    SET_TARIFF_SPECIFICATION_GROUPS = 'setTariffSpecificationGroups',
    SET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE = 'setTariffSpecificationGroupsApiResponse',
    // Content Hosting
    SET_ITEMS_BY_TYPE = 'setItemsByType',

    SET_USER_PERMISSIONS = 'setUserPermissions',

    // ESIM
    SET_ESIM_DATA = 'setEsimData',

    // PROMOTION
    SET_PROMOTIONS = 'setPromotions',
    SET_PROMOTIONS_API_RESPONSE = 'setPromotionsApiResponse',

    // Features
    SET_FEATURES = 'setFeatures',

    // ORCHESTRATION ENGINE
    SET_OE_PLANS = 'setOEPlans',
    SET_OE_PLANS_API_RESPONSE = 'setOEPlansApiResponse',
    SET_OE_EXECUTIONS = 'setOEExecutions',
    SET_OE_EXECUTIONS_API_RESPONSE = 'setOEExecutionsApiResponse',
    SET_OE_EXECUTION = 'setOEExecution',
    SET_OE_EXECUTION_API_RESPONSE = 'setOEExecutionApiResponse',
    SET_OE_TEMPLATES = 'setOETemplates',
    SET_OE_TEMPLATES_API_RESPONSE = 'setOETemplatesApiResponse',
    SET_OE_MAPPED_EXECUTIONS = 'setOEMappedExecutions',
    SET_OE_MAPPED_EXECUTIONS_API_RESPONSE = 'setOEMappedExecutionsApiResponse',
    SET_OE_PAUSED_EXECUTIONS = 'setOEPausedExecutions',
    SET_OE_PAUSED_EXECUTIONS_API_RESPONSE = 'setOEPausedExecutionsApiResponse',
    SET_OE_BATCH_ACTIONS = 'setOEbatchActions',
    SET_OE_BATCH_ACTIONS_API_RESPONSE = 'setOEbatchActionsApiResponse',

    // REWARDS
    SET_REWARDS_ENTITIES = 'setRewardsEntities',
    SET_REWARDS_ENTITIES_API_RESPONSE = 'setRewardsEntitiesApiResponse',

    // OPERATORS
    SetLanguages = 'setLanguages',

    // Documents
    SET_DOCUMENTS = 'setDocuments',
    SET_DOCUMENTS_API_RESPONSE = 'setDocumentsApiResponse',
    SET_DOCUMENT_TEMPLATES = 'setDocumentTemplates',
    SET_DOCUMENT_TEMPLATES_API_RESPONSE = 'setDocumentTemplatesApiResponse',
    SET_DOCUMENT_ASSETS = 'setDocumentAssets',
    SET_DOCUMENT_ASSETS_API_RESPONSE = 'setDocumentAssetsApiResponse',

    // Dataflows
    SET_DATAFLOW_TEMPLATES = 'setDataflowTemplates',
    SET_DATAFLOW_TEMPLATE_UI_MODEL = 'setDataflowTemplateUiModel',

    // Authorization
    SET_AUTH_TARGET_EMAILS = 'setAuthTargetEmails',
    SET_AUTH_TARGET_MSISDNS = 'setAuthTargetMsisdns',
    SET_AUTH_TOKEN_1FA = 'setAuthToken1fa',
    UPDATE_AUTH_2FA_SUCCESSFUL_STATUS = 'update2faSuccessfulStatus',
    SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING = 'setAuthUserIdForAuthStateMapping',
    SET_AUTH_AWS_CONNECT_CONTACT_ID = 'setAuthAwsConnectContactId',

    // Shared file locations
    SET_SHARED_FILE_LOCATIONS = 'setSharedFileLocations',
    SET_SHARED_FILE_LOCATIONS_API_RESPONSE = 'setSharedFileLocationsApiReponse',
}

export enum Getters {
    // core store
    GET_MODE = 'getMode',
    GET_IS_COMPACT_SIDEBAR = 'isCompactSidebar',

    // general
    languageDefault = 'languageDefault',
    languagesWithoutDefault = 'languagesWithoutDefault',

    // customer care
    ACCOUNT_INFO = 'accountInfo',
    SUBSCRIBERS = 'subscribers',
    SUBSCRIBERS_SUMMARY = 'subscribersSummary',
    SUBSCRIBERS_LIST = 'subscribersList',
    GET_CURRENT_SUBSCRIBER_INFO = 'getCurrentSubscriberInfo',
    GET_SUBSCRIBER_WALLET_INFO = 'getSubscriberWalletInfo',

    // config
    GET_COLOR_CONFIG = 'getColorConfig',

    // customer care suite
    GET_LAYOUT_SECTION_CONFIGS = 'getLayoutSectionConfigs',
    GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID = 'getAssociatedUserManagementEntityByTypeAndId',
    GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID = 'getUmEntityHierarchyByTypeAndId',
    GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID = 'getAssociatedChildEntitiesByTypeAndId',
    GET_USER_AUTHENTICATION_STATE = 'getUserAuthenticationState',

    // orders
    GET_PRIMARY_ORDERS = 'getPrimaryOrders',
    GET_REDEMPTION_HISTORY = 'getRedemptionHistory',
    GET_ORDERS = 'getOrders',
    GET_ORDERS_API_RESPONSE = 'getOrdersApiResponse',
    GET_DELIVERIES = 'getDeliveries',
    GET_DELIVERIES_API_RESPONSE = 'getDeliveriesApiResponse',

    // filters
    FILTERS_LIST_BY_FILTER_TYPE = 'filtersListByFilterType',
    FILTER_TYPE_FOR_REQUESTS = 'filterTypeForRequests',
    FILTER_DATA_BY_TYPE_AND_ID = 'filterDataByTypeAndId',
    FILTERS_LIST_FOR_MULTISELECT = 'filtersListForMultiselect',
    MsisdnWhitelistFilters = 'msisdnWhitelistFilters',

    // Entity Mutations
    GET_AFFECTED_ENTITIES = 'getAffectedEntitiesStore',
    GET_SHOW_MODAL = 'getShowModal',

    ACTIVE_APPLICATIONS = 'activeApplications',
    LABEL_MAP = 'entityLabelMap',

    // PRODUCT CATALOG
    // TEMPLATES
    PC_GET_ALL_TEMPLATES = 'PCgetAllTemplates',
    PC_GET_ALL_TEMPLATES_API_RESPONSE = 'PCgetAllTemplatesApiResponse',
    PC_GET_ALL_TEMPLATES_NOT_DELETED = 'PCgetAllTemplatesNotDeleted',
    PC_GET_TEMPLATES_BY_TYPE = 'PCgetTemplatesByType',
    PC_GET_TEMPLATES_BY_TYPE_NOT_DELETED = 'PCgetTemplatesByTypeNotDeleted',
    PC_GET_TEMPLATES_BY_TYPE_APPROVED = 'PCgetTemplatesByTypeNotDeletedApproved',
    PC_GET_TEMPLATES_API_RESPONSE_BY_TYPE = 'PCgetTemplatesApiResponseByType',
    PC_GET_TEMPLATE_BY_ID = 'PCgetTemplateById',
    PC_GET_TEMPLATE_BY_TYPE_AND_ID = 'PCgetTemplateByTypeAndId',
    // ENTITIES
    PC_GET_ENTITIES_BY_TYPE = 'PCgetEntitiesByType',
    PC_GET_ENTITIES_API_RESPONSE_BY_TYPE = 'PCgetEntitiesApiResponseByType',
    PC_GET_ENTITIES_BY_TYPE_NOT_DELETED = 'PCgetEntitiesByTypeNotDeleted',
    PC_GET_ENTITIES_BY_TYPE_APPROVED = 'PCgetEntitiesByTypeApproved',
    PC_GET_ENTITY_BY_TYPE_AND_ID = 'PCgetEntityByTypeAndId',
    PC_GET_BULK_EDIT_ENTITY_IDS_BY_TYPE = 'PCgetBulkEditEntityIdsByType',
    PC_GET_BULK_EDIT_CHANGE_DATA = 'PCgetBulkEditChangeData',
    PC_GET_BULK_EDIT_CHANGE_COMPLETED_STATUS = 'PCgetBulkEditChangeCompletedStatus',

    // CEP
    CACHED_SEGMENTS = 'cachedSegmentsArr',
    CACHED_SEGMENTS_BY_ID = 'cachedSegmentsById',
    GROUPED_SEGMENTS_BY_ID_TYPE = 'groupedSegmentsByIdType',
    GET_STATS_OF_SEGMENT = 'getStatsOfSegment',
    GET_CACHED_CAMPAIGNS = 'getCachedCampaigns',
    GET_CAMPAIGN_PRIORITIES = 'getCampaignPriorities',
    GET_CAMPAIGN_CATEGORIES = 'getCampaignCategories',
    GET_CACHED_CAMPAIGNS_ARRAY = 'getCachedCampaignsArray',
    GET_MAPPED_QUIET_HOURS = 'getMappedQuietHours',

    // CEP Events
    GET_EVENT_REQUIRED_PROPERTIES = 'getEventRequiredProperties',
    GET_EVENT_PROPERTY_TYPES = 'getEventPropertyTypes',
    GET_MAPPED_EVENTS = 'getMappedAllEvents',
    GET_ALL_EVENTS_API_RESPONSE = 'getAllEventsApiResponse',
    GET_EVENT_DATA_SOURCES = 'getEventDataSources',
    GET_ENTITY_TRACKER_DATA = 'getEntityTrackerData',
    GET_EVENT_BY_ID = 'getEventById',

    // CEP Events configs
    GET_ORD_CONFIGS = 'getReportsConfigs',
    GET_ORD_CONFIGS_API_RESPONSE = 'getReportsConfigsApiResponse',
    GET_ORD_CONFIGS_SETTINGS = 'getReportsConfigsSettings',
    GET_ORD_CONFIG_BY_ID = 'getReportConfigById',
    GET_REDSHIFT_CONFIGS = 'getRedshiftConfigs',
    GET_REDSHIFT_CONFIGS_API_RESPONSE = 'getRedshiftConfigsApiResponse',
    GET_REDSHIFT_CONFIGS_SETTINGS = 'getRedshiftConfigsSettings',
    GET_REDSHIFT_CONFIG_BY_ID = 'getRedshiftConfigById',
    GET_REDSHIFT_REQUIRED_PROPERTIES = 'getRedshiftRequiredProperties',
    GET_KAFKA_SINK_CONFIGS = 'getKafkaConfigs',
    GET_EVENT_COMPOSITION_CONFIGS = 'getEventCompositionConfigs',
    GET_KAFKA_SINK_CONFIGS_API_RESPONSE = 'getKafkaConfigsApiResponse',
    GET_KAFKA_SINK_CONFIGS_SETTINGS = 'getKafkaConfigsSettings',
    GET_KAFKA_SINK_CONFIG_BY_ID = 'getKafkaConfigById',
    GET_API_INVOKER_SINK_CONFIGS = 'getApiInvokerSinkConfigs',
    GET_API_INVOKER_SINK_CONFIGS_API_RESPONSE = 'getApiInvokerSinkConfigsApiResponse',
    GET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE = 'getEventcompositionConfigsApiResponse',
    GET_API_INVOKER_SINK_CONFIGS_SETTINGS = 'getApiInvokerConfigsSettings',
    GET_EVENT_COMPOSITION_CONFIGS_SETTINGS = 'getEventCompositionConfigsSettings',
    GET_EVENT_COMPOSITION_CONFIG_BY_ID = 'getEventCompositionConfigById',
    GET_SINK_FORMATTERS = 'getSinkFormatters',
    GET_SINK_TYPE_CASTERS = 'getSinkTypeCasters',
    GET_REDSHIFT_SINK_TYPE_CASTERS = 'getRedshiftSinkTypeCasters',
    GET_REDSHIFT_FORMATTERS = 'getRedshiftFormatters',
    GET_JSON_PATH_OPTIONS = 'getJsonPathOptions',

    // CEP Template Helpers
    GET_TEMPLATE_HELPERS_AUTOCOMPLETE = 'getTemplateHelpersAutocomplete',
    GET_TEMPLATE_HELPERS_FORMATTERS = 'getTemplateHelpersFormatters',
    GET_TEMPLATE_HELPERS_EVENT_FIELDS = 'getTemplateHelpersEventFields',

    // Users
    GET_LIST_OF_USERS = 'getListOfUsers',

    // Roles
    GET_ROLES = 'getRole',
    GET_ROLE_BY_ID = 'getPermissionsByRoleId',

    // Charging
    GET_POLICY_COUNTERS = 'getPolicyCounters',
    GET_APPROVED_POLICY_COUNTERS = 'getApprovedPolicyCounters',
    GET_POLICY_COUNTERS_API_RESPONSE = 'getPolicyCountersApiResponse',
    GET_POLICY_COUNTER_BY_ID = 'getPolicyCounterById',
    GET_POLICY_COUNTER_BY_IDS = 'getPolicyCountersById',
    GET_POLICY_COUNTER_BY_VERSION = 'getPolicyCounterByVersion',
    GET_POLICY_RULES = 'getPolicyRules',
    GET_POLICY_RULES_API_RESPONSE = 'getPolicyRulesApiResponse',
    GET_POLICY_RULE_BY_ID = 'getPolicyRuleById',
    GET_POLICY_RULE_BY_VERSION = 'getPolicyRuleByVersion',
    GET_CHARGING_SPECIFICATIONS = 'getChargingSpecifications',
    GET_ONLY_PUBLISHED_CHARGING_SPECIFICATIONS = 'getOnlyPublishedChargingSpecifications',
    GET_CHARGING_SPECIFICATIONS_API_RESPONSE = 'getChargingSpecificationsApiResponse',
    GET_CHARGING_SPECIFICATION_BY_ID = 'getChargingSpecificationById',
    GET_CHARGING_SPECIFICATIONS_BY_IDS = 'getChargingSpecificationsById',
    GET_CHARGING_SPECIFICATION_BY_VERSION = 'getChargingSpecificationByVersion',
    GET_CHARGING_SPECIFICATIONS_GROUPS = 'getChargingSpecificationsGroups',
    GET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE = 'getChargingSpecificationsGroupsApiResponse',
    GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID = 'getChargingSpecificationsGroupById',
    GET_APPROVED_RATING_GROUPS = 'getApprovedRatingGroups',
    GET_RATING_GROUP_BY_ID = 'getRatingGroupById',
    GET_RATING_GROUPS_API_RESPONSE = 'getRatingGroupsApiResponse',
    GET_NOT_DELETED_RATING_GROUPS = 'getNotDeletedRatingGroups',
    GET_RATING_GROUP_BY_VERSION = 'getRatingGroupByVersion',
    GET_USAGE_COUNTERS = 'getUsageCounters',
    GET_USAGE_COUNTERS_API_RESPONSE = 'getUsageCountersApiResponse',
    GET_USAGE_COUNTER_BY_VERSION = 'getUsageCounterByVersion',
    GET_USAGE_COUNTER_BY_ID = 'getUsageCounterById',
    GET_USAGE_COUNTERS_BY_IDS = 'getUsageCountersByIds',
    GET_APPROVED_USAGE_COUNTERS = 'getApprovedUsageCounters',
    GET_CONDITION_PARAMETERS = 'getConditionParameters',
    GET_CONDITION_PARAMETERS_API_RESPONSE = 'getConditionParametersApiResponse',
    GET_CONDITION_PARAMETER_BY_VERSION = 'getConditionParameterByVersion',
    GET_APPROVED_CONDITION_PARAMETERS = 'getApprovedConditionParameters',
    GET_CONDITION_PARAMETER_BY_ID = 'getConditionParameterById',
    GET_CONDITION_PARAMETERS_BY_IDS = 'getConditionParametersByIds',
    GET_WALLET_COUNTERS = 'getWalletCounters',
    GET_WALLET_COUNTERS_API_RESPONSE = 'getWalletCountersApiResponse',
    GET_APPROVED_WALLET_COUNTERS = 'getApprovedWalletCounters',
    GET_WALLET_COUNTER_BY_ID = 'getWalletCounterById',
    GET_WALLET_COUNTERS_BY_IDS = 'getWalletCountersByIds',
    GET_WALLET_COUNTER_BY_VERSION = 'getWalletCounterByVersion',
    GET_CONTENT_TYPES = 'getContentTypes',
    GET_CONTENT_TYPE_BY_ID = 'getContentTypeById',
    GET_APPROVED_CONTENT_TYPES = 'getApprovedContentTypes',
    GET_ONLY_PUBLISHED_CONTENT_TYPES = 'getOnlyPublishedContentTypes',
    GET_CONTENT_TYPES_API_RESPONSE = 'getContentTypesApiResponse',
    GET_CONTENT_TYPE_BY_VERSION = 'getContentTypeByVersion',
    GET_TARIFF_SPECIFICATIONS = 'getTariffSpecifications',
    GET_APPROVED_TARIFF_SPECIFICATIONS = 'getApprovedTariffSpecifications',
    GET_TARIFF_SPECIFICATIONS_API_RESPONSE = 'getTariffSpecificationsApiResponse',
    GET_TARIFF_SPECIFICATION_BY_ID = 'getTariffSpecificationById',
    GET_TARIFF_SPECIFICATION_BY_VERSION = 'getTariffSpecificationByVersion',
    GET_TARIFF_SPECIFICATION_GROUPS = 'getTariffSpecificationGroups',
    GET_APPROVED_TARIFF_SPECIFICATION_GROUPS = 'getApprovedTariffSpecificationGroups',
    GET_TARIFF_SPECIFICATION_GROUP_BY_ID = 'geTariffSpecificationGroupById',
    GET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE = 'getTariffSpecificationGroupsApiResponse',

    // Content Hosting
    GET_ITEMS_BY_TYPE = 'getItemsByType',
    GET_ITEM_BY_TYPE_AND_ID = 'getItemByTypeAndId',
    GET_ALL_ITEMS = 'getAllItems',
    GET_ITEM_BY_ID = 'getItemById',

    // Promotions
    PROMOTIONS_LIST = 'promotionsList',
    PROMOTION_BY_ID = 'promotionById',
    PROMOTION_ID = 'promotionId',

    // Features
    GET_FEATURES = 'getFeatures',
    GET_FEATURES_BY_COMPANYID = 'getFeaturesByCompanyId',

    // ORCHESTRATION ENGINE
    GET_OE_PLANS = 'getOEPlans',
    GET_OE_PLANS_API_RESPONSE = 'getOEPlansApiResponse',
    GET_OE_PLAN_BY_ID = 'getOEPlanById',
    GET_OE_EXECUTIONS_BY_PLAN_ID = 'getOEExecutionsByPlanId',
    GET_OE_EXECUTIONS_API_RESPONSE = 'getOEExecutionsApiResponse',
    GET_OE_EXECUTION = 'getOEExecution',
    GET_OE_EXECUTION_API_RESPONSE = 'getOEExecutionApiResponse',
    GET_OE_EXECUTION_OBJECT = 'getOEExecutionObject',
    GET_OE_TEMPLATES = 'getOETemplates',
    GET_OE_TEMPLATES_API_RESPONSE = 'getOETemplatesApiResponse',
    GET_OE_MAPPED_EXECUTIONS = 'getOEMappedExecutions',
    GET_OE_MAPPED_EXECUTIONS_API_RESPONSE = 'getOEMappedExecutionsApiResponse',
    GET_OE_PAUSED_EXECUTIONS = 'getOEPausedExecutions',
    GET_OE_PAUSED_EXECUTIONS_API_RESPONSE = 'getOEPausedExecutionsApiResponse',
    GET_OE_BATCH_ACTIONS = 'getOEbatchActions',
    GET_OE_BATCH_ACTION_BY_ID = 'getOEbatchActionById',
    GET_OE_BATCH_ACTIONS_API_RESPONSE = 'getOEbatchActionsApiResponse',

    // REWARDS
    GET_REWARDS_ENTITIES_LIST_BY_TYPE = 'getRewardsEntitiesListByType',
    GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE = 'getRewardsEntitiesApiResponseByType',
    GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE = 'getNotDeletedRewardsEntitiesByType',
    GET_APPROVED_REWARDS_ENTITIES_BY_TYPE = 'getApprovedRewardsEntitiesByType',
    GET_REWARDS_ENTITY_BY_TYPE_AND_ID = 'getRewardsEntityByTypeAndId',
    APPROVED_ENTITIES_BY_TYPE = 'approvedEntitiesByType',

    // Documents
    GET_DOCUMENTS = 'getDocuments',
    GET_DOCUMENTS_API_RESPONSE = 'getDocumentsApiResponse',
    GET_DOCUMENT_BY_ID = 'getDocumentById',
    GET_DOCUMENT_BY_IDS = 'getDocumentByIds',

    GET_DOCUMENT_TEMPLATES = 'getDocumentTemplates',
    GET_APPROVED_DOCUMENT_TEMPLATES = 'getApprovedDocumentTemplates',
    GET_DOCUMENT_TEMPLATES_API_RESPONSE = 'getDocumentTemplatesApiResponse',
    GET_DOCUMENT_TEMPLATE_BY_ID = 'getDocumentTemplateById',
    GET_DOCUMENT_TEMPLATE_BY_IDS = 'getDocumentTemplateByIds',
    GET_DOCUMENT_ASSETS = 'getDocumentAssets',
    GET_DOCUMENT_ASSET_BY_ID = 'getDocumentAssetById',
    GET_DOCUMENT_ASSETS_API_RESPONSE = 'getDocumentAssetsApiResponse',

    // Dataflows
    GET_DATAFLOW_TEMPLATES = 'getDataflowTemplates',
    GET_DATAFLOW_TEMPLATE_UI_MODEL = 'getDataflowTemplateUiModel',

    // Authorization
    GET_AUTH_TARGET_EMAILS = 'getAuthTargetEmails',
    GET_AUTH_TARGET_MSISDNS = 'getAuthTargetMsisdns',
    GET_AUTH_TOKEN_1FA = 'getAuthToken1fa',
    GET_AUTH_2FA_SUCCESSFUL = 'getAuth2faSuccessful',
    GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING = 'getAuthUserIdForAuthStateMapping',
    GET_AUTH_AWS_CONNECT_CONTACT_ID = 'getAuthAwsConnectContactId',

    // Shared file locations
    GET_SHARED_FILE_LOCATIONS = 'getSharedFileLocations',
    GET_SHARED_FILE_LOCATIONS_API_RESPONSE = 'getSharedFileLocationsApiReponse',
    GET_GET_SHARED_FILE_LOCATION_BY_ID = 'getSharedFileLocationById',

    // User Management Subscriber
    GET_SUBSCRIBER_INFO_BY_ID = 'getSubscriberInfoById',
}

export enum State {
    // core store properties
    COMPACT_SIDEBAR = 'compactSidebar',
    FEATURES = 'features',
    CURRENT_MODE = 'currentMode',

    // Entity Mutation
    AFFECTED_ENTITIES = 'affectedEntities',
    SHOW_MODAL = 'showModal',

    // sidebar
    SidebarExpanded = 'sidebarExpanded',
    SidebarMenuItemsToggleStatuses = 'sidebarMenuItemsToggleStatuses',

    // offers
    SelectedOffer = 'selectedOffer',

    // customer care
    ACCOUNT_INFO = 'accountInfo',
    SUBSCRIBERS_INFO = 'subscribersInfo',

    // config
    COLOR_CONFIG = 'colorConfig',

    // customer care suite
    LAYOUT_SECTION_CONFIGS = 'layoutSectionConfigs',
    ASSOCIATED_UM_ENTITIES = 'associatedUserManagementEntities',
    UM_ENTITIES_HIERARCHY = 'umEntitiesHierarchy',
    USER_AUTHENTICATION_STATE = 'userAuthenticationState',

    USER_INFO = 'userInfo',
    SUBSCRIBERS_EXTERNAL_INFO = 'subscribersExternalInfo',
    CURRENT_SUBSCRIBER_ID = 'currentSubscriberId',
    SUBSCRIBERS_DETAILED_INFO = 'subscribersDetailedInfo',
    SUBSCRIBERS_INTERVAL_IDS = 'subscribersIntervalIds',
    GET_CURRENT_SUBSCRIBER_ID = 'getCurrentSubscriberId',

    // filters
    FILTERS_BY_FILTER_TYPE = 'filtersByFilterType',

    // PRODUCT CATALOG
    // TEMPLATES
    PC_TEMPLATES_BY_IDS = 'PCtemplatesByIds',
    PC_TEMPLATES_BY_TYPE = 'PCtemplatesByType',
    PC_TEMPLATES_API_RESPONSE_BY_TYPE = 'PCtemplatesApiResponseByType',
    PC_ALL_TEMPLATES_API_RESPONSE = 'PCallTemplatesApiResponse',

    // ENTITIES
    PC_ENTITIES_BY_TYPE = 'PCentitiesByType',
    PC_ENTITIES_API_RESPONSE_BY_TYPE = 'PCentitiesApiResponseByType',
    PC_BULK_EDIT_ENTITY_IDS_BY_TYPE = 'PCbulkEditEntityIdsByType',
    PC_BULK_EDIT_CHANGE_DATA = 'PCbulkEditChangeData',
    PC_BULK_EDIT_CHANGE_COMPLETED_STATUS = 'PCbulkEditChangeCompletedStatus',

    // CEP
    CACHED_SEGMENTS = 'cachedSegments',
    RAW_SEGMENTS = 'rawSegments',
    SEGMENTS_STATISTICS = 'segmentStatistics',
    CACHED_CAMPAIGNS = 'cachedCampaigns',
    RAW_CAMPAIGNS = 'rawCampaigns',
    FILTER_DEFINITIONS_BY_ID = 'filterDefinitionsById',
    TRIGGER_DEFINITIONS_BY_ID = 'triggerDefinitionsById',
    OPERATOR_STATS = 'operatorStats',
    TOTAL_USERS = 'totalUsers',
    CAMPAIGN_CARRIER_APPS = 'campaignCarrierApps',
    CAMPAIGN_PRIORITIES = 'campaignPriorities',
    CAMPAIGN_CATEGORIES = 'campaignCategories',
    RAW_CAMPAIGN_CATEGORIES = 'rawCampaignCategories',
    QUIET_HOURS = 'quietHours',

    // CEP user groups
    STATIC_FILTERS_COUNT_STATS = 'staticFiltersCountStats',

    // CEP events
    EVENT_REQUIRED_PROPERTIES = 'requiredPropertyFields',
    EVENT_PROPERTY_TYPES = 'eventPropertyTypes',
    ALL_EVENTS = 'allEvents',
    ALL_EVENTS_API_RESPONSE = 'allEventsApiResponse',
    EVENT_DATA_SOURCES = 'eventDataSources',
    ENTITY_TRACKER_DATA = 'entityTrackerData',
    IS_EUT_DATA_LOADING = 'isEUTDataLoading',

    // CEP events configs
    ORD_CONFIGS = 'reportsConfigs',
    ORD_CONFIGS_API_RESPONSE = 'reportsConfigsApiResponse',
    ORD_CONFIGS_SETTINGS = 'reportsConfigsSettings',
    REDSHIFT_CONFIGS = 'redshiftConfigs',
    REDSHIFT_CONFIGS_API_RESPONSE = 'redshiftConfigsApiResponse',
    REDSHIFT_CONFIGS_SETTINGS = 'redshiftConfigsSettings',
    REDSHIFT_REQUIRED_PROPERTIES = 'redshiftRequiredProperties',
    KAFKA_SINK_CONFIGS = 'kafkaConfigs',
    EVENT_COMPOSITION_CONFIGS = 'eventCompositionConfigs',
    KAFKA_SINK_CONFIGS_API_RESPONSE = 'kafkaConfigsApiResponse',
    KAFKA_SINK_CONFIGS_SETTINGS = 'kafkaConfigsSettings',
    API_INVOKER_SINK_CONFIGS = 'apiInvokerSinkConfigs',
    API_INVOKER_SINK_CONFIGS_API_RESPONSE = 'apiInvokerSinkConfigsApiResponse',
    EVENT_COMPOSITION_CONFIGS_API_RESPONSE = 'eventCompositionConfigsApiResponse',
    API_INVOKER_SINK_CONFIGS_SETTINGS = 'apiInvokerConfigsSettings',
    EVENT_COMPOSITION_CONFIGS_SETTINGS = 'eventCompositionConfigsSettings',
    SINK_FORMATTERS = 'sinkFormatters',
    SINK_TYPE_CASTERS = 'sinkTypeCasters',
    REDSHIFT_SINK_TYPE_CASTERS = 'redshfitSinkTypeCasters',
    REDSHIFT_FORMATTERS = 'redshiftFormatters',
    JSON_PATH_OPTIONS = 'jsonPathOptions',

    // CEP Template Helpers
    TEMPLATE_HELPERS_TTL = 'templateHelpersTTL',
    TEMPLATE_HELPERS_FORMATTERS = 'templateHelpersFormatters',

    // Users
    USERS = 'users',

    // Roles
    ROLE_STATE = 'roleState',

    // Charging
    POLICY_COUNTERS = 'policyCounters',
    POLICY_COUNTERS_API_RESPONSE = 'policyCountersApiResponse',
    POLICY_COUNTER_BY_VERSION = 'policyCounterByVersion',
    POLICY_RULES = 'policyRules',
    POLICY_RULES_API_RESPONSE = 'policyRulesApiResponse',
    POLICY_RULE_BY_VERSION = 'policyRuleByVersion',
    AFFECTED_BY_POLICY_COUNTER = 'affectedByPolicyCounter',
    CHARGING_SPECIFICATIONS = 'chargingSpecifications',
    CHARGING_SPECIFICATIONS_API_RESPONSE = 'chargingSpecificationsApiResponse',
    CHARGING_SPECIFICATION_BY_VERSION = 'chargingSpecificationByVersion',
    CHARGING_SPECIFICATIONS_GROUPS = 'chargingSpecificationsGroups',
    CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE = 'chargingSpecificationsGroupsApiResponse',
    RATING_GROUPS = 'ratingGroups',
    RATING_GROUPS_API_RESPONSE = 'ratingGroupsApiResponse',
    RATING_GROUP_BY_VERSION = 'ratingGroupByVersion',
    USAGE_COUNTERS = 'usageCounters',
    USAGE_COUNTERS_API_RESPONSE = 'usageCountersApiResponse',
    USAGE_COUNTER_BY_VERSION = 'usageCounterByVersion',
    CONDITION_PARAMETERS = 'conditionParameters',
    CONDITION_PARAMETERS_API_RESPONSE = 'conditionParametersApiResponse',
    CONDITION_PARAMETER_BY_VERSION = 'conditionParameterByVersion',
    WALLET_COUNTERS = 'walletCounters',
    WALLET_COUNTERS_API_RESPONSE = 'walletCountersApiResponse',
    WALLET_COUNTER_BY_VERSION = 'walletCounterByVersion',
    CONTENT_TYPES = 'contentTypes',
    CONTENT_TYPES_API_RESPONSE = 'contentTypesApiResponse',
    CONTENT_TYPE_BY_VERSION = 'contentTypeByVersion',
    TARIFF_SPECIFICATIONS = 'tariffSpecifications',
    TARIFF_SPECIFICATIONS_API_RESPONSE = 'tariffSpecificationsApiResponse',
    TARIFF_SPECIFICATION_BY_VERSION = 'tariffSpecificationByVersion',
    TARIFF_SPECIFICATION_GROUPS = 'tariffSpecificationGroups',
    TARIFF_SPECIFICATION_GROUPS_API_RESPONSE = 'tariffSpecificationGrouApiResponse',

    // Content Hosting
    ITEMS_BY_TYPE = 'itemsByType',

    // ESIM
    ESIM_DATA = 'esimData',

    // Promotions
    PROMOTIONS = 'promotions',
    PROMOTIONS_API_RESPONSE = 'promotionsApiResponse',

    // Orders
    ORDERS = 'orders',
    ORDERS_API_RESPONSE = 'ordersApiResponse',
    OMDEVEDGE_ORDERS = 'omdevedgeorders',
    DELIVERIES = 'deliveries',
    DELIVERIES_API_RESPONSE = 'deliveriesApiResponse',

    // ORCHESTRATION ENGINE
    OE_PLANS = 'OEplans',
    OE_PLANS_API_RESPONSE = 'OEplansApiResponse',
    OE_EXECUTIONS = 'OEexecutions',
    OE_EXECUTIONS_API_RESPONSE = 'OEexecutionsApiResponse',
    OE_EXECUTION = 'OEExecution',
    OE_EXECUTION_API_RESPONSE = 'OEExecutionApiResponse',
    OE_EXECUTION_OBJECT = 'executionObject',
    OE_TEMPLATES = 'templates',
    OE_TEMPLATES_API_RESPONSE = 'templatesApiResponse',
    OE_MAPPED_EXECUTIONS = 'OEMappedExecutions',
    OE_MAPPED_EXECUTIONS_API_RESPONSE = 'OEMappedExecutionsApiResponse',
    OE_PAUSED_EXECUTIONS = 'OEpausedExecutions',
    OE_PAUSED_EXECUTIONS_API_RESPONSE = 'OEpausedExecutionsApiResponse',
    OE_BATCH_ACTIONS = 'OEbatchActions',
    OE_BATCH_ACTIONS_API_RESPONSE = 'OEbatchActionsApiResponse',

    // REWARDS
    REWARDS_ENTITIES_BY_TYPE = 'rewardsEntitiesByType',
    REWARDS_ENTITIES_API_RESPONSE_BY_TYPE = 'rewardsEntitiesApiResponseByType',

    // OPERATORS
    Languages = 'languages',

    // Document
    DOCUMENTS = 'documentes',
    DOCUMENTS_API_RESPONSE = 'documentesApiResponse',
    DOCUMENT_TEMPLATES = 'documentTemplates',
    DOCUMENT_TEMPLATES_API_RESPONSE = 'documentTemplatesApiResponse',
    DOCUMENT_ASSETS = 'documentAssets',
    DOCUMENT_ASSETS_API_RESPONSE = 'documentAssetsApiResponse',

    // Dataflows
    DATAFLOW_TEMPLATES = 'dataflowTemplates',
    DATAFLOW_TEMPLATE_UI_MODEL = 'dataflowTemplateUiModel',

    // Authorization
    AUTH_TARGET_EMAILS = 'authTargetEmails',
    AUTH_TARGET_MSISDNS = 'authTargetMsisdn',
    AUTH_TOKEN_1FA = 'authToken1fa',
    AUTH_2FA_SUCCESSFUL = 'auth2faSuccessful',
    AUTH_USER_ID_FOR_AUTH_STATE_MAPPING = 'authUserIdForAuthStateMapping',
    AUTH_AWS_CONNECT_CONTACT_ID = 'authAwsConnectContactId',

    // Shared file locations
    SHARED_FILE_LOCATIONS = 'sharedFileLocations',
    SHARED_FILE_LOCATIONS_API_RESPONSE = 'sharedFileLocationsApiReponse',
}

export default Actions;
