
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { cloneDeep } from 'lodash';
import { getAppointmentStateLabel } from '@/__new__/features/customerCare/common/appointmentsHelper';
import { ICON_TYPES } from '@/common/iconHelper';

// HTTP
import appointmentsHTTP from '@/http/appointments';

export default {
    name: 'AppointmentsNotesTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            account: null,

            // proxy
            ICON_TYPES,
        };
    },
    computed: {
        formattedEntities() {
            const appointmentsInfoCopy = cloneDeep(this.rawResponse);
            const appointments = appointmentsInfoCopy?.data?.appointments;
            if (appointments?.length) {
                return appointments.map(item => ({
                    id: item.appointment_id,
                    activityType: item.activity_type,
                    datetime: this.$localeLibrary.getFormattedDateAndTime(item.time_slot.end_time),
                    status: this.$i18n.t(getAppointmentStateLabel(item.state)),
                    nestedRows: item.notes?.length ? this.mapNotes(item.notes) : [],
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.appointmentsSection.appointmentType'),
                    key: 'activityType',
                },
                {
                    name: this.$i18n.t('generic.dateAndTime'),
                    key: 'datetime',
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                },
                {
                    name: this.$i18n.t('appointments.noteType'),
                    key: 'type',
                },
                {
                    name: this.$i18n.t('appointments.noteText'),
                    key: 'text',
                },
                {
                    name: this.$i18n.t('appointments.noteDateAndTime'),
                    key: 'createdOn',
                },
                {
                    name: this.$i18n.t('appointments.noteCreatedBy'),
                    key: 'createdBy',
                },
            ];
        },
        activityType() {
            return this.appointmentsModalData.type?.value || this.appointmentsModalData.type;
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.rawResponse = await appointmentsHTTP.getAppointments({
                        targetId: this.$route.params.id,
                        targetType: this.userManagerHierarchy,
                    });
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectedRow(entity: object): void {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
        mapNotes(notes) {
            return notes.map(({ type, text, created_on: createdOn, created_by: createdBy }) => ({
                type,
                text,
                createdOn: this.$localeLibrary.getFormattedDateAndTime(createdOn),
                createdBy,
            }));
        },
    },
};
