<template>
    <SinkConfigDashboard
        :entityType="ENTITY_TYPES.API_INVOKER_SINK_CONFIG"
        :pageTitle="$i18n.t('generic.apiDestinations')"
        :entities="configsFormatted"
        :isDataLoading="isDataLoading"
        :entitiesApiResponse="configsApiResponse"
        :editRouteName="RouteNames.API_INVOKER_CONFIG_EDITOR"
        :uiParams="apiInvokerUIParams"
        :writePermission="'APIDestinationsWrite'"
        @fetchConfigs="fetchConfigs"
        @deleteConfig="config => confirmConfigDeletion(config, Actions.DELETE_API_INVOKER_SINK_CONFIG)"
        @details="onDetails"
    >
        <template #details-modal>
            <SinkConfigDialog
                v-model="isDetailsModalOpen"
                :jsonData="jsonData"
                :yamlData="yamlData"
                @input="onClose"
            />
        </template>
    </SinkConfigDashboard>
</template>

<script>
// vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';

// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
import SinkConfigDialog from '@/__new__/features/sinkConfigs/SinkConfigDialog.vue';

// http
import { getJobStaticExportById } from '@/__new__/services/dno/sinkConfigs/http/apiInvokerConfig';

// helpers
import RouteNames from '@/router/routeNames';
import { EntityActions } from '@/common/baseStatesHelper';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import * as Sentry from '@sentry/vue';
import { isDocumentTemplateJobId } from '@/common/documentsHelper';

export default {
    name: 'ApiInvokerConfigDashboard',
    components: { SinkConfigDashboard, SinkConfigDialog },
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            Actions,
            ENTITY_TYPES,
            RouteNames,
            isDataLoading: false,
            isDetailsModalOpen: false,
            jsonData: null,
            yamlData: '',
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            configs: Getters.GET_API_INVOKER_SINK_CONFIGS,
            configsApiResponse: Getters.GET_API_INVOKER_SINK_CONFIGS_API_RESPONSE,
            apiInvokerUIParams: Getters.GET_API_INVOKER_SINK_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        configsFormatted() {
            if (this.configs.length) {
                return this.configs.map(c => ({
                    ...c,
                    allowedActionsExternal: this.getAllowedActions(c.id),
                }));
            }
            return [];
        },
    },
    created() {
        this.isDataLoading = true;
        this.$Progress.start();
        const mainPromises = [
            this.fetchEvents(),
            this.fetchSinkFormatters(),
            this[Actions.LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS](),
            this.fetchTriggers(),
        ];

        if (!this.segments.length) {
            mainPromises.push(this.fetchSegments());
        }

        Promise.all(mainPromises)
            .then(() => {
                this.fetchConfigs().then(() => {
                    this.$Progress.finish();
                    this.isDataLoading = false;
                });
            })
            .catch(error => {
                this.$alert(this.$i18n.t('events.alerts.failedToLoadConfigs'));
                this.$Progress.fail();
                Sentry.captureException(error);
                this.isDataLoading = false;
            });
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_API_INVOKER_SINK_CONFIGS,
            Actions.LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS,
            Actions.DELETE_API_INVOKER_SINK_CONFIG,
        ]),
        fetchConfigs() {
            return this[Actions.LOAD_API_INVOKER_SINK_CONFIGS]();
        },
        getAllowedActions(jobId) {
            const actions = [EntityActions.DETAILS];

            if (!isDocumentTemplateJobId(jobId)) {
                actions.push(EntityActions.EDIT, EntityActions.DELETE);
            }

            return actions;
        },
        onDetails(config) {
            this.getJobAsYaml(config?.job_definition?.id);
            this.jsonData = config;
            this.isDetailsModalOpen = true;
        },
        getJobAsYaml(id) {
            return this.$withProgressBar(
                async () => {
                    if (this.yamlData) {
                        return;
                    }

                    const { data: yamlData } = await getJobStaticExportById(id);
                    this.yamlData = yamlData?.data?.toString();
                },
                {
                    errorHandler: () => {
                        this.yamlData = this.$t('generic.error');
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
        onClose() {
            this.yamlData = '';
            this.jsonData = null;
        },
    },
};
</script>
