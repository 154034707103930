import EventProp from '@/__new__/services/dno/events/models/EventProp';
import { isEmpty, toPairs, values } from 'lodash';
import { Event } from '@/__new__/services/dno/events/models/Event';

export class EventCompositionKey {
    eventName: string;
    eventType: string;
    selectedProperty: EventProp;
    allProperties: EventProp[];
    constructor(eventName: string, eventType: string, selectedProperty: EventProp, allProperties: EventProp[]) {
        this.eventName = eventName;
        this.eventType = eventType;
        this.selectedProperty = selectedProperty;
        this.allProperties = allProperties;
    }
}

export function eventCompositionFromJson(compositionKeysBe: any, events: Event[]) {
    return compositionKeysBe.map((key: any) => {
        const keyFieldsObj = values(key)[0];
        return toPairs(keyFieldsObj as Record<string, string>).map(([eventType, selectedProperty]) => {
            const relatedEvent = events.find(ev => ev.id === eventType);
            if (relatedEvent) {
                const relatedProperty = relatedEvent.properties?.find(p => p.name === selectedProperty);
                return new EventCompositionKey(
                    relatedEvent.name as string,
                    eventType,
                    relatedProperty,
                    relatedEvent.properties,
                );
            }
        });
    });
}
export function eventCompositionToJson(compositionKeys: EventCompositionKey[][]) {
    if (isEmpty(compositionKeys)) return {};
    const mappedData = compositionKeys.map((compositionKey, idx) => ({
        [`key_${idx + 1}`]: compositionKey.reduce((acc: Record<string, string>, keyField) => {
            acc[keyField.eventType] = keyField.selectedProperty.name;
            return acc;
        }, {}),
    }));
    return {
        composition_key: mappedData,
    };
}
export function mapEventCompositionForOverview(eventComposition: EventCompositionKey[][]) {
    return eventComposition.map((key: EventCompositionKey[], keyIdx: number) => ({
        isCollapsed: true,
        name: `key_${keyIdx + 1}`,
        rows: key.map(keyField => ({
            name: keyField.eventType,
            value: keyField.selectedProperty.name,
        })),
    }));
}
