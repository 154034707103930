
// Types
import { PropType } from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';

// HTTP
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

interface ChannelPartnerToCsgMapperPropsData {
    csgId: string;
    chargingModelEnum: string | number;
    channelPartnerId: string;
}

export default {
    name: 'ChannelPartnerToChargingSpecGroupMapper',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object as PropType<ChannelPartnerToCsgMapperPropsData>,
            default: () => ({
                csgId: '',
                chargingModelEnum: '',
                channelPartnerId: '',
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TOOLTIP_POSITION,
            channelPartnerOptions: [],
            selectedChannelPartner: '',
            selectedCSG: '',
            selectedMonetizationType: '',

            CSG_API_SUB_TYPES: {
                REGULAR: {
                    value: 1,
                    label: this.$i18n.t('productCatalog.chargingSpecifications.monetizationSubTypes.regular'),
                },
                DURATION: {
                    value: 2,
                    label: this.$i18n.t('productCatalog.chargingSpecifications.monetizationSubTypes.durationBased'),
                },
                DATA_USED: {
                    value: 3,
                    label: this.$i18n.t('productCatalog.chargingSpecifications.monetizationSubTypes.dataUsed'),
                },
                MIXED: {
                    value: 100,
                    label: this.$i18n.t('productCatalog.chargingSpecifications.monetizationSubTypes.mixed'),
                },
            },
        };
    },
    computed: {
        ...mapGetters('charging', [Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]),
        csgsListToDisplay() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]
                .filter((csg: any) => csg.data.channel_partner_id === this.selectedChannelPartner)
                .filter((filteredCsg: any) => filteredCsg.api_sub_type === this.selectedMonetizationType)
                .map((csg: any) => ({
                    id: csg.id,
                    name: csg.data.name.en,
                }));
        },
        monetizationTypesOptions() {
            return Object.values(this.CSG_API_SUB_TYPES);
        },
    },
    async created() {
        await Promise.all([
            this.fetchChannelPartners(),
            this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS]({ includeApiSubType: true }),
        ]);
        this.setInitialData();
    },
    methods: {
        ...mapActions('charging', [Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS]),
        async fetchChannelPartners() {
            await this.$withLoadingSpinner(
                async () => {
                    const response = await getPartyRoles('ChannelPartner');

                    this.channelPartnerOptions = response?.data?.map((channelPartner: any) => ({
                        id: channelPartner?.id || '',
                        name: channelPartner?.name || '',
                    }));
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        emitValue() {
            this.$emit('input', {
                channel_partner_id: this.selectedChannelPartner,
                charging_model_enum: this.selectedMonetizationType,
                charging_spec_group_id: this.selectedCSG,
            });
        },
        setInitialData() {
            this.selectedChannelPartner =
                this.channelPartnerOptions.find(cpOption => cpOption.id === this.value.channelPartnerId)?.id || '';
            this.selectedMonetizationType =
                this.monetizationTypesOptions.find(
                    monetizationTypeOption => monetizationTypeOption.value === this.value.chargingModelEnum,
                )?.value || '';
            this.selectedCSG = this.csgsListToDisplay.find(csg => csg.id === this.value.csgId)?.id || '';
            this.emitValue();
        },
    },
};
