
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';

export default {
    name: 'CampaignDeliveryAnalytics',
    components: { AppMultiselectV3 },
    props: {
        campaignId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            grafanaChartTimeRange: '',
            timeRangeOptions: ['1h', '6h', '24h', '7d', '30d'],
        };
    },
    computed: {
        ...mapGetters({
            graphanaHost: `${Modules.config}/getGrafanaHost`,
        }),
    },
};
