<template>
    <EntityOverview
        :entityType="entityType"
        :entity="sinkConfig"
        @closeOverview="$emit('closeOverview')"
    >
        <div
            :slot="`section-1-header`"
            class="mb-3"
        >
            <OverviewHeaderV2
                v-if="sinkConfig && sinkConfig.name"
                class="mt-3"
                :entityName="sinkConfig.name"
                :entityId="sinkConfig.id"
            >
                <template slot="statusIndicator">
                    <SinkConfigStatusIndicator :status="sinkConfig.state" />
                </template>
            </OverviewHeaderV2>
        </div>
        <div
            v-if="sinkConfig && sinkConfig.fieldsConfiguration"
            :slot="`section-1-content`"
        >
            <!-- advanced config-->
            <div class="block">
                <AppOverviewBlock
                    :isRowTitleCounterVisible="false"
                    :maxItems="99"
                    :maxItemRows="99"
                    :items="mappedAdvancedConfig"
                />

                <AppCollapsibleList
                    v-if="sharedFileLocationData"
                    :items="sharedFileLocationData"
                    :isRowTitleCounterVisible="false"
                    :isTitleCounterVisible="false"
                    class="mt-2"
                >
                    <template #sharedLocationLink>
                        <router-link
                            :to="{
                                name: RouteNames.SHARED_FILE_LOCATIONS_DASHBOARD,
                                query: { id: sinkConfig.id },
                            }"
                            target="_blank"
                            class="heading-xs"
                        >
                            {{ $t('table.more') }}
                        </router-link>
                    </template>
                </AppCollapsibleList>
            </div>

            <!-- filters block -->
            <FiltersRenderer
                :popupTitle="sinkConfig.name"
                :title="conditionsAppliedText"
                :filters="triggerFilters"
                :conditionDefinitionsById="conditionDefinitionsById"
                topLevelCombinator="or"
                class="block"
            />

            <SinkConfigCohortsOverview
                v-if="!isEmpty(sinkConfig.cohortsData)"
                class="block"
                :cohortsDataByEventId="sinkConfig.cohortsData"
            />
            <!-- event composition-->
            <AppOverviewBlock
                v-if="!isEmpty(sinkConfig.eventCompositionKeys)"
                :isRowTitleCounterVisible="false"
                class="block mb-4"
                :title="$t('sinkConfigs.eventComposition.keysOverview')"
                :maxItemRows="100"
                :maxItems="100"
                :items="eventCompositionOverview"
            />

            <!-- entity tracker-->
            <EntityTracker
                v-if="isTrackingEnabledForEntityType"
                :popupTitle="sinkConfig.name"
                :entityType="serverEntityType"
                :entityId="sinkConfig.id"
                class="block"
            />
            <!-- columns-->
            <AppOverviewBlock
                :isRowTitleCounterVisible="false"
                :popupTitle="sinkConfig.name"
                class="block mb-4"
                :title="columnsOverviewTitle"
                :items="mappedColumnsConfig"
            />
        </div>
    </EntityOverview>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// components
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import FiltersRenderer from '@/components/partials/filters/FiltersRenderer.vue';
import SinkConfigStatusIndicator from '@/__new__/features/sinkConfigs/SinkConfigStatusIndicator.vue';
import AppCollapsibleList from '@/components/partials/AppCollapsibleList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { entityTypeDisplayName } from '@/common/utils';
import EntityTracker from '@/components/entityTracker/EntityTracker.vue';
import { EntityType } from '@/components/entityTracker/entityTracker';
import { mapColumnsForOverview } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { transformSpecificConfigLabel, isAdditionalConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { getBeautifulBoolean } from '@/common/formatting';
import SinkConfigCohortsOverview from '@/__new__/features/sinkConfigs/SinkConfigCohortsOverview.vue';
import { isEmpty } from 'lodash';
import RouteNames from '@/router/routeNames';
import { formatCronValueToText } from '@/common/timeFrequencyHelper';
import { mapEventCompositionForOverview } from '@/__new__/services/dno/sinkConfigs/EventCompositionConfig';

/** Some entity types are incorrectly mapped in front enum. Only different types mentioned here. */
const ToServerEntityType = {
    [ENTITY_TYPES.REDSHIFT_CONFIG]: EntityType.DWHConfig,
    [ENTITY_TYPES.EVENT]: EntityType.CustomEvent,
};

const EntitiesWithTrackingEnabled = [EntityType.DWHConfig];

export default {
    name: 'SinkConfigOverview',
    components: {
        SinkConfigCohortsOverview,
        AppCollapsibleList,
        AppOverviewBlock,
        EntityTracker,
        EntityOverview,
        OverviewHeaderV2,
        FiltersRenderer,
        SinkConfigStatusIndicator,
    },
    props: {
        uiParams: {
            required: true,
            default: () => undefined,
            type: Object,
        },
        sinkConfig: {
            type: Object,
            default: null,
        },
        entityType: {
            type: String,
            default: ENTITY_TYPES.ORD_CONFIG,
        },
        conditionDefinitionsById: { type: Object, default: () => undefined },
    },
    data() {
        return {
            ENTITY_TYPES,
            RouteNames,
        };
    },
    computed: {
        ...mapGetters(Modules.sharedFileLocations, {
            getSharedFileLocationFromConfig: Getters.GET_GET_SHARED_FILE_LOCATION_BY_ID,
        }),
        eventCompositionOverview() {
            return mapEventCompositionForOverview(this.sinkConfig.eventCompositionKeys);
        },
        advancedConfigTitle() {
            return entityTypeDisplayName(this.entityType);
        },
        columnsOverviewTitle() {
            return `${entityTypeDisplayName(this.entityType)} ${this.$i18n.t('generic.fields').toLowerCase()}`;
        },
        triggerFilters() {
            return this.sinkConfig?.filters || [];
        },
        mappedAdvancedConfig() {
            return this.mapAdvancedConfig(this.sinkConfig);
        },
        mappedColumnsConfig() {
            return mapColumnsForOverview(this.sinkConfig?.fieldsConfiguration);
        },
        conditionsAppliedText() {
            const conditionsAmount = this.triggerFilters.length;
            const conditionWord =
                conditionsAmount === 1
                    ? this.$i18n.t('productCatalog.services.editor.condition')
                    : this.$i18n.t('productCatalog.chargingSpecifications.conditions');
            return `${conditionWord} ${this.$i18n.t('generic.applied')}`;
        },
        serverEntityType() {
            return ToServerEntityType[this.entityType] || this.entityType;
        },

        isTrackingEnabledForEntityType() {
            return EntitiesWithTrackingEnabled.includes(this.serverEntityType);
        },
        sharedFileLocationData() {
            if (this.getSharedFileLocationFromConfig(this.sinkConfig.id)) {
                return [
                    {
                        name: this.$t('pipelines.sharedFileLocations.distibutedToSftp'),
                        rows: [
                            {
                                name: this.$t('pipelines.sharedFileLocations.remoteLocation'),
                                value: this.sinkConfig.id,
                                slotKey: 'sharedLocationLink',
                            },
                        ],
                        isCollapsed: true,
                    },
                ];
            }

            return false;
        },
    },
    methods: {
        isEmpty,
        mapAdvancedConfig(sinkConfig) {
            if (!sinkConfig?.specificConfig) return [];
            const specificConfigArray = Object.entries(sinkConfig.specificConfig);

            const configs = [{ name: this.advancedConfigTitle, rows: [] }];

            if (specificConfigArray.some(([name]) => isAdditionalConfig(name, this.uiParams))) {
                configs.push({
                    name: this.$t('events.advancedConfiguration'),
                    rows: [],
                    isCollapsed: true,
                });

                return specificConfigArray.reduce(([mainConfig, advancedConfig], [label, value]) => {
                    const config = isAdditionalConfig(label, this.uiParams) ? advancedConfig : mainConfig;

                    config.rows.push(this.formatAdvancedConfigItem(label, value));

                    return [mainConfig, advancedConfig];
                }, configs);
            }

            configs[0].rows = specificConfigArray.map(conf => this.formatAdvancedConfigItem(...conf, this.uiParams));

            return configs;
        },
        formatAdvancedConfigItem(label, val) {
            return {
                name: transformSpecificConfigLabel(label, this.uiParams),
                value: label !== 'cron_expression' ? this.formatAdvancedConfigValue(val) : formatCronValueToText(val),
            };
        },
        formatAdvancedConfigValue(value) {
            let v = value;
            if (typeof v === 'boolean' || [String(true), String(false)].includes(v)) {
                if (typeof v !== 'boolean') {
                    v = v === String(true);
                }

                return getBeautifulBoolean(v);
            }

            return v || this.$t('generic.N/A');
        },
    },
};
</script>
<style scoped lang="scss">
@import '~@/assets/scss/consistency';

.block {
    margin-top: $spacing-xl;
    margin-right: $spacing-l;
}
</style>
