import Router from 'vue-router';
import routes from './routes';
import eventBus from '@/eventBus';
import i18n from '@/i18n';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Account, { userState } from '@/models/Account';
import RouteNames from '@/router/routeNames';
import RouteWrapper from '@/components/layout/RouteWrapper.vue';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

const router = new Router({
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
    routes: [
        {
            path: '/:companyId',
            component: RouteWrapper,
            children: routes,
        },
    ],
});

const originalPush = Router.prototype.push;

/* Overriding push action */
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => {
        if (Router.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

router.beforeEach(async (to, from, next) => {
    if (!Account.authenticated()) {
        if (to.path === '/') {
            next();
            return;
        }
        // Save the URL user was trying to reach so they can be redirected there after authentication
        sessionStorage.setItem('post-auth-redirect-uri', to.path);

        // TODO: Change to redirect to a 404 page.
        next('/');
        return;
    }

    // If we're authenticated and we're directly hitting the root path, there's
    // a possibility that we will not yet know the user's default company (which
    // is loaded within App.vue in the created() lifecycle hook).
    //
    // The path will be updated to "/<company_id>" once the account data has
    // finished loading within the created() hook and we know the default company.
    //
    if (to.path === '/') {
        next();
        return;
    }

    // TODO: Change to redirect to a 404 page.
    if (router.resolve(to.path).resolved.matched.length === 0) {
        next(`/${Account.storedInstance.companyId}`);
        return;
    }

    // TODO: Change to redirect to a 404 page.
    if (to.params.companyId && isNaN(to.params.companyId)) {
        next(`/${Account.storedInstance.companyId}`);
        return;
    }

    next();
});

router.beforeResolve((to, from, next) => {
    const authenticated = Account.authenticated();

    if (!authenticated) {
        if (to.name !== RouteNames.LOGIN) {
            next({ name: RouteNames.LOGIN });
        }
        next();
    }

    if (!to.name && sessionStorage.getItem('post-auth-redirect-uri')) {
        const postAuthRedirectUri = sessionStorage.getItem('post-auth-redirect-uri');
        sessionStorage.removeItem('post-auth-redirect-uri');
        next(postAuthRedirectUri);
    } else if (to.name === RouteNames.LOGIN) {
        next({ name: RouteNames.HOME_VIEW });
    } else if (Account.getUserState() === userState.PENDING && to.name !== RouteNames.ACCOUNT_VIEW) {
        next({ name: RouteNames.ACCOUNT_VIEW });
    } else if (getOperatorConfigValue('disableOldUM', false)) {
        const redirectMapping = {
            [RouteNames.CUSTOMER_CARE_USER_MANAGEMENT]: RouteNames.CCS_SEARCH,
            [RouteNames.USER_MANAGEMENT_USER_TABLE]: RouteNames.CCS_USER_RESULTS_TABLE,
            [RouteNames.USER_MANAGEMENT_SUBSCRIBER_V2]: RouteNames.CCS_SUBSCRIBER_PAGE,
            [RouteNames.USER_MANAGEMENT_USER_V2]: RouteNames.CCS_USER_PAGE,
            [RouteNames.USER_MANAGEMENT_ACCOUNT_V2]: RouteNames.CCS_ACCOUNT_PAGE,
            [RouteNames.USER_MANAGEMENT_ORGANIZATION]: RouteNames.CCS_ORGANIZATION_PAGE,
        };

        if (Object.keys(redirectMapping).includes(to.name)) {
            next({ name: redirectMapping[to.name], ...(to?.params && { params: { ...to?.params } }), replace: true });
        } else {
            next();
        }
    } else {
        const { permissionFunc } = to.meta;

        if (!permissionFunc || (permissionFunc && permissionFunc())) {
            next();
        } else {
            eventBus.$emit('showAlert', {
                message: i18n.t('generic.youDontHavePermissions'),
                type: ALERT_TYPES.warning,
            });
        }

        next();
    }
});

export default router;
