export enum LAYOUT_POSITION_DIRECTION {
    UP = 'up',
    DOWN = 'down',
}

export enum LAYOUT_TILE_SIZES {
    XLARGE = 'xlarge',
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    THIRD = 'third',
    VERTICAL = 'vertical',
}
