import Vue, { VueConstructor } from 'vue';
import { TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import VueRouter, { Route } from 'vue-router';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';

interface AlertOptions {
    message: string | TranslateResult;
    type: ALERT_TYPES;
    buttons?: Button[];
    description?: string;
    keepSuccessAlertVisible?: boolean;
}

export default Vue as VueConstructor<
    Vue & {
        $alert(message: string | TranslateResult, options?: Omit<AlertOptions, 'message'>): void;
        $ALERT_TYPES: ALERT_TYPES;
        $eventBus: Omit<Vue, '$eventBus'>;
        $i18n: {
            t: (key: string, replacements?: Record<string, any>) => string;
            tc: (key: string, count: number) => string;
        };
        $localeLibrary: typeof localeLibrary;
        $Progress: {
            start: () => void;
            finish: () => void;
            fail: () => void;
        };
        $router: VueRouter;
        $route: Route;
        $showErrorAlert(options?: Omit<AlertOptions, 'type'>): void;
        $showInfoAlert(options?: Omit<AlertOptions, 'type'>): void;
        $showSuccessAlert(options?: Omit<AlertOptions, 'type'>): void;
        $showWarningAlert(options?: Omit<AlertOptions, 'type'>): void;
        $t: (key: string, replacements?: Record<string, any>) => string;
        $tc: (key: string, count: number) => string;
        $v: {
            [key: string]: any;
        };
        $withLoadingSpinner(
            asyncFunction: () => Promise<any>,
            options?: { errorHandler?: (e: any) => void },
        ): Promise<void>;
        $withProgressBar(
            asyncFunction: () => Promise<any>,
            options?: {
                errorHandler?: (e: any) => void;
                successHandler?: () => void;
            },
        ): Promise<void>;
    }
>;

export { type PropType } from 'vue';
