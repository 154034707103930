import http from './index';
import { getDnoClaimsHeader } from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    APPT_CANCELLATION_CONTEXT,
    APPT_TYPE,
    APPT_TIMESLOT_BE,
    APPT_SOURCE,
} from '@/__new__/features/customerCare/common/appointmentsHelper';

export const getAppointments = ({
    targetId,
    targetType,
    filters,
}: {
    targetId: string;
    targetType: number;
    filters?: any;
}) => {
    return http({
        method: 'POST',
        url: '/v3appointment/getappointments',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(filters && { filters }),
        },
    });
};

export const getAppointmentHistory = ({
    targetId,
    targetType,
    appointmentId,
}: {
    targetId: string;
    targetType: number;
    appointmentId: string;
}) => {
    return http({
        method: 'POST',
        url: '/v3appointment/getappointmenthistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            appointment_id: appointmentId,
        },
    });
};

export const getAvailableTimeslots = ({
    targetId,
    targetType,
    type,
}: {
    targetId: string;
    targetType: number;
    type: APPT_TYPE;
}) =>
    http({
        method: 'POST',
        url: '/v3appointment/getavailabletimeslots',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            activity_type: type,
        },
    });

export const reserveAppointment = ({
    targetId,
    targetType,
    activityType,
    timeFrom,
    timeTo,
    timeSlotId,
}: {
    targetId: string;
    targetType: number;
    activityType: APPT_TYPE;
    timeFrom: string;
    timeTo: string;
    timeSlotId?: string;
}) =>
    http({
        method: 'POST',
        url: '/v3appointmentwrite/reserveappointment',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            time_slot: {
                start_time: timeFrom,
                end_time: timeTo,
                ...(timeSlotId && { time_slot_id: timeSlotId }),
            },
            activity_type: activityType,
            appointment_source: APPT_SOURCE.PORTAL,
        },
    });

export const bookAppointment = ({
    targetId,
    targetType,
    reservationId,
    note,
    activityType,
}: {
    targetId: string;
    targetType: number;
    reservationId: string;
    note: string;
    activityType: APPT_TYPE;
}) =>
    http({
        method: 'POST',
        url: '/v3appointmentwrite/bookappointment',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            reservation_id: reservationId,
            memo_agent: note,
            activity_type: activityType,
        },
    });

export const updateAppointment = ({
    targetId,
    targetType,
    idOfEditedAppointment,
    timeSlot,
    note,
    isNotificationEnabled,
    activityType,
}: {
    targetId: string;
    targetType: number;
    activityType: APPT_TYPE;
    idOfEditedAppointment: string;
    timeSlot?: APPT_TIMESLOT_BE;
    note?: string;
    isNotificationEnabled?: string;
}) =>
    http({
        method: 'POST',
        url: '/v3appointmentwrite/updateappointment',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            appointment_id: idOfEditedAppointment,
            activity_type: activityType,
            ...(timeSlot && { time_slot: timeSlot }),
            ...(note && { memo_agent: note }),
            ...(isNotificationEnabled && {
                should_notify_customer: isNotificationEnabled,
            }),
        },
    });

export const deleteAppointment = ({
    targetId,
    targetType,
    appointmentId,
}: {
    targetId: string;
    targetType: number;
    appointmentId: string;
}) =>
    http({
        method: 'POST',
        url: '/v3appointmentwrite/cancelappointment',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            appointment_id: appointmentId,
            cancellation_context: APPT_CANCELLATION_CONTEXT.PORTAL,
        },
    });

export default {
    getAppointments,
    getAppointmentHistory,
    getAvailableTimeslots,
    reserveAppointment,
    bookAppointment,
    updateAppointment,
    deleteAppointment,
};
