
import Vue from 'vue';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';

// Models
import Organization from '@/__new__/services/dno/user/models/Organization';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default Vue.extend({
    name: 'OrganizationPageAnchor',

    components: {
        AppCustomerHeader,
    },
    data() {
        return {
            organization: new Organization({}),
        };
    },
    computed: {
        infoData() {
            return [
                {
                    title: this.$i18n.t('generic.details'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('customerCare.organizationId'),
                            value: this.organization.organizationId,
                        },
                        {
                            name: this.$i18n.t('customerCare.domain'),
                            value: this.organization.domain,
                        },
                        {
                            name: this.$i18n.t('customerCare.name'),
                            value: this.organization.name,
                        },
                    ],
                },
            ];
        },
    },
    async created() {
        const { id } = this.$route.params;
        if (id) {
            await this.fetchOrganizationById(id);
        }
    },
    methods: {
        async fetchOrganizationById(id) {
            await this.$withProgressBar(
                async () => {
                    const response = await customerCareHTTP.getOrganization(id, USER_MANAGER_HIERARCHY.ORGANIZATION);
                    const organizationInfo = response.data?.organization_info;
                    if (organizationInfo) {
                        this.organization = new Organization(Organization.remapOrganizationFromBe(organizationInfo));
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                    },
                },
            );
        },
    },
});
