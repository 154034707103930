<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template slot="content">
            <div class="default-page-content-wrapper px-4 mt-5">
                <h3 class="modal-title mt-4">
                    {{ $i18n.t('settings.companySettings') }}
                </h3>
                <span class="small-gray-note mb-4">{{ $i18n.t('settings.message.allUsers') }}</span>
                <h4 class="component-title-um my-3">
                    {{ $i18n.t('generic.general') }}
                </h4>
                <!-- TODO: uncomment after launching multilanguage -->
                <!-- <AppMultiselectV3 v-model="language"
                                  :options="Object.values(languages)"
                                  :allowEmpty="false"
                                  :preselectFirst="true"
                                  :small="true"
                                  :additionalLabel="$i18n.t('settings.language')"
                                  :error="$v.language.$error"
                                  label="name"
                                  trackBy="id"
                                  class="col-12 col-lg-6 mb-3"
                /> -->
                <AppMultiselectV3
                    v-model="timezonePicker"
                    :options="timezonesToDisplay"
                    :allowEmpty="false"
                    :preselectFirst="true"
                    :small="true"
                    :additionalLabel="$i18n.t('settings.timezone')"
                    :error="$v.timezonePicker.$error"
                    class="col-12 col-lg-6 mb-3"
                />
                <span class="section-toggle-label mx-1">{{ $i18n.t('settings.dateFormat') }}</span>
                <template v-if="dataLoaded">
                    <CardListWrapper
                        v-model="dateFormat"
                        :requiredError="$v.dateFormat.$error"
                        :cardsValues="Object.values(dateFormatTypes)"
                        :small="false"
                        class="col-12 col-lg-6 mt-1"
                    >
                        <template slot="cardBodyday_month_year">
                            <div class="d-flex justify-content-center align-items-center pt-2">
                                {{ dateFormatTypes.dayMonthYear.format }}
                            </div>
                            <span class="small-gray-note">{{ $i18n.t('generic.example') }}: 31-12-2020</span>
                        </template>
                        <template slot="cardBodymonth_day_year">
                            <div class="d-flex justify-content-center align-items-center pt-2">
                                {{ dateFormatTypes.monthDayYear.format }}
                            </div>
                            <span class="small-gray-note">{{ $i18n.t('generic.example') }}: 12-31-2020</span>
                        </template>
                        <template slot="cardBodyyear_month_day">
                            <div class="d-flex justify-content-center align-items-center pt-2">
                                {{ dateFormatTypes.yearMonthDay.format }}
                            </div>
                            <span class="small-gray-note">{{ $i18n.t('generic.example') }}: 2020-12-31</span>
                        </template>
                    </CardListWrapper>
                </template>
                <span class="section-toggle-label mx-1">{{ $i18n.t('settings.timeFormat') }}</span>
                <template v-if="dataLoaded">
                    <CardListWrapper
                        v-model="timeFormat"
                        :requiredError="$v.timeFormat.$error"
                        :cardsValues="Object.values(timeFormatTypes)"
                        :small="true"
                        class="col-12 col-lg-6 mt-1"
                    >
                        <template slot="cardBody12">
                            <div class="d-flex justify-content-center align-items-center pt-2">
                                {{ timeFormatTypes.twelveHour.id }} {{ $i18n.t('generic.hour') }}
                            </div>
                            <span class="small-gray-note">{{ $i18n.t('generic.example') }} 1:00 PM</span>
                        </template>
                        <template slot="cardBody24">
                            <div class="d-flex justify-content-center align-items-center pt-2">
                                {{ timeFormatTypes.twentyFourHour.id }} {{ $i18n.t('generic.hour') }}
                            </div>
                            <span class="small-gray-note">{{ $i18n.t('generic.example') }} 13:00</span>
                        </template>
                    </CardListWrapper>
                </template>
                <AppMultiselectV3
                    v-model="currency"
                    :options="Object.values(currencies)"
                    :allowEmpty="false"
                    :preselectFirst="true"
                    :small="true"
                    :additionalLabel="$i18n.t('settings.currency')"
                    :customLabel="nameWithCode"
                    :error="$v.currency.$error"
                    trackBy="code"
                    class="col-12 col-lg-6 mb-1"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                    >
                        <div class="d-flex justify-content-between">
                            <span>{{ option.name }}</span>
                            <span>{{ option.symbol }}</span>
                        </div>
                    </template>
                </AppMultiselectV3>
                <span class="small-gray-note mx-1">{{ $i18n.t('settings.message.wontConvert') }}</span>
                <h4 class="component-title-um my-3">
                    {{ $i18n.t('settings.preferences') }}
                </h4>
                <AppMultiselectV3
                    v-model="defaultUserSearchParam"
                    :options="searchOptions"
                    :allowEmpty="false"
                    :preselectFirst="true"
                    :small="true"
                    trackBy="key"
                    label="label"
                    :additionalLabel="$i18n.t('settings.defaultUserSearchParameter')"
                    class="col-12 col-lg-6 mb-3"
                />
                <AppButton
                    id="localization-save-button"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.save')"
                    :iconType="ICON_TYPES.CHECK"
                    @click="onSave"
                />
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';

// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import CardListWrapper from '@/components/partials/cards/CardListWrapper.vue';

// helper
import currencies from '@/common/currency';
import { getTenantUMSearchOptions } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { UserManagerSearchOptions } from '@/__new__/features/customerCare/common/userInfoHelper';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// libraries
import moment from 'moment';
import 'moment-timezone';
import { isEqual } from 'lodash';
import Locale from '@/models/Locale';
import Preferences from '@/models/Preferences';
import { dateFormatTypes, timeFormatTypes, languages } from '@/common/locale/localeLibrary';
import required from 'vuelidate/lib/validators/required';
import * as Sentry from '@sentry/vue';

export default {
    components: {
        AppMultiselectV3,
        AppButton,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
        CardListWrapper,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin, supportButtonMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            dateFormatTypes,
            timeFormatTypes,
            languages,
            activeLocaleSettings: null,
            activePreferencesSettings: null,
            dataLoaded: false,
            timezonePicker: null,
            language: null,
            dateFormat: null,
            timeFormat: null,
            currency: null,
            currencies,
            defaultUserSearchParam: null,
        };
    },
    validations() {
        return {
            timezonePicker: {
                required,
            },
            language: {
                id: required,
            },
            dateFormat: {
                id: required,
            },
            timeFormat: {
                id: required,
            },
            currency: {
                code: required,
            },
        };
    },
    computed: {
        timezonesToDisplay() {
            const timeZones = moment.tz.names();
            const offsetTmz = [];

            timeZones.forEach(el => offsetTmz.push(`(GMT${moment.tz(el).format('Z')}) ${el}`));

            const timeZoneNames = offsetTmz.sort();
            return timeZoneNames;
        },
        searchOptions() {
            return Object.values(getTenantUMSearchOptions()) || [];
        },
    },
    async created() {
        this.$Progress.start();
        try {
            const locale = await this.getTenantLocale();
            this.dataLoaded = true;
            // checking does cookies are metching the db
            if (!isEqual(locale, this.activeLocaleSettings)) {
                this.activeLocaleSettings = locale;
            }

            this.timezonePicker = this.activeLocaleSettings.timezone;
            this.dateFormat = Object.values(this.dateFormatTypes).find(
                d => d.format === this.activeLocaleSettings.dateFormat,
            );
            this.timeFormat = Object.values(this.timeFormatTypes).find(
                t => t.id === this.activeLocaleSettings.timeFormat,
            );
            this.currency = Object.values(this.currencies).find(c => c.code === this.activeLocaleSettings.currency);

            this.language = Object.values(this.languages)
                // TODO: delete after launching multilanguage
                .find(l => l.id === 'en');
            // TODO: uncomment after launching multilanguage
            //     .find(l => l.id === this.activeLocaleSettings.language);

            const preferencesSettings = await this.getTenantPreferences();
            if (preferencesSettings) {
                if (!isEqual(preferencesSettings, this.activePreferencesSettings)) {
                    this.activePreferencesSettings = preferencesSettings;
                }

                if (this.activePreferencesSettings.userSearchParam) {
                    this.defaultUserSearchParam = Object.values(UserManagerSearchOptions).find(
                        el => el.key === this.activePreferencesSettings.userSearchParam,
                    );
                }
            }

            this.$Progress.finish();
        } catch (error) {
            this.$Progress.fail();
            this.showSupportAlert(
                this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                ALERT_TYPES.error,
            );
        }
    },
    methods: {
        ...mapActions(Modules.config, ['getTenantLocale', 'getTenantPreferences', 'saveTenantConfig']),
        nameWithCode({ name, code }) {
            return `${name} — ${code}`;
        },
        async onSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$Progress.start();
                try {
                    const locale = new Locale({
                        timezone: this.timezonePicker,
                        timeFormat: this.timeFormat.id,
                        dateFormat: this.dateFormat.format,
                        currency: this.currency.code,
                        language: this.language.id,
                    });

                    let preferences = null;

                    if (this.searchOptions.length) {
                        // if search options are present we will have a default value due to multiselect preselect first
                        // or other valid user selection
                        preferences = new Preferences({
                            userSearchParam: this.defaultUserSearchParam.key,
                        });
                    }

                    await this.saveTenantConfig({ locale, preferences });

                    this.$alert(
                        this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            entityName: this.$i18n.t('generic.configuration'),
                            action: this.$i18n.t('generic.updated'),
                        }),
                        { type: ALERT_TYPES.success },
                    );

                    this.$i18n.locale = this.language.id;

                    this.$Progress.finish();
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                }
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typography';

#localization-save-button {
    position: fixed;
    right: 4%;
    bottom: 4%;
}
</style>
