export enum EntityUploadStatus {
    // Issued signed URL for upload and we are expecting file to be uploaded (we are still not aware if upload is done or not)
    REGISTERED = 'Registered',
    // File is uploaded and system is aware of its existence within S3
    DETECTED = 'Started',
    // We are actively processing file
    IN_PROGRESS = 'In progress',
    // Final state. Processing complete
    COMPLETED = 'Completed',
    // Final state. Validation rule was not met (md5 mismatch, duplicated file, wrong format, wrong/unexpected filename)
    DISCARDED = 'Discarded',
}
