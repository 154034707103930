import { AxiosPromise } from 'axios';
import http from '@/http/index';

export type Tenant = {
    companyId: number;
    name: string;
    description: string;
};

/**
 * Gets tenants accessible by the company the user belongs to
 * @returns map from tenant ID to tenant data object
 */
export function getTenants(): AxiosPromise<Record<number, Tenant>> {
    return http({
        method: 'GET',
        url: '/lfinternal/company',
    });
}

/**
 * Gets all tenants known to Portal
 */
export function getAllTenants(): AxiosPromise<Tenant[]> {
    return http({
        method: 'GET',
        url: '/lfinternal/allcompanies',
    });
}

/**
 * Gets accessible tenant IDs for the specified company ID.
 * @returns list of tenant IDs
 */
export function getAccessibleTenants(companyId: number): AxiosPromise<number[]> {
    return http({
        method: 'GET',
        url: '/lfinternal/accessiblecompanies',
        params: {
            companyId,
        },
    });
}

/**
 * Updates the list of companies accessible by `sourceCompanyId`
 * @param sourceCompanyId id of the company whose accessible companies are being updated
 * @param accessibleCompanies list of company ids
 * @returns
 */
export function updateAccessibleTenants(sourceCompanyId: number, accessibleCompanies: number[]): AxiosPromise<void> {
    return http({
        method: 'POST',
        url: '/lfinternalwrite/accessiblecompanies',
        data: {
            sourceCompanyId,
            accessibleCompanies,
        },
    });
}

export function deleteTenant(data: { companyId: number }): AxiosPromise<unknown> {
    return http({
        method: 'DELETE',
        url: '/lfinternalwrite/company',
        data,
    });
}

export type AddTenentRequestBody = {
    name: string;
    data: string; // description
    notes: string;
    operatorName: string;
};

export function addTenant(data: AddTenentRequestBody): AxiosPromise<unknown> {
    return http({
        method: 'POST',
        url: '/lfinternalwrite/company',
        data,
    });
}

export type UpdateTenentRequestBody = {
    name: string;
    data: string; // description
    notes: string;
    operatorName: string;
};

export function updateTenant(data: UpdateTenentRequestBody): AxiosPromise<unknown> {
    return http({
        method: 'PUT',
        url: '/lfinternalwrite/company',
        data,
    });
}

export default {};
