
import Vue from 'vue';
import moment from 'moment';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import LabelDanger from '@/components/partials/LabelDanger.vue';

// Http
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import { fetchResource } from '@/__new__/services/dno/sim/http/inventory';

// helpers
import routeNames from '@/router/routeNames';
import { required } from 'vuelidate/lib/validators';
import { DeliveryBE, DeliveryPackage } from '@/__new__/features/customerCare/common/deliveriesHelper';
import { DELIVERY_RESHIPMENT_REMOTE_CONFIG } from '@/__new__/features/customerCare/common/customerCareHelper';
import { RESOURCE_TYPE, SIM_TYPE, REMOTE_CONFIG_KEYS } from '@/__new__/features/resources/common/sim';
import { dateToEpoch } from '@/common/formatting';
import { SIM_STATE_MAP } from '@/__new__/features/resources/sim/simState';

export default Vue.extend({
    name: 'DeliveryReshipment',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppCheckbox,
        AppMultiselectV3,
        AppTextareaV3,
        LabelDanger,
        CardListRadioInput,
        EditorButtons,
    },
    data() {
        return {
            remoteConfig: {
                deliveryWindowDays: 14,
                deliveredReasons: [],
                notDeliveredReasons: [],
            } as DELIVERY_RESHIPMENT_REMOTE_CONFIG,

            isEligibleForReshipment: false,
            delivery: null as DeliveryBE | null,

            isOrderDelivered: null as string | null,
            isAddressMatches: false,
            reshipmentReason: null as Record<'id' | 'name', string> | null,
            agentNotes: '',
            isCommunicatedAboutPSims: false,
        };
    },
    validations: {
        reshipmentReason: {
            required,
        },
        agentNotes: {
            required,
        },
    },
    computed: {
        noTrackingUrl(): boolean {
            return !this.deliveryPackages.every(pack => pack.tracking_url.length > 0);
        },
        notDeliveredForMoreThanDays(): boolean {
            const dispatched = moment.utc(this.delivery?.created_at, 'x');
            const deliveryWindow = moment.utc().subtract(this.remoteConfig.deliveryWindowDays, 'days');
            return dispatched.isBefore(deliveryWindow);
        },
        orderDate(): string {
            return this.delivery?.delivery_status_update_time_sec
                ? moment(this.delivery.delivery_status_update_time_sec, 'X').format('YYYY-MM-DD')
                : '';
        },
        deliveryPackages(): DeliveryPackage[] {
            return Object.values(this.delivery?.delivery_info?.packages || {});
        },
        shippingAddress(): string {
            const address = this.delivery?.address_info;
            return address
                ? [
                      `${address.address_1} ${address.address_2}`.trim(),
                      address.city,
                      `${address.state} ${address.zip}${address.zip_4 !== '' ? `-${address.zip_4}` : ''}`,
                  ].join(', ')
                : '';
        },
        iccids(): string[] {
            return this.delivery?.delivery_info.iccids || [];
        },
        isSubmitDisabled(): boolean {
            return ![this.isCommunicatedAboutPSims, this.reshipmentReason, this.isAddressMatches].every(Boolean);
        },
        reshipmentReasonOptions(): { id: string; name: string }[] {
            const deliveryStatus =
                this.isOrderDelivered === this.$t('generic.yes') ? 'deliveredReasons' : 'notDeliveredReasons';
            return (this.remoteConfig[deliveryStatus] || []).map((reason: string) => ({
                id: reason,
                name: reason,
            }));
        },
        isOrderDeliveredOptions(): Array<{ id: string; label: string; description: string; disabled?: boolean }> {
            return [
                {
                    id: this.$t('generic.yes'),
                    label: this.$t('generic.yes'),
                    description: this.$t('reshipment.orderDeliveredYesDescription'),
                },
                {
                    id: this.$t('generic.no'),
                    label: this.$t('generic.no'),
                    description: this.$t('reshipment.orderDeliveredNoDescription'),
                    disabled: !this.notDeliveredForMoreThanDays,
                },
            ];
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { data } = await ordersHTTP.getDeliveryDetails({
                    targetId: this.$route.params.targetId,
                    targetType: parseInt(this.$route.params.targetType, 10),
                });

                this.delivery = data?.data.find(order => order.delivery_id === this.$route.params.deliveryId) || null;

                const { data: remoteConfig } = await getRemoteConfig();
                if (remoteConfig?.data[REMOTE_CONFIG_KEYS.DELIVERY_RESHIPMENT]) {
                    this.remoteConfig = JSON.parse(remoteConfig.data[REMOTE_CONFIG_KEYS.DELIVERY_RESHIPMENT].value);
                }

                if (this.delivery) {
                    const reservedOrAssigned = await this.validateIccids();
                    this.isEligibleForReshipment = !reservedOrAssigned.some(Boolean);
                }
            },
            {
                errorHandler: () => {
                    this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                },
            },
        );
    },
    methods: {
        validateIccids() {
            return Promise.all(
                this.iccids.map(async iccid => {
                    const { data } = await fetchResource({
                        resource_id: iccid,
                        resource_type: RESOURCE_TYPE[SIM_TYPE.PSIM],
                        skip_validation: true,
                    });
                    return [SIM_STATE_MAP.RESERVED, SIM_STATE_MAP.ASSIGNED].includes(data?.resource?.state);
                }),
            );
        },
        setIsOrderDelivered(value: string) {
            this.isOrderDelivered = value;
        },
        goBack() {
            this.$router.push({
                name: routeNames.CCS_ACCOUNT_PAGE,
                params: { id: this.$route.params.targetId },
            });
        },
        submitRequest() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                this.requestReshipment();
            }
        },
        requestReshipment() {
            return this.$withLoadingSpinner(
                async () => {
                    await ossmvneHTTP.requestReshipment(
                        this.$route.params.targetId,
                        parseInt(this.$route.params.targetType, 10),
                        this.delivery?.order_id || '',
                        this.reshipmentReason?.name || '',
                    );

                    await this.setAgentNote();

                    this.$showSuccessAlert({
                        message: this.$t('reshipment.reshipmentOrderSubmittedMessage'),
                    });

                    // Timeout for the message to be displayed before redirecting
                    setTimeout(this.goBack, 3000);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('reshipment.failedSubmitReshipmentAlert'));
                    },
                },
            );
        },
        setAgentNote() {
            return this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.$route.params.targetId,
                        idType: parseInt(this.$route.params.targetType, 10),
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.agentNotes,
                        tags: [this.$t('reshipment.agentNotesLabel')],
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'));
                    },
                },
            );
        },
    },
});
