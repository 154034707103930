
import Vue, { PropType } from 'vue';

// components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import BulkUploadTabs, { type Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import LabelDanger from '@/components/partials/LabelDanger.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// helpers
import CustomerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { getUserManagerEntityTypeByTagret } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TargetTuple } from '@/__new__/services/dno/user/models/targetTuple';

export default Vue.extend({
    name: 'ClientStorageModal',
    components: {
        AppDialogV2,
        AppButton,
        BulkUploadTabs,
        AppJSON,
        LabelDanger,
        AppSpinner,
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        entity: {
            default: null,
            type: Object as PropType<TargetTuple>,
        },
    },
    data() {
        return {
            rawResponse: {} as { data: { storage: Record<string, any> } },
            storage: {} as Record<string, any>,
            isDataLoading: false,
            isLoading: false,
            isValidState: true,
            tabId: '',
            BUTTON_TYPES,
        };
    },
    computed: {
        tabs(): Tab[] {
            return Object.keys(this.storage || {}).map(key => ({
                id: key,
                label: key,
            }));
        },
    },
    watch: {
        visible(value) {
            if (value) {
                this.fetchClientStorage();
            }
        },
    },
    methods: {
        getUserManagerEntityTypeByTagret,
        fetchClientStorage() {
            return this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    const response = await CustomerCareHTTP.getClientStorage(
                        this.entity.targetId,
                        this.entity.targetType,
                    );
                    this.rawResponse = response;
                    this.storage = response.data?.storage || {};
                    if (Object.keys(this.storage).length) {
                        [this.tabId] = Object.keys(this.storage);
                    }
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        async onSubmit() {
            this.isLoading = true;
            await CustomerCareHTTP.setClientStorage(
                this.entity.targetId,
                this.entity.targetType,
                this.tabId,
                this.storage[this.tabId],
            ).then(() => {
                this.isLoading = false;
                this.$showSuccessAlert(this.$t('alerts.clientStorageSaveSuccess'));
            });
        },
    },
});
