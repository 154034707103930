<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('authentication.authentication')"
        :isOverviewEnabled="false"
    >
        <template slot="headerButtons">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('authentication.newMapping')"
                :iconType="ICON_TYPES.PLUS"
                @click="onNewMapping"
            />
        </template>

        <template slot="table">
            <div>
                <h3 class="lf-title m-4">
                    {{ $i18n.t('authentication.externalUserManagement') }}
                </h3>
                <div class="table-wrap">
                    <AppTable
                        :entities="mappings"
                        :columnsData="tableColumnConfig"
                        :isDataLoading="loadingData"
                        :canSelectColumns="true"
                        tableKey="externalUserMapping"
                        :selectedEntityId="selectedEntityId"
                        data-test-id="external-users-table-test-id"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                :label="$i18n.t('generic.edit')"
                                :icon="ICON_TYPES.EDIT"
                                class="mr-2"
                                @iconClick="onEditButtonClicked(entity)"
                            />
                            <IconButton
                                :label="$i18n.t('generic.delete')"
                                :icon="ICON_TYPES.DELETE"
                                class="mr-2"
                                @iconClick="onDeleteButtonClicked(entity)"
                            />
                        </template>
                    </AppTable>
                </div>
                <!-- Using v-if to destroy and recreate the component between changes of entity -->
                <ExternalUserMappingModal
                    v-if="editModalVisible"
                    :visible="editModalVisible"
                    :mappingToEdit="selectedMapping"
                    :users="users"
                    @close="closeEditModal"
                    @update="fetchData"
                />
            </div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ExternalUserMappingModal from './ExternalUserMappingModal.vue';
import Button from '@/common/button/Button';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';

// helpers
import tableColumnType from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

// router
import RouteNames from '@/router/routeNames';

// HTTP
import { deleteMapping, getMappings } from '@/__new__/services/portal/auth/http/oauth';
import { getExternalUsers } from '@/__new__/services/portal/admin/http/account';

// VUEX
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

export default {
    name: 'ExternalUserManagement',
    components: {
        AppButton,
        AppTable,
        IconButton,
        ExternalUserMappingModal,
        AbstractListPageWrapper,
    },

    mixins: [supportButtonMixin],

    data() {
        return {
            // Proxy imports
            RouteNames,
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
            alertButtons: {
                deleteButton: new Button({
                    label: this.$i18n.t('generic.delete'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            // end of proxy imports

            // State
            editModalVisible: false,
            loadingData: false,

            selectedMapping: null,
            mappings: [],
            users: [],
            externalUserIdToEmail: {},
            tableColumnConfig: [
                {
                    name: 'External User Id',
                    key: 'externalUserId',
                    field: 'externalUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Role Name',
                    key: 'roleName',
                    field: 'roleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },

    computed: {
        ...mapGetters('roles', [Getters.GET_ROLES]),
        roles() {
            return this[Getters.GET_ROLES];
        },
        selectedEntityId() {
            return this.selectedMapping ? this.selectedMapping.id : null;
        },
    },

    created() {
        // Retrieve the remote config values from backend
        this.fetchData();
    },

    methods: {
        ...mapActions('roles', [Actions.LOAD_ROLE]),
        onEditButtonClicked(mapping) {
            this.selectedMapping = mapping;

            // Toggle modal
            this.editModalVisible = true;
        },
        onDeleteButtonClicked(mapping) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSure'),
                type: this.$ALERT_TYPES.warning,
                buttons: [this.alertButtons.deleteButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.deleteButton.id) {
                    this.$Progress.start();
                    try {
                        await deleteMapping(mapping.id);

                        this.$showSuccessAlert({
                            message: this.$i18n.t('authentication.deleteExternalUserToRoleSuccess'),
                            type: this.$ALERT_TYPES.success,
                        });
                        this.fetchData();
                    } catch (error) {
                        Sentry.captureException(error);
                        this.$Progress.fail();
                        this.showSupportAlert(this.$i18n.t('alertMessage.reports.errorMessage'), ALERT_TYPES.error);
                    } finally {
                        this.$Progress.finish();
                    }
                }
            });
        },
        onNewMapping() {
            // Reset modal editor state props
            this.selectedMapping = null;
            // Toggle modal
            this.editModalVisible = true;
        },
        closeEditModal() {
            this.editModalVisible = false;
        },
        async fetchData() {
            try {
                this.$Progress.start();
                this.loadingData = true;

                const [mappingResponse, usersResponse] = await Promise.all([
                    getMappings(),
                    getExternalUsers(),
                    this[Actions.LOAD_ROLE](),
                ]);

                this.buildUserMappings(usersResponse.data);
                this.initializeMappings(mappingResponse.data);

                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            } finally {
                this.loadingData = false;
            }
        },
        buildUserMappings(users) {
            this.users = users;
            users
                .filter(user => user.externalUserId)
                .forEach(user => {
                    this.externalUserIdToEmail[user.externalUserId] = user.email;
                });
        },
        initializeMappings(mappings) {
            const mappingsWithRoleData = mappings.map(m => {
                const role = this.roles.find(r => r.id === m.roleId);
                return {
                    ...m,
                    roleName: role.name,
                    email: this.externalUserIdToEmail[m.externalUserId] ?? this.$i18n.t('generic.unknown'),
                };
            });
            this.mappings = mappingsWithRoleData;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-wrap {
    max-height: calc(100vh - 190px);
    overflow-y: scroll;
}
</style>
