<script>
import { filterEntities } from '@/common/filterTable';
import LocalStorageHelper from '@/common/LocalStorageHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'FilterTableMixin',
    data() {
        return {
            filterTableMixin: {
                allFilters: [],
            },
        };
    },
    methods: {
        onFilterAdded(filter) {
            this.filterTableMixin.allFilters.push(filter);
        },
        removeFilter(index, entityType = '') {
            this.filterTableMixin.allFilters.splice(index, 1);
            this.removeFilterFromLocalStorageByIndex(index, entityType);
        },
        removeAllFilters(entityType = '') {
            this.filterTableMixin.allFilters = [];
            this.removeAllFiltersFromLocalStorage(entityType);
        },
        filteredEntitiesMixin(entities) {
            return filterEntities(entities, this.filterTableMixin.allFilters);
        },
        computedFilterValue(filter) {
            return filter.condition.getDisplayValue
                ? filter.condition.getDisplayValue(filter.values)
                : filter.values.value;
        },
        computedFilterLabel(filter) {
            return [filter.column.name, filter.condition.label, this.computedFilterValue(filter)].join(' ');
        },
        columnsForFiltering() {
            return this.tableColumnsData.filter(
                column => !Object.prototype.hasOwnProperty.call(column, 'notForFiltering'),
            );
        },
        saveFiltersToLocalStorage(appliedFilters, entityType) {
            if (!appliedFilters || !entityType || !isUserAllowed('OperateAPIsApplicationsRead')) {
                return;
            }

            const data = [...(this.getAppliedFiltersFromLocalStorage(entityType) || []), appliedFilters];
            LocalStorageHelper.set(`applied-filters-${entityType}`, JSON.stringify(data));
        },

        getAppliedFiltersFromLocalStorage(entityType) {
            const data = LocalStorageHelper.get(`applied-filters-${entityType}`) || null;
            return JSON.parse(data);
        },
        setAppliedFiltersFromLocalStorage(entityType) {
            if (!entityType || !isUserAllowed('OperateAPIsApplicationsRead')) {
                return;
            }

            this.filterTableMixin.allFilters = this.getAppliedFiltersFromLocalStorage(entityType) || [];
        },
        removeAllFiltersFromLocalStorage(entityType) {
            if (!entityType || !isUserAllowed('OperateAPIsApplicationsRead')) {
                return;
            }
            LocalStorageHelper.remove(`applied-filters-${entityType}`);
        },
        removeFilterFromLocalStorageByIndex(index, entityType) {
            if (!entityType || !isUserAllowed('OperateAPIsApplicationsWrite')) {
                return;
            }

            const array = this.getAppliedFiltersFromLocalStorage(entityType) || [];
            const newArray = array.filter((_, i) => i !== index);

            LocalStorageHelper.set(`applied-filters-${entityType}`, JSON.stringify(newArray));
        },
    },
};
</script>
