
import Vue from 'vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

import tableColumnType, { TableColumn } from '@/common/filterTable';
import { calculateHotBill, getBillingAccount, getCharges } from '@/__new__/features/charterDemo/charterHttp';
import { startCase } from 'lodash';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { LAYOUT_TILE_SIZES } from '@/__new__/features/customerCareSuite/common/ccsLayoutHelper';

export default Vue.extend({
    name: 'CharterDemoChargesTile',
    components: {
        AbstractTableTile,
        AppButton,
    },
    data() {
        return {
            billingAccount: null as any,
            entities: [] as any[],
            apiResponse: {} as object,
            defaultSort: {
                key: 'timestampStr',
                sortBy: entity => entity.timestamp,
                type: 'desc',
            },
            columnsData: [
                {
                    name: this.$i18n.t('charterDemo.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.chargeId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.billId'),
                    key: 'billId',
                    field: 'billId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.date'),
                    key: 'timestampStr',
                    field: 'timestamp',
                    sortBy: (entity: any) => entity.timestamp,
                    mapper: (entity: any) => this.$localeLibrary.getFormattedDateAndTime(entity.timestamp),
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('charterDemo.type'),
                    key: 'typeStr',
                    field: 'typeStr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.billed'),
                    key: 'billedStr',
                    field: 'billedStr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.amountDue'),
                    key: 'amountDueStr',
                    field: 'amountDueStr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ] as TableColumn[],
            confirmGenerateHotBill: new Button({
                label: this.$i18n.t('charterDemo.generate'),
                handler: () => this.onConfirmGenerateHotBill(),
            }),

            BUTTON_TYPES,
            LAYOUT_TILE_SIZES,
        };
    },
    computed: {
        id() {
            return this.$route.params.id || '';
        },
    },
    watch: {},
    async created() {
        await this.fetchTileData();
    },
    methods: {
        startCase,
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const billingAccountResponse = await getBillingAccount(this.id);
                    this.billingAccount = billingAccountResponse.data?.[0];
                    if (!this.billingAccount) {
                        this.$alert(
                            this.$t('charterDemo.unableToFindBillingAccount', {
                                id: this.id,
                            }),
                        );
                        this.$emit('isDataLoadingUpd', false);
                        return;
                    }
                    const billingAccountId = this.billingAccount.id;

                    this.apiResponse = await getCharges(billingAccountId);
                    this.entities = (this.apiResponse?.data ?? []).map(charge => ({
                        ...charge,
                        billId: charge.bill?.id || '-',
                        typeStr: startCase(charge.appliedBillingRateType),
                        billedStr: charge.isBilled ? this.$t('generic.yes') : this.$t('generic.no'),
                        amountDueStr: `${charge.taxIncludedAmount.value} ${charge.taxIncludedAmount.unit}`,
                        timestamp: new Date(charge.date).getTime(),
                    }));
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.apiResponse = error;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        onGenerateHotBill() {
            // Raise confirmation alert
            this.$alert(this.$t('charterDemo.pleaseConfirmHotBillGeneration'), {
                description: this.$t('charterDemo.generatingHotBillDescription'),
                type: ALERT_TYPES.warning,
                buttons: [this.confirmGenerateHotBill],
            });
        },
        async onConfirmGenerateHotBill() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await calculateHotBill(this.billingAccount.id);
                    this.$alert(this.$t('charterDemo.successfullyGeneratedHotBill'), {
                        type: ALERT_TYPES.success,
                    });
                    window.location.reload();
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('charterDemo.errorOccurredWhileGeneratingHotBill'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
