<template>
    <SinkConfigDashboard
        :entityType="ENTITY_TYPES.EVENT_COMPOSITION"
        :pageTitle="$t('sinkConfigs.eventComposition.eventComposition')"
        :entities="eventCompositionConfigsFormatted"
        :isDataLoading="isDataLoading"
        :entitiesApiResponse="eventCompositionConfigsApiResponse"
        :editRouteName="RouteNames.EVENT_COMPOSITION_EDITOR"
        :uiParams="uiParams"
        :updateTimeFormatterFn="secondsToDate"
        writePermission="EventCompositionConfigurationsWrite"
        @fetchConfigs="fetchConfigs"
        @deleteConfig="config => confirmConfigDeletion(config, Actions.DELETE_EVENT_COMPOSITION_CONFIG)"
    />
</template>

<script>
// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
// helpers
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import RouteNames from '@/router/routeNames';
import { mapEventCompositionConfigActions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import { secondsToDate } from '@/common/formatting';
import * as Sentry from '@sentry/vue';

export default {
    name: 'EventCompositionDashboard',
    components: { SinkConfigDashboard },
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            secondsToDate,
            Actions,
            ENTITY_TYPES,
            RouteNames,
            isDataLoading: false,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            eventCompositionConfigs: Getters.GET_EVENT_COMPOSITION_CONFIGS,
            eventCompositionConfigsApiResponse: Getters.GET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE,
            uiParams: Getters.GET_EVENT_COMPOSITION_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        eventCompositionConfigsFormatted() {
            if (this.eventCompositionConfigs.length) {
                return this.eventCompositionConfigs.map(kc => ({
                    ...kc,
                    allowedActionsExternal: mapEventCompositionConfigActions(kc),
                }));
            }
            return [];
        },
    },
    created() {
        this.isDataLoading = true;
        this.$Progress.start();
        const mainPromises = [
            this.fetchEvents(),
            this.fetchSinkFormatters(),
            this[Actions.LOAD_EVENT_COMPOSITION_CONFIGS_SETTINGS](),
            this.fetchTriggers(),
        ];

        if (!this.segments.length) {
            mainPromises.push(this.fetchSegments());
        }

        Promise.all(mainPromises)
            .then(() => {
                this.fetchConfigs().then(() => {
                    this.$Progress.finish();
                    this.isDataLoading = false;
                });
            })
            .catch(error => {
                this.$alert(this.$t('events.alerts.failedToLoadConfigs'));
                this.$Progress.fail();
                Sentry.captureException(error);
                this.isDataLoading = false;
            });
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_EVENT_COMPOSITION_CONFIGS,
            Actions.LOAD_EVENT_COMPOSITION_CONFIGS_SETTINGS,
            Actions.DELETE_EVENT_COMPOSITION_CONFIG,
        ]),
        fetchConfigs() {
            return this[Actions.LOAD_EVENT_COMPOSITION_CONFIGS]();
        },
    },
};
</script>
