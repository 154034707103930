
import Vue, { type PropType } from 'vue';

// components
import AppButton from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';

export type MenuAction = {
    id: string;
    icon: ICON_TYPES;
    label: string;
    disabled?: boolean;
    handler?: () => void;
};

export default Vue.extend({
    name: 'AppDropdownMenu',
    components: {
        AppButton,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        actions: {
            type: Array as PropType<MenuAction[]>,
            required: true,
        },
        icon: {
            type: String as PropType<ICON_TYPES>,
            default: ICON_TYPES.DETAILS,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        menu(): HTMLDetailsElement {
            return this.$refs.menu as HTMLDetailsElement;
        },
    },
    methods: {
        closeMenu(): void {
            this.menu.removeAttribute('open');
        },
        onActionClick(action: any): void {
            if (action?.handler) {
                action.handler();
            }

            this.$emit(action.id);
            this.closeMenu();
        },
        // Closes menu when clicked outside of component instance.
        onBlur({ relatedTarget }: FocusEvent): void {
            const isCurrent = relatedTarget === this.menu || this.menu.contains(relatedTarget as Node | null);

            if (!isCurrent) {
                this.closeMenu();
            }
        },
    },
});
