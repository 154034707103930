type DevedgeUsageDetailBe = {
    customer_name: string;
    channels: string;
    msisdn: string;
    product_id: string;
    end_time: number | null;
    start_time: number | null;
    transaction_timestamp: number | null;
    dno_app_id: string;
    offer_id: string;
    service_id: string;
    qod_profile: string;
    data_session_length: number | null;
    data_session_length_total: number | null;
    consumed_data_size: number | null;
    consumed_data_size_total: number | null;
};

export class DevedgeUsageDetail {
    customerName: string;
    channels: string;
    msisdn: string;
    productId: string;
    endTime: number | null;
    startTime: number | null;
    transactionTimestamp: number | null;
    dnoAppId: string;
    offerId: string;
    serviceId: string;
    qodProfile: string;
    dataSessionLength: number | null;
    dataSessionLengthTotal: number | null;
    consumedDataSize: number | null;
    consumedDataSizeTotal: number | null;

    constructor(data: Partial<DevedgeUsageDetail> = {}) {
        this.customerName = data?.customerName || '';
        this.channels = data?.channels || '';
        this.msisdn = data?.msisdn || '';
        this.productId = data?.productId || '';
        this.endTime = data?.endTime || null;
        this.startTime = data?.startTime || null;
        this.transactionTimestamp = data?.transactionTimestamp || null;
        this.dnoAppId = data?.dnoAppId || '';
        this.offerId = data?.offerId || '';
        this.serviceId = data?.serviceId || '';
        this.qodProfile = data?.qodProfile || '';
        this.dataSessionLength = data?.dataSessionLength || null;
        this.dataSessionLengthTotal = data?.dataSessionLengthTotal || null;
        this.consumedDataSize = data?.consumedDataSize || null;
        this.consumedDataSizeTotal = data?.consumedDataSizeTotal || null;
    }

    static mapDevedgeUsageDetailFromBE(data: DevedgeUsageDetailBe): DevedgeUsageDetail {
        return new DevedgeUsageDetail({
            customerName: data.customer_name,
            channels: data.channels,
            msisdn: data.msisdn,
            productId: data.product_id,
            endTime: data.end_time,
            startTime: data.start_time,
            transactionTimestamp: data.transaction_timestamp,
            dnoAppId: data.dno_app_id,
            offerId: data.offer_id,
            serviceId: data.service_id,
            qodProfile: data.qod_profile,
            dataSessionLength: data.data_session_length,
            dataSessionLengthTotal: data.data_session_length_total,
            consumedDataSize: data.consumed_data_size,
            consumedDataSizeTotal: data.consumed_data_size_total,
        });
    }
}

type DevedgeBYONSeatUsageBe = {
    order_creation_timestamp: string;
    seat_usage_count: string;
    service_id: string;
    service_name: string;
    offer_id: number | null;
    purchased_seat_count: number | null;
    product_id: number | null;
    report_time: string;
};

export class DevedgeBYONSeatUsage {
    orderCreationTimestamp: string;
    seatUsageCount: string;
    serviceId: string;
    serviceName: string;
    offerId: number | null;
    purchasedSeatCount: number | null;
    productId: number | null;
    reportTime: string;

    constructor(data: Partial<DevedgeBYONSeatUsage> = {}) {
        this.orderCreationTimestamp = data?.orderCreationTimestamp || '';
        this.seatUsageCount = data?.seatUsageCount || '';
        this.serviceId = data?.serviceId || '';
        this.serviceName = data?.serviceName || '';
        this.offerId = data?.offerId || null;
        this.purchasedSeatCount = data?.purchasedSeatCount || null;
        this.productId = data?.productId || null;
        this.reportTime = data?.reportTime || '';
    }

    static mapDevedgeBYONSeatUsageFromBE(data: DevedgeBYONSeatUsageBe): DevedgeBYONSeatUsage {
        return new DevedgeBYONSeatUsage({
            orderCreationTimestamp: data.order_creation_timestamp,
            seatUsageCount: data.seat_usage_count,
            serviceId: data.service_id,
            serviceName: data.service_name,
            offerId: data.offer_id,
            purchasedSeatCount: data.purchased_seat_count,
            productId: data.product_id,
            reportTime: data.report_time,
        });
    }
}

type DevedgeCDRsAuditBe = {
    operator_name: string;
    target_type: number;
    target_id: string;
    file_name: string;
    file_timestamp: number;
    file_version: string;
    file_row_number: number;
    data: any;
};

export class DevedgeCDRsAudit {
    operatorName: string;
    targetType: number;
    targetId: string;
    fileName: string;
    fileTimestamp: number;
    fileVersion: string;
    fileRowNumber: number;
    data: any;

    constructor(data: Partial<DevedgeCDRsAudit> = {}) {
        this.operatorName = data?.operatorName || '';
        this.targetType = data?.targetType || 0;
        this.targetId = data?.targetId || '';
        this.fileName = data?.fileName || '';
        this.fileTimestamp = data?.fileTimestamp || 0;
        this.fileVersion = data?.fileVersion || '';
        this.fileRowNumber = data?.fileRowNumber || 0;
        this.data = data?.data || null;
    }

    static mapDevedgeCDRsAuditFromBE(data: DevedgeCDRsAuditBe): DevedgeCDRsAudit {
        return new DevedgeCDRsAudit({
            operatorName: data.operator_name,
            targetType: data.target_type,
            targetId: data.target_id,
            fileName: data.file_name,
            fileTimestamp: data.file_timestamp,
            fileVersion: data.file_version,
            fileRowNumber: data.file_row_number,
            data: data.data,
        });
    }
}
