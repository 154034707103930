
import { PropType } from 'vue';
import { map, omit } from 'lodash';
import filters from '@/common/filters';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

import AppIcon from '@/components/partials/icon/AppIcon.vue';
import SimpleGrafanaChart from '@/__new__/features/campaigns/SimpleGrafanaChart.vue';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';

export default {
    name: 'CampaignDeliveryAnalytics',
    components: { AppIcon, SimpleGrafanaChart },
    filters,
    props: {
        targetingStrategy: {
            type: String,
            required: true,
        },
        campaignId: {
            type: String,
            default: '',
        },
        channels: {
            type: Array as PropType<string[]>,
            required: true,
        },
        stats: {
            type: Object as PropType<Record<string, any>>,
            required: true,
        },
    },
    data() {
        return {
            ICON_COLORS,
            channelsWithHeader: ['android', 'ios'],
            channelIcons: {
                push: ICON_TYPES.BELL,
                sms: ICON_TYPES.SMS,
                email: ICON_TYPES.EMAIL,
                ott: ICON_TYPES.INBOX,
            },
            grafanaChartTimeRange: '',
            timeRangeOptions: ['1h', '6h', '24h', '7d', '30d'],
        };
    },
    computed: {
        ...mapGetters({
            graphanaHost: `${Modules.config}/getGrafanaHost`,
        }),
        strategy() {
            return this.$t(`campaigns.targetingOptions.${this.targetingStrategy}`);
        },
        byChannel() {
            return map(this.stats.statsByChannel, (stats, channel) => ({
                channel,
                values: map(omit(stats, 'received'), (value, label) => ({ label, value })),
            }));
        },
        isIframeDisplayed() {
            return isViewEnabled('GrafanaGraphs') && isUserAllowed('GrafanaGraphs') && this.graphanaHost;
        },
    },
};
