
import Vue from 'vue';

// Components
import AppTooltip from '@/components/partials/AppTooltip.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import moment from 'moment';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import vClickOutside from 'v-click-outside';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import {
    Appointment,
    AppointmentTimeSlot,
    APPT_TIMESLOT_BE,
    APPT_ACTION,
    APPT_STATE,
    getAppointmentActivityLabel,
} from '@/__new__/features/customerCare/common/appointmentsHelper';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// HTTP
import { deleteAppointment, getAppointments, getAvailableTimeslots, updateAppointment } from '@/http/appointments';

export default Vue.extend({
    name: 'AppointmentTile',
    components: {
        AppTooltip,
        AppButton,
        AppDialogV2,
        AppToggle,
        DateTimePicker,
        AppMultiselectV3,
        AppTextareaV3,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            appointment: {} as Appointment,
            isModalVisible: false,
            changeAppointmentTime: false,
            isFetchingTimeSlots: false,
            appointmentsModalData: {
                date: null,
                timeSlot: '',
                memoAgent: undefined,
                type: '',
                isNotificationEnabled: true,
            },
            appointmentsTimeSlots: [] as Array<AppointmentTimeSlot>,
            allAvailableTimeSlots: [] as Array<AppointmentTimeSlot>,

            alertButtons: {
                confirmEditButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            APPT_STATE,
            TOOLTIP_POSITION,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]),
        childAccount() {
            if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER) {
                const hierarchyData = this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                    targetId: this.$route.params.id,
                    targetType: USER_MANAGER_HIERARCHY.USER,
                });
                return hierarchyData?.children[0]?.id || '';
            }
            return null;
        },
        relatedAccountId() {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT ||
                this.userManagerHierarchy === USER_MANAGER_HIERARCHY.SUBSCRIBER
                ? this.$route.params.id
                : this.childAccount;
        },
        getModalHeader() {
            if (this.appointment.activityType) {
                return this.$i18n.t(getAppointmentActivityLabel(this.appointment.activityType, APPT_ACTION.EDIT));
            }
            return '';
        },
        timeFromMoment() {
            if (this?.appointment?.timeSlot) {
                const { timeFrom } = this.appointment.timeSlot;
                return moment(timeFrom);
            }
            return '';
        },
        timeToMoment() {
            if (this?.appointment?.timeSlot) {
                const { timeTo } = this.appointment.timeSlot;
                return moment(timeTo);
            }
            return '';
        },
        date() {
            if (this?.timeFromMoment) {
                const dateNum = this.timeFromMoment.date();
                return this.timeFromMoment.date(dateNum).format('DD');
            }
            return '';
        },
        month() {
            if (this?.timeFromMoment) {
                const monthNum = this.timeFromMoment.month();
                return this.timeFromMoment.month(monthNum).format('MMM');
            }
            return '';
        },
        dayOfWeek() {
            if (this?.timeFromMoment) {
                const weekdayNum = this.timeFromMoment.isoWeekday();
                return this.timeFromMoment.isoWeekday(weekdayNum).format('dddd');
            }
            return '';
        },
        timeframe() {
            if (this?.appointment?.timeSlot) {
                const timeFrom = this.timeFromMoment.format('hh:mm A');
                const timeTo = this.timeToMoment.format('hh:mm A');
                const {
                    timeZone: { shortName },
                } = this.appointment.timeSlot;

                let timeFrame = `${timeFrom} - ${timeTo}`;
                if (shortName) {
                    timeFrame += ` (${shortName})`;
                }

                return timeFrame;
            }
            return '';
        },
        dateAndTimeLabel() {
            if (this?.appointment?.timeSlot) {
                const localizedDateLabel = this.$localeLibrary.getFormattedDate(this.appointment.timeSlot.timeFrom);
                const localizedStartTime = this.$localeLibrary.getFormattedTime(this.appointment.timeSlot.timeFrom);
                const localizedEndTime = this.$localeLibrary.getFormattedTime(this.appointment.timeSlot.timeTo);

                return `${localizedDateLabel}, ${localizedStartTime} - ${localizedEndTime}`;
            }
            return '';
        },
        anyAppointments() {
            return this.appointment?.appointmentId;
        },
        showButtons() {
            return this.appointment.state !== APPT_STATE.COMPLETED && this.appointment.state !== APPT_STATE.CANCELED;
        },
    },
    watch: {
        relatedAccountId: {
            handler(newVal) {
                this.fetchTileData(newVal);
            },
            immediate: true,
        },
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    if (this.relatedAccountId) {
                        const { data: appointmentsResponse } = await getAppointments({
                            targetId: this.relatedAccountId,
                            targetType: this.userManagerHierarchy,
                        });
                        if (
                            Array.isArray(appointmentsResponse?.appointments) &&
                            appointmentsResponse?.appointments.length > 0
                        ) {
                            const [appointment] = appointmentsResponse.appointments;
                            this.appointment = new Appointment(appointment);
                        }
                    }
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('isDataLoadingUpd', false);
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        checkDisabledDate(date) {
            for (const ts of this.allAvailableTimeSlots) {
                if (ts.date.toDateString() === date.toDateString()) {
                    return false;
                }
            }
            return true;
        },
        async fetchTimeSlots() {
            await this.$withProgressBar(
                async () => {
                    this.appointmentsTimeSlots = [];
                    this.allAvailableTimeSlots = [];
                    this.isFetchingTimeSlots = true;
                    this.appointmentsModalData.date = '';
                    const type = this.appointmentsModalData.type?.value || this.appointmentsModalData.type;

                    if (type) {
                        this.$Progress.start();
                        const res = await getAvailableTimeslots({
                            targetId: this.relatedAccountId,
                            targetType: this.userManagerHierarchy,
                            type,
                        });
                        this.allAvailableTimeSlots = res?.data?.time_slots.map(
                            (el: APPT_TIMESLOT_BE) => new AppointmentTimeSlot(el),
                        );
                        if (!this.allAvailableTimeSlots?.length) {
                            this.$showInfoAlert({
                                message: this.$i18n.t('appointments.alerts.noAppointmentTimeSlots'),
                            });
                        }
                    }

                    this.isFetchingTimeSlots = false;
                },
                {
                    errorHandler: error => {
                        if (!error.isInterceptorAlertShown) {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('appointments.alerts.failedLoadingAppointmentTimeSlots'),
                            });
                        }
                        this.clearModalData();
                        this.isFetchingTimeSlots = false;
                    },
                },
            );
        },
        closeModal() {
            this.isModalVisible = false;
            this.clearModalData();
        },
        clearModalData() {
            this.appointmentsModalData = {
                date: null,
                timeSlot: '',
                memoAgent: undefined,
                type: '',
                isNotificationEnabled: true,
            };
            this.allAvailableTimeSlots = [];
        },
        openEditModal() {
            this.changeAppointmentTime = false;
            this.appointmentsModalData = {
                date: this.appointment.timeSlot.date,
                memoAgent: this.appointment.memoAgent,
                idOfEditedAppointment: this.appointment.appointmentId,
                timeSlot: null,
                type: this.appointment.activityType,
            };
            this.filterAvailableTimeSlots(this.appointment);

            this.isModalVisible = true;
        },
        filterAvailableTimeSlots() {
            this.$set(this.appointmentsModalData, 'timeSlot', '');
            const selectedDate = this.formatDate(this.appointmentsModalData.date);
            const formatTimeLabel = time => {
                const hour = time.substring(11, 13) > 12 ? time.substring(11, 13) - 12 : time.substring(11, 13);
                const min = time.substring(14, 16);
                const meridiem = time.substring(11, 13) >= 12 ? 'PM' : 'AM';

                return `${hour}:${min} ${meridiem}`;
            };

            this.appointmentsTimeSlots = this.allAvailableTimeSlots.reduce((arr, ts) => {
                if (ts.timeFrom.substring(0, 10) === selectedDate) {
                    const { timeFrom, timeTo, timeZone, id } = ts;
                    let label = `${formatTimeLabel(timeFrom)} - ${formatTimeLabel(timeTo)}`;
                    if (timeZone?.shortName) {
                        label += ` (${timeZone.shortName})`;
                    }
                    arr.push({
                        label,
                        timeFrom,
                        timeTo,
                        id,
                    });
                }
                return arr;
            }, []);
        },
        formatDate(date) {
            if (date) {
                let month = `${date.getMonth() + 1}`;
                let day = `${date.getDate()}`;
                const year = date.getFullYear();

                if (month.length < 2) {
                    month = `0${month}`;
                }
                if (day.length < 2) {
                    day = `0${day}`;
                }
                return [year, month, day].join('-');
            }
            return '';
        },
        onUpdateAppointment() {
            this.$alert(this.$i18n.t('appointments.alerts.editAppointmentConfirm'), {
                type: ALERT_TYPES.warning,
                handler: () => this.updateAppointmentsCall(),
            });
        },
        async updateAppointmentsCall() {
            await this.$withProgressBar(
                async () => {
                    this.isFetchingTimeSlots = true;

                    const { relatedAccountId } = this;
                    const { timeSlot, memoAgent, isNotificationEnabled, idOfEditedAppointment, type } =
                        this.appointmentsModalData;

                    let ts;
                    if (this.changeAppointmentTime) {
                        ts = {
                            start_time: timeSlot.timeFrom,
                            end_time: timeSlot.timeTo,
                            ...(timeSlot.id && { time_slot_id: timeSlot.id }),
                        };
                    } else {
                        ts = null;
                    }

                    await updateAppointment({
                        targetId: relatedAccountId,
                        targetType: this.userManagerHierarchy,
                        idOfEditedAppointment,
                        timeSlot: ts,
                        note: memoAgent,
                        isNotificationEnabled,
                        activityType: type,
                    });

                    this.isModalVisible = false;

                    this.$eventBus.$emit('showAlert', {
                        type: ALERT_TYPES.success,
                        message: this.$i18n.t('appointments.alerts.successfullyUpdated'),
                    });

                    this.fetchTileData();
                    this.isFetchingTimeSlots = false;
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('appointments.errors.unableToUpdate'),
                        });
                        this.isFetchingTimeSlots = false;
                    },
                },
            );
        },
        async deleteAppointmentCall() {
            await this.$withProgressBar(
                async () => {
                    const { relatedAccountId } = this;

                    await deleteAppointment({
                        targetId: relatedAccountId,
                        targetType: this.userManagerHierarchy,
                        appointmentId: this.appointment.appointmentId,
                    });

                    this.isModalVisible = false;

                    this.$eventBus.$emit('showAlert', {
                        type: ALERT_TYPES.success,
                        message: this.$i18n.t('appointments.alerts.successfullyDeleted'),
                    });

                    this.fetchTileData();
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('appointments.errors.unableToDelete'),
                        });
                    },
                },
            );
        },
    },
});
