
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AdditionalSidebarForOrdersNextGen from '@/__new__/features/customerCareSuite/components/AdditionalSidebarForOrdersNextGen.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// helpers
import {
    ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
    ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/orderNextGenStateHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';

// Http
import coreOMHTTP from '@/__new__/services/dno/coreom/http/coreom';
import OrderData from '@/__new__/services/dno/coreom/models/OrderData';

export default Vue.extend({
    name: 'OrderDetailsTileNextGen',
    components: {
        AbstractTableTile,
        AdditionalSidebarForOrdersNextGen,
        EntityStatusIndicator,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            entities: [] as OrderData[],
            apiResponse: {} as any,
            showSidebar: false,
            selectedEntityId: null as string | null,

            // proxy
            RouteNames,
            ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
        };
    },
    computed: {
        // Table related properties
        selectedEntity(): OrderData | null {
            return this.entities.find(order => order.id === this.selectedEntityId) || null;
        },
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'id',
                    forbidHideColumn: true,
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    forbidHideColumn: true,
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map(entity => entity.state))),
                },
            ];
        },
    },
    mounted() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            return this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await coreOMHTTP.getOrders(this.$route.params.id, USER_MANAGER_HIERARCHY.ACCOUNT);

                    this.apiResponse = response;

                    if (response?.data?.orders) {
                        this.entities = Object.values(response.data.orders).map(
                            el => new OrderData(OrderData.mapOrdersFromBE(el)),
                        );
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            ALERT_TYPES.error,
                        );
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        onShowSidebar(id: string) {
            this.selectedEntityId = id;
            this.showSidebar = true;
        },
        onCloseSidebar() {
            this.selectedEntityId = null;
            this.showSidebar = false;
        },
    },
});
