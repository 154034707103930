
import Vue, { PropType } from '@/common/typedVue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// http
import inventoryHTTP from '@/__new__/services/dno/sim/http/inventory';

// Helpers
import { partition } from 'lodash';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';

export default Vue.extend({
    name: 'TagSimTransferDialog',
    components: {
        AppDialogV2,
        AppInputV3,
        AppMultiselectV3,
        AppButton,
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        tagCounts: {
            required: true,
            type: Object as PropType<Record<string, number>>,
        },
    },
    data() {
        return {
            isLoading: false,
            BUTTON_TYPES,
            form: {
                fromDc: { tag: '' },
                amount: 0,
                toDc: { tag: '' },
            },
        };
    },
    computed: {
        // eslint-disable-next-line camelcase
        operatorConfig(): { primary_tag: string; tags: string[] } {
            return getOperatorConfigValue('service_config.lf-inventory.tag_count_manager.20', {
                primary_tag: 'DC55',
                tags: ['DC55', 'DC20'],
            });
        },
        tags(): Record<'source' | 'destination', { tag: string }[]> {
            const [source, destination] = partition(
                this.operatorConfig.tags.map((tag: string) => ({ tag })),
                ({ tag }) => tag === this.operatorConfig.primary_tag,
            );
            return {
                source,
                destination,
            };
        },
        maxTransferAmount(): number {
            return this.tagCounts[this.form.fromDc.tag] || 0;
        },
        amountInvalid(): boolean {
            return this.form.amount > this.maxTransferAmount;
        },
        formInvalid(): boolean {
            return [this.amountInvalid].some(Boolean);
        },
    },
    mounted() {
        [this.form.fromDc] = this.tags.source;
        [this.form.toDc] = this.tags.destination;
    },
    methods: {
        submitTransfer(): Promise<void> {
            this.isLoading = true;
            return this.$withProgressBar(
                async () => {
                    await inventoryHTTP.batchUpdateCounts([
                        {
                            tag: this.form.fromDc.tag,
                            amount: -this.form.amount,
                            resource_type: SIM_TYPES_MAP.PSIM,
                        },
                        {
                            tag: this.form.toDc.tag,
                            amount: this.form.amount,
                            resource_type: SIM_TYPES_MAP.PSIM,
                        },
                    ]);
                    this.isLoading = false;
                    this.$emit('close');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                        this.isLoading = false;
                    },
                },
            );
        },
    },
});
