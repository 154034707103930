<template>
    <AbstractEditPageWrapper :shouldDisplaySidebar="true">
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="d-flex align-items-center mb-3">
                <div class="lf-subtitle mb-0">
                    {{ $i18n.t('generic.general') }}
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                    </p>
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.updateTime') }}:
                        {{ $localeLibrary.getFormattedDateAndTime(entityData.updateTime) }}
                    </p>
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                    </p>
                </div>
                <!-- Bar containing labels and buttons -->
                <div class="labels-and-buttons-bar flex-grow-1 d-flex align-items-center justify-content-end">
                    <EntityStatusIndicator
                        v-if="entityData.state && entityData.state !== STATUS_CODES.NA"
                        :status="entityData.state"
                        class="state-label"
                    >
                    </EntityStatusIndicator>
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="state-label"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="state-label"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="state-label"
                    />
                    <AppButton
                        v-if="!inEditMode"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.advanced')"
                        data-test-id="advanced-btn"
                        @click="openAdvanced"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        @click="reloadEditor(EDITOR_MODE.EDIT)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        @click="reloadEditor(EDITOR_MODE.VIEW)"
                    />
                    <AppButton
                        v-if="isRevertAvailable"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('productCatalog.editors.revertToPublished')"
                        @click="resetToPublished"
                    />
                </div>
            </div>
            <!-- generic fields for all entity types -->
            <AppDialogV2
                :visible="advanced"
                :title="$i18n.t('generic.advanced')"
                :disableDefaultSaveBtn="true"
                @close="closeAdvanced()"
            >
                <AppInputV3
                    v-if="!inEditMode"
                    id="template-name-uuid"
                    v-model="uuid"
                    :invalid="!validateUUID()"
                    :placeholder="$i18n.t('generic.uuid')"
                    :label="$i18n.t('generic.uuid')"
                    :explanationText="$i18n.t('generic.uuidtooltip')"
                    :tooltipOffset="0"
                    class="editor-input-largest pb-3"
                    data-test-id="uuid-input"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.apply')"
                        :disabled="!validateUUID()"
                        @click="applyAdvanced()"
                    />
                </template>
            </AppDialogV2>

            <p
                v-show="uuid"
                class="lf-primary-text"
            >
                {{ $i18n.t('generic.id') }}: {{ uuid }}
            </p>

            <AppInputV3
                v-model="entityData.name"
                :placeholder="$i18n.t('generic.name')"
                :label="$i18n.t('generic.name')"
                :disabled="readonly"
                :invalid="!entityData.name && showValidation"
                class="editor-input-largest mb-3"
                data-test-id="entity-name"
            />
            <AppTextareaV3
                v-model="entityData.description"
                :placeholder="$i18n.t('generic.addDescription')"
                :label="$i18n.t('generic.description')"
                :resizeNone="true"
                :disabled="readonly"
                data-test-id="entity-description"
                class="editor-input-largest mb-3"
            />
            <AppInputV3
                v-model="entityData.externalId"
                :placeholder="$i18n.t('generic.externalId')"
                :label="$i18n.t('generic.externalId')"
                :invalid="!validateExternalId()"
                :errorMessage="$i18n.t('alertMessage.pc.externalIdWithUppercaseSupportInvalid')"
                :explanationText="$i18n.t('productCatalog.offers.editor.externalIdInformation')"
                :tooltipOffset="20"
                :optional="true"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                data-test-id="entity-externalid"
            />

            <OfferCoreData
                v-if="isOfferEditor"
                ref="offerCoreDataComponent"
                v-model="offerCoreData"
                :initialOfferData="initialOfferData"
                :showMainValidation="showValidation"
                :childEntities="entityData.childEntities"
                :readonly="readonly"
                @offerValidationResult="isOfferValid => onOfferValidated(isOfferValid)"
            />

            <div v-if="childEntitiesAvailable">
                <h3 class="lf-subtitle mb-2">
                    {{ $i18n.tc('productCatalog.childEntities', PLURALIZATION.PLURAL) }}
                </h3>
                <AppMultiselectV3
                    v-if="isMultipleChildEntitiesTypes"
                    v-model="selectedChildEntitiesType"
                    :options="childEntitiesTypesOptions"
                    :additionalLabel="$i18n.t('productCatalog.childEntityType')"
                    :placeholder="$i18n.t('productCatalog.chooseChildEntityType')"
                    :small="true"
                    :disabled="readonly"
                    label="typeName"
                    data-test-id="child-entities-type-picker"
                    trackBy="type"
                    class="editor-input-largest mb-3"
                />
                <AppMultiselectV3
                    v-if="!isAtiotDemo"
                    v-model="entityData.childEntities"
                    :options="childEntitiesOptionsWithDisplayName"
                    :additionalLabel="childEntitiesLabel"
                    :placeholder="$i18n.t('productCatalog.pleaseSelectChildEntityYouWantToAdd')"
                    :disabled="(isMultipleChildEntitiesTypes && !selectedChildEntitiesType) || readonly"
                    :small="true"
                    :multiple="true"
                    label="displayName"
                    data-test-id="child-entities"
                    trackBy="id"
                    class="editor-input-largest mb-5"
                />
                <AtiotOfferEntityCardinality
                    v-if="isAtiotDemo"
                    v-model="entityData.childEntities"
                    :options="childEntitiesOptions"
                    :optionsType="selectedChildEntitiesType"
                    :disabled="(isMultipleChildEntitiesTypes && !selectedChildEntitiesType) || readonly"
                    class="mb-5"
                />
            </div>

            <!-- template data part -->
            <div class="lf-subtitle">
                {{ $i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR) }}
            </div>
            <AppMultiselectV3
                v-model="entityData.templateId"
                :small="true"
                :disabled="disabledTemplateMultiselect || readonly"
                :verified="disabledTemplateMultiselect"
                :options="templates"
                :additionalLabel="$i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR)"
                :placeholder="$i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR)"
                :error="showValidation && !entityData.templateId"
                data-test-id="template"
                label="name"
                trackBy="id"
                optionId="id"
                class="editor-input-largest mb-5"
                @input="id => buildDataForFormBuilder(id)"
            />
            <div
                v-if="templateRenderError"
                class="lf-error-message"
            >
                {{ $i18n.t('alertMessage.pc.errorRenderingTemplate') }}
            </div>
            <!-- Generate Template Fields -->
            <div v-else>
                <div v-if="entityData.templateId">
                    <div class="lf-primary-text mb-3">
                        {{ templateName }}
                    </div>
                    <GroupGenerator
                        v-model="entityData.templateData"
                        :groups="templateGroups"
                        :showValidation="showValidation && (showTemplateValidation || showFormBuilderWidgetValidation)"
                        :disabled="readonly"
                        class="w-60 mb-5"
                        data-test-id="template-form"
                    >
                        <template #header="{ group }">
                            <AppTextUnderline
                                :text="group.name"
                                class="mb-3"
                            />
                        </template>

                        <template #divider>
                            <div class="mb-3" />
                        </template>

                        <template #dividerGroup>
                            <div class="mb-5" />
                        </template>
                    </GroupGenerator>
                </div>
                <div
                    v-else
                    class="lf-secondary-text mb-3"
                >
                    {{ $i18n.t('productCatalog.chooseTemplate') }}
                </div>
            </div>
            <div class="end-line-content" />
        </template>

        <template
            v-if="readonly"
            #sideBar
        >
            <div class="lf-subtitle m-3">
                {{ $i18n.t('productCatalog.editors.versionHistory') }}
            </div>
            <div
                v-for="entry in versionsHistory"
                :key="entry.version"
            >
                <div
                    class="d-flex lf-primary-text version-item"
                    :class="{ active: entry.version === currentVersion.version }"
                    @click="selectVersion(entry)"
                >
                    <AppIcon
                        v-if="entry.version === currentVersion.version"
                        type="check-new"
                        size="1rem"
                        class="icon mr-2"
                    />
                    <span>{{ entry.label }}</span>
                    <EntityStatusIndicator
                        v-if="entry.state"
                        :status="entry.state"
                        class="justify-content-end flex-grow-1 state-label"
                    >
                    </EntityStatusIndicator>
                </div>
            </div>
        </template>

        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="readonly"
                @cancel="routeToListPage"
                @saveDraft="saveEntity(false)"
                @publish="saveEntity(true)"
                @save="saveEntity"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import GroupGenerator from '@/components/FormBuilder/GroupGenerator.vue';
import AppTextUnderline from '@/components/partials/AppTextUnderline.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AtiotOfferEntityCardinality from '@/__new__/features/pc/atiot/AtiotOfferEntityCardinality.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import OfferCoreData from '@/__new__/features/pc/offers/OfferCoreData.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Helpers
import { ProductCatalogEntityTemplate } from '@/__new__/services/dno/pc/models/ProductCatalogEntityTemplate';
import { ENTITIES_TYPES_MAP } from '@/__new__/features/pc/common/entitiesTypes';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import tableColumnType from '@/common/filterTable';
import { FORM_BUILDER_TYPES, WIDGETS } from '@/formBuilderConfig';
import { cloneDeep, isEmpty } from 'lodash';
import { validationMixin } from 'vuelidate';
import { textToLowerCase, validateKebabCase, validateAlphaNumericWithDashAndUnderscore } from '@/common/formatting';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import permissionsService, { isUserAllowed, getOperatorConfigValue } from '@/services/permissions/permissions.service';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import ProductCatalogProductModel from '@/__new__/services/dno/pc/models/ProductCatalogProductModel';
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';
import ProductCatalogServiceModel from '@/__new__/services/dno/pc/models/ProductCatalogServiceModel';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/features/pc/common/entitiesTypesToModel';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import Button from '@/common/button/Button';
import { ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { deleteEntity } from '@/__new__/services/dno/pc/entityState';
import { STATUS_CODES } from '@/common/commonHelper';
import { isAxiosError } from 'axios';
import luaErrors, { modules } from '@/common/luaErrors';

// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';

// HTTP
import {
    addEntity,
    updateEntity,
    setEntityDraft,
    getEntityDraft,
    deleteEntityDraft,
} from '@/__new__/services/dno/pc/entities';
import { getHistoryVersion } from '@/__new__/services/dno/pc/http/catalog';
import { EDITOR_MODE, loadVersionHistory } from '@/common/entities/entityHelper';
import { isValidUuid } from '@/common/uuidHelper';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import * as Sentry from '@sentry/vue';

const PARENT_TO_CHILD_ENTITY_TYPE_MAP = new Map([
    [ENTITY_TYPES.OFFER, [ENTITY_TYPES.OFFER, ENTITY_TYPES.PRODUCT]],
    [ENTITY_TYPES.PRODUCT, [ENTITY_TYPES.SERVICE, ENTITY_TYPES.RESOURCE]],
    [ENTITY_TYPES.SERVICE, []],
    [ENTITY_TYPES.RESOURCE, []],
]);

export default {
    name: 'EntityEditorPage',

    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        GroupGenerator,
        AppTextUnderline,
        AppDialogV2,
        AtiotOfferEntityCardinality,
        EditorButtons,
        AppLabel,
        OfferCoreData,
        AppIcon,
        EntityStatusIndicator,
    },

    mixins: [validationMixin],

    data() {
        return {
            // entity data to input
            entityData: {
                childEntities: [],
                templateData: {},
                formattedDataForFormBuilder: [],
            },
            // offer specific data
            offerCoreData: {},
            initialOfferData: null,

            entityDraft: undefined,
            isOnlyDraft: false,
            updateName: this.$i18n.t('generic.N/A'),

            /**
             * Validation flags
             */
            // flag indicating whether validation errors should be shown
            showValidation: false,
            // flag indicating if there's an error with form builder widgets
            showFormBuilderWidgetValidation: false,
            // flag indicating if any template has validation errors
            showTemplateValidation: false,

            historyEntity: undefined,
            currentVersion: undefined,
            versionsHistory: [],

            headerOptions: [
                this.$i18n.t('generic.min'),
                this.$i18n.t('generic.max'),
                this.$i18n.t('generic.pricePerUnitPerPeriod'),
            ],

            advanced: false,
            uuid: '',

            readonly: false,
            isUnpublished: false,
            isTemplateIdSet: false,
            isOutdatedDraft: false,

            // generic stuff
            entityType: this.$route.params.entityType,
            selectedChildEntitiesType: null,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            EDITOR_MODE,
            PLURALIZATION,
            STATUS_CODES,
            permissionsService,
            validateKebabCase,
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: this.deleteAction,
            }),
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            resetDraftButton: new Button({
                label: this.$i18n.t('productCatalog.editors.reset'),
                alertType: ALERT_TYPES.warning,
                handler: this.resetDraft,
            }),
            restartDraftEditButton: new Button({
                label: this.$i18n.t('generic.refreshPage'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.$router.go(0),
            }),
            LABEL_COLOR,

            /**
             * Flag indicating there was an error when attempting to render the template
             * chosen for the entity.
             */
            templateRenderError: false,
            /**
             * Remove after demo.
             */
            isAtiotDemo: getOperatorConfigValue('isAtiotDemo'),
        };
    },

    computed: {
        // vuex related stuff
        ...mapGetters('productcatalog', {
            getTemplatesApproved: Getters.PC_GET_TEMPLATES_BY_TYPE_APPROVED,
            getTemplate: Getters.PC_GET_TEMPLATE_BY_TYPE_AND_ID,
            getEntity: Getters.PC_GET_ENTITY_BY_TYPE_AND_ID,
            getEntitiesApproved: Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED,
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
        }),

        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        templateName() {
            const template = this.templates.find(tmp => tmp.id === this.entityData.templateId);

            return template.name;
        },
        childEntitiesTypes() {
            return PARENT_TO_CHILD_ENTITY_TYPE_MAP.get(this.entityType);
        },
        isMultipleChildEntitiesTypes() {
            return this.childEntitiesTypes.length > 1;
        },
        childEntitiesAvailable() {
            return this.childEntitiesTypes.length;
        },
        childEntitiesTypesOptions() {
            return this.childEntitiesAvailable
                ? this.childEntitiesTypes.map(type => ({
                      typeName: this.$i18n.tc(ENTITIES_TYPES_MAP[type]),
                      type,
                  }))
                : [];
        },
        childEntitiesOptions() {
            if (this.childEntitiesAvailable) {
                if (this.isMultipleChildEntitiesTypes && this.selectedChildEntitiesType) {
                    return this.getEntitiesApproved(this.selectedChildEntitiesType.type);
                }
                return this.getEntitiesApproved(PARENT_TO_CHILD_ENTITY_TYPE_MAP.get(this.entityType)[0]);
            }

            return [];
        },
        childEntitiesOptionsWithDisplayName() {
            return this.childEntitiesOptions.map(childEntity => {
                childEntity.displayName = `${childEntity.name} - ${childEntity.id}`;
                return childEntity;
            });
        },
        childEntitiesLabel() {
            return this.isMultipleChildEntitiesTypes
                ? this.$i18n.tc('productCatalog.childEntities', PLURALIZATION.PLURAL)
                : '';
        },
        routeListName() {
            switch (this.entityType) {
                case ENTITY_TYPES.OFFER:
                    return RouteNames.OFFERS_LIST_NEW;
                case ENTITY_TYPES.PRODUCT:
                    return RouteNames.PRODUCT_LIST_NEW;
                case ENTITY_TYPES.SERVICE:
                    return RouteNames.SERVICES_LIST_NEW;
                case ENTITY_TYPES.RESOURCE:
                    return RouteNames.RESOURCE_LIST_NEW;
                default:
                    return '';
            }
        },
        isOfferEditor() {
            return this.entityType === ENTITY_TYPES.OFFER;
        },
        isProductEditor() {
            return this.entityType === ENTITY_TYPES.PRODUCT;
        },
        isServiceEditor() {
            return this.entityType === ENTITY_TYPES.SERVICE;
        },
        entityTypeString() {
            const entityTypeString = ENTITIES_TYPES_MAP[this.entityType];
            return this.$i18n.tc(entityTypeString);
        },
        pageTitle() {
            if (this.readonly) {
                return `${this.entityTypeString}`;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${this.entityTypeString} ${this.$i18n.t(
                    'generic.stateMap.draft',
                )}`;
            }
            return `${this.$i18n.t('generic.addNew')} ${this.entityTypeString}`;
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        disabledTemplateMultiselect() {
            return Boolean(this.inEditMode && !this.isDraftTemplateIdSet);
        },
        isDraftTemplateIdSet() {
            return this.isOnlyDraft && !this.isTemplateIdSet;
        },
        templates() {
            return this.getTemplatesApproved(this.entityType);
        },
        parentRelationsColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        childRelationsColumsData() {
            return [...this.parentRelationsColumnsData];
        },
        allowViewPublishedBtn() {
            return this.$route.params.id && !this.readonly && !this.isOnlyDraft && this.inEditMode;
        },
        allowEditDraftBtn() {
            return this.$route.params.id && this.readonly && !this.isOnlyDraft;
        },
        isDraft() {
            return this.$route.params.id && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.$route.params.id && this.readonly;
        },
        isRevertAvailable() {
            return this.inEditMode && !this.readonly && !this.isOnlyDraft;
        },
        templateGroups() {
            return this?.entityData?.formattedDataForFormBuilder || [];
        },
        // Indicating if there's an error with standard fields (eg: name) in the entity editor
        isMainLayoutInvalid() {
            return (
                !this.entityData.name ||
                !this.entityData.templateId ||
                !this.validateExternalId() ||
                !this.validateUUID()
            );
        },
    },

    created() {
        this.loadData();
    },
    methods: {
        ...mapActions('productcatalog', {
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        ...mapActions(Modules.segments, {
            requestSegments: Actions.FETCH_SEGMENTS,
        }),
        textToLowerCase,
        loadData() {
            this.$withLoadingSpinner(
                async () => {
                    const { id, mode } = this.$route.params;

                    this.readonly = mode === EDITOR_MODE.VIEW;

                    // necessary API calls for all entity types
                    const promises = [
                        this.fetchTemplates(),
                        this.fetchEntities(this.entityType),
                        this.requestTemplatesByType({
                            entityType: TEMPLATE_TYPES_MAP.PRESET,
                        }),
                    ];
                    if (!this.$route.params.clone) {
                        promises.push(this.loadEntityDraft(this.entityType, id));
                    }

                    // API call if children entities are available for current entity type
                    // excl same entity type to editor since it's already loaded
                    if (this.childEntitiesAvailable) {
                        this.childEntitiesTypes.forEach(type => {
                            if (type !== this.entityType) {
                                promises.push(this.fetchEntities(type));
                            }
                        });
                    }

                    // additional API calls for Offer entity type
                    if (this.isOfferEditor) {
                        if (permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
                            promises.push(this.requestSegments());
                        }
                    }

                    await Promise.all(promises);

                    if (id) {
                        this.initData();
                        if (this.readonly) {
                            this.loadVersionHistory(this.entityType, id);
                        }
                    }

                    return true;
                },
                {
                    errorHandler: () => {
                        this.$alert(
                            this.$i18n.t('alertMessage.pc.redirectingToListPage', {
                                entityName: this.entityTypeString.toLowerCase(),
                            }),
                        );

                        setTimeout(this.routeToListPage, 2000);
                    },
                },
            );
        },
        selectVersion(entry) {
            this.currentVersion = entry;
            this.initData();
        },
        async loadEntityDraft(entityType, id) {
            try {
                // Cannot load draft if we don't have an id
                if (!id) {
                    return;
                }

                // Read-only mode does not see draft data
                if (this.readonly) {
                    return;
                }

                // Load draft data
                const result = await getEntityDraft(entityType, id);

                // If the data contents of the draft is empty then we consider it null
                // Short circuit so we don't consider this a valid draft
                const isNullDraft = isEmpty(result?.data?.data?.[id]?.data);
                if (isNullDraft) {
                    return;
                }

                // Construct entityDraft data
                const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
                this.entityDraft = new EntityTypeModel({
                    ...result.data.data[id],
                    entityType: this.entityType,
                    state: STATUS_CODES.NA,
                });

                // Set unpublished flag since we have a draft (that's not null)
                this.isUnpublished = true;
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadDraftData'), { type: ALERT_TYPES.error });
                Sentry.captureException(error);
            }
        },
        async loadVersionHistory(entityType, id) {
            this.versionsHistory = await loadVersionHistory(entityType, id, 'state');
            [this.currentVersion] = this.versionsHistory;
        },
        async loadHistoryEntity(entityType, id) {
            if (id && this.currentVersion.version) {
                await this.$withProgressBar(
                    async () => {
                        const result = await getHistoryVersion(id, entityType, this.currentVersion.version);
                        if (result?.data?.specific_entity_version && this.readonly) {
                            const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
                            this.historyEntity = new EntityTypeModel(result.data.specific_entity_version);
                        }
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        reloadEditor(mode) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({ name: this.routeListName, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.ENTITY_EDITOR,
                        params: {
                            entityType: this.entityType,
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async resetDraft() {
            await this.initData(true);
            this.save(false, true);
        },
        async initData(forceInitPublished = false) {
            let entityData;
            if (this.entityDraft && !forceInitPublished) {
                entityData = cloneDeep(this.entityDraft);
                entityData.version = this.getEntity(this.entityType, this.$route.params.id).version;
                const publishedEntity = this.getEntity(this.entityType, this.$route.params.id);
                this.isOnlyDraft = publishedEntity?.state === 24;
            } else if (this.currentVersion) {
                await this.loadHistoryEntity(this.entityType, this.$route.params.id);
                entityData = cloneDeep(this.historyEntity);
            } else {
                entityData = cloneDeep(this.getEntity(this.entityType, this.$route.params.id));
            }

            this.isTemplateIdSet = Boolean(this?.entityDraft?.templateId);
            this.getUpdateUserName(entityData.updateUser);

            this.entityData = {
                ...entityData,
                // setting this to null in order to let component load all data necessary
                // for rendering referrence type props. Template id is set correctly later.
                templateId: null,
            };

            if (entityData.childEntities && entityData.childEntities.length) {
                if (this.isMultipleChildEntitiesTypes) {
                    this.selectedChildEntitiesType = this.childEntitiesTypesOptions.find(
                        option => option.type === entityData.childEntities[0].type,
                    );
                }

                this.entityData.childEntities = entityData.childEntities.map(childEntity => {
                    const entity = this.getEntity(childEntity.type, childEntity.id);
                    return {
                        ...cloneDeep(entity),
                        displayName: `${entity.name} - ${entity.id}`,
                        type: childEntity.type,
                    };
                });
            }

            // setting watcher like this to prevent removing childEntities in component "edit mode
            this.$watch('selectedChildEntitiesType', () => {
                if (this.isMultipleChildEntitiesTypes) {
                    this.entityData.childEntities = [];
                }
            });

            // clone case data manipulations
            const { name } = entityData;
            const externalID = entityData.externalId || '';

            if (this.$route.params.clone) {
                this.entityData.name = `${name} (cloned)`;
                this.entityData.externalId = `${externalID}_cloned`;
            }

            if (this.isOfferEditor) {
                this.initialOfferData = {
                    startTime: entityData.startTime,
                    endTime: entityData.endTime,
                    currencyType: entityData.currencyType,
                    priceType: entityData.priceType,
                    amountRange: entityData.amountRange,
                    price: entityData.price,
                    categories: entityData.categories,
                };
            }

            // setting data to form builder
            await this.buildDataForFormBuilder(entityData.templateId, true);
            if (forceInitPublished && !this.isOutdatedDraft) {
                this.$alert(this.$i18n.t('productCatalog.editors.revertSuccess'), { type: ALERT_TYPES.success });
            }
        },
        isDropdownType(dataType) {
            return FORM_BUILDER_TYPES.DROPDOWN === dataType || FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE === dataType;
        },
        getTemplateDefaultProperties() {
            const template = this.getTemplate(this.entityType, this.templateId);
            const properties = template?.properties || [];

            return properties.reduce((acc, prop) => {
                if (Object.prototype.hasOwnProperty.call(prop, 'defaultValue') && prop.defaultValue !== undefined) {
                    acc[prop.id] = prop.defaultValue;
                } else if (
                    Object.prototype.hasOwnProperty.call(this.$lfFormBuilder.config[prop.dataType], 'defaultValue')
                ) {
                    acc[prop.id] = this.$lfFormBuilder.config[prop.dataType]?.defaultValue;
                } else {
                    acc[prop.id] = '';
                }

                return acc;
            }, {});
        },
        async buildDataForFormBuilder(id) {
            this.templateRenderError = false;
            const template = this.getTemplate(this.entityType, id);
            if (!this.isDraft || !isEmpty(template)) {
                const data = this.entityData;

                const presetTemplates = template.presetTemplates.reduce((acc, presetTemplate) => {
                    const presetTemplateData = this.getTemplate(TEMPLATE_TYPES_MAP.PRESET, presetTemplate.id);

                    const widgetIds = Object.keys(WIDGETS);

                    if (widgetIds.includes(presetTemplateData.externalId)) {
                        const widgetData = WIDGETS[presetTemplateData.externalId];

                        acc.push({
                            groups: presetTemplateData.groups,
                            properties: [
                                {
                                    id: presetTemplateData.externalId,
                                    name: widgetData.name,
                                    dataType: widgetData.type,
                                    groupId: presetTemplateData.groups[0].id,
                                    defaultValue: widgetData.getDefaultValue(data),
                                },
                            ],
                        });
                    } else {
                        acc.push(presetTemplateData);
                    }

                    return acc;
                }, []);

                const presetTemplatesProperties = presetTemplates.length
                    ? presetTemplates.flatMap(pt => pt.properties)
                    : [];

                const presetTemplatesGroups = presetTemplates.length ? presetTemplates.flatMap(pt => pt.groups) : [];

                let properties = presetTemplates.length
                    ? [...(template?.properties || []), ...presetTemplatesProperties]
                    : template?.properties || [];

                // TODO: refactor cycle by sortWeight with offsets for presets after BE implementation
                const [maxSortWeight] = template.groups.map(({ sortWeight }) => sortWeight);
                const totalLength = template.groups.length + presetTemplatesGroups.length;
                const offsetMaxSortWeight = totalLength === maxSortWeight ? 0 : totalLength - maxSortWeight;
                const groups = [];
                for (let index = totalLength, addedPresets = 0; index > 0; index -= 1) {
                    const sortWeightIteration = index - offsetMaxSortWeight;
                    let group = template.groups.find(({ sortWeight }) => sortWeight === sortWeightIteration);

                    if (!group) {
                        group = presetTemplatesGroups[addedPresets];

                        addedPresets += 1;
                    }

                    groups.push(group);
                }

                this.$set(this.entityData, 'templateData', null);

                properties = properties.map(prop => {
                    const propFormatted = {
                        ...prop,
                    };

                    if (!propFormatted?.componentProps) {
                        propFormatted.componentProps = {};
                    }

                    // these types not available for now
                    if (this.isDropdownType(prop.dataType)) {
                        if (propFormatted.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                            propFormatted.componentProps.labelFormatter = entity => entity.name;
                        }
                    }

                    return propFormatted;
                });

                if (data) {
                    properties = properties.map(prop => {
                        const defaultValue = data?.properties?.[prop.id];

                        if (defaultValue !== undefined) {
                            return {
                                ...prop,
                                defaultValue,
                            };
                        }

                        return prop;
                    });
                }

                /**
                 * Format template data for form builder.
                 * If this operation fails then do not show template section.
                 */
                let formattedData;
                try {
                    formattedData = await ProductCatalogEntityTemplate.formattedDataForFormBuilder(groups, properties);
                } catch (error) {
                    this.$alert(this.$i18n.t('alertMessage.pc.errorRenderingTemplate'));
                    this.templateRenderError = true;
                }

                this.$set(this.entityData, 'formattedDataForFormBuilder', formattedData);
                this.entityData.templateId = id;
            } else if (!this.isDraftTemplateIdSet) {
                this.suggestDeleteEntity();
            }
        },
        suggestDeleteEntity() {
            this.$alert(this.$i18n.t('alertMessage.pc.templateNotAvailable'), {
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmationButton],
            });
        },
        async deleteAction() {
            try {
                if (this.entityData.state !== STATUS_CODES.NA) {
                    await deleteEntity(this.entityType, this.entityData.id, this.entityData.version);
                } else {
                    await deleteEntityDraft(this.entityType, this.entityData.id);
                }
                this.$alert(
                    this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                        action: this.$i18n.t('generic.deleted'),
                        entityName: this.entityTypeString,
                        entityNameRedirect: this.entityTypeString.toLowerCase(),
                    }),
                    { type: ALERT_TYPES.success },
                );

                setTimeout(this.routeToListPage, 1000);
            } catch (e) {
                Sentry.captureException(e);
                this.$alert(
                    this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.deleting'),
                        entityName: this.entityTypeString.toLowerCase(),
                    }),
                );
            }
        },
        // TODO: enable later
        // validateTemplateValues() {
        //     const templateDataArr = Object.values(this.entityData.templateData);
        //     const hasInvalidTemplateVal = templateDataArr
        //         .some(group => group.groupValue.invalidKeys.length);

        //     if (hasInvalidTemplateVal) {
        //         this.showTemplateValidation = true;
        //     }

        //     return hasInvalidTemplateVal;
        // },
        /**
         * Checks if any form builder widgets have detected errors.
         * If there is an error the `value.error` property of the widget will be true.
         */
        validateFormBuilderWidgets() {
            if (!this.entityData.templateData) {
                return false;
            }
            const groups = Object.values(this.entityData.templateData);

            // Build a list of property tuples {externalId, value, type}
            const properties = groups
                .map(group =>
                    Object.entries(group?.groupValue?.value ?? []).map(([externalId, value]) => ({
                        externalId,
                        value,
                        type: group.fields?.find(field => field.id === externalId)?.type,
                    })),
                )
                .flat();

            // Filter out all properties which aren't presets (widgets)
            const presetTypes = Object.keys(WIDGETS);
            const presets = properties.filter(prop => presetTypes.includes(prop.type));

            // Check if any of the widgets report an error
            this.showFormBuilderWidgetValidation = presets.some(preset => preset.value?.error);
            return this.showFormBuilderWidgetValidation;
        },
        getIsInvalid() {
            return this.isMainLayoutInvalid || this.validateFormBuilderWidgets();
            // TODO: add later
            // || this.validateTemplateValues();
        },
        validateUUID() {
            if (this.uuid) {
                return isValidUuid(this.uuid);
            }
            return true;
        },
        validateExternalId() {
            return !this.entityData.externalId || validateAlphaNumericWithDashAndUnderscore(this.entityData.externalId);
        },
        saveEntity(isPublish = true) {
            if (isPublish) {
                this.showValidation = true;
                if (this.getIsInvalid()) {
                    this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'), { type: ALERT_TYPES.error });
                    return;
                }
            }

            const confirmations = [];

            // offer specific validations
            if (this.isOfferEditor) {
                // triggering validation within the OfferCoreData component
                this.$refs.offerCoreDataComponent.validateOfferData();

                if (!this.entityData.childEntities.length) {
                    confirmations.push(this.$i18n.t('alertMessage.pc.offerDoesNotHaveChild'));
                }

                if (!this.isOfferValid) {
                    return;
                }
            }

            this.processConfirmations(confirmations, () => this.save(isPublish));
        },
        processConfirmations(confirmations, successCallback) {
            if (confirmations.length === 0) {
                successCallback();
                return;
            }

            const confirmation = confirmations.pop();
            this.$alert(confirmation, {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.yes').toUpperCase(),
                        alertType: ALERT_TYPES.warning,
                        handler: () => this.processConfirmations(confirmations, successCallback),
                    }),
                    new Button({
                        label: this.$i18n.t('generic.no').toUpperCase(),
                        alertType: ALERT_TYPES.warning,
                    }),
                ],
            });
        },
        save(isPublish = true, stayInEditor = false) {
            this.$withProgressBar(
                async () => {
                    // Generic data part

                    let payload = {};
                    // Entity type specific data part
                    if (this.isOfferEditor) {
                        payload = ProductCatalogOfferModel.toJson({
                            ...this.entityData,
                            ...this.offerCoreData,
                        });
                    } else if (this.isProductEditor) {
                        payload = ProductCatalogProductModel.toJson(this.entityData);
                    } else if (this.isServiceEditor) {
                        payload = ProductCatalogServiceModel.toJson(this.entityData);
                    } else {
                        payload = ProductCatalogEntityBase.toJson(this.entityData);
                    }

                    // Template data part
                    let properties;

                    if (this.entityData.templateId && this.entityData.templateData) {
                        // parsing template related data
                        const templateDataArr = Object.values(this.entityData.templateData);
                        properties = {};

                        templateDataArr.forEach(group => {
                            const formattedProperties = {};

                            Object.entries(group.groupValue.value).forEach(([key, value]) => {
                                const matchingProp = group.fields.find(field => field.id === key);
                                if (matchingProp.type === FORM_BUILDER_TYPES.JSON) {
                                    formattedProperties[key] = value || null;
                                } else if (matchingProp.type === FORM_BUILDER_TYPES.REFERENCE) {
                                    if (value) {
                                        formattedProperties[key] = value.id;
                                    }
                                } else {
                                    formattedProperties[key] = value;
                                }
                            });

                            properties = {
                                ...properties,
                                ...formattedProperties,
                            };
                        });

                        // actually formatting payload which will go to the API
                        payload = {
                            ...payload,
                            ...properties,
                        };
                    } else if (this.entityData.properties) {
                        payload = {
                            ...payload,
                            ...this.entityData.properties,
                        };
                    } else {
                        properties = this.getTemplateDefaultProperties();
                    }

                    const propertiesFormatted = properties || this.entityData.properties;
                    Object.entries(propertiesFormatted).forEach(([key, val]) => {
                        const isArrayValValid = Array.isArray(val) ? val.length : true;

                        if (val === null || !isArrayValValid) {
                            delete propertiesFormatted[key];
                        }
                    });

                    /// SPECIAL HANDLING FOR UNIQUE PRESETS

                    if (payload[FORM_BUILDER_TYPES.PAYOUT_LIMITS]) {
                        if (payload[FORM_BUILDER_TYPES.PAYOUT_LIMITS].error) {
                            this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'));
                            return;
                        }
                        delete payload[FORM_BUILDER_TYPES.PAYOUT_LIMITS].error;

                        payload = {
                            ...payload,
                            ...payload[FORM_BUILDER_TYPES.PAYOUT_LIMITS],
                        };

                        delete payload[FORM_BUILDER_TYPES.PAYOUT_LIMITS];
                    }

                    if (payload[FORM_BUILDER_TYPES.PURCHASE_LIMIT]) {
                        const copyOfPurchaseLimitData = { ...payload[FORM_BUILDER_TYPES.PURCHASE_LIMIT] };
                        delete payload[FORM_BUILDER_TYPES.PURCHASE_LIMIT];
                        // adding all th PurchasLimit related data to the payload
                        payload = { ...payload, ...copyOfPurchaseLimitData };
                    }

                    if (payload[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT]) {
                        const copyOfSubLimit = { ...payload[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT] };
                        delete payload[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT];
                        // binding to a proper key accepted on the BE
                        payload.max_subscription_per_target = copyOfSubLimit;
                    }

                    if (payload[FORM_BUILDER_TYPES.COHORTS_EXPRESSION]) {
                        const copyOfCohort = { ...payload[FORM_BUILDER_TYPES.COHORTS_EXPRESSION] };
                        delete payload[FORM_BUILDER_TYPES.COHORTS_EXPRESSION];
                        // binding to a proper key accepted on the BE
                        payload.cohort_expression = copyOfCohort;
                    }

                    if (payload[FORM_BUILDER_TYPES.TAX_ATTRIBUTION]) {
                        const copyOfTaxAttributionData = { ...payload[FORM_BUILDER_TYPES.TAX_ATTRIBUTION] };
                        delete payload[FORM_BUILDER_TYPES.TAX_ATTRIBUTION];
                        payload.tax = copyOfTaxAttributionData.tax;

                        /**
                         * Note:
                         *  Adding this attribute explicitly on the payload.
                         *  Without this set DNO can potentially trigger the old
                         *  tax calculation.
                         */
                        payload.tax_attribute_version = 2;
                    }

                    if (payload[FORM_BUILDER_TYPES.PRODUCT_TAX_ATTRIBUTE]) {
                        const copyOfProductTaxAttributeData = { ...payload[FORM_BUILDER_TYPES.PRODUCT_TAX_ATTRIBUTE] };
                        delete copyOfProductTaxAttributeData.error;
                        delete payload[FORM_BUILDER_TYPES.PRODUCT_TAX_ATTRIBUTE];
                        payload = { ...payload, ...copyOfProductTaxAttributeData };
                    }

                    if (payload[FORM_BUILDER_TYPES.CP_TO_CSG_MAPPER]) {
                        const copyOfCpToCsgData = { ...payload[FORM_BUILDER_TYPES.CP_TO_CSG_MAPPER] };
                        delete payload[FORM_BUILDER_TYPES.CP_TO_CSG_MAPPER];
                        payload = { ...payload, ...copyOfCpToCsgData };
                    }

                    if (payload[FORM_BUILDER_TYPES.DNO_OBLIGATION]) {
                        const copyOfDnoObligationData = { ...payload[FORM_BUILDER_TYPES.DNO_OBLIGATION] };
                        delete payload[FORM_BUILDER_TYPES.DNO_OBLIGATION];
                        payload = { ...payload, ...copyOfDnoObligationData };
                    }

                    /// END OF SPECIAL HANDLING FOR UNIQUE PRESETS

                    // Make changes on DNO
                    if (isPublish) {
                        if (this.inEditMode) {
                            if (this.isOnlyDraft) {
                                await this.addEntityApiCall(payload, this.$route.params.id);
                            } else {
                                await this.updateEntityApiCall(payload);
                            }
                        } else {
                            await this.addEntityApiCall(payload);
                        }
                    } else {
                        await this.setEntityDraftApiCall(payload);
                    }

                    // Show alert indicating change was made successfully
                    const wasEntityUpdated = this.inEditMode && !this.isOnlyDraft;
                    this.$alert(
                        this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                            action: wasEntityUpdated
                                ? this.$i18n.t('generic.updated')
                                : this.$i18n.t('generic.created'),
                            entityName: this.entityTypeString,
                            entityNameRedirect: this.entityTypeString.toLowerCase(),
                        }),
                        { type: ALERT_TYPES.success },
                    );

                    if (stayInEditor) {
                        this.reloadEditor(EDITOR_MODE.EDIT);
                    } else {
                        setTimeout(this.routeToListPage, 1000);
                    }
                },
                {
                    errorHandler: error => {
                        if (
                            isAxiosError(error) &&
                            error.response?.data &&
                            error.response.data.module === modules.CATALOG &&
                            error.response.data.code === luaErrors.CATALOG.WRONG_VERSION.code
                        ) {
                            this.$alert(
                                this.$i18n.t('alertMessage.pleaseRestartDraftEdit', {
                                    entityName: this.entityTypeString.toLowerCase(),
                                }),
                                {
                                    buttons: [this.restartDraftEditButton],
                                },
                            );
                        } else {
                            this.$alert(
                                this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                    action: this.$i18n.t('generic.saving'),
                                    entityName: this.entityTypeString.toLowerCase(),
                                }),
                            );
                        }
                    },
                },
            );
        },
        routeToListPage() {
            this.$router.push({ name: this.routeListName, params: { companyId: this.$route.params.companyId } });
        },
        async fetchTemplates() {
            await this.requestTemplatesByType({ entityType: this.entityType });
        },
        async fetchEntities(entityType) {
            await this.requestEntitiesByType({ entityType });
        },
        async addEntityApiCall(payload, draftId) {
            const formattedPayload = {
                template_id: this.entityData.templateId,
                data: {
                    ...payload,
                    name: this.entityData.name,
                    description: this.entityData.description,
                },
                ...(draftId && { entity_id: draftId }),
                ...(this.uuid && { entity_id: this.uuid }),
            };

            await addEntity(formattedPayload, this.entityType);
        },
        async updateEntityApiCall(data) {
            await updateEntity(
                {
                    id: this.$route.params.id,
                    version: this.entityData.version,
                    data,
                },
                this.entityType,
            );
        },
        async setEntityDraftApiCall(data) {
            const response = await setEntityDraft(
                {
                    ...(!this.inEditMode && this.uuid && { id: this.uuid }),
                    ...(this.inEditMode && { id: this.$route.params.id }),
                    data,
                    template_id: this.entityData.templateId,
                },
                this.entityType,
            );
            return response;
        },
        openAdvanced() {
            this.advanced = true;
        },
        closeAdvanced() {
            this.uuid = '';
            this.advanced = false;
        },
        applyAdvanced() {
            this.advanced = false;
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        onOfferValidated(isOfferValid) {
            this.isOfferValid = isOfferValid;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/palette';

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.end-line-content {
    height: 1rem;
}
.labels-and-buttons-bar > * {
    margin-left: 1rem;
}
.state-label {
    height: 1.5rem;
}

.table-input {
    width: 5rem;
}

.table-multiselect {
    max-height: 2rem;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.pricing-input {
    max-width: 18rem;
}

.unit-picker {
    // align unit picker
    margin-right: -1rem;

    ::v-deep.multiselect-picker {
        width: 9rem;
    }
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

.inline-warning {
    color: $yellow-800;
    padding: 0.5rem;
    background-color: $yellow-100;
    border: 0.063rem solid $yellow-400;
    border-radius: 0.25rem;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue5;
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}
</style>
