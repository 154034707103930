<template>
    <div
        v-if="entity.enabled"
        v-tooltip="{
            content: entity.name,
            show: showTooltip,
            trigger: 'manual',
            placement: 'right',
            classes: ['navigation-tooltip'],
        }"
    >
        <div
            :class="{ compact }"
            class="menu-item"
            @mouseenter="handleMenuItemHover"
            @mouseleave="handleMenuItemBlur"
        >
            <!-- Entities without dropdown menu -->
            <span
                v-if="entity.link && !entity.subItems"
                @click="handleParentClick"
            >
                <!-- "a" for http(s) links else "router-link" -->
                <component
                    :is="getHtmlTag(entity)"
                    :[getLinkAttr(entity)]="getLink(entity.link, entity.external)"
                    :target="entity.external ? '_blank' : '_self'"
                    :class="{ compact }"
                    class="no-dropdown-wrapper"
                >
                    <div
                        :class="{ compact }"
                        class="img-wrapper"
                    >
                        <AppIcon
                            :type="entity.icon"
                            class="menu-item-icon"
                        />
                    </div>
                    <div
                        class="no-dropdown"
                        @click="setActiveParentItem"
                    >
                        <span
                            :class="{ active: isEntityActive, compact }"
                            class="hover-indicator"
                        />
                        <span
                            :class="{ active: isEntityActive, visible: isEntityVisible, compact }"
                            class="header-without-dropdown"
                        >
                            {{ entity.name }}
                        </span>
                    </div>
                </component>
            </span>
            <div
                v-if="entity.subItems"
                class="dropdown-wrapper"
            >
                <span
                    :class="{ 'active-compact': isEntityActive && compact }"
                    class="hover-indicator"
                />
                <div
                    :class="{ compact }"
                    class="img-wrapper"
                    @click="handleParentClick"
                >
                    <AppIcon
                        :type="entity.icon"
                        class="menu-icon"
                    />
                </div>
                <div
                    :class="{ compact, visible: isEntityVisible && !tenantDropdownVisible }"
                    class="dropdown"
                >
                    <span
                        :key="entity.name"
                        :class="{
                            compact,
                            active: isEntityVisible,
                            'active-parent': isEntityActive,
                        }"
                        class="dropdown-header"
                        @click="handleParentClick"
                    >
                        <div>
                            <span
                                :class="{ 'active-header': isEntityActive && !compact, 'hover-indicator': !compact }"
                            />
                            {{ entity.name }}
                        </div>

                        <span
                            v-show="!compact"
                            class="menu-dropdown-toggle"
                        >
                            <img
                                src="../../assets/icons/arrow-down.svg"
                                alt="dropdown-arrow"
                                class="dropdown-arrow"
                            />
                        </span>
                    </span>
                    <span
                        v-for="(subItem, index) in entity.subItems"
                        :key="index"
                        @click="handleSubItemClick(subItem)"
                    >
                        <!-- "div" for logout, "a" for http(s) links else "router-link" -->
                        <component
                            :is="getHtmlTag(subItem)"
                            v-if="subItem.enabled && isEntityVisible && !tenantDropdownVisible"
                            :[getLinkAttr(subItem)]="getLink(subItem.link, subItem.external)"
                            :target="subItem.external ? '_blank' : '_self'"
                            :class="{ compact, active: subActive === subItem.name }"
                            class="sub-item"
                        >
                            <span
                                :class="{ 'active-sub-indicator': subActive === subItem.name }"
                                class="hover-sub-indicator"
                            />
                            {{ subItem.name }}
                        </component>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Helpers
import Account from '@/models/Account';
import { logout as authLogout } from '@/__new__/services/portal/auth/http/auth';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'SidebarMenuItem',
    components: {
        AppIcon,
    },
    props: {
        entity: {
            type: Object,
            default: () => undefined,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: String,
            default: '',
        },
        subActive: {
            type: String,
            default: '',
        },
        dropdownVisible: {
            type: String,
            default: '',
        },
        tenantDropdownVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            activeParent: true,
            isHover: false,
            showTooltip: false,
        };
    },

    computed: {
        isEntityActive() {
            return this.isActive === this.entity.name;
        },
        isEntityVisible() {
            return this.dropdownVisible === this.entity.name;
        },
    },

    methods: {
        getLink(path, isExternal) {
            if (isExternal) {
                return path;
            }

            if (path === '/') {
                return `/${this.$route.params.companyId}`;
            }
            return `/${this.$route.params.companyId}${path}`;
        },
        getHtmlTag(item) {
            if (item.link?.includes('http')) return 'a';
            if (item.logout) return 'div';
            return 'router-link';
        },
        // if use both - router-link works as "a" tag
        getLinkAttr(item) {
            return item.link?.includes('http') ? 'href' : 'to';
        },
        getSidebarIcon(icon) {
            // if (icon && this.isEntityActive) {
            //     return `${icon}-active`;
            // }
            // if (icon && this.isHover) {
            //     return `${icon}-hover`;
            // }
            return icon || '#';
        },
        handleMenuItemHover() {
            this.isHover = true;
            this.showTooltip = this.compact && (!this.isEntityVisible || !this.entity.subItems?.length);
        },
        handleMenuItemBlur() {
            this.isHover = false;
            this.showTooltip = false;
        },
        handleParentClick() {
            this.setActiveParentItem();
            this.showTooltip = false;
            this.toggleDropdown();
        },
        handleSubItemClick(subItem) {
            if (!subItem.external) {
                this.setActiveSubItem(subItem.name);
            }
            if (subItem.logout) {
                this.logout();
            }
            if (this.compact) {
                this.toggleDropdown();
            }
        },
        setActiveParentItem() {
            // entity is a link / no subItems and do dropdown
            if (this.entity.link) {
                this.$emit('parentActive', this.entity.name);
                this.$emit('subActive', '');
                this.activeParent = false;
            } else {
                // some entity has an active child - check is it this one
                const entityHasActiveSubItem = this.entity.subItems.find(item => item.name === this.subActive);
                if (entityHasActiveSubItem) {
                    this.$emit('parentActive', this.entity.name);
                    this.activeParent = true;
                } else {
                    this.activeParent = false;
                }
            }
        },
        setActiveSubItem(subName) {
            this.activeParent = true;
            this.$emit('subActive', subName);
            this.$emit('parentActive', this.entity.name);
        },
        toggleDropdown() {
            this.$emit('toggleDropdown', this.entity.name);
        },
        async logout() {
            this.$Progress.start();
            try {
                // necessary to pass this as true in case of portal being in an iframe
                const isDestroySession = isUserAllowed('AmazonAppAccess');
                await authLogout(isDestroySession);
                Account.deleteStore();
                this.$Progress.finish();
                // Note: Must use assign instead of reload.
                // Reloading page without session will cause URL at time of logout to be saved in session
                window.location.assign('/');
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('account.unableToLogout'),
                    type: ALERT_TYPES.error,
                });
                Sentry.captureException(error);
                this.$Progress.fail();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/z-indexes';

.hover-indicator {
    @include sidebarHoverIndicator(0, $white, none);
    height: 37px;
}

.no-dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.compact {
        display: inline-flex;
    }

    .img-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 41px;
        cursor: pointer;
        color: $white;

        &.compact {
            position: static;
        }

        &:hover {
            color: $white;
        }

        .menu-item-icon {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            color: $white;
        }
    }

    .no-dropdown {
        display: flex;
        align-items: center;
        min-height: 41px;
        margin-left: 52px;
        color: $white;

        .header-without-dropdown {
            padding: 7px 0;
            color: rgba($white, 0.8);

            &.active {
                font-weight: bold;
                color: $white;
            }

            &.compact {
                display: none;
            }
        }

        .hover-indicator {
            width: 0;

            &.active {
                width: 4px;
            }
        }
    }

    &:hover {
        .hover-indicator {
            width: 4px;
        }
    }
}

.dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;

    .hover-indicator {
        left: 0;

        &.active-compact {
            width: 4px;
            left: 0;
        }
    }

    &:hover {
        .hover-indicator {
            width: 4px;
        }
    }

    .img-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 35px;
        height: 41px; // 41px needed because of the browser's height miscalculation
        width: 41px;
        z-index: $sidebar-stuff-z-index;
        cursor: pointer;

        &.compact {
            position: static;
            .menu-icon {
                margin-left: 21px;
            }
        }

        .menu-icon {
            width: 16px;
            height: 16px;
            margin-left: 16px;

            .compact & {
                position: static;
            }
        }
    }

    .dropdown {
        position: relative;
        min-height: 41px;
        width: 100%;

        &.compact {
            position: absolute;
            background: $navy;
            left: 64px;
            min-width: 250px;
            padding: 5px 0 15px 0;
            border-bottom-right-radius: 13px;
            border-top-right-radius: 13px;
            visibility: hidden;

            .dropdown-header.compact {
                display: block;
                margin-left: 0;
            }

            &.visible {
                visibility: visible;
            }
        }

        .dropdown-header {
            display: flex;
            justify-content: space-between;
            padding: $spacing-xs 0 $spacing-xs 3.25rem;
            width: 100%;
            color: rgba($white, 0.8);
            cursor: pointer;

            &.compact {
                padding-left: $spacing-xl;
                visibility: hidden;
            }

            .menu-dropdown-toggle {
                padding-right: $spacing-xs;
            }

            &.active {
                font-weight: bold;
                color: $white;

                &.compact {
                    visibility: visible;
                }
            }

            &.active-parent {
                font-weight: bold;
                color: $white;
            }

            .hover-indicator {
                &.active-header {
                    width: 4px;
                }
            }

            &:hover {
                .hover-indicator {
                    width: 4px;
                }
            }
        }

        .sub-item {
            display: block;
            position: relative;
            padding: 0.4375rem 0 0.4375rem 3.25rem;
            max-width: 95%;
            color: rgba($white, 0.6);
            cursor: pointer;
            text-decoration: none;

            &.compact {
                padding-left: 1.5rem;
            }

            &.active {
                border-bottom-right-radius: 100px;
                border-top-right-radius: 100px;
                background-color: #21395b;
                font-weight: bold;
                color: $white;
            }

            .hover-sub-indicator {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                border-bottom-right-radius: 100px;
                border-top-right-radius: 100px;
                background-color: $white;
                transition: width 0.2s ease;
                z-index: $sidebar-stuff-z-index;
                opacity: 1;

                &.active-sub-indicator {
                    @extend .hover-sub-indicator;
                    background-image: $sidebar-gradient;
                    width: 4px;
                }
            }

            &:hover {
                .hover-sub-indicator {
                    width: 4px;
                    border-bottom-right-radius: 100px;
                    border-top-right-radius: 100px;
                    background-color: $white;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
