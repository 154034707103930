import { Module, ActionContext } from 'vuex';
import {
    preferences as fetchPreferences,
    getTenantPreferences,
    setTenantPreferences,
    setUserPreferences,
    getUserPreferences,
} from '@/__new__/services/portal/preferences/http/preferences';
import { getTenantLocale, setTenantLocale, getUserLocale, setUserLocale } from '@/http/localization';
import Locale from '@/models/Locale';
import Preferences from '@/models/Preferences';
import Features from '@/models/Features';
import { mapDataFromAPI } from '@/common/featuresHelper';
import {
    getCompanyFeatures,
    getFeatures,
    getViews,
    getCompanyViews,
    getCompanyPermissions,
} from '@/__new__/services/portal/auth/http/login';
import { getOperatorConfig } from '@/__new__/services/portal/operators/http/operators';
import { getViewsByCompanyId } from '@/common/viewsHelper';
import Permission from '@/models/Permission';
import { Views } from '@/models/ViewsHelper';
import { getAccountData } from '@/__new__/services/portal/admin/http/accountdata';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { getEnvLabel, getGrafanaHost } from '@/__new__/services/portal/env/http/env';
import * as Sentry from '@sentry/vue';

export type AccountData = {
    id: number;
    name: string;
    email: string;
    companyName: string;
    companyId: string;
    accountState: string;
    permissions: string[];
    groups: string[];
    externalUserId: string;
};

export interface CustomerCareSuiteColorConfig {
    palette: {
        titleColor: string;
        contentTextColor: string;
        contentBackgroundColor: string;
    };
}

export interface ConfigStoreState {
    locale: Locale;
    preferences: Preferences;
    features: Features;
    views: Record<number, Views>;
    permissions: Permission[];
    operatorConfig: object;
    isConfigLoaded: boolean;
    accountData: AccountData;
    envLabel: string;
    grafanaHost: string;
    [State.COLOR_CONFIG]: CustomerCareSuiteColorConfig;
}

const store: Module<ConfigStoreState, unknown> = {
    namespaced: true,
    state: {
        locale: new Locale({}),
        preferences: new Preferences({}),
        features: new Features({}),
        views: {} as Record<number, Views>,
        permissions: [],
        operatorConfig: {},
        isConfigLoaded: false,
        accountData: {} as AccountData,
        envLabel: '',
        grafanaHost: '',
        [State.COLOR_CONFIG]: {
            palette: {
                titleColor: '#ffffff',
                contentTextColor: '#ffffff',
                contentBackgroundColor: '#ffffff',
            },
        },
    },
    mutations: {
        setLocale: (state: ConfigStoreState, locale: Locale) => {
            state.locale = locale;
        },
        setPreferences: (state: ConfigStoreState, prefs: Preferences) => {
            state.preferences = prefs;
        },
        setFeatures: (state: ConfigStoreState, features: Features) => {
            state.features = features;
        },
        setViews: (state: ConfigStoreState, views: Record<number, Views>) => {
            state.views = views;
        },
        setPermissions: (state: ConfigStoreState, permissions: Permission[]) => {
            state.permissions = permissions;
        },
        setOperatorConfig: (state: ConfigStoreState, operatorConfig: object) => {
            state.operatorConfig = operatorConfig;
        },
        setIsConfigLoaded: (state: ConfigStoreState, isConfigLoaded: boolean) => {
            state.isConfigLoaded = isConfigLoaded;
        },
        setAccountData: (state: ConfigStoreState, accountData: AccountData) => {
            state.accountData = accountData;
        },
        setEnvLabel: (state: ConfigStoreState, envLabel: string) => {
            state.envLabel = envLabel;
        },
        setGrafanaHost: (state: ConfigStoreState, grafanaHost: string) => {
            state.grafanaHost = grafanaHost;
        },
        [Mutations.SET_COLOR_CONFIG]: (state: ConfigStoreState, colorConfig: CustomerCareSuiteColorConfig) => {
            state[State.COLOR_CONFIG] = colorConfig;
        },
    },
    actions: {
        async loadConfig(context: ActionContext<ConfigStoreState, unknown>, companyId: number): Promise<void> {
            const [
                prefs,
                featuresResponse,
                getViewsResponseBody,
                allFeaturesResponse,
                getCompanyViewsResponseBody,
                operatorConfig,
                permissionsResponse,
            ] = await Promise.all([
                fetchPreferences(),
                getFeatures(),
                getViews(),
                getCompanyFeatures(companyId),
                getCompanyViews(companyId),
                getOperatorConfig(),
                getCompanyPermissions(),
            ]);
            context.commit('setLocale', prefs.data.locale);
            context.commit('setPreferences', prefs.data.preferences);
            const features = new Features(mapDataFromAPI(featuresResponse.data, allFeaturesResponse.data));
            context.commit('setFeatures', features);
            context.commit(
                'setViews',
                getViewsByCompanyId(getViewsResponseBody.data, getCompanyViewsResponseBody.data),
            );
            context.commit('setOperatorConfig', operatorConfig.data);
            context.commit('setPermissions', permissionsResponse.data);
            context.commit('setIsConfigLoaded', true);
        },
        async loadPreferences(context: ActionContext<ConfigStoreState, unknown>): Promise<void> {
            const {
                data: { locale, preferences },
            } = await fetchPreferences();
            context.commit('setLocale', locale);
            context.commit('setPreferences', preferences);
        },
        async loadPermissions(context: ActionContext<ConfigStoreState, unknown>): Promise<boolean> {
            const permissionResponse = await getCompanyPermissions();
            context.commit('setPermissions', permissionResponse.data);
            return true;
        },
        async loadAccountData(context: ActionContext<ConfigStoreState, unknown>): Promise<boolean> {
            const accountDataResponse = await getAccountData();
            context.commit('setAccountData', accountDataResponse.data);
            return true;
        },
        async loadEnvData(context: ActionContext<ConfigStoreState, unknown>): Promise<void> {
            try {
                const envLabelResponse = await getEnvLabel();
                context.commit('setEnvLabel', envLabelResponse.data);
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async loadGrafanaHost(context: ActionContext<ConfigStoreState, unknown>): Promise<void> {
            try {
                const grafanaHostResponse = await getGrafanaHost();
                context.commit('setGrafanaHost', grafanaHostResponse.data);
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async getTenantLocale(): Promise<Locale> {
            const response = await getTenantLocale();
            return response.data;
        },
        async saveTenantConfig(
            context: ActionContext<ConfigStoreState, unknown>,
            config: { locale: Locale; preferences: Preferences },
        ): Promise<void> {
            const promises = [setTenantLocale(config.locale)];
            if (config.preferences) {
                promises.push(setTenantPreferences(config.preferences));
            }
            await Promise.all(promises);
            await context.dispatch('loadPreferences');
        },
        async getTenantPreferences(): Promise<Preferences> {
            const response = await getTenantPreferences();
            return response.data;
        },

        async getUserLocale(): Promise<Locale> {
            const response = await getUserLocale();
            return response.data;
        },
        async saveUserConfig(
            context: ActionContext<ConfigStoreState, unknown>,
            config: { locale: Locale; preferences: Preferences },
        ): Promise<void> {
            await Promise.all([setUserLocale(config.locale), setUserPreferences(config.preferences)]);
            await context.dispatch('loadPreferences');
        },
        async getUserPreferences(): Promise<Preferences> {
            const response = await getUserPreferences();
            return response.data;
        },
        async [Actions.LOAD_COLOR_CONFIG](context: ActionContext<ConfigStoreState, any>): Promise<void> {
            //ToDo: Implement color config load when API will be implemented
            context.commit(Mutations.SET_COLOR_CONFIG, {
                palette: {
                    titleColor: '#ffffff',
                    contentTextColor: '#ffffff',
                    contentBackgroundColor: '#ffffff',
                },
            });
        },
        async [Actions.SAVE_COLOR_CONFIG](
            context: ActionContext<ConfigStoreState, unknown>,
            // colorConfig: CustomerCareSuiteColorConfig,
        ): Promise<void> {
            //ToDo: Implement color config save when API will be implemented
            context.commit(Mutations.SET_COLOR_CONFIG, {
                palette: {
                    titleColor: '#ffffff',
                    contentTextColor: '#ffffff',
                    contentBackgroundColor: '#ffffff',
                },
            });
        },
    },
    getters: {
        getFeatures(state: ConfigStoreState): Features {
            return state.features;
        },
        getViews(state: ConfigStoreState): Record<number, Views> {
            return state.views;
        },
        getPermissions(state: ConfigStoreState): Permission[] {
            return state.permissions;
        },
        getOperatorConfig(state: ConfigStoreState): object {
            return state.operatorConfig;
        },
        getIsConfigLoaded(state: ConfigStoreState): boolean {
            return state.isConfigLoaded;
        },
        getAccountData(state: ConfigStoreState): AccountData {
            return state.accountData;
        },
        getEnvLabel(state: ConfigStoreState): string {
            return state.envLabel;
        },
        getGrafanaHost(state: ConfigStoreState): string {
            return state.grafanaHost;
        },
        [Getters.GET_COLOR_CONFIG]: (state: ConfigStoreState): any => state[State.COLOR_CONFIG] || {},
    },
};

export default store;
