import { render, staticRenderFns } from "./AccountPageAnchor.vue?vue&type=template&id=c0915724&scoped=true"
import script from "./AccountPageAnchor.vue?vue&type=script&lang=ts"
export * from "./AccountPageAnchor.vue?vue&type=script&lang=ts"
import style0 from "./AccountPageAnchor.vue?vue&type=style&index=0&id=c0915724&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0915724",
  null
  
)

export default component.exports