
import Vue, { PropType } from '@/common/typedVue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AdditionalSidebarDeliveries from '@/__new__/features/customerCare/account/AdditionalSidebarDeliveries.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import {
    DELIVERY_RESHIPMENT_REMOTE_CONFIG,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { remapDeliveriesFromBe } from '@/__new__/features/customerCare/common/deliveriesHelper';
import {
    DELIVERY_STATES,
    DELIVERY_STATES_TO_STATUS_NAME_MAP,
    DELIVERY_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/account/common/deliveryStateHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { REMOTE_CONFIG_KEYS } from '@/__new__/features/resources/common/sim';
import { ICON_TYPES } from '@/common/iconHelper';
import moment from 'moment';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import Delivery from '@/__new__/services/dno/orders/models/Delivery';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import routeNames from '@/router/routeNames';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import coreOMHTTP from '@/__new__/services/dno/coreom/http/coreom';

export default Vue.extend({
    name: 'DeliveriesTile',
    components: {
        AssociatedUMEntityPicker,
        AbstractTableTile,
        AdditionalSidebarDeliveries,
        EntityStatusIndicator,
        IconButton,
    },
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
        },
    },
    data() {
        return {
            selectedAssociatedAccount: null as Record<string, any> | null,
            deliveries: {},
            apiResponse: {},
            showAdditionalSidebar: false,
            selectedDelivery: {},
            remoteConfig: {
                notAllowedMinHours: 24,
                notAllowedMaxDays: 28,
            } as DELIVERY_RESHIPMENT_REMOTE_CONFIG,
            friendlyOrderIdsMap: {} as Record<string, string>,

            // proxy
            DELIVERY_STATES_TO_STATUS_NAME_MAP,
            DELIVERY_STATUS_INDICATOR_MAP,
            USER_MANAGER_HIERARCHY,
            ICON_TYPES,
            TOOLTIP_POSITION,
            isUserAllowed,
        };
    },
    computed: {
        isTileForUserHierarchy(): boolean {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER;
        },
        isTileForAccountHierarchy(): boolean {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT;
        },
        deliveriesFormatted(): any[] {
            return Object.values(this.deliveries).map((delivery: any) => ({
                ...delivery,
                friendlyOrderId: this.friendlyOrderIdsMap[delivery.orderId] || '',
            }));
        },
        columnsData(): any[] {
            return [
                {
                    name: this.$t('customerCare.deliveries.deliveryId'),
                    key: 'deliveryId',
                    forbidHideColumn: true,
                    field: 'deliveryId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    forbidHideColumn: true,
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCare.deliveries.addressId'),
                    key: 'addressId',
                    field: 'addressId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCare.deliveries.trackingId'),
                    key: 'trackingId',
                    field: 'trackingId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.deliveriesFormatted.map(entity => entity.state))),
                },
            ];
        },
        reshipmentAvailable(): (delivery: Delivery) => {
            minHours: boolean;
            maxDays: boolean;
            notUnsuccessful: boolean;
            result: boolean;
        } {
            return delivery => {
                const deliveryMoment = moment.utc(delivery?.createdAt, 'x');
                // Delivery status is not 'Unsuccessful'
                const notUnsuccessful = delivery?.status !== DELIVERY_STATES.UNSUCCESSFUL;
                // Delivery created not earlier than MIN hours ago
                const minHours = moment.utc().diff(deliveryMoment, 'h') >= (this.remoteConfig?.notAllowedMinHours || 0);
                // Delivery created not earlier than MAX days ago
                const maxDays = moment.utc().diff(deliveryMoment, 'd') <= (this.remoteConfig?.notAllowedMaxDays || 0);
                return {
                    minHours,
                    maxDays,
                    notUnsuccessful,
                    result: [notUnsuccessful, minHours, maxDays].every(Boolean),
                };
            };
        },
        reshipmentButtonLabel(): (delivery: Delivery) => string {
            return delivery => {
                switch (true) {
                    case !this.reshipmentAvailable(delivery).notUnsuccessful:
                        return this.$t('reshipment.notAllowedStatus');
                    case !this.reshipmentAvailable(delivery).minHours:
                        return this.$t('reshipment.notAllowedMinHours', {
                            hours: this.remoteConfig?.notAllowedMinHours,
                        });
                    case !this.reshipmentAvailable(delivery).maxDays:
                        return this.$t('reshipment.notAllowedMaxDays', {
                            days: this.remoteConfig?.notAllowedMaxDays,
                        });
                    default:
                        return '';
                }
            };
        },
    },
    watch: {
        userManagerHierarchy: {
            handler(newVal) {
                if (newVal === USER_MANAGER_HIERARCHY.ACCOUNT) {
                    this.selectedAssociatedAccount = {
                        id: this.$route.params.id,
                    };
                }
            },
            immediate: true,
        },
        selectedAssociatedAccount: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    const response = await ordersHTTP.getDeliveryDetails({
                        targetId: this.selectedAssociatedAccount?.id,
                        targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    });
                    this.deliveries = remapDeliveriesFromBe(response.data?.data);
                    for (const property in this.deliveries) {
                        if (this.deliveries[property]) {
                            this.deliveries[property].state = this.deliveries[property].status;
                        }
                    }
                    this.apiResponse = response;

                    if (isUserAllowed('UMAccountOrderDetailsRead')) {
                        const ordersResponse = await coreOMHTTP.getOrders(
                            this.selectedAssociatedAccount?.id,
                            USER_MANAGER_HIERARCHY.ACCOUNT,
                        );
                        this.friendlyOrderIdsMap = Object.fromEntries(
                            Object.values(ordersResponse?.data?.orders || {}).map(order => [
                                order.id,
                                order.friendly_order_id,
                            ]),
                        );
                    }

                    if (isUserAllowed('UMAccountRequestReshipment')) {
                        const { data: remoteConfig } = await getRemoteConfig();
                        if (remoteConfig?.data[REMOTE_CONFIG_KEYS.DELIVERY_RESHIPMENT]) {
                            this.remoteConfig = JSON.parse(
                                remoteConfig.data[REMOTE_CONFIG_KEYS.DELIVERY_RESHIPMENT].value,
                            );
                        }
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        toggleModal(val: boolean) {
            this.showAdditionalSidebar = val;
        },
        selectDelivery(deliveryId: string) {
            this.selectedDelivery = this.deliveriesFormatted.find(entity => entity.deliveryId === deliveryId);
        },
        createReshipment(deliveryId: string) {
            this.$router.push({
                name: routeNames.CCS_DELIVERY_RESHIPMENT,
                params: {
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    targetId: this.selectedAssociatedAccount?.id,
                    deliveryId,
                },
            });
        },
    },
});
