import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export const OPERATE_APIS_TYPES = {
    APPLICATION: 'application',
    APPLICATION_OWNER: 'application_owner',
    API_PRODUCT_ORDER: 'api_product_order',
    API_PRODUCT: 'api_product',
};

export enum APPROVAL_HISTORY_STATUS {
    PENDING_APPROVAL = 'pendingApproval',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export const APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP = new Map([
    [APPROVAL_HISTORY_STATUS.PENDING_APPROVAL, i18n.t('generic.stateMap.pendingApproval')],
    [APPROVAL_HISTORY_STATUS.APPROVED, i18n.t('generic.stateMap.approved')],
    [APPROVAL_HISTORY_STATUS.REJECTED, i18n.t('generic.stateMap.rejected')],
]);

export const APPROVAL_HISTORY_STATUS_INDICATOR_MAP = new Map([
    [APPROVAL_HISTORY_STATUS.PENDING_APPROVAL, LABEL_COLOR.yellow],
    [APPROVAL_HISTORY_STATUS.APPROVED, LABEL_COLOR.green],
    [APPROVAL_HISTORY_STATUS.REJECTED, LABEL_COLOR.red],
]);

export default { OPERATE_APIS_TYPES };
