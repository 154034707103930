import i18n from '@/i18n';
import { invert, uniqBy, compact, isEmpty } from 'lodash';
import Validator from 'z-schema';
import { capitalizeFirstLetter, stringToSnakeCase } from '@/common/formatting';
import { SinkConfigCol, SinkConfigRowValue } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { TranslateResult } from 'vue-i18n';
import { Event } from '@/__new__/services/dno/events/models/Event';
import { SinkRequiredFields } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import EventProp from '@/__new__/services/dno/events/models/EventProp';
import SinkTypeCaster from '@/__new__/services/dno/sinkConfigs/models/SinkTypeCasters';
import { snakeToCamelCase } from '@/common/utils';

// types
export type StepperItem = {
    title: TranslateResult;
    isCompleted: boolean;
};

export const validateName = (str: string): boolean => {
    const regexp = new RegExp('^([a-z][a-z0-9]*)(_[a-z0-9]+)*$');
    return regexp.test(str);
};

export const validateEventName = (str: string): boolean => {
    const regexp = new RegExp('^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$');
    return regexp.test(str);
};

export const validatePropertyName = validateEventName;

export const validateEventType = validateName;

export const nameErrorMsg = (nameIsValid: boolean, nameIsNotEmpty: boolean): string | TranslateResult => {
    let errorMsg = '' as TranslateResult;
    if (!nameIsValid) {
        errorMsg = i18n.t('events.alerts.failedNameValidation');
    }
    if (!nameIsNotEmpty) {
        errorMsg = i18n.t('generic.validations.fieldIsRequired');
    }
    return errorMsg;
};

export const sinkFormattingFunctions = {
    stringToSnakeCase,
};

export const eventStatuses = {
    PUBLISHED: i18n.t('events.statuses.published'),
    DRAFT: i18n.t('events.statuses.draft'),
    UNPUBLISHED: i18n.t('events.statuses.unpublished'),
};

export const EVENT_SOURCE_NAME_VALUES = {
    EXTERNAL: 'external',
    INTERNAL: 'internal',
};

export const CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS = {
    EXTERNAL: 0,
    INTERNAL: 1,
};

export const CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_VALUES = invert(CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS);

export const CDP_SUPPORTED_API_CONTROL_AUTH_TYPES = {
    SERVICE: 'service',
    CLIENT: 'client',
    SERVER: 'server',
    UNREGISTERED: 'unregistered',
};

export const getAPIControlItemsList = (
    internalAPIVal: boolean,
    clientAPIVal: boolean,
    serverAPIVal: boolean,
    unauthenticatedAPIVal: boolean,
) => {
    return [
        {
            label: i18n.t('events.eventApiControlsList.internalApi'),
            description: i18n.t('events.eventApiControlsList.internalApiDescription'),
            value: internalAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.clientApi'),
            description: i18n.t('events.eventApiControlsList.clientApiDescription'),
            value: clientAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.serverToServerApi'),
            description: i18n.t('events.eventApiControlsList.serverToServerApiDescription'),
            value: serverAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.unauthenticatedApi'),
            description: i18n.t('events.eventApiControlsList.unauthenticatedApiDescription'),
            value: unauthenticatedAPIVal,
        },
    ];
};

export const eventRequiredFieldsFromJson = (requiredFields: SinkRequiredFields[], events: Event[]): SinkConfigCol[] =>
    requiredFields.map(field => {
        let relatedFormatter = null;
        if (field.formatting_settings) {
            relatedFormatter = {
                name: field.formatting_settings.name,
                options: field.formatting_settings.params,
                value: field.formatting_settings.params[0],
                conversionType: field.formatting_settings.final_field_type,
                columnType: field.formatting_settings.final_field_type,
            };
        }

        return new SinkConfigCol({
            name: field.sink_event_field_name,
            // doc is the same for all required fields, thats' why we can take first event [0]
            doc: events[0]?.properties.find(prop => prop.name === field.sink_event_field_name)?.doc,
            defaultValueType: field.sink_event_field_type,
            forbidFieldRemoval: true,
            rowValues: uniqBy(events, event => event.eventType).map(event => {
                const eventPropName =
                    event?.properties.find(prop =>
                        [field.custom_event_field_name, snakeToCamelCase(field.custom_event_field_name)].includes(
                            prop.name,
                        ),
                    )?.name || field.custom_event_field_name;
                return new SinkConfigRowValue({
                    eventName: event.name.toString(),
                    eventType: event.eventType,
                    property: new EventProp({
                        name: eventPropName,
                        mandatory: true,
                        type: field.custom_event_field_type,
                        sourceType: field.custom_event_field_type,
                    }),
                });
            }),
            formatter: relatedFormatter,
            typeCaster: isEmpty(field.casting_settings)
                ? null
                : new SinkTypeCaster({
                      label: `to ${capitalizeFirstLetter(field?.casting_settings.name)}`,
                      toType: field.casting_settings?.final_field_type,
                  }),
            isRequiredField: true,
        });
    });
// is used for table filters on events dashboard page
export function getAllDataFromEventField(events: Event[], fieldName: keyof Event) {
    return Array.from(
        new Set(
            compact(
                events
                    .map(e => e[fieldName])
                    .flat()
                    .filter(e => !isEmpty(e)),
            ),
        ),
    );
}

const LFJsonSchema = {
    description: 'Event json schema format',
    type: 'object',
    required: ['title', 'humanReadableName', 'description', 'properties', 'type', 'required'],
    properties: {
        type: {
            enum: ['object'],
        },
        description: {
            type: 'string',
        },
        // https://github.com/lotusflare/lua/blob/master/services_root/services/customevent/resources/jsonschema/event_schema.json#L24
        title: {
            type: 'string',
            minLength: 3,
            pattern: '^([A-Za-z]+(_[a-z0-9]+)*|[a-z]+[A-Za-z0-9]*)$',
        },
        humanReadableName: {
            type: 'string',
            minLength: 1,
            pattern: '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        },
        required: {
            type: 'array',
            items: {
                type: 'string',
            },
        },
        properties: {
            oneOf: [
                {
                    type: 'object',
                    required: ['event_type', 'id_type', 'id_value', 'epoch_millis'],
                    properties: {
                        event_type: {
                            type: 'object',
                            properties: {
                                type: {
                                    type: 'string',
                                },
                                description: {
                                    enum: ['Unique name used by the platform to identify event type'],
                                },
                            },
                        },
                        id_type: {
                            type: 'object',
                            properties: {
                                type: {
                                    enum: ['integer'],
                                },
                                description: {
                                    enum: [
                                        'The type of the unique identifier (for the list of supported id types refer to product documentation)',
                                    ],
                                },
                            },
                        },
                        id_value: {
                            type: 'object',
                            properties: {
                                type: {
                                    type: 'string',
                                },
                                description: {
                                    enum: [
                                        'The unique identifier used to provide the value for the id_type group member',
                                    ],
                                },
                            },
                        },
                        epoch_millis: {
                            type: 'object',
                            properties: {
                                type: {
                                    enum: ['integer'],
                                },
                                description: {
                                    enum: ['The timestamp when the event was produced, in epoch milliseconds format'],
                                },
                            },
                        },
                    },
                },
                {
                    type: 'object',
                    required: ['eventType', 'idType', 'idValue', 'epochMillis'],
                    properties: {
                        eventType: {
                            type: 'object',
                            properties: {
                                type: {
                                    type: 'string',
                                },
                                description: {
                                    enum: ['Unique name used by the platform to identify event type'],
                                },
                            },
                        },
                        idType: {
                            type: 'object',
                            properties: {
                                type: {
                                    enum: ['integer'],
                                },
                                description: {
                                    enum: [
                                        'The type of the unique identifier (for the list of supported id types refer to product documentation)',
                                    ],
                                },
                            },
                        },
                        idValue: {
                            type: 'object',
                            properties: {
                                type: {
                                    type: 'string',
                                },
                                description: {
                                    enum: [
                                        'The unique identifier used to provide the value for the id_type group member',
                                    ],
                                },
                            },
                        },
                        epochMillis: {
                            type: 'object',
                            properties: {
                                type: {
                                    enum: ['integer'],
                                },
                                description: {
                                    enum: ['The timestamp when the event was produced, in epoch milliseconds format'],
                                },
                            },
                        },
                    },
                },
            ],
        },
    },
};

export function isJsonSchemaValid(schemaInstance: any) {
    const validator = new Validator({
        breakOnFirstError: true,
    });
    const isSchemaValid = validator.validate(schemaInstance, LFJsonSchema);

    return isSchemaValid ? '' : `${validator.getLastErrors()[0].message}, path: ${validator.getLastErrors()[0].path}`;
}

export default {
    validateName,
};
