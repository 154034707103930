<template>
    <AbstractListPageWrapper
        :pageTitle="pageTitle"
        :entitiesCount="entitiesCount"
        :isOverviewEnabled="isOverviewEnabled"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template slot="button">
            <ResponseModalButton
                :response="entityResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdd"
            />
        </template>

        <!-- Commented for now because it doesn't work and we have demo -->
        <!-- <template slot="headerButtons">
            <AppButton
                v-if="writePermission"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="addButtonLabel"
                data-test-id="add-entity-button"
                @click="onAdd"
            />
        </template> -->

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="index => removeFilter(index, filtersLocalStorageKey)"
                @removeAllFilters="removeAllFilters(filtersLocalStorageKey)"
            />
        </template>

        <template slot="customHtml">
            <div class="m-4">
                <AppMultiselectV3
                    v-model="selectedPartyRole"
                    :additionalLabel="$i18n.t('operateAPIs.selectChannelPartner')"
                    :options="partyRoleOptions"
                    :small="true"
                    :allowEmpty="false"
                    label="name"
                    optionId="id"
                    class="party-role-multiselect"
                />
            </div>
        </template>

        <template slot="table">
            <AppTable
                :entities="formattedFilteredEntities"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :columnsData="tableColumnsData"
                :selectedEntityId="selectedEntityId"
                :tableKey="`${entityType}-table`"
                :data-test-id="`${entityType}-table`"
                @selectEntity="selectEntity"
            >
                <template #customRowButtons="{ entity }">
                    <IconButton
                        v-if="showModalOnPendingApproval(entity.approvalStatus)"
                        :label="$i18n.t('generic.edit')"
                        :icon="ICON_TYPES.EDIT"
                        class="mr-2"
                        @iconClick="onOpenEditEntityModal(entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template slot="overview">
            <EntityOverview
                :entityType="entityType"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <div
                    slot="section-1-content"
                    key="1"
                >
                    <slot name="sidebarOverview" />

                    <h2 class="lf-title mb-2 mt-3">
                        {{ $i18n.t('formBuilderTypes.JSON') }}
                    </h2>
                    <VueJsonPretty
                        selectableType="single"
                        :data="selectedEntityJSON"
                        :deep="2"
                        class="state-history-content"
                    />

                    <div v-if="approvalHistoryEnabled">
                        <h2 class="lf-title mb-2 mt-3">
                            {{ $i18n.t('operateAPIs.approvalHistory') }}
                        </h2>

                        <div
                            v-for="(entry, index) in approvalHistoryEntities"
                            :key="`approval-history-${index}`"
                        >
                            <div
                                class="d-flex lf-primary-text version-item"
                                @click="selectApprovalHistoryEntity(entry)"
                            >
                                <span>{{ entry.label }}</span>
                                <EntityStatusIndicator
                                    v-if="entry.state"
                                    :status="entry.state"
                                    :stateMap="APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP"
                                    :stateMapColor="APPROVAL_HISTORY_STATUS_INDICATOR_MAP"
                                    class="justify-content-end flex-grow-1 state-label"
                                >
                                </EntityStatusIndicator>
                            </div>
                        </div>
                    </div>
                </div>
            </EntityOverview>
        </template>

        <template slot="modal">
            <AppDialogV2
                :title="$i18n.t('operateAPIs.editEntity')"
                :visible="isEditEntityModalVisible"
                @close="isEditEntityModalVisible = false"
            >
                <AppMultiselectV3
                    v-model="selectedApprovalStatus"
                    :additionalLabel="$i18n.t('operateAPIs.approvalStatus')"
                    :options="approvalStatusOptions"
                    :small="true"
                    :showLabels="false"
                    label="label"
                    optionId="id"
                />
                <AppInputV3
                    v-model="approvalStatusReason"
                    :label="$i18n.t('operateAPIs.approvalStatusReason')"
                    :invalid="$v.approvalStatusReason.$error"
                    :placeholder="$i18n.t('operateAPIs.approvalStatusReason')"
                    class="mt-3"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$t('generic.save')"
                        class="mr-3"
                        @click="onEditEntity"
                    />
                </template>
            </AppDialogV2>

            <AppDialogV2
                :title="$i18n.t('operateAPIs.approvalHistory')"
                :visible="isApprovalHistoryEntityModalVisible"
                :disableDefaultSaveBtn="true"
                @close="onCloseApprovalHistoryEntityModal"
            >
                <VueJsonPretty
                    selectableType="single"
                    :data="selectedApprovalHistoryEntityJSON"
                    :deep="2"
                    class="state-history-content"
                />
            </AppDialogV2>

            <slot name="additionalModal" />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';
import {
    OPERATE_APIS_TYPES,
    APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP,
    APPROVAL_HISTORY_STATUS_INDICATOR_MAP,
} from '@/__new__/features/operateAPIs/common/operateAPIsHelper';
import { required } from 'vuelidate/lib/validators';
import localeLibrary from '@/common/locale/localeLibrary';

// Http
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';
import { getApprovalHistory } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

const APPROVAL_STATUS_OPTIONS = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING_APPROVAL: 'pendingApproval',
};

export default {
    name: 'OperateApisListPageLayout',

    components: {
        AppMultiselectV3,
        AppDialogV2,
        AppTable,
        AppButton,
        AppInputV3,
        AbstractListPageWrapper,
        ResponseModalButton,
        TableFiltersTags,
        FilterTable,
        EntityOverview,
        VueJsonPretty,
        IconButton,
        EntityStatusIndicator,
    },

    mixins: [FilterTableMixin, ListPageMixin],

    props: {
        writePermission: {
            type: Boolean,
            required: false,
            default: false,
        },
        entityType: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            default: '',
        },
        entityResponse: {
            type: Object,
            default: () => undefined,
        },
        entityData: {
            type: Array,
            default: () => undefined,
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        addNewRouteName: {
            type: String,
            default: '',
        },
        approvalHistoryEnabled: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            approvalStatusOptions: [
                { label: this.$i18n.t('generic.stateMap.approved'), id: APPROVAL_STATUS_OPTIONS.APPROVED },
                { label: this.$i18n.t('generic.stateMap.rejected'), id: APPROVAL_STATUS_OPTIONS.REJECTED },
            ],
            approvalStatusReason: '',
            searchQueryForTable: '',
            isOverviewEnabled: false,
            selectedEntity: null,
            selectedEntityJSON: {},
            selectedApprovalStatus: '',
            selectedEnityId: '',
            isEditEntityModalVisible: false,
            partyRoleOptions: [],
            selectedPartyRole: null,
            selectedEntityId: null,
            approvalHistoryEntities: [],
            selectedApprovalHistoryEntityJSON: {},
            isApprovalHistoryEntityModalVisible: false,
            filtersLocalStorageKey: `operate-apis-${this.entityType}`,

            ICON_TYPES,
            BUTTON_TYPES,
            APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP,
            APPROVAL_HISTORY_STATUS_INDICATOR_MAP,
        };
    },

    validations() {
        return {
            approvalStatusReason: {
                required,
            },
        };
    },

    computed: {
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entityData);
        },
        entitiesCount() {
            return this.entityData?.length || 0;
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.entityType !== OPERATE_APIS_TYPES.API_PRODUCT_ORDER) {
                columns.unshift({
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if (this.entityType !== OPERATE_APIS_TYPES.API_PRODUCT) {
                columns.unshift({
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return columns;
        },
    },

    watch: {
        selectedPartyRole: {
            handler(val) {
                this.$emit('onSelectPartyRole', val);
            },
        },
    },

    created() {
        this.$withLoadingSpinner(
            async () => {
                const response = await getPartyRoles('ChannelPartner');

                this.partyRoleOptions = response?.data?.map(partyRole => ({
                    id: partyRole?.id || '',
                    name: partyRole?.name || '',
                }));

                [{ id: this.selectedPartyRole }] = this.partyRoleOptions;
                this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        onAdd() {
            this.$router.push({ name: this.addNewRouteName });
        },
        selectApprovalHistoryEntity(entity) {
            this.selectedApprovalHistoryEntityJSON = entity.data;
            this.isApprovalHistoryEntityModalVisible = true;
        },
        onCloseApprovalHistoryEntityModal() {
            this.isApprovalHistoryEntityModalVisible = false;
            this.selectedApprovalHistoryEntityJSON = '';
        },
        async selectEntity(entity) {
            this.selectedEntityId = entity;
            this.selectedEntity = this.entityData.find(pr => pr.id === entity);
            this.isOverviewEnabled = true;
            this.$emit('onSelectEntity', entity);

            // Set value for JSON editor
            this.selectedEntityJSON = this.entityResponse.data.find(obj => obj.id === entity);

            if (this.approvalHistoryEnabled) {
                let response;
                try {
                    response = await getApprovalHistory({
                        channel_partner_id: this.selectedPartyRole,
                        type: this.entityType,
                        id: entity,
                    });
                } catch (err) {
                    console.log('approval history err', err);
                }

                if (response?.data?.data?.length) {
                    this.approvalHistoryEntities = await Promise.all(
                        response.data.data.map(async approvalHistoryEntity => {
                            const { approver_portal_id: approverPortalId, data } = approvalHistoryEntity;

                            const timestamp = data?.lastUpdate
                                ? `${localeLibrary.getFormattedDateAndTime(data.lastUpdate)}`
                                : '';

                            let userName;

                            try {
                                const user = await getUserNameById(approverPortalId);
                                userName = user?.data || '';
                            } catch (error) {
                                userName = '';
                            }

                            const label = [userName, timestamp].filter(Boolean).join(' - ');

                            return {
                                data,
                                label,
                                state: data?.approvalStatus || '',
                            };
                        }),
                    );
                }
            }
        },
        onOpenEditEntityModal(entity) {
            this.selectedEnityId = entity.id;
            this.selectedApprovalStatus = entity.approvalStatus;
            this.isEditEntityModalVisible = true;
        },
        onEditEntity() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.$emit('onEditEntity', {
                id: this.selectedEnityId,
                selectedApprovalOption: this.selectedApprovalStatus,
                approvalStatusReason: this.approvalStatusReason,
            });

            this.isEditEntityModalVisible = false;
        },
        onFilterAdd(filter) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
        showModalOnPendingApproval(approvalStatus) {
            return approvalStatus === APPROVAL_STATUS_OPTIONS.PENDING_APPROVAL;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.party-role-multiselect {
    width: 50% !important;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue-200;
    }

    &.active {
        color: $blue;
        background-color: $blue-300;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.state-label {
    height: 1.5rem;
}
</style>
