
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// http
import OssmvneHTTP, { ImeiHistoryItem } from '@/__new__/services/dno/ossmvne/http/ossmvne';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { AxiosError } from 'axios';
import { mapGetters } from 'vuex';
import uniq from 'lodash/uniq';

export default Vue.extend({
    name: 'ImeiHistoryTile',
    components: {
        AbstractTableTile,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            rawResponse: {} as any,
            imeiHistory: [] as ImeiHistoryItem[],
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData(): Subscriber {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetId: this.$route.params.id,
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
            }) as Subscriber;
        },
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.lastUpdated'),
                    key: 'lastUpdateDateTime',
                    field: 'lastUpdateDateTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('customerCare.imei'),
                    key: 'imei',
                    field: 'imei',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.make'),
                    key: 'make',
                    field: 'make',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.imeiHistory.map(e => e.make)),
                },
                {
                    name: this.$t('generic.model'),
                    key: 'model',
                    field: 'model',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.imeiHistory.map(e => e.model)),
                },
                {
                    name: this.$t('generic.blocked'),
                    key: 'blocked',
                    field: 'blocked',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();

                this.rawResponse = await OssmvneHTTP.subscriberImeiHistory({
                    target_id: this.$route.params.id,
                    target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    msisdn: this.subscriberData?.msisdn || '',
                });
                this.imeiHistory = this.rawResponse.data?.imeiInfo || [];

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                this.rawResponse = (error as AxiosError).response;
                Sentry.captureException(error);
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
    },
});
