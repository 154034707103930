import http, { DnoResponseBase } from '@/http/index';
import { AxiosPromise } from 'axios';

/**
 * Get CSG
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecificationsGroups(ids = [], includeApiSubType: boolean): AxiosPromise<DnoResponseBase> {
    let data = {};
    if (ids.length) {
        data = {
            ids,
        };
    }
    if (includeApiSubType) {
        data = {
            ...data,
            include_api_sub_type: true,
        };
    }
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecificationsgroups',
        data,
    });
}

/**
 * Add CSG
 *
 * @returns {AxiosPromise}
 */
export function addChargingSpecificationsGroups(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addchargingspecificationsgroups',
        data,
    });
}

/**
 * Update CSG
 *
 * @returns {AxiosPromise}
 */
export function updateChargingSpecificationsGroups(
    id: string,
    version: number,
    data: any,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatechargingspecificationsgroups',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update CSG state
 *
 * @returns {AxiosPromise}
 */
export function updateChargingSpecificationsGroupsState(
    id: string,
    version: number,
    state: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatechargingspecificationsgroups',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get CSG draft list
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecificationsGroupsDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecificationsgroupsdraftlist',
    });
}

/**
 * Get CSG entity draft
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecificationsGroupEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecificationsgroupdraft',
        data: {
            id,
        },
    });
}

/**
 * Set CSG draft.
 *
 * @returns {AxiosPromise}
 */
type CSGdraftPayload = {
    id?: string;
    data: object;
};

export function setChargingSpecificationsGroupDraft(data: CSGdraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setchargingspecificationgroupdraft',
        data,
    });
}

/**
 * Delete CSG draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteChargingSpecificationsGroupDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/chargingspecificationgroupdraftdelete',
        data: {
            id,
        },
    });
}

export default {
    getChargingSpecificationsGroups,
    addChargingSpecificationsGroups,
    updateChargingSpecificationsGroups,
    updateChargingSpecificationsGroupsState,
    getChargingSpecificationsGroupsDraftList,
    getChargingSpecificationsGroupEntityDraft,
    setChargingSpecificationsGroupDraft,
    deleteChargingSpecificationsGroupDraft,
};
