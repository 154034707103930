// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import VueI18n from 'vue-i18n';
import i18n from '@/i18n';

// COMPONENTS
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import PayoutLimits from '@/components/partials/PayoutLimits.vue';
import PurchaseLimit from '@/__new__/features/pc/offers/PurchaseLimit.vue';
import SubscriptionLimit from '@/__new__/features/pc/offers/SubscriptionLimit.vue';
import CohortExpressionForFormBuilder from '@/__new__/features/pc/templates/CohortExpressionForFormBuilder.vue';
import TaxAttributionTableFormBuilderWrapper from '@/__new__/features/pc/offers/TaxAttributionTableFormBuilderWrapper.vue';
import ProductTaxAttributeFormBuilderWrapper from '@/__new__/features/pc/products/ProductTaxAttributeFormBuilderWrapper.vue';
import ChannelPartnerToChargingSpecGroupMapper from '@/__new__/features/pc/offers/ChannelPartnerToChargingSpecGroupMapper.vue';
import DnoObligation from '@/__new__/features/pc/offers/DnoObligation.vue';

// TODO: uncomment after BE migration
// import ApplicabilityCohorts from '@/components/partials/ApplicabilityCohorts.vue';
// import Recurrence from '@/components/partials/Recurrence.vue';

interface PurchaseLimit {
    max_purchases: number;
    max_purchases_per_user: number;
    max_purchases_per_duration: {
        per_subscriber: {
            max: number;
            duration: number;
            number_of_durations: number;
        };
        overall: {
            max: number;
            duration: number;
            number_of_durations: number;
        };
    };
}

type FieldValidationFn = (val: any, { isRequired }: { isRequired: boolean }) => boolean;
type FieldDefinition = {
    key: string;
    type: string;
    defaultValue?: any;
    validation?: FieldValidationFn;
    componentProps: { [propName: string]: any };
};
type FormComponentDefinition = {
    name: VueI18n.TranslateResult;
    type: string;
    component: any;
    invalidKey?: string;
    defaultValue?: any;
    staticComponentProps?: { [propName: string]: any };
    fields?: FieldDefinition[];
    getDefaultValue?: (data: any) => any;
};
type FormBuilderConfig = { [formBuilderType: string]: FormComponentDefinition };

// TODO: think about automatic type generation
export const FORM_BUILDER_TYPES = {
    INPUT: 'text',
    TEXTAREA: 'textarea',
    NUMERIC: 'number',
    TOGGLE: 'boolean',
    DROPDOWN: 'enum',
    DROPDOWN_MULTIPLE: 'array_enum',
    JSON: 'object',
    REFERENCE: 'reference',
    PAYOUT_LIMITS: 'dno_purchase_limit_preset_template',
    PURCHASE_LIMIT: 'dno_purchase_limit',
    SUBSCRIPTION_LIMIT: 'dno_subscription_limit',
    COHORTS_EXPRESSION: 'dno_cohort',
    TAX_ATTRIBUTION: 'dno_offer_tax_attribute_v2',
    PRODUCT_TAX_ATTRIBUTE: 'dno_product_tax_attribute',
    CP_TO_CSG_MAPPER: 'dno_channel_partner_to_csg_mapper',
    DNO_OBLIGATION: 'dno_obligation',
};

const requiredValidationFunc = (val: any) => Boolean(val);

// Temporary widgets before we handle  it with general decision
export const WIDGETS = {
    [FORM_BUILDER_TYPES.PAYOUT_LIMITS]: {
        name: i18n.t('generic.payoutLimits'),
        type: FORM_BUILDER_TYPES.PAYOUT_LIMITS,
        component: PayoutLimits,
        defaultValue: null,
        hidden: true,
        staticComponentProps: {
            entityType: i18n.t('generic.purchases'),
        },
        getDefaultValue: (data: { properties: PurchaseLimit }) =>
            data.properties?.['max_purchases']
                ? {
                      max_purchases: data.properties.max_purchases,
                      max_purchases_per_user: data.properties.max_purchases_per_user,
                      max_purchases_per_duration: data.properties.max_purchases_per_duration,
                  }
                : null,
    },
    [FORM_BUILDER_TYPES.PURCHASE_LIMIT]: {
        name: i18n.t('productCatalog.offers.purchaseLimit.purchaseLimit'),
        type: FORM_BUILDER_TYPES.PURCHASE_LIMIT,
        component: PurchaseLimit,
        defaultValue: null,
        hidden: true,
        // staticComponentProps: {},
        getDefaultValue: (data: { properties }) =>
            data.properties?.max_purchase_per_target ||
            data.properties?.max_purchases_overall_per_duration ||
            data.properties?.max_purchases
                ? {
                      max_purchase_per_target: data.properties?.max_purchase_per_target || null,
                      max_purchases_overall_per_duration: data.properties?.max_purchases_overall_per_duration || null,
                      max_purchases: data.properties?.max_purchases || null,
                  }
                : null,
    },
    [FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT]: {
        name: i18n.t('productCatalog.offers.subscriptionLimit.subscriptionLimit'),
        type: FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT,
        component: SubscriptionLimit,
        defaultValue: null,
        hidden: true,
        // staticComponentProps: {},
        getDefaultValue: (data: { properties }) => data.properties?.max_subscription_per_target || {},
    },
    [FORM_BUILDER_TYPES.COHORTS_EXPRESSION]: {
        name: i18n.t('cohorts.cohortsExpression'),
        type: FORM_BUILDER_TYPES.COHORTS_EXPRESSION,
        component: CohortExpressionForFormBuilder,
        defaultValue: null,
        hidden: true,
        // staticComponentProps: {},
        getDefaultValue: (data: { properties }) => data.properties?.cohort_expression || null,
    },
    [FORM_BUILDER_TYPES.TAX_ATTRIBUTION]: {
        name: i18n.t('productCatalog.offers.editor.distribution'),
        type: FORM_BUILDER_TYPES.TAX_ATTRIBUTION,
        component: TaxAttributionTableFormBuilderWrapper,
        defaultValue: null,
        hidden: true,
        getDefaultValue: data => ({
            childEntities: data.childEntities,
            tax: data.properties?.tax,
        }),
    },
    [FORM_BUILDER_TYPES.PRODUCT_TAX_ATTRIBUTE]: {
        name: i18n.t('productCatalog.products.productTaxAttribute.productTaxAttribute'),
        type: FORM_BUILDER_TYPES.PRODUCT_TAX_ATTRIBUTE,
        component: ProductTaxAttributeFormBuilderWrapper,
        defaultValue: {
            tax_component_id: '',
            tax_address_type_per_target: {},
        },
        hidden: true,
        getDefaultValue: data => ({
            tax_component_id: data?.properties?.tax_component_id || '',
            tax_address_type_per_target: data?.properties?.tax_address_type_per_target || {},
        }),
    },
    [FORM_BUILDER_TYPES.CP_TO_CSG_MAPPER]: {
        name: i18n.t('productCatalog.offers.channelPartnerToChargingSpecGroupMapper'),
        type: FORM_BUILDER_TYPES.CP_TO_CSG_MAPPER,
        component: ChannelPartnerToChargingSpecGroupMapper,
        defaultValue: {
            channelPartnerId: '',
            csgId: '',
            chargingModelEnum: '',
        },
        hidden: true,
        getDefaultValue: data => ({
            channelPartnerId: data?.properties?.channel_partner_id || '',
            csgId: data?.properties?.charging_spec_group_id || '',
            chargingModelEnum: data?.properties?.charging_model_enum || '',
        }),
    },
    [FORM_BUILDER_TYPES.DNO_OBLIGATION]: {
        name: i18n.t('productCatalog.offers.dnoObligation'),
        type: FORM_BUILDER_TYPES.DNO_OBLIGATION,
        component: DnoObligation,
        defaultValue: {
            subscription_model: 1,
            first_charge_at: 1,
            activation_type: 3,
            override_first_charge_at: {},
            continue_recurrence: true,
            override_activation_type: {},
            recurrence_cadence: 'QUARTERLY',
            recurrence_limit: 0,
        },
        hidden: true,
        getDefaultValue: data => ({
            subscription_model: data?.properties?.subscription_model || 1,
            first_charge_at: data?.properties?.first_charge_at || 1,
            activation_type: data?.properties?.activation_type || 3,
            override_first_charge_at: data?.properties?.override_first_charge_at || {},
            continue_recurrence: data?.properties?.continue_recurrence || true,
            override_activation_type: data?.properties?.override_activation_type || {},
            recurrence_cadence: data?.properties?.recurrence_cadence || 'QUARTERLY',
            recurrence_limit: data?.properties?.recurrence_limit || 0,
        }),
    },
};

// CONFIG
const CONFIG_FORM_BUILDER: FormBuilderConfig = {
    ...WIDGETS,

    [FORM_BUILDER_TYPES.INPUT]: {
        name: i18n.t('formBuilderTypes.text'),
        type: FORM_BUILDER_TYPES.INPUT,
        component: AppInputV3,
        invalidKey: 'invalid',
        defaultValue: '',
        staticComponentProps: {
            tooltipOffset: 10,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.INPUT,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
            {
                key: 'defaultValue',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: (val: any, { isRequired }) => !isRequired || val,

                componentProps: {
                    label: i18n.t('generic.defaultValue'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.TEXTAREA]: {
        name: i18n.t('formBuilderTypes.textarea'),
        type: FORM_BUILDER_TYPES.TEXTAREA,
        component: AppTextareaV3,
        invalidKey: 'invalid',
        defaultValue: '',
        staticComponentProps: {
            tooltipOffset: 10,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
            {
                key: 'defaultValue',
                type: FORM_BUILDER_TYPES.TEXTAREA,
                validation: (val, { isRequired }) => !isRequired || val,

                componentProps: {
                    label: i18n.t('generic.defaultValue'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.NUMERIC]: {
        name: i18n.t('formBuilderTypes.number'),
        type: FORM_BUILDER_TYPES.NUMERIC,
        component: AppInputV3,
        invalidKey: 'invalid',
        staticComponentProps: {
            type: 'number',
            tooltipOffset: 10,
            allowEmptyValueAsNull: true,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.INPUT,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
            {
                key: 'defaultValue',
                type: FORM_BUILDER_TYPES.NUMERIC,
                validation: (val, { isRequired }) => !isRequired || val !== undefined,

                componentProps: {
                    label: i18n.t('generic.defaultValue'),
                    step: 0.1,
                },
            },
            {
                key: 'step',
                type: FORM_BUILDER_TYPES.NUMERIC,
                validation: val => val > 0,
                defaultValue: 1,

                componentProps: {
                    label: i18n.t('generic.step'),
                    step: 0.1,
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.TOGGLE]: {
        name: i18n.t('formBuilderTypes.toggle'),
        type: FORM_BUILDER_TYPES.TOGGLE,
        component: AppToggle,
        invalidKey: 'invalid',
        defaultValue: false,
        staticComponentProps: {
            small: true,
            tooltipOffset: 10,
            fitContent: true,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'defaultValue',
                type: FORM_BUILDER_TYPES.TOGGLE,

                componentProps: {
                    label: i18n.t('generic.defaultValue'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.DROPDOWN]: {
        name: i18n.t('formBuilderTypes.dropdown'),
        type: FORM_BUILDER_TYPES.DROPDOWN,
        component: AppMultiselectV3,
        invalidKey: 'invalid',
        defaultValue: null,
        staticComponentProps: {
            small: true,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.INPUT,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE]: {
        name: i18n.t('formBuilderTypes.dropdownMultiple'),
        type: FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE,
        component: AppMultiselectV3,
        invalidKey: 'error',
        defaultValue: null,
        staticComponentProps: {
            small: true,
            multiple: true,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.INPUT,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.JSON]: {
        name: i18n.t('formBuilderTypes.JSON'),
        type: FORM_BUILDER_TYPES.JSON,
        component: AppJSON,
        invalidKey: 'invalid',
        defaultValue: {},
        staticComponentProps: {
            hidePreview: true,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'defaultValue',
                type: FORM_BUILDER_TYPES.JSON,
                validation: (val, { isRequired }) => !isRequired || val,

                componentProps: {
                    label: i18n.t('generic.defaultValue'),
                },
            },
        ],
    },
    [FORM_BUILDER_TYPES.REFERENCE]: {
        name: i18n.t('formBuilderTypes.reference'),
        type: FORM_BUILDER_TYPES.REFERENCE,
        component: AppMultiselectV3,
        invalidKey: 'error',
        defaultValue: null,
        staticComponentProps: {
            tooltipOffset: 10,
            multiple: false,
            small: true,
            optionId: null,
        },
        fields: [
            {
                key: 'name',
                type: FORM_BUILDER_TYPES.INPUT,
                validation: requiredValidationFunc,

                componentProps: {
                    label: i18n.t('generic.name'),
                },
            },
            {
                key: 'description',
                type: FORM_BUILDER_TYPES.TEXTAREA,

                componentProps: {
                    label: i18n.t('generic.description'),
                },
            },
            {
                key: 'placeholder',
                type: FORM_BUILDER_TYPES.INPUT,

                componentProps: {
                    label: i18n.t('generic.placeholder'),
                },
            },
        ],
    },
};

export default CONFIG_FORM_BUILDER;
