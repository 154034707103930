
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// components
import AppButtonDropdown from '@/components/partials/inputs/AppButtonDropdown.vue';
import CancelDeviceLocationModal from './CancelDeviceLocationModal.vue';
import NetworkCoverageModal from './NetworkCoverageModal.vue';
import ClientStorageModal from './ClientStorageModal.vue';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    SUPPORT_ACTION,
    SupportActionMenuItem,
} from '@/__new__/features/customerCareSuite/common/supportActionsHelper';
import { TARGET_TYPE, TargetTuple } from '@/__new__/services/dno/user/models/targetTuple';

export default Vue.extend({
    name: 'SupportActions',
    components: {
        AppButtonDropdown,
        CancelDeviceLocationModal,
        NetworkCoverageModal,
        ClientStorageModal,
    },
    props: {
        entity: {
            default: () => ({
                targetId: '',
                targetType: TARGET_TYPE.SUBSCRIBER,
            }),
            type: Object as PropType<TargetTuple>,
        },
    },
    data(): {
        actionsConfig: Record<TARGET_TYPE, SupportActionMenuItem[]>;
        modalVisible: Record<string, boolean>;
        SUPPORT_ACTION: typeof SUPPORT_ACTION;
        ICON_TYPES: typeof ICON_TYPES;
    } {
        return {
            actionsConfig: {
                [TARGET_TYPE.SUBSCRIBER]: [
                    {
                        label: this.$t('customerCare.cancelDeviceLocation.modalTitle'),
                        value: SUPPORT_ACTION.CANCEL_DEVICE_LOCATION,
                        visible: isUserAllowed('UMSubscriberCancelDeviceLocation'),
                    },
                    {
                        label: this.$t('customerCare.networkCoverage.modalTitle'),
                        value: SUPPORT_ACTION.NETWORK_COVERAGE,
                        visible: isUserAllowed('UMSubscriberNetworkCoverage'),
                    },
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                ],
                [TARGET_TYPE.ACCOUNT]: [
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                ],
                [TARGET_TYPE.USER]: [
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                ],
                [TARGET_TYPE.ORGANIZATION]: [],
            },
            modalVisible: {
                [SUPPORT_ACTION.CANCEL_DEVICE_LOCATION]: false,
                [SUPPORT_ACTION.NETWORK_COVERAGE]: false,
                [SUPPORT_ACTION.CLIENT_STORAGE]: false,
            },

            SUPPORT_ACTION,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        umentity(): any {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID](this.entity);
        },
        actions(): SupportActionMenuItem[] {
            return this.actionsConfig[this.entity.targetType] || [];
        },
        visibleActions(): SupportActionMenuItem[] {
            return this.actions.filter(({ visible }) => visible);
        },
    },
    methods: {
        selectSupportAction(action: SupportActionMenuItem) {
            this.modalVisible[action.value] = true;
        },
        closeActionModal(action: SUPPORT_ACTION) {
            this.modalVisible[action] = false;
        },
    },
});
