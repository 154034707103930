
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AdditionalSidebarForOrders from '@/__new__/features/customerCare/AdditionalSidebarForOrders.vue';
import AdditionalSidebarOrdersEntities from '@/__new__/features/customerCare/AdditionalSidebarOrdersEntities.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import ChangeOrderStatusForm from '@/__new__/features/customerCare/ChangeOrderStatusForm.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import OrderChangePlanModal from '@/__new__/features/customerCareSuite/components/OrderChangePlanModal.vue';
import OrderCancelPlanChangeModal from '@/__new__/features/customerCareSuite/components/OrderCancelPlanChangeModal.vue';

// helpers
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import isEmpty from 'lodash/isEmpty';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Order from '@/__new__/services/dno/orders/models/Order';
import RouteNames from '@/router/routeNames';

// Vuex
import { Modules } from '@/store/store';
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

const DIALOG_TYPES = {
    CANCEL: 'cancel',
    CHANGE_PLAN: 'change_plan',
    EDIT_STATUS: 'edit_status',
};

export default Vue.extend({
    name: 'OrderDetailsTile',
    components: {
        AbstractTableTile,
        IconButton,
        AppDialog,
        AppToggle,
        AdditionalSidebarForOrders,
        AdditionalSidebarOrdersEntities,
        ChangeOrderStatusForm,
        EntityStatusIndicator,
        OrderChangePlanModal,
        OrderCancelPlanChangeModal,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            modalVisible: true,
            showOnlyActiveOrders: false,
            searchQuery: '',
            showSidebar: false,
            selectedEntityId: null,

            // dialog related data
            showDialog: false,
            dialogType: null as typeof DIALOG_TYPES | null,

            // consts
            targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
            defaultSort: {
                key: 'createTimestamp',
                sortBy: entity => entity.createTimestamp,
                type: 'desc',
            },

            // permissions related
            isFriendlyOrderIdEnabled:
                permissionsService.accountOrdersFriendlyOrderIdEnabled() &&
                isViewEnabled('UMAccountOrderDetailsFriendlyOrderId'),
            isOrderTypeEnabled:
                permissionsService.accountOrdersOrderTypeEnabled() && isViewEnabled('UMAccountOrderDetailsType'),
            isSuborderDisabled:
                permissionsService.accountOrdersSubordersDisabled() &&
                isViewEnabled('UMAccountOrderDetailsSubordersDisabled'),
            isSubscriberIdEnabled: permissionsService.subscriberLevelEnabled() && isViewEnabled('UMSubscriber'),
            // do not enable for non LFAdmins without change of logic(atm all states are allowed)
            isEditOrderStateAllowed:
                permissionsService.isUserLotusFlareAdmin() && isUserAllowed('UMAccountOrderDetailsChangeOrderStatus'),
            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),

            // proxy
            RouteNames,
            ICON_TYPES,
            ENTITY_TYPES,
            TOOLTIP_POSITION,
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            ORDER_ENTITY_STATES,
        };
    },
    computed: {
        ...mapGetters(Modules.orders, {
            orderMapFromVuex: Getters.GET_ORDERS,
            ordersApiResponse: Getters.GET_ORDERS_API_RESPONSE,
        }),
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_USER_AUTHENTICATION_STATE]),
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        umEmtityId() {
            return this.$route.params.id || '';
        },
        relatedAccount() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this.$route.params.id,
            });
        },
        // permissions related stuff
        isOrderDetailsDevEdge() {
            return (
                permissionsService.userManagementOMDevEdgeServiceEnabled() &&
                isViewEnabled('UMAccountOrderDetailsDevEdge')
            );
        },
        isParentUserAuthenticated() {
            return this[Getters.GET_USER_AUTHENTICATION_STATE]
                ? this[Getters.GET_USER_AUTHENTICATION_STATE]?.status ===
                      this.i18n.t('customerCare.userAuthentication.verified')
                : false;
        },
        // table related stuff
        selectedOrder() {
            return this.selectedEntityId
                ? this.formattedEntities.find(orderFormatted => orderFormatted.orderId === this.selectedEntityId)
                : {};
        },
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        formattedEntities(): Array<Order> {
            if (!isEmpty(this.orderMapFromVuex)) {
                return Object.values(this.orderMapFromVuex)
                    .filter(({ state }) => (this.showOnlyActiveOrders ? state === ORDER_ENTITY_STATES.ACTIVE : true))
                    .map((order: Order) => ({
                        ...order,
                        state:
                            order.replaceInfo && order.state === ORDER_ENTITY_STATES.ACTIVE
                                ? ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE
                                : order.state,
                    }));
            }
            return [];
        },
        columnsData() {
            const omdeOrdersColumnsData = [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.offer'),
                    key: 'offer',
                    forbidHideColumn: true,
                    field: 'offer',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.imei'),
                    key: 'imei',
                    field: 'imei',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.iccid'),
                    key: 'iccid',
                    field: 'iccid',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    classes: ['overflow-visible-all'],
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedEntities.map(entity => entity.stateLabel))),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'buyerFirstName',
                    field: 'buyerFirstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'buyerLastName',
                    field: 'buyerLastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.accountId'),
                    key: 'buyerAccountId',
                    field: 'buyerAccountId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'buyerMSISDN',
                    field: 'buyerMSISDN',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.isOrderDetailsDevEdge) {
                return omdeOrdersColumnsData;
            }

            const columns = [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.chargedAmount'),
                    key: 'amountFormatted',
                    field: 'amountFormatted',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.currency'),
                    key: 'currency',
                    field: 'currency',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.totalAmount'),
                    key: 'totalAmountFormatted',
                    field: 'totalAmountFormatted',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    classes: ['overflow-visible-all'],
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedEntities.map(entity => entity.stateLabel))),
                },
            ];

            if (this.isFriendlyOrderIdEnabled) {
                // Must be placed as 1st column if enabled.
                columns.unshift({
                    name: this.$i18n.t('generic.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if (this.isOrderTypeEnabled) {
                // Placed after 'orderId'
                columns.splice(columns.findIndex(c => c.key === 'orderId') + 1, 0, {
                    name: this.$i18n.t('customerCare.orderHistory.orderType'),
                    key: 'type',
                    classes: [],
                    field: 'type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.formattedEntities.map(entity => entity.type))],
                });
            }

            if (this.isSubscriberIdEnabled) {
                columns.push({
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return columns;
        },
        orderDetails() {
            if (Object.values(this.orderMapFromVuex).length) {
                const orderId = Object.keys(this.orderMapFromVuex).find(
                    key =>
                        this.orderMapFromVuex[key].state === ORDER_ENTITY_STATES.PENDING ||
                        this.orderMapFromVuex[key].state === ORDER_ENTITY_STATES.PENDING_ACTIVATION ||
                        this.orderMapFromVuex[key].state === ORDER_ENTITY_STATES.ACTIVE,
                );
                return this.orderMapFromVuex[orderId];
            }
            return null;
        },
        // modals related stuff
        showCancelPlanChangeDialog() {
            return this.dialogType === DIALOG_TYPES.CANCEL && this.showDialog;
        },
        showEditStatusDialog() {
            return this.dialogType === DIALOG_TYPES.EDIT_STATUS && this.showDialog;
        },
        showChangePlanDialog() {
            return this.dialogType === DIALOG_TYPES.CHANGE_PLAN && this.showDialog;
        },
        // this one is necessary to make sure we have all needed data before we can actually render OrderPlanChangeModal
        renderChangePlanDialog() {
            return !!(this.offers.length && this.relatedAccount && this.showChangePlanDialog);
        },
    },
    async mounted() {
        await this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.orders, [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        ...mapActions(Modules.productcatalog, [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const promises = [
                        this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.umEmtityId),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({
                            entityType: ENTITY_TYPES.OFFER,
                            includeDrafts: false,
                        }),
                    ];
                    await Promise.all(promises);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            ALERT_TYPES.error,
                        );
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectItem(orderId: string) {
            this.selectedEntityId = orderId;
        },
        checkIfEditingIsAvailableForOrder(order) {
            return (
                order.state === ORDER_ENTITY_STATES.PENDING ||
                order.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION ||
                order.state === ORDER_ENTITY_STATES.ACTIVE
            );
        },
        // sidebar related stuff
        onShowSidebar(orderId: string) {
            this.selectItem(orderId);
            this.showSidebar = true;
        },
        onCloseSidebar() {
            this.showSidebar = false;
        },

        // dialog related stuff
        hideDialog() {
            this.showDialog = false;
            this.dialogType = null;
        },
        openEditOrderStateDialog(order) {
            this.selectItem(order.orderId);
            this.showDialog = true;
            this.dialogType = DIALOG_TYPES.EDIT_STATUS;
        },
        openCancelPlanChangeDialog() {
            this.dialogType = DIALOG_TYPES.CANCEL;
            this.showDialog = true;
        },
        openPlanChangeDialog() {
            this.dialogType = DIALOG_TYPES.CHANGE_PLAN;
            this.showDialog = true;
        },
    },
});
