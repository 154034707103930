import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';

type Relationship = {
    id: string;
    type: string;
};

type OfferData = {
    name: string;
    description: string;
    start_time: number;
    end_time?: number;
    relationships?: Relationship[];
} & Record<string, any>;

type CompatibilityRule = {
    id: string;
    rule_type: number;
    rule_eval_operation: number;
};

export type Offer = Entity<OfferData> & {
    compatibility_rules?: CompatibilityRule[];
};

type getOffersResponseBody = DnoResponseBase & {
    offer_by_id: Record<string, Offer>;
};

/**
 * Get offers.
 *
 * @returns {AxiosPromise}
 */
export function getOffers(ids: string[] = []): AxiosPromise<getOffersResponseBody> {
    const data = {
        ids: ids.length ? ids : [],
        option: {
            include_deleted: true,
            include_unapproved: true,
            include_paused: true,
            include_future_amendments: true,
            include_compatibility_rules: true,
            include_amount: true,
            management_api: true,
            include_portal_id: true,
        },
    };

    return http({
        method: 'POST',
        url: '/v3productcatalog/getoffers',
        data,
    });
}

interface GetOffersByTimestampResponse extends DnoResponseBase {
    entity_data_with_timestamps_by_id: Record<number, Record<'offer_by_id', Record<string, Offer>>>;
}

export function getOffersByTimestamp(
    ids_with_timestamps: { id: string; timestamp: number }[],
): AxiosPromise<GetOffersByTimestampResponse> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getoffersbytimestamps',
        data: {
            ids_with_timestamps,
        },
    });
}

/**
 * Get offers from ProductCatalogClient swagger
 *
 * @returns {AxiosPromise}
 */
export function getOffersClientAPI(payload: any = null): AxiosPromise<getOffersResponseBody> {
    return http({
        method: 'POST',
        url: '/v3productcatalogclient/getoffers',
        data: payload,
    });
}

export type AddOfferRequestBody = {
    data: OfferData;
    template_id: string;
    entity_id?: string;
};

/**
 * Add offer.
 *
 * @returns {AxiosPromise}
 */
export function addOffer(data: AddOfferRequestBody): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/addoffer',
        data,
    });
}

/**
 * Update offer.
 *
 * @returns {AxiosPromise}
 */
export function updateOffer(id: string, version: number, data: OfferData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateoffer',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update offer state.
 *
 * @returns {AxiosPromise}
 */
export function updateOfferState(id: string, version: string, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateofferstate',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get offer draft list.
 *
 * @returns {AxiosPromise}
 */
export function getOfferDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getofferdraftlist',
    });
}

/**
 * Delete offer draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteOfferDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/offerdraftdelete',
        data: {
            id,
        },
    });
}

/**
 * Get offer draft.
 *
 * @returns {AxiosPromise}
 */
export function getOfferDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getofferdraft',
        data: {
            id,
        },
    });
}

/**
 * Set offer draft.
 *
 * @returns {AxiosPromise}
 */
type OfferDraftPayload = {
    id?: string;
    data: object;
    template_id: string;
};

export function setOfferDraft(data: OfferDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/setofferdraft',
        data,
    });
}

export function addCompatibilityRule(id: string, rule: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/addcompatibilityrule',
        data: {
            id,
            rule: rule.toRaw(),
        },
    });
}

export function removeCompatibilityRule(id: string, rule: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/removecompatibilityrule',
        data: {
            id,
            rule: rule.toRaw(),
        },
    });
}

export default {
    getOffers,
    getOffersClientAPI,
    getOffersByTimestamp,
};
