
// components

import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AgentNotesDragDropUploader from '@/__new__/features/customerCare/AgentNotesDragDropUploader.vue';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';
import AppAdditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// helpers
import tableColumnType from '@/common/filterTable';
import httpNotes from '@/__new__/services/dno/crm/http/crm';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import moment from 'moment';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    getUserManagerEntityTypeById,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { documentTypes, getMimeType } from '@/common/documentTypeHelper';
import { dateToEpoch } from '@/common/formatting';
import { uploadToSignedURLNoProgress } from '@/http/fileUploader';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

export const NOTE_TAGS = {
    INTERACTION: 'Interaction',
    DOCUMENT: 'Document',
    REFUND: 'Refund',
    COMPLAINT: 'Complaint',
};

export default {
    name: 'AgentNotesTile',
    components: {
        AbstractTableTile,
        AppButton,
        AppDialogV2,
        AppMultiselectV3,
        AppTextareaV3,
        AgentNotesDragDropUploader,
        FileUploaderModal,
        AppAdditionalSidebar,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            notes: [],
            rawResponse: {},
            showModal: false,
            selectedNote: {},
            showAditionalSidebar: false,

            selectedTags: [],
            noteEpoch: 0,
            noteText: '',
            noteDocuments: [],
            noteDocumentIds: [],
            uploadedDocsIds: [],
            uploadInProgress: false,
            uploadFinished: false,
            uploadSuccessIndex: null,
            uploadFailed: false,
            showWarningModal: false,
            redirectTimeoutId: null,

            NOTE_TAGS,
            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        formattedNotes() {
            if (this.notes && this.notes.length > 0) {
                return this.notes.map((entity, index) => ({
                    index,
                    epoch: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.epoch),
                    timeMoment: moment(entity.epoch * 1000),
                    creatorName: Object.hasOwnProperty.call(entity, 'crm_user_id')
                        ? entity.crm_user_id
                        : this.$i18n.t('generic.N/A'),
                    noteText: getMultiLangFieldValueByLocale(entity.note),
                    tags: entity?.tags,
                    documentUrls: entity.document_urls ?? null,
                    entityId: entity.id,
                    entityType: entity.id_type ? getUserManagerEntityTypeById(entity.id_type) : '',
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.notesSection.timestamp'),
                    key: 'epoch',
                    sortBy: entity => entity.epoch,
                    field: 'timeMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.agentName'),
                    key: 'creatorName',
                    field: 'creatorName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.note'),
                    key: 'noteText',
                    field: 'noteText',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.tags'),
                    key: 'tags',
                    field: 'tags',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: Array.from(new Set(this.notes.map(note => note.tags).flat())),
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.entityId'),
                    key: 'entityId',
                    field: 'entityId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.entityType'),
                    key: 'entityType',
                    field: 'entityType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        isUploadButtonDisabled() {
            return (!this.noteText && !this.noteDocuments.length) || this.uploadInProgress || this.uploadFinished;
        },
        acceptType() {
            return [
                documentTypes.PDF,
                documentTypes.PNG,
                documentTypes.JPG,
                documentTypes.DOC,
                documentTypes.DOCX,
            ].join(', ');
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();
                this.notesLoading = true;
                if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER) {
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.USER,
                        targetId: this.$route.params.id,
                    });
                    const user = this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                        targetType: USER_MANAGER_HIERARCHY.USER,
                        targetId: this.$route.params.id,
                    });
                    const ids = [
                        [this.$route.params.id, this.userManagerHierarchy],
                        ...Object.entries(user.ownedResources || {}),
                    ].map(([id, type]) => ({
                        id,
                        id_type: type,
                    }));
                    this.rawResponse = await httpNotes.getNotesByIds(ids);
                    this.notes = this.rawResponse.data.notes;
                } else {
                    this.rawResponse = await httpNotes.getNotes(this.$route.params.id, this.userManagerHierarchy);
                    this.notes = this.rawResponse.data.flat(Infinity);
                }
                this.notesLoading = false;
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                this.rawResponse = error.response;
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        responseModalData() {
            return this.rawResponse;
        },
        addNewNote() {
            this.showModal = true;
        },
        updateDocumentTag(filesLength) {
            if (filesLength) {
                if (!this.selectedTags?.includes(NOTE_TAGS.DOCUMENT)) {
                    this.selectedTags.push(NOTE_TAGS.DOCUMENT);
                }
            } else if (this.selectedTags?.includes(NOTE_TAGS.DOCUMENT)) {
                this.selectedTags = this.selectedTags.filter(tag => tag !== NOTE_TAGS.DOCUMENT);
            }
        },
        updateFiles(files) {
            // If the note has a document attached, the note should automatically be tagged with the “document” tag.
            this.updateDocumentTag(files?.length);
            this.noteDocuments = files;
        },
        onCancel() {
            if (this.uploadInProgress) {
                this.showWarningModal = true;
            } else {
                this.showModal = false;
            }
            if (this.redirectTimeoutId) {
                clearTimeout(this.redirectTimeoutId);
            }
        },
        async onSave() {
            // If the note does not have any selected tags,
            // the agent should be prompted to select at least one tag for the note before saving.
            if (!this.selectedTags?.length) {
                this.showMessage(this.$i18n.t('customerCare.notesSection.noTagsWarning'), ALERT_TYPES.warning);
            } else {
                await this.addNotes();
                this.fetchTileData();
            }
        },
        async addNotes() {
            try {
                this.$Progress.start();
                this.uploadInProgress = true;

                // Set epoch. Must be same for add/update note.
                this.noteEpoch = dateToEpoch(Date.now());

                // Get document IDs for all pending files if they exist
                if (this.noteDocuments?.length) {
                    this.noteDocumentIds = this.noteDocuments.map(doc => doc.id);
                }

                // Add note with tags and get URLs for document upload
                const { data } = await httpNotes.addNote({
                    id: this.$route.params.id,
                    idType: this.userManagerHierarchy,
                    epoch: this.noteEpoch,
                    noteText: this.noteText,
                    tags: this.selectedTags.map(tag => tag.toLowerCase()),
                    documentIds: this.noteDocumentIds?.length ? this.noteDocumentIds : null,
                });

                // Upload files
                /* eslint-disable camelcase */
                if (Object.keys(data?.document_urls)?.length) {
                    await this.uploadFiles(data?.document_urls);
                    await this.updateNotes();
                }

                await this.showMessage(
                    this.$i18n.t('customerCare.notesSection.alerts.successfullyCreated'),
                    ALERT_TYPES.success,
                );
                this.$Progress.finish();
            } catch (e) {
                this.uploadFailed = true;
                this.uploadInProgress = false;
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showMessage(this.$i18n.t('customerCare.notesSection.errors.unableToCreate'), ALERT_TYPES.error);
            } finally {
                this.showModal = false;
                if (!this.noteDocuments?.length) {
                    this.uploadInProgress = false;
                    this.uploadFinished = true;
                }
            }
        },
        async updateNotes() {
            if (!this.uploadedDocsIds.length) return;

            try {
                await httpNotes.updateNote({
                    id: this.$route.params.id,
                    idType: this.userManagerHierarchy,
                    epoch: this.noteEpoch,
                    documentIds: this.uploadedDocsIds,
                });
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.errors.somethingWentWrongWhileAddingFiles'),
                    ALERT_TYPES.error,
                );
            }
        },
        async uploadFiles(docURLs) {
            const promises = this.noteDocuments.map((doc, index) => {
                doc.url = docURLs[doc.id];
                const newFile = new File([doc.file], doc.id);
                doc.blob = new Blob([newFile], { type: 'binary' });

                return this.uploadFile(doc, index);
            });
            await Promise.all(promises);
        },
        async uploadFile({ id, blob, url, name }, index) {
            try {
                await uploadToSignedURLNoProgress(blob, url, getMimeType(name));
                this.uploadSuccessIndex = index;
                this.uploadedDocsIds.push(id);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.alerts.successfullyUploaded'),
                    ALERT_TYPES.success,
                );
            } catch (e) {
                this.uploadFailed = true;
                Sentry.captureException(e);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.errors.unableToUploadFile'),
                    ALERT_TYPES.error,
                );
            }
        },
        showMessage(message, type) {
            this.$eventBus.$emit('showAlert', { type, message });
        },
        selectNote(index) {
            this.selectedNote = this.formattedNotes.find(el => el.index === index);
            this.showAditionalSidebar = true;
        },
    },
};
