
import Vue from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import BulkUploadTabs, { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';

// helpers
import { copyToClipboard } from '@/common/utils';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import MonacoLoader from '@monaco-editor/loader';
import type { editor } from 'monaco-editor/esm/vs/editor/editor.api';

export default Vue.extend({
    name: 'SinkConfigDialog',
    components: {
        AppButton,
        AppIcon,
        AppJSON,
        AppDialogV2,
        BulkUploadTabs,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        jsonData: {
            type: Object,
            default: () => ({}),
        },
        yamlData: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            currentTab: 'json',
            tabs: [
                {
                    id: 'json',
                    label: this.$t('formBuilderTypes.JSON'),
                },
                {
                    id: 'yaml',
                    label: 'YAML',
                },
            ] as Tab[],
            yamlEditor: null as editor.IStandaloneCodeEditor | null,
        };
    },
    computed: {
        modalData(): Record<string, any> | string {
            if (this.isJsonTab) {
                return this.jsonData;
            }
            return this.yamlData;
        },
        isJsonTab(): boolean {
            return this.currentTab === 'json';
        },
    },
    watch: {
        yamlData(value): void {
            this.yamlEditor?.setValue(value);
        },
    },
    beforeDestroy() {
        this.yamlEditor?.dispose();
    },
    methods: {
        copyToClipboard,
        onTabChange(tabId: Tab['id']): void {
            if (tabId === 'yaml') {
                this.loadYamlEditor();
            }
        },
        loadYamlEditor(): void {
            MonacoLoader.init().then(monaco => {
                this.yamlEditor = monaco.editor.create(this.$refs.yamlEditor as HTMLElement, {
                    language: 'javascript',
                    value: this.yamlData,
                    automaticLayout: true,
                    fontSize: 14,
                    padding: { top: 5, bottom: 5 },
                    domReadOnly: true,
                    readOnly: true,
                    scrollBeyondLastLine: false,
                    showDeprecated: false,
                });
            });
        },
    },
});
