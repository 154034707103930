// TODO: import colors
// import colors from '../assets/scss/consistency-colors.scss';

export enum ICON_TYPES {
    ARROW_DOWN = 'arrow-down-new',
    ARROW_UP = 'arrow-up-new',
    ARROW_LEFT = 'arrow-left-new',
    ARROW_RIGHT = 'arrow-right-new',
    BELL = 'bell-new',
    CALENDAR = 'calendar-new',
    CHECK = 'check-new',
    CLOCK = 'clock-new',
    CLONE = 'clone-new',
    CLOSE = 'close-new',
    COPY = 'copy',
    CURLY_BRACKETS = 'curly-brackets-new',
    DATA = 'data-new',
    DETAILS = 'details-new',
    DELETE = 'delete-new',
    DOCUMENT = 'document',
    DOWNLOAD = 'download-new',
    EDIT = 'edit-new',
    EMAIL = 'email-new',
    ESIM = 'e-sim',
    EYE_SHOW = 'eye-show-new',
    FILTER = 'filter-new',
    GLOBAL = 'global',
    FORK = 'fork',
    INBOX = 'inbox-new',
    INFO = 'info-new',
    LINK = 'link-new',
    LOGOUT = 'logout-new',
    LOADER = 'loader',
    MAIL = 'mail-new',
    MINUS = 'minus-new',
    MMS = 'mms-new',
    MORE = 'more-new',
    PAUSE = 'pause-new',
    PENDING = 'pending-new',
    PLUS = 'plus-new',
    PSIM = 'p-sim',
    PUBLISH = 'publish',
    SEARCH = 'search-new',
    SEND = 'send-new',
    SMS = 'sms-new',
    SPINNER = 'spinner',
    START = 'start-new',
    STOP = 'stop-new',
    RECEIPT = 'receipt',
    REFUND = 'refund-new',
    REFRESH = 'refresh-new',
    REGENERATE = 'regenerate',
    UPLOAD = 'upload-new',
    USER = 'user-new',
    VOICE = 'voice-new',
    WALLET = 'wallet-new',
    WARNING = 'warning-new',

    // Text formatting related icons
    BLOCK_TO_CODE = 'block-to-code-new',
    UNDO = 'undo-new',
    REDO = 'redo-new',
    REPLACE = 'replace-new',
    FONT_ZOOM_IN = 'font-zoom-in-new',
    FONT_ZOOM_OUT = 'font-zoom-out-new',
    FONT_ZOOM_RESET = 'font-zoom-reset-new',
    FORMATTER = 'formatter-new',
    FORMAT_CODE = 'format-code-new',
    TEMPLATE = 'template-new',

    // Sidebar icons
    SIDEBAR_MY_ACCOUNT = 'sidebar-my-account',
    SIDEBAR_ANALYTICS = 'sidebar-analytics',
    SIDEBAR_CAMPAIGNS = 'sidebar-campaigns',
    SIDEBAR_CHARGING = 'sidebar-charging',
    SIDEBAR_DATA_HUB = 'sidebar-data-hub',
    SIDEBAR_EXPERIMENTS = 'sidebar-experiments',
    SIDEBAR_HOME = 'sidebar-home',
    SIDEBAR_OPERATIONS = 'sidebar-operations',
    SIDEBAR_OPERATOR = 'sidebar-operator',
    SIDEBAR_ORCHESTRATION = 'sidebar-orchestration',
    SIDEBAR_PRICING_AND_FEES = 'sidebar-pricing-and-fees',
    SIDEBAR_PRODUCT_CATALOG = 'sidebar-product-catalog',
    SIDEBAR_PROMOTIONS = 'sidebar-promotions',
    SIDEBAR_REWARDS = 'sidebar-rewards',
    SIDEBAR_SEGMENTS = 'sidebar-segments',
    SIDEBAR_SETTINGS = 'sidebar-settings',
    SIDEBAR_SUPPORT = 'sidebar-support',
    SIDEBAR_TRIGGERS = 'sidebar-triggers',
    SIDEBAR_USER_MANAGEMENT = 'sidebar-user-management',

    // Tenant icons
    TENANT_LOTUSFLARE = 'tenant-lotusflare',
    TENANT_GLOBE_ONE = 'tenant-globe-one',
    TENANT_T_MOBILE = 'tenant-t-mobile',

    // Alert/Notification icons
    ALERT_SUCCESS = 'alert-success-new',
    ALERT_INFO = 'alert-info-new',
}

export enum ICON_COLORS {
    GRAY = '#9BA3B4', // $gray-300
    WHITE = '#FFF', // $white
    BLUE_LIGHT = '#8D9EC4', // $blue-300
    BLUE = '#335195', // $blue-400
    RED = '#F85359', // $red-400
    YELLOW = '#FFAD20', // $yellow-400
    GREEN = '#37C9AA', // $green-400
}

export default {
    ICON_TYPES,
    ICON_COLORS,
};
