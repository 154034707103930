<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('authentication.authentication')"
        :isOverviewEnabled="false"
    >
        <template slot="table">
            <div class="default-page-content-wrapper main">
                <h3 class="modal-title">
                    {{ $i18n.t('authentication.authenticationSettings') }}
                </h3>
                <span class="small-gray-note mb-4">{{ $i18n.t('authentication.authenticationSettingsSummary') }}</span>
                <table>
                    <tbody>
                        <tr
                            v-for="setting in authSettings"
                            :key="setting.strategy"
                        >
                            <td>
                                <AppToggle
                                    v-model="setting.enabled"
                                    @input="onSliderClick(setting)"
                                />
                            </td>
                            <td>
                                <span>{{ $i18n.t(`authentication.authenticationTypes.${setting.strategy}`) }}</span>
                            </td>
                            <td>
                                <AppButton
                                    v-if="setting.strategy !== 'local'"
                                    :buttonType="BUTTON_TYPES.TERTIARY"
                                    :iconType="ICON_TYPES.SIDEBAR_SETTINGS"
                                    :isSmall="true"
                                    @click="configureAuth(setting.strategy)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h3 class="modal-title mt-3">
                    {{ $i18n.t('authentication.otherDetails') }}
                </h3>
                <AppInputV3
                    v-model="firstSubdomain"
                    :label="$i18n.t('authentication.hostnameFirstSubdomain')"
                    :disabled="true"
                    :explanationText="$i18n.t('authentication.firstSubdomainInfo')"
                    :tooltipPosition="TOOLTIP_POSITION.right"
                />
            </div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import { getCurrentCompanyId } from '@/services/permissions/permissions.service';
import { TOOLTIP_POSITION } from '@/common/tooltip';

// HTTP
import { getAuthStrategyState, setAuthStrategyState } from '@/__new__/services/portal/auth/http/auth';
import { getTenants } from '@/http/config/tenant';

export default {
    name: 'AuthenticationMain',

    components: {
        AppButton,
        AppInputV3,
        AbstractListPageWrapper,
        AppToggle,
    },

    data() {
        return {
            // Object with auth provider type as a key for its properties
            authProviders: {
                azuread: {
                    name: 'Azure Active Directory',
                    type: 'azuread',
                    enabled: false,
                },
            },
            authSettings: [],
            oAuthConfigs: [],
            firstSubdomain: '',
            ICON_TYPES,
            BUTTON_TYPES,
            TOOLTIP_POSITION,
        };
    },
    async created() {
        await this.loadCompanyData();
        await this.loadAuthenticationConfig();
    },

    methods: {
        async loadCompanyData() {
            // Determine first subdomain for company
            try {
                const res = await getTenants();
                const currentCompanyId = getCurrentCompanyId();
                this.firstSubdomain = res.data[currentCompanyId]?.firstSubdomain;
                if (!this.firstSubdomain) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('authentication.firstSubdomainMissingWarning'),
                        type: ALERT_TYPES.warning,
                    });
                }
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alerts.failedToLoadCompanyData'),
                });
                Sentry.captureException(e);
            }
        },
        async loadAuthenticationConfig() {
            // Retrieve configs if they exist toggle the correct ones on
            try {
                const authSettingsRes = await getAuthStrategyState();
                if (authSettingsRes.data) {
                    this.authSettings = authSettingsRes.data;
                }
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alerts.unableToLoadConfigs'),
                });
                Sentry.captureException(e);
            }
        },
        configureAuth(provider) {
            this.$router.push({
                name: RouteNames.AUTHENTICATION_CONFIGURATOR,
                query: { provider },
                params: { companyId: this.$route.params.companyId },
            });
        },
        async onSliderClick(setting) {
            const strategyStr = this.$i18n.t(`authentication.authenticationTypes.${setting.strategy}`);
            const enabledStr = setting.enabled
                ? this.$i18n.t('settings.alerts.enabled')
                : this.$i18n.t('settings.alerts.disabled');
            try {
                await setAuthStrategyState(setting.strategy, setting.enabled);
                const toggleWarningMessage = this.$i18n.t('settings.alerts.strategyToggled', {
                    strategy: strategyStr,
                    enabled: enabledStr,
                });
                this.$eventBus.$emit('showAlert', {
                    message: toggleWarningMessage,
                    type: ALERT_TYPES.warning,
                });
            } catch (error) {
                const toggleFailedMessage = this.$i18n.t('settings.alerts.strategyToggleFailed', {
                    strategy: strategyStr,
                    enabled: enabledStr,
                });
                this.$eventBus.$emit('showAlert', {
                    message: toggleFailedMessage,
                });
                Sentry.captureException(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';

.main {
    padding: $spacing-xl;
}
.mapping-button {
    margin-top: $spacing-m;
}

table {
    table-layout: fixed;
    margin-top: $spacing-m;
}

th,
td {
    padding: $spacing-xs;
}
</style>
