import http from '@/http';
import type { AxiosPromise } from 'axios';
import type ORDSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ORDSinkConfig';
import type { GetJobStaticExportResponse } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

export function getJobStaticExport(data: ORDSinkConfig['originalConfig']): AxiosPromise<GetJobStaticExportResponse> {
    return http({
        method: 'POST',
        url: '/v3datahub/config/report/export',
        data,
    });
}

export function getJobStaticExportById(id: string): AxiosPromise<GetJobStaticExportResponse> {
    return http({
        method: 'GET',
        url: '/v3datahub/config/report/exportbyid',
        params: {
            id,
        },
    });
}
