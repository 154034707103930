
import Vue from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// HTTP
import { registerExternalPartnerPubKeys } from '@/__new__/services/dno/authorization/http/authorization';

// Helpers
import { cloneDeep } from 'lodash';
import {
    emptyPartnerPubKeysRequest,
    placeholderPartnerPubKeysRequest,
    pubKeyValidator,
    cidrValidator,
} from '@/__new__/features/settings/thirdPartyApplications/common/ThirdPartyApplicationsHelper';

// Validators
import { validationMixin } from 'vuelidate';
import { required, ipAddress, or } from 'vuelidate/lib/validators';

export default Vue.extend({
    name: 'NewThirdPartyApplication',
    components: {
        AppDialogV2,
        AppButton,
        AppInputV3,
        AppTextareaV3,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLoading: false as boolean,
            app: cloneDeep(emptyPartnerPubKeysRequest),
            BUTTON_TYPES,
            ICON_TYPES,
            placeholderPartnerPubKeysRequest,
        };
    },
    computed: {
        title(): string {
            return this.entity?.id
                ? `${this.$t('generic.edit')} ${this.entity.id}`
                : this.$t('thirdPartyApplications.registerNewApplication');
        },
    },
    watch: {
        entity(value) {
            if (value) {
                this.app.key_info = value;
                this.app.partner_name = value.partner_name;
                return;
            }

            this.app = cloneDeep(emptyPartnerPubKeysRequest);
        },
    },
    validations() {
        return {
            app: {
                partner_name: {
                    required,
                },
                key_info: {
                    scope: {
                        required,
                    },
                    acl: {
                        required,
                        $each: {
                            required,
                            cidr: or(cidrValidator, ipAddress),
                        },
                    },
                    pub_key: {
                        required,
                        pubKeyValidator,
                    },
                },
            },
        };
    },
    methods: {
        addAcl(): void {
            this.app.key_info.acl.push('');
        },
        removeAcl(index: number): void {
            this.app.key_info.acl.splice(index, 1);
        },
        async submit(): Promise<void> {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$alert(this.$t('alertMessage.pleaseFixValidation'));
                return;
            }

            await this.registerKey();
            this.$emit('submit');
            this.$v.$reset();
        },
        registerKey(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isLoading = true;
                    await registerExternalPartnerPubKeys(this.app);
                    this.isLoading = false;
                    this.app = cloneDeep(emptyPartnerPubKeysRequest);

                    this.$showSuccessAlert({
                        message: this.$t('alertMessage.registerExternalPartnerPublicKeysSuccess'),
                    });
                },
                {
                    errorHandler: () => {
                        this.isLoading = false;
                        this.$alert(this.$t('alertMessage.failedToRegisterExternalPartnerPublicKeys'));
                    },
                },
            );
        },
    },
});
