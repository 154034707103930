
import { PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';

// HTTP
import OfferHTTP from '@/__new__/services/dno/pc/http/offer';

/* eslint-disable-next-line no-shadow */
enum SECTION_TEMPLATE {
    LABEL_VALUE = 'labelValue',
    JUSTIFIED_PRICE = 'justifiedPrice',
    DESCRIPTIONS = 'descriptions',
    LIST = 'list',
    CONTACT = 'contact',
}

interface LabelSection {
    template: SECTION_TEMPLATE;
    value?: string;
    label?: string;
    header?: string;
    links?: { label: string; url: string; option?: string }[];
    descriptions?: string[];
    list?: [string, string][];
    border?: boolean;
}

interface FccLabel {
    sections: LabelSection[];
    mainHeader: {
        header: string;
        description: string;
    };
}

export default {
    name: 'BasePlanbasePlanFccLabelDialog',
    components: {
        AppDialogV2,
        AppButton,
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        offer: {
            default: () => ({ id: '', timestamp: 0 }),
            type: Object as PropType<{ id: string; timestamp: number }>,
        },
    },
    data() {
        return {
            label: null as FccLabel | null,

            // Proxy
            SECTION_TEMPLATE,
        };
    },
    computed: {
        header(): { title: string; description?: string } {
            return {
                title: this.label?.mainHeader?.header || this.$t('basePlanFccLabel.title'),
                description: this.label?.mainHeader?.description,
            };
        },
    },
    watch: {
        offer() {
            this.fetchData();
        },
    },
    methods: {
        fetchData() {
            if (!this.offer.id || !this.offer.timestamp) {
                return false;
            }
            return this.$withProgressBar(
                () =>
                    OfferHTTP.getOffersByTimestamp([this.offer]).then(({ data }) => {
                        const { offer_by_id: offers } = data.entity_data_with_timestamps_by_id[this.offer.timestamp];
                        this.label = offers?.[this.offer.id]?.data?.fcc_broadband_label || {
                            sections: [
                                {
                                    template: SECTION_TEMPLATE.LABEL_VALUE,
                                    value: this.$t('basePlanFccLabel.labelNotFound'),
                                    label: '',
                                },
                            ],
                        };
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        handleClose() {
            this.label = null;
            this.$emit('close');
        },
    },
};
