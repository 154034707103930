import { isUserAllowed } from '@/services/permissions/permissions.service';
import { EntityActions } from '@/common/commonEntityStateMapper';

export const getEventActions = () => {
    const actions = [];

    if (isUserAllowed('EventsSend')) {
        actions.push(EntityActions.SEND_EVENT);
    }

    if (isUserAllowed('EventsWrite')) {
        actions.push(EntityActions.DETAILS, EntityActions.EDIT, EntityActions.DELETE);
    }

    return actions;
};
