import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export { STATUS_CODES } from '@/common/commonHelper';

export enum SUBSCRIPTION_STATES {
    // BE enums
    CREATED = 1,
    ACTIVE = 2,
    SUSPENDED = 3,
    DEACTIVATED = 4,
    PENDING_ACTIVATION = 5,
    CANCELED = 6,
}

export const SUBSCRIPTION_STATUS_INDICATOR_MAP: Map<SUBSCRIPTION_STATES, string | undefined> = new Map([
    [SUBSCRIPTION_STATES.CREATED, LABEL_COLOR.yellow],
    [SUBSCRIPTION_STATES.ACTIVE, LABEL_COLOR.green],
    [SUBSCRIPTION_STATES.SUSPENDED, LABEL_COLOR.red],
    [SUBSCRIPTION_STATES.DEACTIVATED, LABEL_COLOR.red],
    [SUBSCRIPTION_STATES.PENDING_ACTIVATION, LABEL_COLOR.yellow],
    [SUBSCRIPTION_STATES.CANCELED, LABEL_COLOR.red],
]);

export const SUBSCRIPTION_STATES_TO_STATUS_NAME_MAP: Map<SUBSCRIPTION_STATES, TranslateResult> = new Map([
    [SUBSCRIPTION_STATES.CREATED, i18n.t('generic.stateMap.created')],
    [SUBSCRIPTION_STATES.ACTIVE, i18n.t('generic.stateMap.active')],
    [SUBSCRIPTION_STATES.SUSPENDED, i18n.t('generic.stateMap.suspended')],
    [SUBSCRIPTION_STATES.DEACTIVATED, i18n.t('generic.stateMap.deactivated')],
    [SUBSCRIPTION_STATES.PENDING_ACTIVATION, i18n.t('generic.stateMap.pendingActivation')],
    [SUBSCRIPTION_STATES.CANCELED, i18n.t('generic.stateMap.canceled')],
]);

export enum SUBSCRIPTION_STATE_DECORATORS {
    // BE enums
    UNSET = 0,
    PENDING_DEACTIVATION = 1,
    PENDING_UPDATE = 2,
    PENDING_SUSPENSION = 3,
}

export const SUBSCRIPTION_STATE_DECORATORS_INDICATOR_MAP: Map<SUBSCRIPTION_STATE_DECORATORS, string | undefined> =
    new Map([
        [SUBSCRIPTION_STATE_DECORATORS.UNSET, LABEL_COLOR.red],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_DEACTIVATION, LABEL_COLOR.yellow],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_UPDATE, LABEL_COLOR.yellow],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_SUSPENSION, LABEL_COLOR.yellow],
    ]);

export const SUBSCRIPTION_STATE_DECORATORS_TO_STATUS_NAME_MAP: Map<SUBSCRIPTION_STATE_DECORATORS, TranslateResult> =
    new Map([
        [SUBSCRIPTION_STATE_DECORATORS.UNSET, i18n.t('generic.unset')],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_DEACTIVATION, i18n.t('generic.pendingDeactivation')],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_UPDATE, i18n.t('generic.pendingUpdate')],
        [SUBSCRIPTION_STATE_DECORATORS.PENDING_SUSPENSION, i18n.t('generic.pendingSuspension')],
    ]);
