
import Vue, { PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// Http
import { getClusterJob, updateClusterJob } from '@/__new__/services/dno/pipelines/http/pipelines';

// Helpers
import { Job } from '@/__new__/features/settings/common/applicationManagerHelper';
import { JOB_TYPE } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

export default Vue.extend({
    name: 'JobEditModal',
    components: {
        AppDialogV2,
        AppButton,
        AppJSON,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: Object as PropType<Job>,
            required: true,
        },
        appName: {
            type: String,
            default: '',
        },
        godeMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            jobData: null as Job['data'],
            isJobDataValid: false as boolean,
            isLoading: false as boolean,
        };
    },
    computed: {
        dialogTitle(): string {
            return `${this.$i18n.t('pipelines.applicationManager.editJob')}: ${this.jobId}`;
        },
        jobId(): string {
            return this.jobData?.id || this.entity?.id;
        },
        isEditDisabled(): boolean {
            return this.jobData?.job_definition?.job_type === JOB_TYPE.STATIC;
        },
        isSubmitDisabled(): boolean {
            return !this.isJobDataValid || this.isEditDisabled;
        },
    },
    watch: {
        entity: {
            immediate: true,
            handler(value: Job) {
                if (value) {
                    this.jobData = value?.data as Job['data'];
                }
            },
        },
    },
    methods: {
        onCloseModal(): void {
            this.jobData = null;
            this.$emit('close');
        },
        async onSave() {
            if (!this.isJobDataValid) {
                return;
            }
            this.isLoading = true;
            await this.updateJob();
            this.isLoading = false;

            this.onCloseModal();
        },
        async updateJob() {
            await this.$withProgressBar(
                async () => {
                    await updateClusterJob(this.appName, this.jobData, this.godeMode);
                    // Must refetch all in case cluster ID has changed(no unique identifier).
                    this.$emit('save');
                    this.$showSuccessAlert({
                        message: `${this.jobId} ${this.$i18n.t('alerts.updatedSuccessfully')}`,
                    });
                },
                {
                    errorHandler: e => {
                        this.$alert(
                            e?.response?.data?.message ||
                                this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                    action: this.$i18n.t('generic.update'),
                                    entityName: this.$i18n.t('pipelines.backgroundGovernor.jobs'),
                                }),
                        );
                    },
                },
            );
        },
        async getJob() {
            await this.$withProgressBar(
                async () => {
                    if (this.jobId && this.appName) {
                        return;
                    }
                    const res = await getClusterJob(this.appName, this.jobId);
                    const entity = Job.mapFromBE(res.data?.data);
                    this.jobData = entity.data;
                },
                {
                    errorHandler: (e: Error) => {
                        this.$alert(e?.message || this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
    },
});
