import httpClient from '@/http';
import { CONTACT_ATTRIBUTES_TOKEN_KEY } from '../common/constants';

export const setTokenOnContactAttributes = (
    tokenKey: (typeof CONTACT_ATTRIBUTES_TOKEN_KEY)[keyof typeof CONTACT_ATTRIBUTES_TOKEN_KEY],
    contactId: string,
    instanceId: string,
) =>
    httpClient({
        method: 'POST',
        url: '/awsconnect/settokenoncontactattributes',
        data: {
            contactId,
            instanceId,
            tokenKey,
        },
    });
