
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { AxiosError } from 'axios';
import {
    SUBSCRIBER_STATE,
    SUBSCRIBER_PORT_IN_COLOR_MAP,
    SUBSCRIBER_PORT_IN_LABEL_MAP,
    SUBSCRIBER_PORT_IN_MAP,
    SUBSCRIBER_PORT_OUT_COLOR_MAP,
    SUBSCRIBER_PORT_OUT_LABEL_MAP,
    USER_MANAGER_HIERARCHY,
    TRANSACTION_STATUS_TO_LABEL,
    TRANSACTION_STATUS_TO_COLOR,
    SUBSCRIBER_PORT_OUT_MAP,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/vue';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { FLAG_MAP, FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import {
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
    CHANNEL,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';

// eslint-disable-next-line no-shadow
enum PORTING_TYPE {
    PORT_IN = 'portIn',
    PORT_OUT = 'portOut',
    TRANSACTION_HISTORY = 'transactionHistory',
    TRANSACTION_VERIFICATION_HISTORY = 'transactionVerificationHistory',
}

/* eslint-disable camelcase */
interface PortInHistoryEntry {
    msisdn: string;
    created_at: number;
    request_id: string;
    subscriber_id: string;
    subscription_type: string;
    account_number: string;
    external_port_in_request_id: string;
    state: SUBSCRIBER_PORT_IN_MAP;
    error_message: string;
    execution_data: {
        account_password: string;
        account_number: string;
        name: string;
        billing_address: {
            street1: string;
            street2: string;
            city: string;
            state: string;
            zip: string;
        };
    };
}
/* eslint-enable camelcase */

export default Vue.extend({
    name: 'PortingTableTile',
    components: {
        AbstractTableTile,
        AppLabel,
        AppButton,
    },
    data() {
        return {
            displayTable: true,
            portInData: [] as PortInHistoryEntry[],
            portInHistoryApiResponse: {},
            portOutData: [],
            portOutHistoryApiResponse: {},
            transactionHistory: [],
            transactionHistoryApiResponse: {},
            transactionVerificationHistory: [],
            transactionVerificationHistoryApiResponse: {},
            activeTab: PORTING_TYPE.PORT_IN,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData(): Subscriber {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.$route.params.id,
            }) as Subscriber;
        },
        isFraud(): boolean {
            return !!this.subscriberData?.flags?.[FLAG_TYPE.IS_FRAUD]?.value;
        },
        tabs(): { id: PORTING_TYPE; title: string }[] {
            const tabs = [
                {
                    id: PORTING_TYPE.PORT_IN,
                    title: this.$t('customerCare.userInformation.portIn'),
                },
                {
                    id: PORTING_TYPE.PORT_OUT,
                    title: this.$t('customerCare.userInformation.portOut'),
                },
            ];
            if (this.isTransactionHistoryAvailable) {
                tabs.push({
                    id: PORTING_TYPE.TRANSACTION_HISTORY,
                    title: this.$t('customerCare.userInformation.transactionHistory'),
                });
                tabs.push({
                    id: PORTING_TYPE.TRANSACTION_VERIFICATION_HISTORY,
                    title: this.$t('customerCare.userInformation.transactionVerificationHistory'),
                });
            }
            return tabs;
        },
        isIssueTemporaryMNPPINAvailable(): boolean {
            return (
                !(this.subscriberData?.flags?.[FLAG_TYPE.PORT_OUT_INITIATED]?.originalValue === FLAG_MAP.TRUE) &&
                isUserAllowed('UMSubscriberEditSubscriberGenerateMNPPIN')
            );
        },
        tableKey(): string {
            return `porting-table-${this.activeTab}`;
        },
        isPortInAvailable(): boolean {
            return isUserAllowed('UMSubscriberPortingTablePortIn');
        },
        isTransactionHistoryAvailable(): boolean {
            return isUserAllowed('UMSubscriberPortingTablePortingHistory');
        },
        isReSubmitPortInAvailable(): boolean {
            return this.lastPortInRequest?.state === SUBSCRIBER_PORT_IN_MAP.MANUALLY_CANCELLED;
        },
        isCancelPortInAvailable(): boolean {
            return [SUBSCRIBER_PORT_IN_MAP.APPLICATION_APPROVED, SUBSCRIBER_PORT_IN_MAP.RESOLUTION_REQUIRED].includes(
                this.lastPortInRequest?.state,
            );
        },
        isPortIn(): boolean {
            return this.activeTab === PORTING_TYPE.PORT_IN;
        },
        isPortOut(): boolean {
            return this.activeTab === PORTING_TYPE.PORT_OUT;
        },
        isTransactionHistory(): boolean {
            return this.activeTab === PORTING_TYPE.TRANSACTION_HISTORY;
        },
        isTransactionVerificationHistory(): boolean {
            return this.activeTab === PORTING_TYPE.TRANSACTION_VERIFICATION_HISTORY;
        },
        lastPortInRequest(): PortInHistoryEntry {
            const clone = [...this.portInData];
            return clone.sort((a, b) => b.created_at - a.created_at)[0];
        },
        entities(): any[] {
            if (this.isPortIn) {
                return this.portInData;
            }
            if (this.isPortOut) {
                return this.portOutData;
            }
            if (this.isTransactionHistory && !isEmpty(this.transactionHistory)) {
                return this.transactionHistory;
            }
            if (this.isTransactionVerificationHistory && !isEmpty(this.transactionVerificationHistory)) {
                return this.transactionVerificationHistory;
            }
            return [];
        },
        columnsData(): TableColumn[] {
            if (this.isPortIn) {
                return this.columnsDataPortIn;
            }
            if (this.isPortOut) {
                return this.columnsDataPortOut;
            }
            if (this.isTransactionHistory) {
                return this.columnsDataHistory;
            }
            if (this.isTransactionVerificationHistory) {
                return this.columnsDataVerificationHistory;
            }
            return [];
        },
        columnsDataPortIn(): TableColumn[] {
            return [
                {
                    key: 'msisdn',
                    field: 'msisdn',
                    name: this.$t('customerCare.msisdn'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    name: this.$t('generic.timestamp'),
                    key: 'created_at',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.created_at),
                    sortBy: entity => entity.created_at,
                    field: 'created_at',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'account_number',
                    field: 'account_number',
                    name: this.$t('customerCare.accountId'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'external_port_in_request_id',
                    field: 'external_port_in_request_id',
                    name: this.$t('customerCare.userInformation.portingColumns.portInRequestId'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'status',
                    field: 'state',
                    name: this.$t('generic.status'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'error_message',
                    field: 'error_message',
                    name: this.$t('customerCare.userInformation.portingColumns.resultDescription'),
                    filterType: tableColumnType.TEXT,
                },
            ];
        },
        columnsDataPortOut(): TableColumn[] {
            return [
                {
                    key: 'msisdn',
                    field: 'msisdn',
                    name: this.$t('customerCare.msisdn'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    name: this.$t('generic.timestamp'),
                    key: 'requestDateFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.created_at),
                    sortBy: entity => entity.created_at,
                    field: 'created_at',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'status',
                    field: 'status',
                    name: this.$t('generic.status'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'result',
                    field: 'result',
                    name: this.$t('customerCare.userInformation.portingColumns.validationResultDescription'),
                    filterType: tableColumnType.TEXT,
                },
            ];
        },
        columnsDataHistory(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.startTime'),
                    key: 'startTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.endTime'),
                    key: 'endTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    field: 'channel',
                    name: this.$t('generic.channel'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'status',
                    field: 'status',
                    name: this.$t('generic.status'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'targetEntity',
                    field: 'targetEntity',
                    name: this.$t('generic.targetEntity'),
                    filterType: tableColumnType.TEXT,
                },
            ];
        },
        columnsDataVerificationHistory(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    field: 'channel',
                    name: this.$t('generic.channel'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'method',
                    field: 'method',
                    name: this.$t('generic.method'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'status',
                    field: 'status',
                    name: this.$t('generic.status'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'id',
                    field: 'id',
                    name: this.$t('generic.id'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'userId',
                    field: 'userId',
                    name: this.$t('customerCare.userId'),
                    filterType: tableColumnType.TEXT,
                },
                {
                    key: 'portalUserId',
                    field: 'portalUserId',
                    name: this.$t('generic.portalUserId'),
                    filterType: tableColumnType.TEXT,
                },
            ];
        },
        apiResponse(): any {
            if (this.isPortIn) {
                return this.portInHistoryApiResponse;
            }
            if (this.isPortOut) {
                return this.portOutHistoryApiResponse;
            }
            if (this.isTransactionHistory) {
                return this.transactionHistoryApiResponse;
            }
            if (this.isTransactionVerificationHistory) {
                return this.transactionVerificationHistoryApiResponse;
            }
            return {};
        },
        isSubscriberActive(): boolean {
            return this.subscriberData?.state === SUBSCRIBER_STATE.ACTIVE;
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const dataRequests = [
                        this.getPortInHistory(this.$route.params.id),
                        this.getPortOutHistory(this.$route.params.id),
                    ];
                    if (this.isTransactionHistoryAvailable) {
                        dataRequests.push(this.getTransactionsHistory(this.$route.params.id));
                        dataRequests.push(this.getTransactionVerificationHistory());
                    }
                    await Promise.all(dataRequests);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        async getPortInHistory(id: string): Promise<void> {
            try {
                const response = await userManagementHTTP.getSubscriberPortInHistory(id);
                this.portInHistoryApiResponse = response;
                this.portInData = (response.data?.port_in_history || []).map((request: any) => ({
                    ...request,
                    ...request.execution_data,
                }));
            } catch (error) {
                this.portInHistoryApiResponse = (error as AxiosError).response || {};
                this.$alert(this.$t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'));
                Sentry.captureException(error);
            }
        },
        async getPortOutHistory(id: string): Promise<void> {
            try {
                const response = await userManagementHTTP.getSubscriberPortOutHistory(id);
                this.portOutHistoryApiResponse = response;
                this.portOutData = (response.data?.port_out_history || []).map((request: any) => ({
                    ...request,
                    ...request.execution_data,
                }));
            } catch (error) {
                this.portOutHistoryApiResponse = (error as AxiosError).response || {};
                this.$alert(this.$t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'));
                Sentry.captureException(error);
            }
        },
        async getTransactionsHistory(id: string): Promise<void> {
            try {
                const response = await customerCareHttp.getUserSensitiveTransactionHistory(
                    id,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    SENSITIVE_TRANSACTIOS_USE_CASE.GENERATE_MNP_PIN,
                );
                this.transactionHistoryApiResponse = response;
                const transactions = !isEmpty(response?.data?.transactions) ? response.data.transactions : [];
                this.transactionHistory = transactions.map(el => ({
                    startTime: el.start_time,
                    endTime: el.end_time,
                    channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(el.channel),
                    status: el.status,
                    targetEntity: el.target_id,
                }));
            } catch (error) {
                this.transactionHistoryApiResponse = (error as AxiosError).response || {};
                this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        async getTransactionVerificationHistory(): Promise<void> {
            try {
                const response = await customerCareHttp.fetchServiceBlockerHistory(
                    this.subscriberData?.userId,
                    USER_MANAGER_HIERARCHY.USER,
                    SERVICE_TYPE.END_USER_AUTHORIZATION,
                );
                this.transactionVerificationHistoryApiResponse = response;
                const otpAttempts = !isEmpty(response?.data?.otp_attempts) ? response.data.otp_attempts : [];
                this.transactionVerificationHistory = otpAttempts
                    .filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.GENERATE_MNP_PIN)
                    .map(el => ({
                        time: el.event_time,
                        channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                            el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                        ),
                        method: el?.properties?.method || '',
                        status: el.event_type,
                        id: el?.properties?.external_id || '',
                        userId: el?.identifier || '',
                        portalUserId: el?.properties?.portal_id || '',
                    }));
            } catch (error) {
                this.transactionVerificationHistoryApiResponse = (error as AxiosError).response || {};
                this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        resubmitPortInRequest(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    if (!this.lastPortInRequest) {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        return;
                    }

                    await userManagementHTTP.initiatePortIn(this.$route.params.id, {
                        port_in_external_id: this.lastPortInRequest.msisdn,
                        port_in_subscription_type: this.lastPortInRequest.subscription_type,
                        customer_data: {
                            account_password: this.lastPortInRequest.execution_data?.account_password,
                            account_number: this.lastPortInRequest.execution_data?.account_number,
                            name: this.lastPortInRequest.execution_data?.name,
                            billing_address: {
                                street1: this.lastPortInRequest.execution_data?.billing_address?.street1,
                                street2: this.lastPortInRequest.execution_data?.billing_address?.street2,
                                city: this.lastPortInRequest.execution_data?.billing_address?.city,
                                state: this.lastPortInRequest.execution_data?.billing_address?.state,
                                zip: this.lastPortInRequest.execution_data?.billing_address?.zip,
                            },
                        },
                    });
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        cancelPortInRequest() {
            this.$withProgressBar(
                async () => {
                    if (this.lastPortInRequest?.request_id) {
                        await userManagementHTTP.cancelPortIn(
                            this.$route.params.id,
                            this.lastPortInRequest?.request_id,
                        );
                        this.$showSuccessAlert({
                            message: this.$t('customerCare.subscriberTab.cancelPortInRequestSuccess', {
                                id: this.lastPortInRequest?.request_id,
                            }),
                        });
                    } else {
                        this.$alert(this.$t('customerCare.subscriberTab.cancelPortInRequestFailed'));
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('customerCare.subscriberTab.cancelPortInRequestFailed'));
                    },
                },
            );
        },
        getStatusMapping(entity: any): { title?: string; color?: string } {
            if (this.isPortIn) {
                return this.portInStatus(entity);
            }
            if (this.isPortOut) {
                return this.portOutStatus(entity);
            }
            if (this.isTransactionHistory || this.isTransactionVerificationHistory) {
                return this.transactionHistoryStatus(entity);
            }
            return {};
        },
        portInStatus(entity: any) {
            return {
                title: this.$t(
                    `generic.stateMap.${SUBSCRIBER_PORT_IN_LABEL_MAP[entity.state as SUBSCRIBER_PORT_IN_MAP]}`,
                ),
                color: SUBSCRIBER_PORT_IN_COLOR_MAP.get(entity.state),
            };
        },
        portOutStatus(entity: any) {
            const status = entity.reason
                ? SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_FAILED
                : SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_SUCCESS;
            return {
                title: this.$t(`generic.stateMap.${SUBSCRIBER_PORT_OUT_LABEL_MAP[status]}`),
                color: SUBSCRIBER_PORT_OUT_COLOR_MAP.get(status),
            };
        },
        transactionHistoryStatus(entity: any) {
            return {
                title: TRANSACTION_STATUS_TO_LABEL.get(entity.status),
                color: TRANSACTION_STATUS_TO_COLOR.get(entity.status),
            };
        },
        onTabSelected(id: PORTING_TYPE) {
            this.activeTab = id;
            this.forceRerender();
        },
        async forceRerender() {
            // Updates table columns
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
        issueTemporaryMNPPIN() {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.subscriberData?.accountId || '',
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    operationType: SENSITIVE_OPERATION_TYPES.ISSUE_TEMPORARY_MNP_PIN,
                    operationTargetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    operationTargetId: this.$route.params.id,
                },
            });
        },
    },
});
