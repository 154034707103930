import http from '@/http/index';
import { type AxiosPromise } from 'axios';

/**
 * @returns {AxiosPromise}
 */
export function getApplications(id: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operateapiorchestration/getapplications',
        params: {
            'channelPartner.id': id,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApplicationOwners(id: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operateapiorchestration/getapplicationowners',
        params: {
            'channelPartner.id': id,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApiProducts(id: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operateapiorchestration/getapiproducts',
        params: {
            'channelPartner.id': id,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApiProductOrders(id: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operateapiorchestration/getapiproductorders',
        params: {
            'channelPartner.id': id,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function createApplication(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapplication',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function createApplicationOwner(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapplicationowner',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function createApiProductOrder(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapiproductorder',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApiTransactions(data: {
    start_timestamp: number;
    end_timestamp: number;
    target_id: string;
    target_type: 1;
}): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingtransaction/getapitransactions',
        data: {
            ...data,
            // Notice: API fails for all other operator names other than 'lotusflaretelecom'
            // @todo Remove once BE does proper implementation.
            operator_name: 'lotusflaretelecom',
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function updateApplication({
    id,
    payload,
    channelPartnerId,
}: {
    id: string;
    payload: any;
    channelPartnerId: string;
}): AxiosPromise<any> {
    return http({
        method: 'PATCH',
        url: `/v3operateapiorchestration/updateapplication`,
        params: {
            id,
            'channelPartner.id': channelPartnerId,
        },
        data: payload,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function updateApplicationOwner({
    id,
    payload,
    channelPartnerId,
}: {
    id: string;
    payload: any;
    channelPartnerId: string;
}): AxiosPromise<any> {
    return http({
        method: 'PATCH',
        url: `/v3operateapiorchestration/updateapplicationowner`,
        params: {
            id,
            'channelPartner.id': channelPartnerId,
        },
        data: payload,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApprovalHistory(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3ossdtmace/getapprovalhistory',
        data,
    });
}
