import http from '@/http';
import type { AxiosPromise } from 'axios';
import type ApiInvokerSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ApiInvokerSinkConfig';
import type { GetJobStaticExportResponse } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

export function getJobStaticExport(
    data: ApiInvokerSinkConfig['originalConfig'],
): AxiosPromise<GetJobStaticExportResponse> {
    return http({
        method: 'POST',
        url: '/v3datahub/config/invoker/export',
        data,
    });
}

export function getJobStaticExportById(id: string): AxiosPromise<GetJobStaticExportResponse> {
    return http({
        method: 'GET',
        url: '/v3datahub/config/invoker/exportbyid',
        params: {
            id,
        },
    });
}
