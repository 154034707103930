
// http
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { USER_INFO_CRED_TYPES } from '@/__new__/services/dno/user/models/User';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'TMOIDLinked',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        userInfo() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.id,
            });
        },
        tmoIdLinked() {
            return this.userInfo?.creds?.find(el => el.id_type === USER_INFO_CRED_TYPES.SECURE_ID_INTERNAL)
                ? this.$i18n.t('generic.yes')
                : this.$i18n.t('generic.no');
        },
        showUnlinkBtn() {
            return (
                this.userInfo?.creds?.find(el => el.id_type === USER_INFO_CRED_TYPES.SECURE_ID_INTERNAL) &&
                isUserAllowed('UMUserUnlinkTMOId')
            );
        },
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        unlinkTMOIDHandler() {
            const button = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.unlinkTMOID();
                },
            });
            this.$alert(this.$i18n.t('alertMessage.userManagement.userWillBeUnlinked'), {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
        async unlinkTMOID() {
            await this.$withProgressBar(
                async () => {
                    await osstmofiberHTTP.removeUserTMOId(this.id, USER_MANAGER_HIERARCHY.USER);
                    this.$alert(this.$i18n.t('alertMessage.userManagement.userTMOIDUnlinkedSuccessfully'), {
                        type: ALERT_TYPES.success,
                    });
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.USER,
                        targetId: this.id,
                    });
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.userManagement.theUpdateWasNotSuccessful'),
                        });
                    },
                },
            );
        },
    },
};
