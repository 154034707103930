
import Vue from '@/common/typedVue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import DeviceInfoTile from '@/__new__/features/customerCareSuite/components/DeviceInfoTile.vue';
import ExportCustomerInfoDialog from '@/__new__/features/customerCareSuite/components/ExportCustomerInfoDialog.vue';

// Helpers
import permissionsService, {
    getAccountConsentFields,
    getAccountDetailFields,
    getOperatorConfigValue,
    isUserAllowed,
    isServiceEnabled,
} from '@/services/permissions/permissions.service';
import OutageInfo from '@/__new__/services/dno/osstmofiber/models/OutageInfo';
import Flag, { FLAG_TYPE, FLAG_TC_GLOBAL_ACCEPTED_TEXT } from '@/__new__/services/dno/user/models/Flag';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { ordinalNumberFormatter, formatAddressLines } from '@/common/formatting';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { isEmpty } from 'lodash';
import ExternalResource from '@/__new__/services/dno/ossgpfiber/models/ExternalResource';
import { ACCOUNT_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import {
    USER_MANAGER_HIERARCHY,
    subscriberStateToText,
    subscriberStateToColor,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import User, { USER_INFO_CRED_TYPES, IS_OTP_VALIDATED, IS_PIN_SETUP } from '@/__new__/services/dno/user/models/User';
import { getOutageTypeLabel } from '@/__new__/features/customerCare/common/outageHelper';
import { findActiveOutage } from '@/__new__/features/customerCareSuite/common/outageHelper';
// TODO: should be renamed to UMEntity State History since not Subscriber specific impl once old UM deprecated
import SubscriberStateHistory from '@/__new__/features/customerCare/subscriber/SubscriberStateHistory.vue';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    CONSENT_CONTEXT_TYPE,
    Consent,
    getTermsConsentTypeI18nKey,
} from '@/__new__/features/customerCare/common/consentsHelper';
import RouteNames from '@/router/routeNames';
import Account from '@/__new__/services/dno/user/models/Account';
import { TranslateResult } from 'vue-i18n';

// HTTP
import consentHTTP from '@/__new__/services/dno/consent/http/consent';
import ossgpfiberHTTP from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

type InfoField = {
    name: string | TranslateResult;
    value: any;
    explanationText?: string | TranslateResult;
};
type InfoSection = {
    title: string;
    value?: InfoField[];
    slotKey?: string;
    additionalLabels?: { title: TranslateResult; color?: string | undefined }[];
    initiallyExpanded?: boolean;
};

export default Vue.extend({
    name: 'AccountPageAnchor',

    components: {
        AppButton,
        AppCustomerHeader,
        AppInfoBlock,
        ExportCustomerInfoDialog,
        SubscriberStateHistory,
        DeviceInfoTile,
    },
    data() {
        return {
            isOutageHistoryEnabled:
                permissionsService.userManagementOutageHistoryEnabled() && isUserAllowed('UMAccountOutageHistoryRead'),

            isCustomerInfoModalVisible: false,
            externalResource: new ExternalResource(),
            billingAccount: null as { data: any; isAutoPay: boolean } | null,
            serviceInfo: { providerProduct: '' },
            activeOutage: null as OutageInfo | null,
            BUTTON_TYPES,
            USER_MANAGER_HIERARCHY,
            consents: [] as Consent[],
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementAccount', ['getStateHistoryByIdAscending', 'getPaymentMethodsById']),
        accountData(): Account {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this.$route.params.id,
            });
        },
        userInfo(): User {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this?.accountData?.userId,
            });
        },
        accountDetailFields(): ACCOUNT_DETAIL_FIELD[] {
            return getAccountDetailFields();
        },
        accountConsentFields(): CONSENT_CONTEXT_TYPE[] {
            return getAccountConsentFields();
        },
        hasAccountConsentFields(): boolean {
            return !!this.accountConsentFields?.length;
        },
        isOutageInfoVisible(): boolean {
            return this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.OUTAGE_INFO) || this.isOutageHistoryEnabled;
        },
        basicInfoStatusIndicators(): { title: TranslateResult; color?: string }[] {
            const additionalLabels = [];

            if (Number.isInteger(this.accountData.state)) {
                additionalLabels.push({
                    title: subscriberStateToText(this.accountData.state as number),
                    color: subscriberStateToColor(this.accountData.state as number),
                });
            }

            return additionalLabels;
        },
        infoData(): InfoSection[] {
            let result: InfoSection[] = [];

            if (!isEmpty(this.accountData)) {
                result = [
                    {
                        title: this.$i18n.t('customerCare.basicInfo'),
                        slotKey: 'basicInfo',
                        additionalLabels: this.basicInfoStatusIndicators,
                        initiallyExpanded: true,
                    },
                    {
                        title: this.$i18n.t('generic.address'),
                        value: this.addressInfo,
                        initiallyExpanded: true,
                    },
                ];

                if (this.networkInfo.length) {
                    result.push({
                        title: this.$i18n.t('generic.network'),
                        value: this.networkInfo,
                        initiallyExpanded: true,
                    });
                }

                if (this.termsAndConditionsInfo.length) {
                    result.push({
                        title: this.$i18n.t('generic.termsAndConditions'),
                        value: this.termsAndConditionsInfo,
                        initiallyExpanded: true,
                    });
                }
                if (this.flagsInfo.length) {
                    result.push({
                        title: this.$i18n.t('customerCare.account.flags'),
                        value: this.flagsInfo,
                        additionalLabels: this.flagsInfoStatusIndicators,
                        initiallyExpanded: true,
                    });
                }

                if (this.isOutageInfoVisible) {
                    result.push({
                        title: this.$i18n.t('customerCare.account.outageInfo'),
                        value: this.outageInfo,
                        initiallyExpanded: true,
                    });
                }
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ACCOUNT_PROPERTIES)) {
                result.push({
                    title: this.$i18n.t('customerCare.userInformation.accountProperties'),
                    value: this.accountProperties,
                    initiallyExpanded: false,
                    additionalLabels: [],
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.TMO_ID_SECTION)) {
                result.push({
                    title: this.$i18n.t('customerCareSuite.tmoIdInfo'),
                    value: this.tmoIdSection,
                    initiallyExpanded: true,
                    additionalLabels: [],
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DEVICE_SECTION)) {
                // make sure to insert this section at 6th position (under network section in 3rd column)
                if (result.length < 6) {
                    result.length = 6;
                }
                result.splice(5, 0, {
                    title: this.$i18n.t('customerCareSuite.deviceInfoSection'),
                    slotKey: 'device',
                    initiallyExpanded: true,
                });
            }
            return result;
        },
        basicInfo(): InfoField[] {
            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ACCOUNT_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.accountId'),
                    value: this.accountData.accountId,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.FRIENDLY_ACCOUNT_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.friendlyAccountId'),
                    value: this.accountData.friendlyAccountId,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ACCOUNT_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.accountName'),
                    value: this.accountData.accountName,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.BILLING_DAY)) {
                const billingDay = this.getAccountBillingDay
                    ? `${ordinalNumberFormatter(this.getAccountBillingDay)} ${this.$i18n.t(
                          'customerCare.account.everyMonth',
                      )}`
                    : this.$i18n.t('generic.N/A');
                data.push({
                    name: this.$i18n.t('customerCare.account.billingDay'),
                    value: billingDay,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.LAST_4_DIGITS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.last4Digits'),
                    value: this.last4Digits ? this.last4Digits : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.EXPIRY_TIME)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.expiryTime'),
                    value: this.externalResource?.expirationDate
                        ? this.$localeLibrary.getFormattedDate(this.externalResource.expirationDate as number)
                        : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.accountData.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CARDINALITY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.cardinality'),
                    value: this.accountData.cardinality,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.autopay'),
                    value: this.billingAccount?.isAutoPay
                        ? this.$i18n.t('generic.stateMap.on')
                        : this.$i18n.t('generic.stateMap.off'),
                });
            }

            return data;
        },
        addressInfo(): InfoField[] {
            const data: InfoField[] = [];

            if (!this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ADDRESS)) {
                return data;
            }

            if (
                this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.BUSSINES_ADDRESS_TYPE) &&
                this.accountData.businessAccount
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.account.b/r'),
                    value: this.accountData.businessAccount,
                    explanationText: this.$i18n.t('customerCare.account.b/rInfo'),
                });
            }

            if (this.accountData?.addresses?.length) {
                this.accountData.addresses.forEach(address => {
                    const addressLines = formatAddressLines(address).lines;

                    data.push({
                        name: address.stringAddressType,
                        value: addressLines || [],
                    });
                    if (address.eligibilityStatus) {
                        data.push({
                            name: address.eligibilityStatusString,
                            value: address.eligibilityStatus as string,
                        });
                    }

                    if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.TAX_AREA_ID) && address.taxAreaId) {
                        data.push({
                            name: this.$i18n.t('customerCare.account.taxAreaID'),
                            value: address.taxAreaId,
                        });
                    }
                });
            }

            return data;
        },
        networkInfo(): InfoField[] {
            const data: InfoField[] = [];
            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DOCUMENT)) {
                data.push({
                    name: this.$i18n.t('customerCare.document'),
                    value: [
                        {
                            text: this.accountData.documentType,
                            link: this.accountData.documentSrc,
                        },
                    ],
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DOCUMENT_NUMBER)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.documentNumber'),
                    value: this.accountData.documentNumber || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.PROVIDER)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.provider'),
                    value: this.accountData?.providerId ? onlyFirstLetterUppercase(this.accountData?.providerId) : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.LOCATION_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.locationId'),
                    value: this.accountData?.addresses?.[0]?.locationId || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.SERVICE_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.serviceId'),
                    value: this.accountData?.pilotServiceId || '',
                });
            }

            if (this.isServiceInfoEnabled) {
                data.push({
                    name: this.$i18n.t('customerCare.account.providerProduct'),
                    value: this.serviceInfo?.providerProduct || '',
                });
            }

            if (this.isExternalDataEnabled) {
                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.EXTERNAL_SERVICE_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.serviceId'),
                        value: this.externalResource.serviceId,
                    });
                }
                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ORDER_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.orderHistory.orderId'),
                        value: this.externalResource.orderId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ORDER_ACTION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.orderActionId'),
                        value: this.externalResource.orderActionId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.RESERVATION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservationId'),
                        value: this.externalResource.reservationId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.RESERVED_DP_NAP_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservedDpNapNodeId'),
                        value: this.externalResource.reservedDpNapNodeId,
                    });
                }
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.cvlan'),
                    value: this.accountData?.cvlan,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.SVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.svlan'),
                    value: this.accountData?.svlan,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.MAC_ADDRESS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.macAddress'),
                    value: this.accountData?.macAddress || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DEVICE_TYPE)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.deviceType'),
                    value: this.accountData?.deviceType || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DEVICE_MODEL)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.deviceModel'),
                    value: this.accountData?.deviceModel || '',
                });
            }

            return data;
        },
        tmoIdSection(): InfoField[] {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.tmoIdLinked'),
                    value: this.userInfo?.creds?.find(el => el.id_type === USER_INFO_CRED_TYPES.SECURE_ID_INTERNAL)
                        ? this.$i18n.t('generic.yes')
                        : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('generic.email'),
                    value: this.userInfo?.externalEmail || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumber'),
                    value: this.userInfo?.externalMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.validationMethod'),
                    value:
                        this.userInfo?.flags?.is_otp_validated?.originalValue === IS_OTP_VALIDATED.OTP
                            ? this.$i18n.t('customerCareSuite.otp')
                            : this.$i18n.t('customerCareSuite.pin'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.isPinEnabled'),
                    value:
                        this.userInfo?.flags?.is_pin_setup?.originalValue === IS_PIN_SETUP.PIN
                            ? this.$i18n.t('generic.yes')
                            : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumberForOtp'),
                    value: this.userInfo?.notificationMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.tmoWirelessNumber'),
                    value: this.userInfo?.isTmoUser ? this.$i18n.t('generic.yes') : this.$i18n.t('generic.no'),
                },
            ];
        },
        isExternalDataEnabled(): boolean {
            return (
                permissionsService.userManagementAccountExternalDataEnabled() &&
                [
                    ACCOUNT_DETAIL_FIELD.EXTERNAL_SERVICE_ID,
                    ACCOUNT_DETAIL_FIELD.ORDER_ID,
                    ACCOUNT_DETAIL_FIELD.ORDER_ACTION_ID,
                    ACCOUNT_DETAIL_FIELD.RESERVATION_ID,
                    ACCOUNT_DETAIL_FIELD.RESERVED_DP_NAP_ID,
                ].some(this.isDetailFieldEnabled)
            );
        },
        isServiceInfoEnabled(): boolean {
            return (
                permissionsService.userManagementServiceInfoEnabled() &&
                [ACCOUNT_DETAIL_FIELD.PROVIDER_PRODUCT].some(this.isDetailFieldEnabled)
            );
        },
        outageInfo(): InfoField[] {
            if (!this.activeOutage) {
                return [
                    {
                        name: this.$i18n.t('generic.status'),
                        value: this.$i18n.t('customerCareSuite.outageInfo.noActiveOutage'),
                    },
                ];
            }
            return [
                {
                    name: this.$i18n.t('generic.status'),
                    value: this.activeOutage?.status,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageType'),
                    value: this.$i18n.t(getOutageTypeLabel(this.activeOutage?.outageType)),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageReason'),
                    value: this.activeOutage?.reason,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    value: this.activeOutage?.description,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.estimatedResolution'),
                    value: this.activeOutage?.estimatedResolution ? `${this.activeOutage.estimatedResolution}h` : '',
                },
                {
                    name: this.$i18n.t('generic.startTime'),
                    value: this.activeOutage?.startTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.startTime)
                        : '',
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    value: this.activeOutage?.endTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.endTime)
                        : '',
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageId'),
                    value: this.activeOutage?.id,
                },
            ];
        },
        termsAndConditionsInfo(): InfoField[] {
            if (this.hasAccountConsentFields) {
                return this.accountConsentFields.map(consentType => {
                    const foundConsent = this.consents.find(consent => consent.context_type === consentType);
                    return {
                        name: this.$i18n.t(getTermsConsentTypeI18nKey(consentType)),
                        value: foundConsent?.is_set
                            ? this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(foundConsent.epoch)
                            : '',
                    };
                });
            }

            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY_TC)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.autopayTC'),
                    value: this.accountData.tcGlobalAccepted,
                });
            }

            // Checking if the Autopay T&C is accepted so in relation to that Autopay T&C timestamp is displayed
            if (
                this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY_TC_TIMESTAMP) &&
                this.accountData.tcGlobalAccepted === FLAG_TC_GLOBAL_ACCEPTED_TEXT.ACCEPTED
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.autopayTcTimestamp'),
                    value: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                        this.accountData.tcAcceptanceTimestamp || 0,
                    ),
                });
            }

            if (this.isTcConcentVisible) {
                const tcConsent = this.consents.find(
                    consent => consent.context_type === CONSENT_CONTEXT_TYPE.PLATFORM_TERMS_AND_CONDITIONS,
                );
                data.push({
                    name: this.$i18n.t('customerCare.account.ts&cs'),
                    value: tcConsent?.is_set
                        ? this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(tcConsent.epoch)
                        : '',
                });
            }

            return data;
        },
        flagsInfo(): InfoField[] {
            const data: InfoField[] = [];

            if (isEmpty(this.accountData.flags) || !this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.FLAGS)) {
                return data;
            }

            const accountFlags = getOperatorConfigValue('service_config.lf-user.flags.account_flags');

            const isFilteringPossible =
                Array.isArray(accountFlags) && !getOperatorConfigValue('isFlagFilteringDisabled');

            Object.values(this.accountData.flags || {})
                .filter((flag: Flag) => {
                    // filter out the taxExemption flag if it's not enabled
                    if (
                        flag.field === FLAG_TYPE.TAX_EXEMPTION &&
                        (!this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.FLAG_TAX_EXEMPTION) ||
                            flag.originalValue === undefined)
                    ) {
                        return false;
                    }
                    if (isFilteringPossible && !accountFlags.includes(flag.field)) {
                        return false;
                    }
                    return true;
                })
                .forEach((flag: Flag) => {
                    data.push({
                        name: flag.name,
                        value: flag.detailedValue,
                    });
                });

            return data;
        },
        accountProperties(): InfoField[] {
            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.WIRELESS_BRAND_CODE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.wirelessBrandCode'),
                    value: this.accountData.brandCode,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.WIRELESS_NUMBER_ELIGIBLE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.wirelessNumberEligible'),
                    value: this.accountData.isMsisdnDiscountEligible
                        ? this.$i18n.t('generic.yes')
                        : this.$i18n.t('generic.no'),
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.WIRELESS_NUMBER_STATUS)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.wirelessNumberStatus'),
                    value: this.accountData.lineStatus,
                });
            }

            return data;
        },
        flagsInfoStatusIndicators(): { title: string; color: string }[] {
            const additionalLabels = [];
            const hasSuspendedServices = Object.values(this.accountData.flags || {}).some(
                Flag.isFlagSuspendingSomeServices,
            );

            if (hasSuspendedServices) {
                // TODO: isSetForCancelationAtEndOfBillCycle not present. Remove?
                additionalLabels.push({
                    title: this.isSetForCancelationAtEndOfBillCycle
                        ? this.$i18n.t('customerCare.cancelTerminate.servicesSetForCancelation')
                        : this.$i18n.t('customerCare.suspendServices.someServicesSuspended'),
                    color: LABEL_COLOR.yellow as string,
                });
            }

            return additionalLabels;
        },
        // State History related part
        stateHistoryFormatted(): { state: string; date: TranslateResult; time: TranslateResult }[] {
            return (this.getStateHistoryByIdAscending(this.$route.params.id) || []).map(
                // eslint-disable-next-line camelcase
                (stateData: { state: string; change_time: number }) => ({
                    state: stateData.state,
                    date: this.$localeLibrary.getFormattedDate(stateData.change_time),
                    time: this.$localeLibrary.getFormattedTime(stateData.change_time),
                }),
            );
        },
        isStateHistoryNotEmpty(): boolean {
            return (this.stateHistoryFormatted || []).length > 0;
        },
        isExportCustomerInfoEnabled(): boolean {
            return this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.EXPORT_CUSTOMER_INFO);
        },
        paymentMethods(): any[] {
            return this.$route.params.id ? this.getPaymentMethodsById(this.$route.params.id) : [];
        },
        isTcConcentVisible(): boolean {
            return this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.TC_ACCEPTANCE_TIME);
        },
        getBillingDataInfoDay(): number {
            return this.billingAccount?.data?.cycle_info?.billing_date_info?.billing_day || 0;
        },
        getAccountBillingDay(): number {
            return this.accountData?.billingDay || this.getBillingDataInfoDay;
        },
        last4Digits(): string[] {
            return [...this.paymentMethods]
                .sort((a, b) => b.default - a.default)
                .map(({ default: isDefault, last_4: last4Digits }) =>
                    this.paymentMethods.length > 1 && isDefault
                        ? `${last4Digits} (${this.$i18n.t('generic.default')})`
                        : last4Digits,
                );
        },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push({
                name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                params: { companyId: this.$route.params.companyId },
            });
            return;
        }

        this.fetchStateHistory();
        this.fetchExternalResource();
        if (this.isOutageInfoVisible) {
            this.fetchOutageData();
        }
        if (this.isServiceInfoEnabled) {
            this.fetchServiceInfo();
        }

        if (this.isTcConcentVisible || this.hasAccountConsentFields) {
            this.fetchConsents();
        }
        if (isServiceEnabled('payment')) {
            this.fetchPaymentMethods();
        }
        if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY)) {
            this.fetchBillingAccount();
        }
    },
    methods: {
        ...mapActions('userManagementAccount', ['getStateHistory', 'getPaymentMethods']),
        fetchStateHistory() {
            this.$withProgressBar(
                async () => {
                    await this.getStateHistory(this.$route.params.id);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        fetchExternalResource() {
            if (this.isExternalDataEnabled) {
                this.$withProgressBar(
                    async () => {
                        const res = await ossgpfiberHTTP.getResource(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.ACCOUNT,
                        );
                        this.externalResource = new ExternalResource(
                            ExternalResource.mapResourceFromBE(res?.data?.resource),
                        );
                    },
                    {
                        errorHandler: () => {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            });
                        },
                    },
                );
            }
        },
        fetchPaymentMethods() {
            this.$withProgressBar(
                async () => {
                    await this.getPaymentMethods(this.$route.params.id);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        fetchBillingAccount() {
            this.$withProgressBar(
                async () => {
                    const res = await ordersHTTP.getBillingAccount(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );
                    const billingAccountData = (res?.data as any)?.billing_account;

                    this.billingAccount = {
                        data: billingAccountData,
                        isAutoPay: billingAccountData.auto_pay,
                    };
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        async fetchOutageData() {
            await this.$withProgressBar(
                async () => {
                    const outageHistoryApiResponse = await osstmofiberHTTP.getOutageHistory(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );
                    const outageHistory = outageHistoryApiResponse?.data?.outage_history;
                    if (outageHistory && Array.isArray(outageHistory)) {
                        const outageHistoryRemapped = outageHistory.map(
                            o => new OutageInfo(OutageInfo.mapOutageFromBe(o)),
                        );
                        this.activeOutage = findActiveOutage(outageHistoryRemapped) || null;
                    }
                    if (this.activeOutage) {
                        this.$alert(this.$i18n.t('alertMessage.userManagement.outageIsOngoing'), {
                            type: ALERT_TYPES.warning,
                        });
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingOutageData'));
                    },
                },
            );
        },
        fetchServiceInfo() {
            this.$withProgressBar(
                async () => {
                    const serviceInfoResponse = await osstmofiberHTTP.getServiceInfo(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );
                    if (serviceInfoResponse?.data) {
                        this.serviceInfo.providerProduct = serviceInfoResponse?.data?.provider_product;
                    }
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        async fetchConsents() {
            await this.$withProgressBar(
                async () => {
                    const consentResponse = await consentHTTP.getConsents(this.$route.params.id, TARGET_TYPE.ACCOUNT);
                    this.consents = consentResponse?.data?.consents || [];
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        isDetailFieldEnabled(fieldName: ACCOUNT_DETAIL_FIELD): boolean {
            return this.accountDetailFields.includes(fieldName);
        },
        openCustomerInfoModal() {
            this.isCustomerInfoModalVisible = true;
        },
        closeCustomerInfoModal() {
            this.isCustomerInfoModalVisible = false;
        },
    },
});
