import { AgentClient } from '@amazon-connect/contact';

export async function getConnectInstanceId(): Promise<string> {
    const agentClient = new AgentClient();
    const agentArn = await agentClient.getARN();
    const instanceIdMatch = agentArn.match(/instance\/(.*?)\/agent/);
    if (instanceIdMatch) {
        return instanceIdMatch[1];
    } else {
        throw new Error('Failed to get instanceId: no match in Agent ARN: ' + agentArn);
    }
}
