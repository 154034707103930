import http, { type DnoResponseBase } from '@/http/index';
import type { AxiosPromise } from 'axios';
import type { ReportRequestParams } from '@/__new__/services/dno/reports/http/reports';

export function fetchReportsDefinitionsOrd(): AxiosPromise<DnoResponseBase> {
    return http('/v3reports/definitionsord').then(resp => resp.data.data);
}

export function fetchOrdReports({
    definitionId,
    startDate,
    endDate,
    size = 100,
    pageToken = '',
}: ReportRequestParams & { size?: number; pageToken?: string }): AxiosPromise<DnoResponseBase> {
    const params: Record<string, any> = {
        definitionId,
        startDate,
        endDate,
        timezone: 'Etc/UTC',
        size,
    };

    if (pageToken) {
        params['page-token'] = pageToken;
    }

    return http('/v3reports/reportsord', {
        params,
    }).then(resp => resp.data);
}

export function generateOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http.post('/v3reports/generateord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function fetchOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http('/v3reports/fetchord', {
        params: {
            definitionId,
            startDate,
            endDate,
            timezone,
        },
    }).then(resp => resp.data.data);
}

export function publishOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http.post('/v3reports/publishord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function previewOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http.post('/v3reports/previeword', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export default {
    fetchReportsDefinitionsOrd,
    fetchOrdReports,
    generateOrdReport,
    fetchOrdReport,
    publishOrdReport,
    previewOrdReport,
};
