import { CSV_COLUMN_DATA } from '@/common/csvHelper';
import { ACTION, STATUS } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import i18n from '@/i18n';

export type PortalId = string;
export type MillisecondsSinceEpoch = number;

export interface CustomerLine {
    msisdn: string;
    appClientId: string;
    channel: string;
    customerBan: string;
    payingCustomerId: string;
    commercialOfferName: string;
    action: ACTION;
    actionStr: string;
    status: STATUS;
    statusStr: string;
    lastUpdatedAt: MillisecondsSinceEpoch;
    /**
     * Portal ID of user who last updated this MSISDN
     */
    lastUpdatedBy: PortalId;
    /**
     * Portal name of user who last updated this MSISDN
     */
    lastUpdatedByName?: string;
    bulkUploadId: string;
    customerName: string;
    qodProfiles: string;
    approverName: string;
    serviceIds: string;
}

export enum DOWNLOAD_FILE_TYPE {
    ORIGINAL = 'original',
    FAILED_RECORDS_ONLY = 'failed_records_only',
}

/**
 * Defines columns in CSVs for all API types
 * Columns should have the same meaning regardless of which API type CSV they appear in.
 */
export enum CSV_COLUMN_TYPE {
    MSISDN = 'msisdn',
    APPLICATION_CLIENT_ID = 'appClientId',
    CHANNEL = 'channel',
    CUSTOMER_BAN = 'customerBan',
    CUSTOMER_NAME = 'customerName',
    APPROVER_NAME = 'approverName',
    QOD_PROFILES = 'qodProfiles',
    SERVICE_IDS = 'serviceIds',
    ORG_ID_PARTNER_ID = 'orgIdPartnerId',
    ORG_ID = 'orgId',
    COMMERCIAL_OFFER_NAME = 'commercialOfferName',
    ACTION = 'action',
}

export interface API_TYPE_PERMISSIONS {
    read: string;
    write: string;
}

export interface API_TYPE_CONFIG {
    /**
     * String used to identify the API Config in the UI
     */
    label: string;
    /**
     * Optional verbose version of label. Eg:
     *      label: 'QoD'
     *      labelVerbose: `Quality On Demand`
     */
    labelVerbose?: string;
    /**
     * Dataflows are used to determine the URL for many line auth operations.
     * It helps backend services sort our actions into predefined categories.
     * Uploading, progress tracking all take dataflow into account.
     * Look at "/v3developerlineauthorization/registeredentities" route definition
     * in routes.json for a concrete example.
     */
    dataflow: string;
    columns: CSV_COLUMN_TYPE[];
    permissions: API_TYPE_PERMISSIONS;
}

export const API_TYPE_TO_CONFIG = new Map<DEVICE_LINE_AUTH_API_TYPE, API_TYPE_CONFIG>([
    [
        DEVICE_LINE_AUTH_API_TYPE.QOD,
        {
            label: i18n.t('qodNumberManagement.qodAcronym').toString(),
            labelVerbose: i18n.t('qodNumberManagement.qod').toString(),
            dataflow: DATAFLOW_API_TYPE.QOD,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.CUSTOMER_NAME,
                CSV_COLUMN_TYPE.QOD_PROFILES,
                CSV_COLUMN_TYPE.ORG_ID_PARTNER_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'QoDNumberManagementRead',
                write: 'QoDNumberManagementWrite',
            },
        },
    ],
    [
        DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS,
        {
            label: i18n.t('qodNumberManagement.deviceStatus').toString(),
            dataflow: DATAFLOW_API_TYPE.DEVICE_STATUS,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.APPROVER_NAME,
                CSV_COLUMN_TYPE.SERVICE_IDS,
                CSV_COLUMN_TYPE.ORG_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'DeviceStatusNumberManagementRead',
                write: 'DeviceStatusNumberManagementWrite',
            },
        },
    ],
    [
        DEVICE_LINE_AUTH_API_TYPE.SIM_SWAP,
        {
            label: i18n.t('qodNumberManagement.SIMSwap').toString(),
            dataflow: DATAFLOW_API_TYPE.SIM_SWAP,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.APPROVER_NAME,
                CSV_COLUMN_TYPE.SERVICE_IDS,
                CSV_COLUMN_TYPE.ORG_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'SIMSwapNumberManagementRead',
                write: 'SIMSwapNumberManagementWrite',
            },
        },
    ],
    [
        DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION,
        {
            label: i18n.t('qodNumberManagement.locationVerification').toString(),
            dataflow: DATAFLOW_API_TYPE.LOCATION_VERIFICATION,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.APPROVER_NAME,
                CSV_COLUMN_TYPE.SERVICE_IDS,
                CSV_COLUMN_TYPE.ORG_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'LocationVerificationNumberManagementRead',
                write: 'LocationVerificationNumberManagementWrite',
            },
        },
    ],
    [
        DEVICE_LINE_AUTH_API_TYPE.SD_WAN,
        {
            label: i18n.t('qodNumberManagement.SDWANacronym').toString(),
            labelVerbose: i18n.t('qodNumberManagement.SDWAN').toString(),
            dataflow: DATAFLOW_API_TYPE.SD_WAN,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.APPROVER_NAME,
                CSV_COLUMN_TYPE.SERVICE_IDS,
                CSV_COLUMN_TYPE.ORG_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'SDWANNumberManagementRead',
                write: 'SDWANNumberManagementWrite',
            },
        },
    ],
    [
        DEVICE_LINE_AUTH_API_TYPE.NETWORK_AUTHENTICATION,
        {
            label: i18n.t('qodNumberManagement.networkAuthentication').toString(),
            dataflow: DATAFLOW_API_TYPE.NETWORK_AUTHENTICATION,
            columns: [
                CSV_COLUMN_TYPE.MSISDN,
                CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
                CSV_COLUMN_TYPE.CHANNEL,
                CSV_COLUMN_TYPE.CUSTOMER_BAN,
                CSV_COLUMN_TYPE.APPROVER_NAME,
                CSV_COLUMN_TYPE.SERVICE_IDS,
                CSV_COLUMN_TYPE.ORG_ID,
                CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
                CSV_COLUMN_TYPE.ACTION,
            ],
            permissions: {
                read: 'NetworkAuthenticationNumberManagementRead',
                write: 'NetworkAuthenticationNumberManagementWrite',
            },
        },
    ],
]);

/**
 * List of all known line auth permissions
 * Derived from API_TYPE_TO_CONFIG
 */
export const ALL_LINE_AUTH_PERMISSIONS = Array.from(API_TYPE_TO_CONFIG.values()).flatMap(config => [
    config.permissions.read,
    config.permissions.write,
]);

export interface COLUMN_DATA extends CSV_COLUMN_DATA {
    /**
     * Key indicates which property key on an object retrieves the associated data
     */
    key: string;
    /**
     * Options indicates what the expected options for the column are.
     */
    options?: string[];
}

export const COLUMN_TYPE_TO_COLUMN_DATA = new Map<CSV_COLUMN_TYPE, COLUMN_DATA>([
    [
        CSV_COLUMN_TYPE.MSISDN,
        {
            label: i18n.t('qodNumberManagement.msisdn').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.msisdn').toString(),
            key: 'msisdn',
        },
    ],
    [
        CSV_COLUMN_TYPE.APPLICATION_CLIENT_ID,
        {
            label: i18n.t('qodNumberManagement.appClientId').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.appClientId').toString(),
            key: 'appClientId',
        },
    ],
    [
        CSV_COLUMN_TYPE.CHANNEL,
        {
            label: i18n.t('qodNumberManagement.channel').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.channel').toString(),
            key: 'channel',
        },
    ],
    [
        CSV_COLUMN_TYPE.CUSTOMER_BAN,
        {
            label: i18n.t('qodNumberManagement.customerBan').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.customerBan').toString(),
            key: 'customerBan',
        },
    ],
    [
        CSV_COLUMN_TYPE.CUSTOMER_NAME,
        {
            label: i18n.t('qodNumberManagement.customerName').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.customerName').toString(),
            key: 'customerName',
        },
    ],
    [
        CSV_COLUMN_TYPE.APPROVER_NAME,
        {
            label: i18n.t('qodNumberManagement.approverName').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.approverName').toString(),
            key: 'approverName',
        },
    ],
    [
        CSV_COLUMN_TYPE.QOD_PROFILES,
        {
            label: i18n.t('qodNumberManagement.qodProfiles').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.qodProfiles').toString(),
            key: 'qodProfiles',
        },
    ],
    [
        CSV_COLUMN_TYPE.SERVICE_IDS,
        {
            label: i18n.t('qodNumberManagement.serviceIds').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.serviceIds').toString(),
            key: 'serviceIds',
        },
    ],
    [
        CSV_COLUMN_TYPE.ORG_ID_PARTNER_ID,
        {
            label: i18n.t('qodNumberManagement.orgIdPartnerId').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.orgIdPartnerId').toString(),
            key: 'payingCustomerId',
        },
    ],
    [
        CSV_COLUMN_TYPE.ORG_ID,
        {
            label: i18n.t('qodNumberManagement.orgId').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.orgId').toString(),
            key: 'payingCustomerId',
        },
    ],
    [
        CSV_COLUMN_TYPE.COMMERCIAL_OFFER_NAME,
        {
            label: i18n.t('qodNumberManagement.commercialOfferName').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.commercialOfferName').toString(),
            key: 'commercialOfferName',
        },
    ],
    [
        CSV_COLUMN_TYPE.ACTION,
        {
            label: i18n.t('generic.action').toString(),
            description: i18n.t('qodNumberManagement.csvColumnDescriptions.action').toString(),
            key: 'actionStr',
            options: [i18n.t('generic.add').toString(), i18n.t('generic.remove').toString()],
        },
    ],
]);
