<template>
    <AbstractSubSidebarPageWrapper
        :pageTitle="$i18n.t('settings.thirdPartyApplications')"
        :entitiesCount="filteredEntities.length"
    >
        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                @change="changeActiveSection"
            />
        </template>

        <template #headerButtons>
            <ResponseModalButton
                :response="applicationKeysApiResponse"
                :title="$i18n.t('settings.thirdPartyApplications')"
                class="mr-2"
            />
            <FilterTable
                :columns="columnData"
                class="mr-3"
                @filterAdded="onFilterAdded"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('generic.addNew')"
                :iconType="ICON_TYPES.PLUS"
                data-test-id="add-new-btn"
                @click="isAddNewModalVisible = true"
            />
        </template>

        <template #content>
            <AbstractListPageWrapper :isOverviewEnabled="false">
                <template #allFilters>
                    <TableFiltersTags
                        :filterTableMixin="filterTableMixin"
                        class="my-3 ml-2"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />
                </template>
                <template #table>
                    <AppTable
                        :columnsData="columnData"
                        :entities="filteredEntities"
                        :canSelectColumns="true"
                        :enableRowStateControls="true"
                        :emitEntityActions="true"
                        :isPaginationEnabled="true"
                        :isDefaultHoverEnabled="true"
                        :isDataLoading="isDataLoading"
                        :newDesign="true"
                        tableKey="applicationKeysList"
                        class="mb-3"
                        @edit="onEdit"
                    />
                </template>
                <template #modal>
                    <NewThirdPartyApplication
                        v-model="isAddNewModalVisible"
                        :entity="selectedEntity"
                        @submit="closeModalAndFetch"
                        @input="selectedEntity = null"
                    />
                </template>
            </AbstractListPageWrapper>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import NewThirdPartyApplication from '@/__new__/features/settings/thirdPartyApplications/NewThirdPartyApplication.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
// mixins
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';
import { EntityActions } from '@/common/commonEntityStateMapper';

// http
import { fetchExternalPartnerPubKeys } from '@/__new__/services/dno/authorization/http/authorization';

export default {
    name: 'ThirdPartyApplications',
    components: {
        AbstractListPageWrapper,
        AbstractSubSidebarPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        ResponseModalButton,
        SubSidebar,
        TableFiltersTags,
        NewThirdPartyApplication,
    },
    mixins: [FilterTableMixin, SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            applicationKeys: [],
            applicationKeysApiResponse: null,
            isDataLoading: false,
            isAddNewModalVisible: false,
            selectedEntity: null,
            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    computed: {
        filteredEntities() {
            return this.filteredEntitiesMixin(this.applicationKeys);
        },
        columnData() {
            return [
                {
                    name: this.$i18n.t('generic.keyId'),
                    key: 'key_id',
                    field: 'key_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('thirdPartyApplications.partnerName'),
                    key: 'partner_name',
                    field: 'partner_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('thirdPartyApplications.scope'),
                    key: 'scope',
                    field: 'scope',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('thirdPartyApplications.acl'),
                    key: 'acl',
                    field: 'acl',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: Array.from(
                        new Set(this.applicationKeys.map(({ acl }) => acl || this.$i18n.t('generic.empty')).flat()),
                    ),
                },
            ];
        },
    },
    created() {
        this.fetchApplicationKeys();
    },
    methods: {
        closeModalAndFetch() {
            this.isAddNewModalVisible = false;
            this.fetchApplicationKeys();
        },
        fetchApplicationKeys() {
            this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    this.applicationKeysApiResponse = await fetchExternalPartnerPubKeys();
                    const { keys = [] } = this.applicationKeysApiResponse?.data;
                    this.applicationKeys = Array.isArray(keys)
                        ? keys.map(e => ({
                              ...e,
                              id: e.key_id,
                              allowedActionsExternal: [EntityActions.EDIT],
                          }))
                        : [];
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        this.isDataLoading = false;
                        this.applicationKeysApiResponse = e.response;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        onEdit(entityId) {
            this.selectedEntity = this.applicationKeys.find(({ id }) => id === entityId);
            this.isAddNewModalVisible = true;
        },
    },
};
</script>
