
// Generic
import Vue from '@/common/typedVue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// helpers
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import localeLibrary from '@/common/locale/localeLibrary';
import {
    SensitiveTransactionHistory,
    SensitiveTransactionVerificationHistory,
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
    CHANNEL,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import {
    SUBSCRIBER_STATE,
    TRANSACTION_STATUS_TO_COLOR,
    TRANSACTION_STATUS_TO_LABEL,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import isEmpty from 'lodash/isEmpty';
import { getResourceTypeLabel } from '@/__new__/features/resources/common/sim';
import { AxiosError } from 'axios';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';

// eslint-disable-next-line no-shadow
enum SIM_SWAP_CHANGES_TABLE {
    CHANGES_HISTORY = 'changesHistory',
    VERIFICATION_HISTORY = 'verificationHistory',
}

export default Vue.extend({
    name: 'SimSwapTransactionHistoryTile',
    components: {
        AbstractTableTile,
        EntityStatusIndicator,
        AppButton,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            displayTable: true,
            activeTab: SIM_SWAP_CHANGES_TABLE.CHANGES_HISTORY,
            dataLoading: false,
            simSwapHistory: [] as SensitiveTransactionHistory[],
            simSwapVerificationHistory: [] as any[],
            rawResponse: {} as any,
            rawResponseVerificationHistory: {} as any,

            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData(): Subscriber {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.$route.params.id,
            });
        },
        isFraud(): boolean {
            return !!this.subscriberData?.flags?.[FLAG_TYPE.IS_FRAUD]?.value;
        },
        isSuspended(): boolean {
            return Boolean(
                this.subscriberData?.flags?.[FLAG_TYPE.SUSPEND_LINE_LOST]?.value ||
                    this.subscriberData?.flags?.[FLAG_TYPE.SUSPEND_LINE_STOLEN]?.value,
            );
        },
        isSubscriberNotActiveState(): boolean {
            return this.subscriberData?.state !== SUBSCRIBER_STATE.ACTIVE;
        },
        isSimSwapEnabled(): boolean {
            return isViewEnabled('UMSubscriberSimSwap') && isUserAllowed('UMSubscriberEditSubscriberSimSwap');
        },
        isSimSwapButtonDisabled(): boolean {
            return !!(this.isFraud || this.isSuspended || this.isSubscriberNotActiveState);
        },
        simSwapDisabledMessage(): string {
            if (this.isFraud) {
                return this.$t('customerCareSuite.simSwapTransactionHistoryTile.simSwapDisabled');
            }
            if (this.isSuspended) {
                return this.$t('customerCareSuite.simSwapTransactionHistoryTile.simSwapSuspended');
            }
            if (this.isSubscriberNotActiveState) {
                return this.$t('customerCareSuite.simSwapTransactionHistoryTile.simSwapNotAllowed');
            }
            return '';
        },
        tableKey(): string {
            return `sim-swap-changes-table-${this.activeTab}`;
        },
        tabs(): { id: SIM_SWAP_CHANGES_TABLE; title: string }[] {
            return [
                {
                    id: SIM_SWAP_CHANGES_TABLE.CHANGES_HISTORY,
                    title: this.$t('customerCareSuite.simSwapTransactionHistoryTile.changesHistory'),
                },
                {
                    id: SIM_SWAP_CHANGES_TABLE.VERIFICATION_HISTORY,
                    title: this.$t('customerCareSuite.simSwapTransactionHistoryTile.verificationHistory'),
                },
            ];
        },
        formattedData(): SensitiveTransactionHistory[] | SensitiveTransactionVerificationHistory[] {
            if (this.isChangeHistory && !isEmpty(this.simSwapHistory)) {
                return this.simSwapHistory?.map((entity: any) => ({
                    channel: entity.channel,
                    channelLabel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(entity.channel),
                    startTime: entity.start_time,
                    endTime: entity.end_time,
                    targetId: entity.target_id,
                    status: entity.status,
                    statusLabel: TRANSACTION_STATUS_TO_LABEL.get(entity.status) || '',
                    iccidOld: entity.misc?.iccid_old || '',
                    iccidNew: entity.misc?.iccid_new || '',
                    simTypeOld: entity.misc?.sim_type_old || '',
                    simTypeNew: entity.misc?.sim_type_new || '',
                }));
            }
            if (this.isVerificationHistory && !isEmpty(this.simSwapVerificationHistory)) {
                return this.simSwapVerificationHistory
                    ?.filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.SWAP_SIM)
                    .map(el => ({
                        time: el.event_time,
                        channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                            el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                        ),
                        method: el?.properties?.method || '',
                        status: el.event_type,
                        id: el?.properties?.external_id || '',
                        userId: el?.identifier || '',
                        portalUserId: el?.properties?.portal_id || '',
                    })) as SensitiveTransactionVerificationHistory[];
            }
            return [];
        },
        isChangeHistory(): boolean {
            return this.activeTab === SIM_SWAP_CHANGES_TABLE.CHANGES_HISTORY;
        },
        isVerificationHistory(): boolean {
            return this.activeTab === SIM_SWAP_CHANGES_TABLE.VERIFICATION_HISTORY;
        },
        apiResponse(): any {
            if (this.isChangeHistory) {
                return this.rawResponse;
            }
            if (this.isVerificationHistory) {
                return this.rawResponseVerificationHistory;
            }
            return {};
        },
        columnsData(): TableColumn[] {
            if (this.isChangeHistory) {
                return this.changesHistoryColumnsData;
            }
            if (this.isVerificationHistory) {
                return this.changesVerificationHistoryColumnsData;
            }
            return [];
        },
        changesHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.startTime'),
                    key: 'startTime',
                    mapper: entity => localeLibrary.getFormattedDateAndTime(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.endTime'),
                    key: 'endTime',
                    mapper: entity => localeLibrary.getFormattedDateAndTime(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.channel'),
                    key: 'channelLabel',
                    field: 'channelLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.simSwapHistory.map(e => e.statusLabel))],
                },
                {
                    name: this.$t('generic.status'),
                    key: 'status',
                    field: 'statusLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.simSwapHistory.map(e => e.statusLabel))],
                },
                {
                    name: this.$t('customerCareSuite.simSwapTransactionHistoryTile.simTypeOld'),
                    key: 'simTypeOld',
                    field: 'simTypeOld',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity => getResourceTypeLabel(entity.simTypeOld),
                },
                {
                    name: this.$t('customerCareSuite.simSwapTransactionHistoryTile.iccidOld'),
                    key: 'iccidOld',
                    field: 'iccidOld',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCareSuite.simSwapTransactionHistoryTile.simTypeNew'),
                    key: 'simTypeNew',
                    field: 'simTypeNew',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity => getResourceTypeLabel(entity.simTypeNew),
                },
                {
                    name: this.$t('customerCareSuite.simSwapTransactionHistoryTile.iccidNew'),
                    key: 'iccidNew',
                    field: 'iccidNew',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        changesVerificationHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$t('generic.channel'),
                    field: 'channel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'method',
                    name: this.$t('generic.method'),
                    field: 'method',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'status',
                    name: this.$t('generic.status'),
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'id',
                    name: this.$t('generic.id'),
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'userId',
                    name: this.$t('customerCare.userId'),
                    field: 'userId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'portalUserId',
                    name: this.$t('generic.portalUserId'),
                    field: 'portalUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        stateMap(): any {
            return TRANSACTION_STATUS_TO_LABEL;
        },
        stateMapColor(): any {
            return TRANSACTION_STATUS_TO_COLOR;
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();
                this.dataLoading = true;

                this.rawResponse = await customerCareHttp.getSimSensitiveTransactionHistory(
                    this.$route.params.id,
                    this.userManagerHierarchy,
                    SENSITIVE_TRANSACTIOS_USE_CASE.SWAP_SIM,
                );
                this.simSwapHistory = this.rawResponse?.data?.transactions;

                this.rawResponseVerificationHistory = await customerCareHttp.fetchServiceBlockerHistory(
                    this.subscriberData?.userId,
                    USER_MANAGER_HIERARCHY.USER,
                    SERVICE_TYPE.END_USER_AUTHORIZATION,
                );
                this.simSwapVerificationHistory = !isEmpty(this.rawResponseVerificationHistory?.data?.otp_attempts)
                    ? this.rawResponseVerificationHistory.data.otp_attempts
                    : [];

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                this.rawResponse = (error as AxiosError).response;
                Sentry.captureException(error);
            } finally {
                this.dataLoading = false;
                this.$emit('isDataLoadingUpd', false);
            }
        },
        simSwap() {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.subscriberData?.accountId || '',
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT as unknown as string,
                    operationType: SENSITIVE_OPERATION_TYPES.SIM_SWAP,
                    operationTargetType: USER_MANAGER_HIERARCHY.SUBSCRIBER as unknown as string,
                    operationTargetId: this.$route.params.id,
                },
            });
        },
        onTabSelected(id: SIM_SWAP_CHANGES_TABLE) {
            this.activeTab = id;
            this.forceRerender();
        },
        async forceRerender() {
            // Seems like table sets columns only on created or so
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
    },
});
