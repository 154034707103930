<template>
    <div>
        <div class="container-fluid wrapper-container background-color h-100">
            <AppHeader
                :pageTitle="$t('analytics.reports')"
                :isSearchEnabled="false"
                class="mb-2"
            >
                <template #headerButtons>
                    <AppButton
                        v-if="reportArchives.length"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$t('analytics.reportsPage.bulkDownloadManager')"
                        class="text-capitalize ml-2"
                        data-test-id="archive-reports-manager-btn"
                        @click="isArchiveDialogOpen = true"
                    />
                </template>
            </AppHeader>

            <div class="section-layout mx-5">
                <div class="col-6">
                    <div class="row mt-5">
                        <h2 class="component-title-um my-3">
                            {{ $t('analytics.reportsPage.searchReports') }}
                        </h2>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12">
                            <AppMultiselectV3
                                v-model="selectedReport"
                                :additionalLabel="$t('analytics.reportsPage.selectReport')"
                                :placeholder="$t('analytics.reportsPage.reportName')"
                                :options="allReports"
                                :showLabels="false"
                                :small="true"
                                :error="$v.selectedReport.$error"
                                label="reportName"
                                data-test-id="selected-report-multi"
                                @select="onSelectReport"
                            />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4">
                            <AppMultiselectV3
                                v-model="selectedSearchOption"
                                :options="Object.values(reportTimeframeOptions)"
                                :additionalLabel="$t('analytics.reportsPage.timeframe')"
                                :preselectFirst="true"
                                :small="true"
                                :blueArrow="true"
                                :placeholder="$t('analytics.reportsPage.chooseTimeframe')"
                                :error="$v.selectedSearchOption.key.$error"
                                trackBy="key"
                                label="label"
                                data-test-id="search-option-multi"
                                @input="({ key }) => onSelectProperty(key)"
                            />
                        </div>
                        <div class="col-8">
                            <DateTimePicker
                                :value="setDateTimePickerValue"
                                :disabledDates="disabledBeforeTodayAndAfterAWeek"
                                :disabled="disableDatePicker"
                                :range="datePickerOptions.isRangePicker"
                                :type="datePickerOptions.type"
                                :error="$v.datePickerOptions.dateValue.$error"
                                :timezoneNormalizing="false"
                                :useFullWidth="true"
                                class="date-picker-position"
                                data-test-id="date-time-picker"
                                @input="onDateSelected"
                                @range-selected="rangeDateSelected"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <AppButton
                            v-if="!isDownloadAllEnabled"
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$t('analytics.reportsPage.getReports')"
                            class="title-button ml-2 mt-5 fetch-button"
                            data-test-id="get-reports-btn"
                            @click="getReports()"
                        />
                        <AppButton
                            v-else
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$t('generic.downloadAll')"
                            :iconType="ICON_TYPES.DOWNLOAD"
                            :isLoading="isGeneratingReport"
                            class="title-button ml-2 mt-5 fetch-button"
                            data-test-id="download-reports-btn"
                            @click="[downloadAllReports(), (isArchiveDialogOpen = true)]"
                        />
                    </div>
                </div>
            </div>
            <div
                class="section-layout"
                :style="`width: ${calcWithForRebuildLayout}`"
            >
                <div
                    v-if="tableReports.length"
                    class="d-flex align-items-center"
                >
                    <h4
                        v-if="tableReports.length"
                        class="component-title-um my-5 ml-5 mr-1"
                    >
                        {{ $t('analytics.reportsPage.allReports') }} ({{ tableReports.length }})
                    </h4>
                </div>

                <AppTable
                    :entities="sortedFilteredReports"
                    :columnsData="tableColumnsData"
                    :canSelectColumns="false"
                    :isDataLoading="isDataLoading"
                    :useFullWidth="false"
                    :keyName="'reportEntryId'"
                    :useExternalPagination="true"
                    :rangeAmount="{ start: 1, end: reportsPerPage }"
                    tableKey="analytics/reports"
                    entityType="reports"
                    class="mt-5"
                    data-test-id="reports-table"
                >
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            v-if="actionLoading"
                            :icon="ICON_TYPES.LOADER"
                            class="spinning"
                        />
                        <template v-else>
                            <IconButton
                                v-if="doesReportHaveTableAction(entity, REPORTS_TABLE_ACTIONS.FETCH)"
                                :label="$t('generic.fetch')"
                                :icon="ICON_TYPES.DOWNLOAD"
                                @iconClick="fetchAction(entity)"
                            />
                            <IconButton
                                v-if="doesReportHaveTableAction(entity, REPORTS_TABLE_ACTIONS.PREVIEW)"
                                :label="$t('generic.fetchPreview')"
                                :icon="ICON_TYPES.DOWNLOAD"
                                @iconClick="fetchPreview()"
                            />
                            <IconButton
                                v-if="
                                    isUserInternal() && doesReportHaveTableAction(entity, REPORTS_TABLE_ACTIONS.REPLACE)
                                "
                                :label="$t('generic.replace')"
                                :icon="ICON_TYPES.UPLOAD"
                                @iconClick="toggleReplaceDialog(entity)"
                            />
                            <IconButton
                                v-if="doesReportHaveTableAction(entity, REPORTS_TABLE_ACTIONS.REGENERATE)"
                                :label="$t('generic.regenerate')"
                                :icon="ICON_TYPES.REGENERATE"
                                @iconClick="generateReport(entity)"
                            />
                            <IconButton
                                v-if="doesReportHaveTableAction(entity, REPORTS_TABLE_ACTIONS.PUBLISH)"
                                :label="$t('generic.publish')"
                                :icon="ICON_TYPES.PUBLISH"
                                @iconClick="publishAction(entity)"
                            />
                        </template>
                    </template>
                </AppTable>
                <AppPaginationLoadMore
                    v-if="tableReports.length"
                    v-model="pagingStateIndex"
                    :hasMore="!disabledTablePagination"
                    class="d-flex justify-content-center"
                    data-test-id="pagination-buttons"
                    @nextPage="getNextReports(true)"
                    @prevPage="getNextReports(false)"
                />
            </div>

            <AppAditionalSidebar
                :visible="isSidebarVisible"
                :isCollapsible="true"
                :rebuildLayout="true"
                @input="val => (isSidebarVisible = val)"
                @rebuildLayout="val => (rebuildLayout = val)"
                @rebuildLayoutToggle="val => (rebuildLayoutToggle = val)"
            >
                <template #header>
                    <p class="sidebar-title text--xs">
                        {{ $t('analytics.reportsPage.reportDetails') }}
                    </p>
                </template>
                <template #content>
                    <OverviewHeaderV2
                        v-if="selectedReport && selectedReportId"
                        :entityName="selectedReport.reportName"
                        :entityId="selectedReportId"
                    />
                    <AppOverviewBlock
                        :maxItems="99"
                        :maxItemRows="99"
                        :items="reportInfoSection"
                        class="block"
                    />
                </template>
            </AppAditionalSidebar>
        </div>

        <ReportsArchiveDialog
            :visible="isArchiveDialogOpen"
            :archives="reportArchives"
            @close="isArchiveDialogOpen = false"
            @download="triggerReportArchiveDownload"
        />
        <ReportsReplaceDialog
            :visible="isReplaceDialogOpen"
            :report="replaceEntity"
            @close="toggleReplaceDialog"
        />
        <ReportsErrorDetailsDialog
            v-model="isErrorDetailsDialogOpen"
            :error="reportErrorDetails"
        />
    </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppPaginationLoadMore from '@/components/partials/AppPaginationLoadMore.vue';
import ReportsArchiveDialog from '@/__new__/features/reports/ReportsArchiveDialog.vue';
import ReportsErrorDetailsDialog from '@/__new__/features/reports/ReportsErrorDetailsDialog.vue';
import ReportsReplaceDialog from '@/__new__/features/reports/ReportsReplaceDialog.vue';

// MIXINS
import ReportsArchiveDownloadMixin from '@/__new__/features/reports/ReportsArchiveDownloadMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';

// HELPERS
import * as Sentry from '@sentry/vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    SEARCH_OPTIONS,
    REPORT_ENGINE_TYPES,
    QRD_GENERATION_TYPES,
    REPORT_FREQUENCY_TYPES,
    REPORTS_TABLE_ACTIONS,
    formatReportDataForOverview,
    getReportTimeframeOptions,
    getDatePickerOptions,
    buildRequestFromReports,
} from '@/__new__/features/reports/common/reportsStateHelper';
import { orderBy, isEmpty } from 'lodash';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import tableColumnType from '@/common/filterTable';
import { required } from 'vuelidate/lib/validators';
import { isUserInternal } from '@/services/permissions/permissions.service';
import moment from 'moment';
import Button from '@/common/button/Button';

// HTTP
import {
    fetchReports,
    fetchReport,
    generateReport,
    publishReport,
    previewReport,
} from '@/__new__/services/dno/reports/http/reports';
import { fetchReportsDefinitionsOrd } from '@/__new__/services/dno/reports/http/ordReports';
import { fetchReportsDefinitionsQrd } from '@/__new__/services/dno/reports/http/qrdReports';

export default {
    name: 'Reports',
    components: {
        AppHeader,
        AppMultiselectV3,
        DateTimePicker,
        AppButton,
        AppTable,
        IconButton,
        AppAditionalSidebar,
        OverviewHeaderV2,
        AppOverviewBlock,
        AppPaginationLoadMore,
        ReportsArchiveDialog,
        ReportsErrorDetailsDialog,
        ReportsReplaceDialog,
    },
    mixins: [FilterTableMixin, ReportsArchiveDownloadMixin],
    data() {
        return {
            ENTITY_TYPES,
            SEARCH_OPTIONS,
            REPORTS_TABLE_ACTIONS,
            selectedReport: null,
            reportData: null,
            allReports: [],
            selectedSearchOption: null,
            datePickerOptions: {},
            selectedReportId: null,
            tableReports: [],
            actionLoading: false,
            ICON_TYPES,
            BUTTON_TYPES,
            rebuildLayout: false,
            rebuildLayoutToggle: false,
            reportTimeframeOptions: {},
            reportPollers: {},
            reportsPerPage: 20,
            pagingStateIndex: 0,
            pagingStates: new Map(),
            replaceEntity: undefined,
            isEmpty,
            isUserInternal,
            isArchiveDialogOpen: false,
            isDataLoading: false,
            isSaveButtonClicked: false,
            isSidebarVisible: false,
            isReplaceDialogOpen: false,
            isErrorDetailsDialogOpen: false,
            reportErrorDetails: null,
            errorDetailsBtn: new Button({
                label: this.$t('generic.details'),
                handler: () => {
                    this.isErrorDetailsDialogOpen = true;
                },
            }),
        };
    },
    validations() {
        return {
            selectedReport: {
                required,
            },
            selectedSearchOption: {
                key: {
                    required,
                },
            },
            datePickerOptions: {
                dateValue: {
                    required,
                },
            },
        };
    },
    computed: {
        ...mapGetters([Getters.GET_IS_COMPACT_SIDEBAR]),
        disableDatePicker() {
            return isEmpty(this.selectedSearchOption) || this.datePickerOptions.disabled;
        },
        reportInfoSection() {
            if (!this.reportData) {
                return [];
            }
            return formatReportDataForOverview(this.reportData);
        },
        sortedFilteredReports() {
            return this.filteredEntitiesMixin(this.sortedReports);
        },
        sortedReports() {
            if (this.tableReports.length) {
                return [...this.tableReports]
                    .map(report => ({
                        ...report,
                        name: getMultiLangFieldValueByLocale(report.reportName, 'en', report.reportName),
                        reportID: report.reportEntryId,
                    }))
                    .sort((group1, group2) => group2.startTime - group1.startTime);
            }
            return [];
        },
        tableColumnsData() {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    mapper: entity => entity.name,
                    classes: ['data-name'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.date'),
                    key: 'date',
                    mapper: entity => this.displayDatesForTable(entity),
                    classes: ['data-report-date'],
                    forbidHideColumn: true,
                    field: 'date',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
        setDateTimePickerValue() {
            return this.datePickerOptions.isRangePicker
                ? [this.datePickerOptions.dateValue, this.datePickerOptions.endDateValue]
                : this.datePickerOptions.dateValue;
        },
        buildRequestFromReports() {
            return buildRequestFromReports(this.selectedSearchOption.key, this.selectedReport, this.datePickerOptions);
        },
        calcWithForRebuildLayout() {
            const isCompactSidebar = this[Getters.GET_IS_COMPACT_SIDEBAR];
            if (this.rebuildLayout) {
                return `calc(100vw - 40rem - 272px ${isCompactSidebar ? ' + 250px' : ''})`;
            }
            if (this.rebuildLayoutToggle) {
                return `calc(100vw - 64px - 272px ${isCompactSidebar ? ' + 250px' : ''})`;
            }
            return '';
        },
        getPageToken() {
            return this.pagingStates.get(this.pagingStateIndex) || null;
        },
        loadedReportsAreLessThanRequested() {
            return this.sortedFilteredReports.length < this.reportsPerPage;
        },
        disabledTablePagination() {
            return !this.sortedFilteredReports.length || this.loadedReportsAreLessThanRequested;
        },
        isDownloadAllEnabled() {
            return this.tableReports.length > 1 && !this.isSelectedReportEngineType(REPORT_ENGINE_TYPES.QRD);
        },
        reportType() {
            return this.selectedReport?.reportEngine;
        },
    },
    mounted() {
        this.fetchAllReportsDefinitions();
    },
    methods: {
        onSelectProperty(timeframeType) {
            this.datePickerOptions = getDatePickerOptions(timeframeType, this.reportData);
            this.tableReports = [];
            this.clearPaginationState();
        },
        onDateSelected(date) {
            this.tableReports = [];
            this.datePickerOptions.dateValue = date;
        },
        rangeDateSelected(startDate, endDate) {
            this.tableReports = [];
            this.datePickerOptions.dateValue = startDate;
            this.datePickerOptions.endDateValue = endDate;
            this.clearPaginationState();
        },
        onSelectReport(report) {
            this.$v.$reset();
            this.reportData = report;
            this.selectedReportId = report.definitionId;
            this.isSidebarVisible = true;
            this.tableReports = [];
            this.clearPaginationState();
            this.selectedSearchOption = null;
            this.datePickerOptions = getDatePickerOptions(null, report);
            this.reportTimeframeOptions = getReportTimeframeOptions(report);
        },
        doesReportHaveTableAction(entity, type) {
            return entity.actions.includes(type);
        },
        getReports(pageToken = null) {
            if (this.isSaveButtonClicked) {
                return;
            }

            this.tableReports = [];
            this.isSaveButtonClicked = true;

            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$alert(this.$t('alertMessage.pleaseFixValidation'));
                this.isSaveButtonClicked = false;
            } else {
                this.fetchReports(this.buildRequestFromReports, pageToken);
            }
        },
        isSelectedReportEngineType(type) {
            return this.reportType === type;
        },
        fetchAllReportsDefinitions() {
            return this.$withProgressBar(
                async () => {
                    const reports = [];
                    const reqs = await Promise.allSettled([fetchReportsDefinitionsQrd(), fetchReportsDefinitionsOrd()]);

                    reqs.forEach(result => {
                        if (result.status === 'fulfilled') {
                            reports.push(...result.value);
                        }
                    });

                    this.allReports = orderBy(reports, ['reportName'], ['asc']).filter(
                        ({ visibleOnPortal }) => visibleOnPortal,
                    );
                },
                {
                    errorHandler: () => this.$alert('generic.somethingWentWrong'),
                },
            );
        },
        async fetchReports({ definitionId, startDate, endDate }, pageToken = null) {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.gettingReports') });

            this.$Progress.start();
            this.isDataLoading = true;

            try {
                const reports = await fetchReports(this.reportType, {
                    definitionId,
                    startDate,
                    endDate,
                    size: this.reportsPerPage,
                    pageToken,
                });

                this.$Progress.finish();
                this.$eventBus.$emit('closeAllAlerts');

                this.tableReports = this.filterPreviewOutOfRange(reports.data);
                if (reports.next_page_token) {
                    this.pagingStates.set(this.pagingStateIndex + 1, reports.next_page_token);
                }
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('closeAllAlerts');
                this.$alert(error?.response?.data?.message || this.$t('alertMessage.reports.gettingReports'));
            } finally {
                this.isDataLoading = false;
                this.isSaveButtonClicked = false;
            }
        },
        toggleReplaceDialog(entity) {
            this.isReplaceDialogOpen = !!entity;
            this.replaceEntity = entity;
        },
        displayDatesForTable({ actions, startTime, endTime }) {
            const { formatDateToUTC, getFormattedDateAndTime } = this.$localeLibrary;

            if (this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.INTRA_DAY) {
                // Preview reports are formatted in timezone of the report.
                // Applicable only when there is timepresent, otherwise it's at 00:00(midnight).
                const formatDateTime = actions.includes(REPORTS_TABLE_ACTIONS.PREVIEW)
                    ? getFormattedDateAndTime
                    : this.formatDateToUtcDateTime;

                return `${formatDateTime(startTime)} - ${formatDateTime(endTime)}`;
            }

            if (
                this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.DAILY &&
                this.reportType === REPORT_ENGINE_TYPES.ORD
            ) {
                return formatDateToUTC(startTime);
            }

            return this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.MONTHLY ||
                this.selectedSearchOption?.key !== SEARCH_OPTIONS.asof.key
                ? `${formatDateToUTC(startTime)} - ${formatDateToUTC(endTime)}`
                : formatDateToUTC(startTime);
        },
        disabledBeforeTodayAndAfterAWeek(date) {
            const { from, to } = this.datePickerOptions.restrictReportDates;
            return date < to || date > from;
        },
        clearReportInterval(definitionId) {
            clearInterval(this.reportPollers[definitionId]);
            this.$delete(this.reportPollers, definitionId);
            this.$eventBus.$emit('closeAllAlerts');
            this.isSaveButtonClicked = false;
            this.actionLoading = false;
            this.forceGeneration = false;
        },
        showErrorDetailsAlert(error) {
            const message = error?.response?.data?.message || this.$t('analytics.reportsPage.reportGenerationFail');
            this.reportErrorDetails = error?.response?.data?.details;
            this.$alert(
                message,
                // Details btn visible only for admin users.
                isUserInternal() && {
                    buttons: [this.errorDetailsBtn],
                },
            );
        },
        fetchAction(entity) {
            if (entity?.url) {
                this.reportGenerationSucceeded(entity);
                return;
            }

            this.generateReport(entity);
        },
        async publishAction(entity) {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportPublish') });

            this.$Progress.start();
            this.actionLoading = true;

            try {
                const res = await publishReport(this.reportType, this.buildRequestFromReports);

                if (res.status === 202) {
                    this.fetchReport(entity);
                } else {
                    this.showErrorDetailsAlert({ response: res });
                }
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();

                this.showErrorDetailsAlert(error);
                this.clearReportInterval(this.buildRequestFromReports.definitionId);
            }
        },
        generateReport(entity) {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportGenerated') });
            this.actionLoading = true;

            return this.$withProgressBar(
                async () => {
                    const res = await generateReport(this.reportType, this.buildRequestFromReports);

                    if (res.status === 202) {
                        this.fetchReport(entity);
                    } else {
                        this.showErrorDetailsAlert({ response: res });
                    }
                },
                {
                    errorHandler: error => {
                        this.clearReportInterval(this.buildRequestFromReports.definitionId);
                        this.showErrorDetailsAlert(error);
                    },
                },
            );
        },
        fetchReport(entity) {
            const { definitionId } = this.buildRequestFromReports;

            return this.$withProgressBar(
                async () => {
                    const res = await fetchReport(this.reportType, this.buildRequestFromReports);

                    if (
                        [QRD_GENERATION_TYPES.STARTED_GENERATION, QRD_GENERATION_TYPES.GENERATION_IN_PROGRESS].includes(
                            res?.status,
                        )
                    ) {
                        const poller = setInterval(() => this.checkAvailabilityOfReport(definitionId, entity), 2000);
                        this.$set(this.reportPollers, definitionId, poller);
                    } else {
                        this.reportGenerationSucceeded(res.report, entity);
                        this.clearReportInterval(definitionId);
                    }
                },
                {
                    errorHandler: error => {
                        this.isDataLoading = false;
                        this.clearReportInterval(definitionId);
                        this.showErrorDetailsAlert(error);
                    },
                },
            );
        },
        async checkAvailabilityOfReport(definitionId, entity) {
            try {
                const res = await fetchReport(this.reportType, this.buildRequestFromReports);

                if ([QRD_GENERATION_TYPES.REPORT_GENERATED, QRD_GENERATION_TYPES.AVAILABLE].includes(res?.status)) {
                    this.reportGenerationSucceeded(res.report, entity);
                    this.clearReportInterval(definitionId);
                }
            } catch (error) {
                this.clearReportInterval(definitionId);
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showErrorDetailsAlert(error);
            }
        },
        reportGenerationSucceeded({ url = null }, entity = null) {
            if (url) {
                window.location.href = url;

                if (entity) {
                    const index = this.tableReports.findIndex(el => el.definitionId === entity?.definitionId);
                    this.$set(this.tableReports, index, {
                        ...entity,
                        url,
                    });
                }
            }
        },
        async fetchPreview() {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportPreview') });

            this.$Progress.start();
            this.actionLoading = true;

            const { definitionId } = this.buildRequestFromReports;

            try {
                const res = await previewReport(this.reportType, this.buildRequestFromReports);

                if (
                    [QRD_GENERATION_TYPES.STARTED_GENERATION, QRD_GENERATION_TYPES.GENERATION_IN_PROGRESS].includes(
                        res.data.data.status,
                    )
                ) {
                    const poller = setInterval(() => this.checkAvailabilityOfPreview(definitionId), 2000);
                    this.$set(this.reportPollers, definitionId, poller);
                } else {
                    this.reportGenerationSucceeded(res.data.data.report);
                    this.clearReportInterval(definitionId);
                }
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.isDataLoading = false;
                this.clearReportInterval(definitionId);
                this.showErrorDetailsAlert(error);
            }
        },
        async checkAvailabilityOfPreview(definitionId) {
            try {
                const res = await previewReport(this.reportType, this.buildRequestFromReports);

                if (
                    [QRD_GENERATION_TYPES.REPORT_GENERATED, QRD_GENERATION_TYPES.AVAILABLE].includes(
                        res.data.data.status,
                    )
                ) {
                    this.reportGenerationSucceeded(res.data.data.report);
                    this.clearReportInterval(definitionId);
                }
            } catch (error) {
                this.clearReportInterval(definitionId);
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$alert(error?.response?.data?.message);
            }
        },
        formatDateToUtcDateTime(date) {
            const { getDateFormat, timeFormatMap, getTimeFormat } = this.$localeLibrary;
            return moment.tz(date, 'Etc/UTC').format(`${getDateFormat()} ${timeFormatMap.get(getTimeFormat())}`);
        },
        changeStateIndex(next) {
            this.pagingStateIndex += next ? 1 : -1;
        },
        getNextReports(next = true) {
            this.changeStateIndex(next);
            const token = this.getPageToken;
            this.getReports(token);
        },
        clearPaginationState() {
            this.pagingStateIndex = 0;
            this.pagingStates.clear();
        },
        // Remove after BE implements DATAP-2516
        filterPreviewOutOfRange(reports) {
            if (!this.datePickerOptions.endDateValue) {
                return reports;
            }

            return reports.filter(
                r =>
                    !r.actions.includes(REPORTS_TABLE_ACTIONS.PREVIEW) ||
                    new Date(r.startTime).getDate() <= this.datePickerOptions.endDateValue.getDate(),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/palette';

.pointer-events-none {
    &,
    ::v-deep .icon-button {
        pointer-events: none;
    }
}

.section-layout {
    transition: $slow-animation-time;

    .date-picker-position {
        margin-top: 1.8rem;
    }
}

.sidebar-title {
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $blue-300;
}

.block {
    margin-top: $spacing-xl;
    padding-right: $spacing-l;
}

.spinning {
    animation: spin 1.4s 0.2s infinite linear;
}

::v-deep .hover-row-buttons .button-wrapper {
    padding-right: $spacing-m;
}
</style>
