import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http/index';
import { type REPORT_ARCHIVE_STATUS } from '@/__new__/features/reports/common/reportArchiveHelper';
import { type ReportRequestParams } from '@/__new__/services/dno/reports/common/reportsHelper';

interface ArchiveReportResponse extends DnoResponseBase {
    data: {
        status: REPORT_ARCHIVE_STATUS;
        execution_id?: string;
    };
}

const startArchiveCreation = (data: ReportRequestParams): AxiosPromise<ArchiveReportResponse> => {
    return http({
        method: 'POST',
        url: '/v3reports/archive',
        data,
    });
};

interface GetArchiveReportStatusResponse extends DnoResponseBase {
    data: {
        status: REPORT_ARCHIVE_STATUS;
        task_id: string;
        url: string;
    };
}

const getArchiveReportStatus = (taskId: string): AxiosPromise<GetArchiveReportStatusResponse | string> => {
    return http({
        url: `/v3reports/archivestatus`,
        params: {
            taskId,
        },
    });
};

export default {
    startArchiveCreation,
    getArchiveReportStatus,
};
