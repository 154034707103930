export enum SIM_PROFILE {
    ESIM = 'esim_profile',
    PSIM = 'psim_profile',
}

type TagCounts = Record<string, number>;

type SimProfileCounts = {
    total: number;
    available: number;
    assigned: number;
    prereserved: number;
    reserved: number;
    tag_counts: TagCounts;
};

export type CountersType = {
    [SIM_PROFILE.ESIM]: SimProfileCounts;
    [SIM_PROFILE.PSIM]: SimProfileCounts;
};
