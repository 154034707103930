import { get, keyBy, mapValues } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';
import { dateToEpoch } from '@/common/formatting';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import {
    ValidationRuleType,
    ValidationRuleTargetType,
    TargetTypeMapReverse,
    ValidationRuleState,
    PrerequisiteEval,
    TargetTypeMap,
    VROperatorExpression,
    VROperatorExpressionMap,
} from '@/__new__/features/pc/validationRules/common/validationRule';

export function getLabel(key: string) {
    return (i18n.t(`productCatalog.validationRules.${key}`) as string) ?? 'Unknown';
}

export function validationTargetsToString(entities?: string[], map?: { id: string; name: string }[]) {
    const offers = keyBy(map, ({ id }) => id);
    return (entities || []).map(id => offers[id]?.name ?? id).join(', ');
}

export interface RawValidationRule {
    id?: string;
    version?: number;
    state?: number;
    update_time?: number;
    data: {
        name: { en: string } | string;
        description?: { en: string } | string;
        start_time?: number;
        end_time?: number;
        type: ValidationRuleType;
        entity_type: ENTITY_TYPES;
        entity_ids: string[];
        conflicting_entity_ids?: string[];
        requires_entity_ids?: string[];
        target_type?: keyof typeof TargetTypeMapReverse;
        max_subscription_limit?: {
            maximum: number;
        };
        eval_operation?: PrerequisiteEval;
        max_purchases?: number;
        max_purchases_overall_per_duration?: MaxPurchaseOverallPerDuration;
        max_purchase_per_target?: MaxPurchasePerTarget;
        operator_expression?: VROperatorExpression;
        ratio_expression?: string;
        per_entity_limit?: PerEntityLimitConfig;
    };
}

type MaxPurchaseOverallPerDuration = {
    max_purchase: number;
    duration_unit: number;
    number_of_duration_units: any;
};

type MaxPurchasePerTarget = {
    [k: number]: {
        max_purchases_per_duration: MaxPurchaseOverallPerDuration;
    };
};

export type PerEntityLimitConfig = Record<string, Partial<Record<'min' | 'max', number>>>;

export type ServerValidationRule = RawValidationRule | Omit<RawValidationRule, 'id'>;

export class ValidationRule {
    id: string | null = null;
    version?: number;
    state?: ValidationRuleState;
    update_time?: number;
    name = '';
    description = '';
    start_time: Date;
    end_time?: Date;
    type: ValidationRuleType;
    entity_type: ENTITY_TYPES;
    target_type: keyof typeof TargetTypeMapReverse;
    entity_ids: string[];
    conflicting_entity_ids: string[] = [];
    requires_entity_ids: string[] = [];
    max_subscription_limit: {
        maximum: number;
    };
    eval_operation: PrerequisiteEval;
    operator_expression: VROperatorExpression;
    max_purchases: number;
    max_purchases_overall_per_duration: MaxPurchaseOverallPerDuration;
    max_purchase_per_target: MaxPurchasePerTarget;
    ratio_expression: [number, number];
    per_entity_limit: PerEntityLimitConfig = {};

    constructor(json: ServerValidationRule) {
        this.id = get(json, 'id', null);
        this.version = json.version;
        this.state = json.state;
        this.update_time = json.update_time;
        this.name = get(json, 'data.name.en', '');
        this.description = get(json, 'data.description.en', '');
        this.start_time = moment.utc(json.data.start_time, 'X').toDate();
        this.end_time = json.data.end_time ? moment.utc(json.data.end_time, 'X').toDate() : undefined;
        this.type = json.data.type;
        this.entity_type = json.data.entity_type;
        this.target_type = json.data.target_type ?? TargetTypeMap[ValidationRuleTargetType.GLOBAL];
        this.entity_ids = json.data.entity_ids;
        this.conflicting_entity_ids = json.data.conflicting_entity_ids || [];
        this.requires_entity_ids = json.data.requires_entity_ids || [];
        this.max_subscription_limit = json.data.max_subscription_limit || { maximum: 0 };
        this.eval_operation = json.data.eval_operation || PrerequisiteEval.ANY;
        this.max_purchases = json.data.max_purchases || 0;
        this.max_purchases_overall_per_duration = json.data.max_purchases_overall_per_duration || {
            max_purchase: 0,
            duration_unit: 1,
            number_of_duration_units: 1,
        };
        this.max_purchase_per_target = json.data.max_purchase_per_target || {};
        this.operator_expression = json.data.operator_expression || VROperatorExpression.EQ;
        this.ratio_expression = [
            parseInt(json.data.ratio_expression?.split(':')[0] || '1', 10),
            parseInt(json.data.ratio_expression?.split(':')[1] || '1', 10),
        ];
        this.per_entity_limit = json.data.per_entity_limit || {};
    }

    static fromJson(json: RawValidationRule): ValidationRule {
        return new ValidationRule(json);
    }

    static fromDefault(): ValidationRule {
        return new ValidationRule({
            version: 0,
            state: ValidationRuleState.APPROVED,
            update_time: 0,
            data: {
                name: '',
                description: '',
                start_time: moment().unix(),
                end_time: moment().add(1, 'year').unix(),
                type: ValidationRuleType.SUBSCRIPTION_LIMIT,
                entity_type: ENTITY_TYPES.OFFER,
                target_type: TargetTypeMap[ValidationRuleTargetType.SUBSCRIBER],
                entity_ids: [],
                conflicting_entity_ids: [],
                requires_entity_ids: [],
                max_subscription_limit: { maximum: 0 },
                eval_operation: PrerequisiteEval.ANY,
                operator_expression: VROperatorExpression.EQ,
                ratio_expression: '1:1',
            },
        });
    }

    toString(entitiesMap: ProductCatalogOfferModel[]): string {
        const targetType = this.target_type ? getLabel(TargetTypeMapReverse[this.target_type]).toLocaleLowerCase() : '';
        const entities = validationTargetsToString(this.entity_ids, entitiesMap) || '(None)';

        switch (this.type) {
            case ValidationRuleType.SUBSCRIPTION_LIMIT:
                const limit = this.max_subscription_limit?.maximum ?? 0;
                return `The ${targetType} can have up to ${limit} of these offer(s): [ ${entities} ]`;
            case ValidationRuleType.PREREQUISITE:
                const evaluationMethod =
                    this.eval_operation === PrerequisiteEval.ANY
                        ? (i18n.t('productCatalog.validationRules.evalMustHaveOne') as string)
                        : (i18n.t('productCatalog.validationRules.evalMustHaveAll') as string);
                const requiredEntities = validationTargetsToString(this.requires_entity_ids, entitiesMap);
                return `The ${targetType} ${evaluationMethod.toLocaleLowerCase()} of the following offer(s): [ ${requiredEntities} ] in order to have any of the following offer(s): [ ${entities} ]`;
            case ValidationRuleType.CONFLICT:
                const conflictingEntities = validationTargetsToString(this.conflicting_entity_ids, entitiesMap);
                return `The ${targetType} may not have any of the following offer(s): [ ${entities} ] if they have any of the following offer(s): [ ${conflictingEntities} ]`;
            case ValidationRuleType.DYNAMIC_SUBSCRIPTION_LIMIT:
                const expression = VROperatorExpressionMap[this.operator_expression];
                const validatedEntities = validationTargetsToString(this.requires_entity_ids, entitiesMap);
                return `[ ${validatedEntities} ] : [ ${entities} ] ${expression} ${this.ratio_expression.join(':')}`;
            default:
                return 'N/A';
        }
    }

    toJson(): ServerValidationRule {
        let validData: any = {
            name: this.name,
            description: this.description,
            type: this.type,
            entity_type: this.entity_type,
            entity_ids: this.entity_ids,
            start_time: dateToEpoch(this.start_time),
            end_time: this.end_time ? dateToEpoch(this.end_time) : undefined,
            target_type:
                this.target_type === TargetTypeMap[ValidationRuleTargetType.GLOBAL] ? undefined : this.target_type,
        };

        switch (this.type) {
            case ValidationRuleType.SUBSCRIPTION_LIMIT:
                validData = {
                    ...validData,
                    max_subscription_limit: this.max_subscription_limit,
                };
                break;
            case ValidationRuleType.PREREQUISITE:
                validData = {
                    ...validData,
                    requires_entity_ids: this.requires_entity_ids,
                    eval_operation: this.eval_operation,
                };
                break;
            case ValidationRuleType.CONFLICT:
                validData = {
                    ...validData,
                    conflicting_entity_ids: this.conflicting_entity_ids,
                };
                break;
            case ValidationRuleType.PURCHASE_LIMIT:
                validData = {
                    ...validData,
                    max_purchases: this.max_purchases,
                    max_purchases_overall_per_duration: this.max_purchases_overall_per_duration,
                    max_purchase_per_target: this.max_purchase_per_target,
                };
                break;
            case ValidationRuleType.DYNAMIC_SUBSCRIPTION_LIMIT:
                validData = {
                    ...validData,
                    requires_entity_ids: this.requires_entity_ids,
                    operator_expression: this.operator_expression,
                    ratio_expression: `${this.ratio_expression[0]}:${this.ratio_expression[1]}`,
                    per_entity_limit: mapValues(this.per_entity_limit, ({ min, max }) => ({
                        min: min !== 0 ? min : undefined,
                        max: max !== 0 ? max : undefined,
                    })),
                };
                break;
            default:
                break;
        }

        return {
            id: this.id ?? undefined,
            state: this.state ?? undefined,
            version: this.version,
            update_time: this.update_time,
            data: validData as ServerValidationRule['data'],
        };
    }
}
