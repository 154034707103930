
import Vue, { type PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// Helpers
import type { ReportErrorDetailsResponse } from '@/__new__/services/dno/reports/common/reportsHelper';

export default Vue.extend({
    name: 'ReportsErrorDetailsDialog',
    components: {
        AppDialogV2,
        AppJSON,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Object as PropType<ReportErrorDetailsResponse>,
            default: undefined,
        },
    },
    computed: {
        title(): string {
            return this.error?.report_id || this.$t('generic.errorDetails');
        },
        description(): string {
            return [this.error?.start_date, this.error?.end_date].join(' - ');
        },
        message(): string {
            return this.error?.cause_error_message || this.$t('generic.N/A');
        },
        details(): Record<string, any> | null {
            return this.error?.cause_error_details ? JSON.parse(this.error.cause_error_details) : null;
        },
    },
});
