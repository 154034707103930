
import Vue, { PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// http
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// Helpers
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';

export default Vue.extend({
    name: 'AbstractActionWithAgentNote',
    components: {
        AppDialogV2,
        AppTextareaV3,
    },
    mixins: [validationMixin],
    props: {
        title: {
            required: true,
            type: String,
        },
        visible: {
            required: true,
            type: Boolean,
        },
        subscriberId: {
            default: '',
            type: String,
        },
        noteTags: {
            default: () => [],
            type: Array as PropType<string[]>,
        },
    },
    data() {
        return {
            isLoading: false,
            notesTxt: '',
            BUTTON_TYPES,
        };
    },
    computed: {
        notesInvalid(): boolean {
            return this.$v?.notesTxt?.$error;
        },
    },
    validations() {
        return {
            notesTxt: {
                required,
            },
        };
    },
    methods: {
        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            this.$emit('submit', async () => {
                await this.setAgentNote();
                this.isLoading = false;
                this.$emit('close');
            });
        },
        setAgentNote(): Promise<void> {
            return this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.subscriberId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: this.noteTags,
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongSavingAgentNotes'));
                        this.isLoading = false;
                    },
                },
            );
        },
    },
});
