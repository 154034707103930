
import Vue from 'vue';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
import SinkConfigDialog from '@/__new__/features/sinkConfigs/SinkConfigDialog.vue';
// http
import { getJobStaticExportById } from '@/__new__/services/dno/sinkConfigs/http/reportConfig';
// helpers
import { capitalize } from 'lodash';
import RouteNames from '@/router/routeNames';
import { mapORDConfigActions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import { optionsEnum } from '@/common/filterTable';
import { ORD_CONFIG_STATUS } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
// models
import ORDSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ORDSinkConfig';

interface ORDSinkConfigRow extends ORDSinkConfig {
    allowedActionsExternal: ReturnType<typeof mapORDConfigActions>;
}

export default Vue.extend({
    name: 'ORDConfigDashboard',
    components: { SinkConfigDashboard, SinkConfigDialog },
    mixins: [SinkConfigEditorMixin],

    data() {
        return {
            Actions,
            RouteNames,
            ENTITY_TYPES,
            isDataLoading: false,
            tableFilters: [
                {
                    column: 'state',
                    condition: {
                        ...optionsEnum.isNot,
                        i18nLabel: optionsEnum.isNot.i18nLabelKey,
                    },
                    values: { value: capitalize(ORD_CONFIG_STATUS.DELETED) },
                },
            ],
            isDetailsModalOpen: false as boolean,
            jsonData: null as null,
            yamlData: '' as string,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            reportsConfigs: Getters.GET_ORD_CONFIGS,
            reportsConfigsApiResponse: Getters.GET_ORD_CONFIGS_API_RESPONSE,
            reportsUIParams: Getters.GET_ORD_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        reportsConfigsFormatted(): ORDSinkConfigRow[] {
            return this.reportsConfigs.map((rc: ORDSinkConfig) => ({
                ...rc,
                allowedActionsExternal: mapORDConfigActions(rc),
            }));
        },
    },

    created() {
        this.$withProgressBar(
            async () => {
                this.isDataLoading = true;
                const promises = [
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchTriggers(),
                    this[Actions.LOAD_ORD_CONFIGS_SETTINGS](),
                ];

                if (isUserAllowed('SharedFileLocationsRead')) {
                    promises.push(this[Actions.FETCH_SHARED_FILE_LOCATIONS]());
                }

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                await this.fetchConfigs();
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('events.alerts.failedToLoadConfigs'));
                    this.isDataLoading = false;
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_ORD_CONFIGS,
            Actions.LOAD_ORD_CONFIGS_SETTINGS,
            Actions.DELETE_ORD_CONFIG,
        ]),
        ...mapActions(Modules.sharedFileLocations, [Actions.FETCH_SHARED_FILE_LOCATIONS]),
        fetchConfigs(): Promise<void> {
            return this[Actions.LOAD_ORD_CONFIGS]();
        },
        confirmORDConfigDeletion(config: ORDSinkConfigRow): void {
            this.confirmConfigDeletion(
                config,
                Actions.DELETE_ORD_CONFIG,
                this.$t('sinkConfigs.alerts.reportDeleteActionNotReversible'),
            );
        },
        onDetails(config: any): void {
            this.getJobAsYaml(config?.job_definition?.id);
            this.jsonData = config;
            this.isDetailsModalOpen = true;
        },
        getJobAsYaml(id: string): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    if (this.yamlData) {
                        return;
                    }
                    const { data: yamlData } = await getJobStaticExportById(id);
                    this.yamlData = yamlData?.data?.toString();
                },
                {
                    errorHandler: () => {
                        this.yamlData = this.$t('generic.error');
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
        onClose(): void {
            this.yamlData = '';
            this.jsonData = null;
        },
    },
});
