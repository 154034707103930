
import Vue, { type PropType } from 'vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'AppCountCard',
    components: {
        AppIcon,
        IconButton,
    },
    props: {
        value: {
            type: Number,
            required: true,
            default: 0,
        },
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: Object as PropType<{ type: string; color?: string }>,
            default: undefined,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
        };
    },
    computed: {
        countText(): string {
            const digits = this.value.toString().length;
            const lookup = [
                { value: 1, symbol: '' },
                { value: 1e3, symbol: 'k' },
                { value: 1e6, symbol: 'M' },
                { value: 1e9, symbol: 'B' },
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            const item = lookup
                .slice()
                .reverse()
                .find(({ value }) => this.value >= value);
            return item ? (this.value / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
        },
    },
});
