import RouteNames from './routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import permissionsService, {
    getOperatorConfigValue,
    isUserAllowed,
    isUserInternal,
    isViewConfig,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import RouteWrapper from '@/components/layout/RouteWrapper.vue';

// Pricing and Fees

import FeeRulesListPage from '@/__new__/features/pricingAndFees/feeRules/FeeRulesListPage.vue';
import FeeRulesEditor from '@/__new__/features/pricingAndFees/feeRules/FeeRulesEditor.vue';
import PricingRulesListPage from '@/__new__/features/pricingAndFees/pricingRules/PricingRulesListPage.vue';
import PricingRulesEditor from '@/__new__/features/pricingAndFees/pricingRules/PricingRulesEditor.vue';

// Billing
import DunningProfilesListPage from '@/__new__/features/billing/dunningProfiles/DunningProfilesListPage.vue';
import DunningProfilesEditor from '@/__new__/features/billing/dunningProfiles/DunningProfilesEditor.vue';
import CycleSpecificationListPage from '@/__new__/features/billing/cycleSpecification/CycleSpecificationListPage.vue';
import CycleSpecificationEditor from '@/__new__/features/billing/cycleSpecification/CycleSpecificationEditor.vue';

// Charging

import ChargingSpecificationEditor from '@/__new__/features/charging/chargingSpecification/ChargingSpecificationEditor.vue';
import ChargingSpecifications from '@/__new__/features/charging/chargingSpecification/ChargingSpecifications.vue';
import ChargingSpecificationsGroupEditor from '@/__new__/features/charging/chargingSpecificationsGroups/ChargingSpecificationsGroupEditor.vue';
import ChargingSpecificationsGroups from '@/__new__/features/charging/chargingSpecificationsGroups/ChargingSpecificationsGroups.vue';
import ConditionParameters from '@/__new__/features/charging/conditionParameters/ConditionParameters.vue';
import ConditionParametersEditor from '@/__new__/features/charging/conditionParameters/ConditionParametersEditor.vue';
import PolicyCounters from '@/__new__/features/charging/policyCounters/PolicyCounters.vue';
import PolicyCounterEditor from '@/__new__/features/charging/policyCounters/PolicyCounterEditor.vue';
import PolicyRules from '@/__new__/features/charging/policyRules/PolicyRules.vue';
import PolicyRuleEditor from '@/__new__/features/charging/policyRules/PolicyRuleEditor.vue';
import RatingGroupsDashboard from '@/__new__/features/charging/ratingGroups/RatingGroupsDashboard.vue';
import RatingGroupEditor from '@/__new__/features/charging/ratingGroups/RatingGroupEditor.vue';
import UsageCounters from '@/__new__/features/charging/usageCounters/UsageCounters.vue';
import UsageCountersEditor from '@/__new__/features/charging/usageCounters/UsageCountersEditor.vue';
import WalletCounters from '@/__new__/features/charging/walletCounters/WalletCounters.vue';
import WalletCountersEditor from '@/__new__/features/charging/walletCounters/WalletCountersEditor.vue';
import ContentTypes from '@/__new__/features/charging/contentType/ContentType.vue';
import ContentTypeEditor from '@/__new__/features/charging/contentType/ContentTypeEditor.vue';
import TariffSpecification from '@/__new__/features/charging/tariffSpecification/TariffSpecification.vue';
import TariffSpecificationEditor from '@/__new__/features/charging/tariffSpecification/TariffSpecificationEdit.vue';
import TariffSpecificationGroups from '@/__new__/features/charging/tariffSpecificationGroups/TariffSpecificationGroups.vue';
import TariffSpecificationGroupEditor from '@/__new__/features/charging/tariffSpecificationGroups/TariffSpecificationGroupEditor.vue';
import BucketWizardUserSearch from '@/__new__/features/charging/bucketWizard/BucketWizardUserSearch.vue';
import BucketWizard from '@/__new__/features/charging/bucketWizard/BucketWizard.vue';

// Orchestration Engine

import OrchestrationEngineListPage from '@/__new__/features/orchestrationengine/plans/OrchestrationEngineListPage.vue';
import OrchestrationEngineEditorPage from '@/__new__/features/orchestrationengine/plans/OrchestrationEngineEditorPage.vue';
import OrchestrationEngineTemplatesListPage from '@/__new__/features/orchestrationengine/templates/OrchestrationEngineTemplatesListPage.vue';
import PlanEditor from '@/__new__/features/orchestrationengine/plans/PlanEditor.vue';
import OrchestrationEngineExecutionDetailsPage from '@/__new__/features/orchestrationengine/plans/OrchestrationEngineExecutionDetailsPage.vue';
import OrchestrationEngineTemplateEditor from '@/__new__/features/orchestrationengine/templates/OrchestrationEngineTemplateEditor.vue';
import OEExecutionsSearch from '@/__new__/features/orchestrationengine/mappedExecutions/OEExecutionsSearch.vue';
import OEMappedExecutionsSearchResult from '@/__new__/features/orchestrationengine/mappedExecutions/OEMappedExecutionsSearchResult.vue';
import OrchestrationEnginePausedExecutions from '@/__new__/features/orchestrationengine/pausedExecutions/OrchestrationEnginePausedExecutions.vue';
import BatchActionsListPage from '@/__new__/features/orchestrationengine/batchActions/BatchActionsListPage.vue';
import BatchActionsEditorPage from '@/__new__/features/orchestrationengine/batchActions/BatchActionsEditorPage.vue';

// Operate APIs
import ApplicationOwnersListPage from '@/__new__/features/operateAPIs/applicationOwners/ApplicationOwnersListPage.vue';
import ApplicationOwnersEditorPage from '@/__new__/features/operateAPIs/applicationOwners/ApplicationOwnersEditorPage.vue';
import ApplicationsListPage from '@/__new__/features/operateAPIs/applications/ApplicationsListPage.vue';
import ApplicationsEditorPage from '@/__new__/features/operateAPIs/applications/ApplicationsEditorPage.vue';
import ApiProductsListPage from '@/__new__/features/operateAPIs/apiProducts/ApiProductsListPage.vue';
import ApiProductOrdersListPage from '@/__new__/features/operateAPIs/apiProductOrders/ApiProductOrdersListPage.vue';
import ApiProductOrdersEditorPage from '@/__new__/features/operateAPIs/apiProductOrders/ApiProductOrdersEditorPage.vue';
import ChannelPartnersListPage from '@/__new__/features/operateAPIs/channelPartners/ChannelPartnersListPage.vue';

// Product Catalog

import TemplatesListPage from '@/__new__/features/pc/templates/TemplatesListPage.vue';
import OfferListPage from '@/__new__/features/pc/offers/OfferListPage.vue';
import AtiotOfferPriceListPage from '@/__new__/features/pc/atiot/AtiotOfferPriceListPage.vue';
import ProductListPage from '@/__new__/features/pc/products/ProductListPage.vue';
import ServiceListPage from '@/__new__/features/pc/services/ServiceListPage.vue';
import ResourceListPage from '@/__new__/features/pc/resources/ResourceListPage.vue';
import Categories from '@/__new__/features/pc/categories/Categories.vue';
import PresetTemplateListPage from '@/__new__/features/pc/templates/PresetTemplateListPage.vue';
import FacetsListPage from '@/__new__/features/pc/facets/FacetsListPage.vue';
import TemplateEditor from '@/__new__/features/pc/templates/TemplateEditor.vue';
import PresetTemplateEditor from '@/__new__/features/pc/templates/PresetTemplateEditor.vue';
import EntityEditor from '@/__new__/features/pc/EntityEditor.vue';
import BulkEntityEditor from '@/__new__/features/pc/BulkEntityEditor.vue';
import BulkEntityFeedback from '@/__new__/features/pc/BulkEntityFeedback.vue';
import CategoryEditor from '@/__new__/features/pc/categories/CategoryEditor.vue';
import FacetEditor from '@/__new__/features/pc/facets/FacetEditor.vue';
import ValidationRulesListPage from '@/__new__/features/pc/validationRules/ValidationRulesListPage.vue';
import ValidaitonRulesEditor from '@/__new__/features/pc/validationRules/ValidationRulesEditor.vue';

// Rewards

import Rewards from '@/modules/rewards/views/Rewards.vue';
import RewardEditor from '@/modules/rewards/views/RewardEditor.vue';
import Prizes from '@/modules/rewards/views/Prizes.vue';
import PrizeEditor from '@/modules/rewards/views/PrizeEditor.vue';
import PrizeEngine from '@/modules/rewards/views/PrizeEngine.vue';
import PrizeEngineEditor from '@/modules/rewards/views/PrizeEngineEditor.vue';
import VoucherSets from '@/modules/rewards/views/VoucherSets.vue';
import VoucherSetEditor from '@/modules/rewards/views/VoucherSetEditor.vue';
import Payouts from '@/modules/rewards/views/Payouts.vue';
import PayoutEditor from '@/modules/rewards/views/PayoutEditor.vue';
import Lotteries from '@/modules/rewards/views/Lotteries.vue';
import LotteryEditor from '@/modules/rewards/views/LotteryEditor.vue';

// Pricing
import PricingRulesListPageKuiperDemo from '@/__new__/features/pricing/pricingRules/PricingRulesListPageKuiperDemo.vue';
import PricingRulesEditorKuiperDemo from '@/__new__/features/pricing/pricingRules/PricingRulesEditorKuiperDemo.vue';

// Promotions

import Promotions from '@/modules/promotions/views/Promotions.vue';
import PromotionsEditor from '@/modules/promotions/views/PromotionsEditor.vue';

// Manual Order Resume
import ManualOrderResume from '@/__new__/features/manualOrderResume/ManualOrderResume.vue';

// Operations
import NumberManagement from '@/__new__/features/resources/number/NumberManagement.vue';
import SimProfileManagement from '@/__new__/features/resources/sim/SimProfileManagement.vue';
import DeveloperLineAuthorization from '@/__new__/features/resources/developerLineAuthorization/DeveloperLineAuthorization.vue';
import UpdateWps from '@/__new__/features/resources/updateWps/UpdateWps.vue';
import DevedgeApplication from '@/__new__/features/resources/DevedgeApplication.vue';

// User Management

import CustomerCare from '@/__new__/features/customerCare/CustomerCare.vue';
import SubscriberDetails from '@/__new__/features/customerCare/subscriber/SubscriberDetails.vue';
import SubscriberDetailsEditor from '@/__new__/features/customerCare/SubscriberDetailsEditor.vue';
import PhoneTable from '@/__new__/features/customerCare/phoneTable/PhoneTable.vue';
import CustomerCareAccount from '@/__new__/features/customerCare/account/Account.vue';
import Organization from '@/__new__/features/customerCare/organization/Organization.vue';
import UserProfile from '@/__new__/features/customerCare/user/UserProfile.vue';
import UserTable from '@/__new__/features/customerCare/userTable/UserTable.vue';
import UserManagement from '@/__new__/features/customerCare/userManager/UserManagement.vue';
import ESIMSearch from '@/__new__/features/customerCare/esim/eSimSearch.vue';
import ESIMDetails from '@/__new__/features/customerCare/esim/eSimDetails.vue';
import MessageHistory from '@/__new__/features/customerCare/messageHistory/MessageHistory.vue';
import NewNote from '@/__new__/features/customerCare/newNote/NewNote.vue';
import ViasatLeads from '@/__new__/features/customerCare/viasat/ViasatLeads.vue';
import ViasatPartnerManagement from '@/__new__/features/customerCare/viasat/ViasatPartnerManagement.vue';
import GorPaymentUserSearch from '@/__new__/features/customerCare/gorPayment/GorPaymentUserSearch.vue';
import GorPaymentInfo from '@/__new__/features/customerCare/gorPayment/GORPaymentInfo.vue';

// Segments

import Segment from '@/__new__/features/segments/Segment.vue';
import Segments from '@/__new__/features/segments/Segments.vue';
import NewSegment from '@/__new__/features/segments/dynamic/NewSegment.vue';
import EditSegment from '@/__new__/features/segments/dynamic/EditSegment.vue';
import StaticSegmentEditor from '@/__new__/features/segments/static/StaticSegmentEditor.vue';

// Campaigns

import Messages from '@/__new__/features/campaigns/Messages.vue';
import Campaigns from '@/__new__/features/campaigns/Campaigns.vue';
import CampaignEditor from '@/__new__/features/campaigns/CampaignEditor.vue';

// Events
import EventsDashboard from '@/__new__/features/events/EventsDashboard.vue';
import EventsEditor from '@/__new__/features/events/EventsEditor.vue';

// Sink Configs
import ORDConfigDashboard from '@/__new__/features/sinkConfigs/reports/ORDConfigDashboard.vue';
import ORDConfigEditor from '@/__new__/features/sinkConfigs/reports/ORDConfigEditor.vue';
import RedshiftConfigDashboard from '@/__new__/features/sinkConfigs/redshift/RedshiftConfigDashboard.vue';
import RedshiftConfigEditor from '@/__new__/features/sinkConfigs/redshift/RedshiftConfigEditor.vue';
import KafkaConfigDashboard from '@/__new__/features/sinkConfigs/kafka/KafkaConfigDashboard.vue';
import KafkaConfigEditor from '@/__new__/features/sinkConfigs/kafka/KafkaConfigEditor.vue';
import ApiInvokerConfigDashboard from '@/__new__/features/sinkConfigs/apii/ApiInvokerConfigDashboard.vue';
import ApiInvokerConfigEditor from '@/__new__/features/sinkConfigs/apii/ApiInvokerConfigEditor.vue';
import DLQDashboard from '@/__new__/features/sinkConfigs/dlq/DLQDashboard.vue';
import EventComposition from '@/__new__/features/sinkConfigs/eventComposition/EventCompositionDashboard.vue';
import EventCompositionEditor from '@/__new__/features/sinkConfigs/eventComposition/EventCompositionEditor.vue';

// Pipelines
import SharedFileLocations from '@/__new__/features/sinkConfigs/shared-file-locations/SharedFileLocations.vue';

// Graphs
import OperationsGraphs from '@/__new__/features/operationalGraphs/Graphs.vue';

// Reports
import Reports from '@/__new__/features/reports/Reports.vue';

// Dataflows
import Dataflows from '@/__new__/features/dataflows/Dataflows.vue';
import DataflowsEditor from '@/__new__/features/dataflows/DataflowsEditor.vue';

// Settings
import Settings from '@/__new__/features/settings/Settings.vue';
import UserSettings from '@/__new__/features/settings/userPreferences/UserSettings.vue';
import TenantLocalization from '@/__new__/features/settings/tenantPreferences/TenantLocalization.vue';
import RemoteConfig from '@/__new__/features/settings/remoteConfig/RemoteConfig.vue';
import QuietHoursEditor from '@/__new__/features/settings/quietHours/QuietHoursEditor.vue';
import QuietHours from '@/__new__/features/settings/quietHours/QuietHours.vue';
import Users from '@/__new__/features/settings/users/Users.vue';
import UserEditor from '@/__new__/features/settings/users/UserEditor.vue';
import Roles from '@/__new__/features/settings/roles/Roles.vue';
import RoleEditor from '@/__new__/features/settings/roles/RoleEditor.vue';
import Tenants from '@/__new__/features/settings/tenants/Tenants.vue';
import TenantEditor from '@/__new__/features/settings/tenants/TenantEditor.vue';
import HomepageConfig from '@/__new__/features/home/HomeConfig.vue';
import ManualProxyRequest from '@/__new__/features/settings/manualProxy/ManualProxyRequest.vue';
import ApplicationManager from '@/__new__/features/settings/applicationManager/ApplicationManager.vue';
import ThirdPartyApplications from '@/__new__/features/settings/thirdPartyApplications/ThirdPartyApplications.vue';

// Account
import AccountPage from '@/__new__/features/settings/account/Account.vue';

// login page
import Login from '@/__new__/features/login/Login.vue';

// Home page
import Home from '@/__new__/features/home/Home.vue';

import UserGuide from '@/modules/userGuide/views/UserGuide.vue';
import UserGuideHome from '@/modules/userGuide/views/UserGuideHome.vue';
import TagPage from '@/modules/userGuide/views/TagPage.vue';
import TagsPage from '@/modules/userGuide/views/TagsPage.vue';
import ArticlePage from '@/modules/userGuide/views/ArticlePage.vue';
import ArticlesPage from '@/modules/userGuide/views/ArticlesPage.vue';

// Documents
import DocumentTemplates from '@/__new__/features/documents/DocumentTemplates.vue';
import DocumentTemplateEditor from '@/__new__/features/documents/DocumentTemplateEditor.vue';
import DocumentAssets from '@/__new__/features/documents/DocumentAssets.vue';
import DocumentAssetsEditor from '@/__new__/features/documents/DocumentAssetsEditor.vue';

// Authentication

import ExternalUserManagement from '@/__new__/features/authentication/ExternalUserManagement.vue';
import AuthenticationConfigurator from '@/__new__/features/authentication/AuthenticationConfigurator.vue';
import AuthenticationMain from '@/__new__/features/authentication/AuthenticationMain.vue';
import AuthRoleMapper from '@/__new__/features/authentication/AuthRoleMapper.vue';

// Customer Care Suite

import CustomerCareSuite from '@/__new__/features/customerCareSuite/CustomerCareSuite.vue';
import CustomerCareSuiteSearch from '@/__new__/features/customerCareSuite/CustomerCareSuiteSearch.vue';
import CustomerCareUserSearchResults from '@/__new__/features/customerCareSuite/CustomerCareUserSearchResults.vue';
import CustomerCareUserPhoneSearchResults from '@/__new__/features/customerCareSuite/CustomerCareUserSearchResults.vue';
import UserPageView from '@/__new__/features/customerCareSuite/UserPageView.vue';
import AccountPageView from '@/__new__/features/customerCareSuite/AccountPageView.vue';
import SubscriberPageView from '@/__new__/features/customerCareSuite/SubscriberPageView.vue';
import OrganizationPageView from '@/__new__/features/customerCareSuite/OrganizationPageView.vue';
import BillingAccountPageView from '@/__new__/features/customerCareSuite/BillingAccountPageView.vue';
import EndUserAuthorization from '@/__new__/features/customerCareSuite/EndUserAuthorization.vue';
import DeliveryReshipment from '@/__new__/features/customerCareSuite/DeliveryReshipment.vue';

import ResendQRCode from '@/__new__/features/customerCare/resendQRCode/ResendQRCode.vue';
import SearchGuestUser from '@/__new__/features/customerCare/resendQRCode/SearchUser.vue';
import JSONPathTester from '@/__new__/features/events/JSONPathTester.vue';
import CampaignCategories from '@/__new__/features/settings/categories/CampaignCategories.vue';
import CampaignCategoriesEditor from '@/__new__/features/settings/categories/CampaignCategoriesEditor.vue';
import MessageTemplateTester from '@/__new__/features/campaigns/MessageTemplateTester.vue';
import { ALL_LINE_AUTH_PERMISSIONS } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';

const routes = [
    {
        name: RouteNames.LOGIN,
        path: 'login',
        component: Login,
    },

    {
        name: RouteNames.HOME_VIEW,
        path: '',
        component: Home,
    },

    {
        name: RouteNames.USER_GUIDE,
        path: 'user-guide',
        component: UserGuide,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
        children: [
            {
                name: RouteNames.USER_GUIDE_HOME,
                path: '',
                component: UserGuideHome,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
            {
                name: RouteNames.USER_GUIDE_TAGS,
                path: 'tags',
                component: TagsPage,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
            {
                name: RouteNames.USER_GUIDE_TAG,
                path: 'tag/:id',
                component: TagPage,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
            {
                name: RouteNames.USER_GUIDE_ARTICLES,
                path: 'articles',
                component: ArticlesPage,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
            {
                name: RouteNames.USER_GUIDE_ARTICLE,
                path: 'article/:id',
                component: ArticlePage,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
        ],
    },

    // PRICING AND FEES

    {
        path: 'pricing-and-fees/fee-rules',
        name: RouteNames.FEE_RULES,
        component: FeeRulesListPage,
        meta: {
            permissionFunc: () => permissionsService.feeRulesEnabled() && isUserAllowed('FeeRulesRead'),
        },
    },
    {
        path: 'pricing-and-fees/fee-rules-editor/:id?',
        name: RouteNames.FEE_RULES_EDITOR,
        component: FeeRulesEditor,
        meta: {
            permissionFunc: () => permissionsService.feeRulesEditorEnabled() && isUserAllowed('FeeRulesWrite'),
        },
    },
    {
        path: 'pricing-and-fees/pricing-rules',
        name: RouteNames.PRICING_RULES,
        component: PricingRulesListPage,
        meta: {
            permissionFunc: () =>
                isViewConfig() &&
                isViewEnabled('PricingRules') &&
                (isUserAllowed('PricingRulesRead') || isUserAllowed('PricingRulesWrite')),
        },
    },
    {
        path: 'pricing-and-fees/pricing-rules-editor/:id?/:mode',
        name: RouteNames.PRICING_RULES_EDITOR,
        component: PricingRulesEditor,
        meta: {
            permissionFunc: () =>
                isViewConfig() &&
                isViewEnabled('PricingRules') &&
                (isUserAllowed('PricingRulesRead') || isUserAllowed('PricingRulesWrite')),
        },
    },

    // BILLING

    {
        path: 'billing/dunning-profiles',
        name: RouteNames.DUNNING_PROFILES,
        component: DunningProfilesListPage,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('postpaid-demo'),
        },
    },
    {
        path: 'billing/dunning-profiles-editor/:id?/:mode',
        name: RouteNames.DUNNING_PROFILES_EDITOR,
        component: DunningProfilesEditor,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('postpaid-demo'),
        },
    },
    {
        path: 'billing/cycle',
        name: RouteNames.CYCLE_SPECIFICATION,
        component: CycleSpecificationListPage,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('postpaid-demo'),
        },
    },
    {
        path: 'billing/cycle-editor/:id?/:mode',
        name: RouteNames.CYCLE_SPECIFICATION_EDITOR,
        component: CycleSpecificationEditor,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('postpaid-demo'),
        },
    },

    {
        name: RouteNames.CCS_BILLING_ACCOUNT_PAGE,
        path: 'billing/billing-account/:id?',
        component: BillingAccountPageView,
        props: true,
        meta: {
            permissionFunc: () => true,
        },
    },

    // CHARGING

    {
        path: 'charging/templates',
        name: RouteNames.CHARGING_WRAPPER,
        component: RouteWrapper,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
        path: 'charging/charging-specifications',
        component: ChargingSpecifications,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingChargingSpecificationsEnabled() &&
                (isUserAllowed('ChargingSpecificationsReadOnly') || isUserAllowed('ChargingSpecificationsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_CHARGING_SPECIFICATION_EDITOR,
        path: 'charging/charging-specification/:id?',
        component: ChargingSpecificationEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingChargingSpecificationsEnabled() &&
                (isUserAllowed('ChargingSpecificationsReadOnly') || isUserAllowed('ChargingSpecificationsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS_GROUPS,
        path: 'charging/charging-specifications-groups',
        component: ChargingSpecificationsGroups,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingChargingSpecificationsGroupsEnabled() &&
                (isUserAllowed('ChargingSpecificationGroupsReadOnly') ||
                    isUserAllowed('ChargingSpecificationGroupsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_SPECIFICATIONS_GROUP_EDITOR,
        path: 'charging/charging-specifications-group/:id?',
        component: ChargingSpecificationsGroupEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingChargingSpecificationsGroupsEnabled() &&
                (isUserAllowed('ChargingSpecificationGroupsReadOnly') ||
                    isUserAllowed('ChargingSpecificationGroupsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_POLICY_COUNTERS,
        path: 'charging/policy-counters',
        component: PolicyCounters,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingPolicyCountersEnabled() &&
                (isUserAllowed('PolicyCountersReadOnly') || isUserAllowed('PolicyCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_POLICY_COUNTER_EDITOR,
        path: 'charging/policy-counter/:id?',
        component: PolicyCounterEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingPolicyCountersEnabled() &&
                (isUserAllowed('PolicyCountersReadOnly') || isUserAllowed('PolicyCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_POLICY_RULES,
        path: 'charging/policy-rules',
        component: PolicyRules,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingPolicyRulesEnabled() &&
                (isUserAllowed('PolicyRulesReadOnly') || isUserAllowed('PolicyRulesReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_POLICY_RULE_EDITOR,
        path: 'charging/policy-rule/:id?',
        component: PolicyRuleEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingPolicyRulesEnabled() &&
                (isUserAllowed('PolicyRulesReadOnly') || isUserAllowed('PolicyRulesReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_RATING_GROUPS,
        path: 'charging/rating-groups',
        component: RatingGroupsDashboard,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingRatingGroupsEnabled() &&
                (isUserAllowed('RatingGroupsReadOnly') || isUserAllowed('RatingGroupsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_RATING_GROUP_EDITOR,
        path: 'charging/rating-group/:id?',
        component: RatingGroupEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingRatingGroupsEnabled() &&
                (isUserAllowed('RatingGroupsReadOnly') || isUserAllowed('RatingGroupsReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_USAGE_COUNTERS,
        path: 'charging/usage-counters',
        component: UsageCounters,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingUsageCountersEnabled() &&
                (isUserAllowed('UsageCountersReadOnly') || isUserAllowed('UsageCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_USAGE_COUNTERS_EDITOR,
        path: 'charging/usage-counter/:id?',
        component: UsageCountersEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingUsageCountersEnabled() &&
                (isUserAllowed('UsageCountersReadOnly') || isUserAllowed('UsageCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_CONDITION_PARAMETERS,
        path: 'charging/condition-parameters',
        component: ConditionParameters,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingConditionParametersEnabled() &&
                (isUserAllowed('ConditionParametersReadOnly') || isUserAllowed('ConditionParametersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR,
        path: 'charging/condition-parameter/:id?',
        component: ConditionParametersEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingPolicyCountersEnabled() &&
                (isUserAllowed('PolicyCountersReadOnly') || isUserAllowed('PolicyCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_WALLET_COUNTERS,
        path: 'charging/wallet-counters',
        component: WalletCounters,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingWalletCountersEnabled() &&
                (isUserAllowed('WalletCountersReadOnly') || isUserAllowed('WalletCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_WALLET_COUNTER_EDITOR,
        path: 'charging/wallet-counter/:id?',
        component: WalletCountersEditor,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingWalletCountersEnabled() &&
                (isUserAllowed('WalletCountersReadOnly') || isUserAllowed('WalletCountersReadWrite')),
        },
    },
    {
        name: RouteNames.CHARGING_CONTENT_TYPES,
        path: 'charging/content-types',
        component: ContentTypes,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingContentTypesEnabled() &&
                isUserAllowed('ContentTypesReadOnly', 'ContentTypesWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_CONTENT_TYPE_EDITOR,
        path: 'charging/content-type/:id?',
        component: ContentTypeEditor,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingContentTypesEnabled() && isUserAllowed('ContentTypesWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_TARIFF_SPECIFICATIONS,
        path: 'charging/tariff-specifications/:type?',
        component: TariffSpecification,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingTarifSpecificationsEnabled() &&
                isUserAllowed('TariffSpecificationsRead', 'TariffSpecificationsWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_TARIFF_SPECIFICATION_EDITOR,
        path: 'charging/tariff-specification/:type?/:id?',
        component: TariffSpecificationEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                (permissionsService.chargingTarifSpecificationsEnabled() &&
                    isUserAllowed('TariffSpecificationsWrite')) ||
                (permissionsService.chargingTarifSpecificationsZeroRateEnabled() &&
                    isUserAllowed('TariffSpecificationsZeroRateWrite')),
        },
    },
    {
        name: RouteNames.ZERO_RATE_CHARGING_TARIFF_SPECIFICATIONS,
        path: 'charging/tariff-specifications/:type?',
        component: TariffSpecification,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingTarifSpecificationsZeroRateEnabled() &&
                isUserAllowed('TariffSpecificationsZeroRateRead', 'TariffSpecificationsZeroRateWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUPS,
        path: 'charging/tariff-specification-groups',
        component: TariffSpecificationGroups,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingTarifSpecificationGroupsEnabled() &&
                isUserAllowed('TariffSpecificationGroupsRead', 'TariffSpecificationGroupsWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUP_EDITOR,
        path: 'charging/tariff-specification-group/:id?',
        component: TariffSpecificationGroupEditor,
        props: true,
        meta: {
            permissionFunc: () =>
                permissionsService.chargingTarifSpecificationsEnabled() &&
                isUserAllowed('TariffSpecificationGroupsWrite'),
        },
    },
    {
        name: RouteNames.CHARGING_BUCKET_WIZARD_USER_SEARCH,
        path: 'charging/bucket-wizard-search',
        component: BucketWizardUserSearch,
        props: true,
        meta: {
            permissionFunc: () => true,
        },
    },
    {
        name: RouteNames.CHARGING_BUCKET_WIZARD,
        path: 'charging/bucket-wizard-search/:userType/:id',
        component: BucketWizard,
        props: true,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin() && isUserAllowed('ChargingBucketWizard'),
        },
    },

    // ORCHESTRATION ENGINE

    {
        name: RouteNames.ORCHESTRATION_ENGINE_LIST,
        path: 'orchestration-engine/plans',
        component: OrchestrationEngineListPage,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_EDITOR,
        path: 'orchestration-engine/editor/:id?',
        component: OrchestrationEngineEditorPage,
        props: true,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.PLAN_EDITOR,
        path: 'orchestration-engine/plan/:id?',
        component: PlanEditor,
        props: true,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
        path: 'orchestration-engine/editor/:id?/:execId?',
        component: OrchestrationEngineExecutionDetailsPage,
        props: true,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_LIST_PAGE,
        path: 'orchestration-engine/templates',
        component: OrchestrationEngineTemplatesListPage,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_EDITOR,
        path: 'orchestration-engine/template/:id?',
        component: OrchestrationEngineTemplateEditor,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTIONS_SEARCH,
        path: 'orchestration-engine/executions-search',
        component: OEExecutionsSearch,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_MAPPED_EXECUTIONS_SEARCH_RESULT,
        path: 'orchestration-engine/mapped-executions-result/',
        component: OEMappedExecutionsSearchResult,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
        props: true,
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_PAUSED_EXECUTIONS_LIST_PAGE,
        path: 'orchestration-engine/paused-executions/',
        component: OrchestrationEnginePausedExecutions,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_BATCH_ACTIONS_LIST,
        path: 'orchestration-engine/batch-actions',
        component: BatchActionsListPage,
        meta: {
            permissionFunc: () =>
                permissionsService.isUserLotusFlareAdmin() && isUserAllowed('BatchActionsRead', 'BatchActionsWrite'),
        },
    },
    {
        name: RouteNames.ORCHESTRATION_ENGINE_BATCH_ACTIONS_EDITOR,
        path: 'orchestration-engine/batch-action/editor',
        component: BatchActionsEditorPage,
        props: true,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin() && isUserAllowed('BatchActionsWrite'),
        },
    },

    // OPERATE APIS

    {
        name: RouteNames.OPERATE_APIS_APPLICATION_OWNER_LIST_PAGE,
        path: 'operate-apis/application-owners',
        component: ApplicationOwnersListPage,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_APPLICATION_OWNER_EDITOR,
        path: 'operate-apis/application-owner/:id?',
        component: ApplicationOwnersEditorPage,
        props: true,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_APPLICATIONS_LIST_PAGE,
        path: 'operate-apis/applications',
        component: ApplicationsListPage,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_APPLICATIONS_EDITOR,
        path: 'operate-apis/application/:id?',
        component: ApplicationsEditorPage,
        props: true,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_PRODUCTS_LIST_PAGE,
        path: 'operate-apis/api-products',
        component: ApiProductsListPage,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_LIST_PAGE,
        path: 'operate-apis/api-product-orders',
        component: ApiProductOrdersListPage,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_EDITOR,
        path: 'operate-apis/api-product-order/:id?',
        component: ApiProductOrdersEditorPage,
        props: true,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },
    {
        name: RouteNames.OPERATE_APIS_CHANNEL_PARTNERS_LIST_PAGE,
        path: 'operate-apis/channel-partners',
        component: ChannelPartnersListPage,
        meta: {
            permissionFunc: isUserInternal(),
        },
    },

    // MANUAL ORDER RESUME

    {
        name: RouteNames.MANUAL_ORDER_RESUME,
        path: 'manual-order-resume',
        component: ManualOrderResume,
        meta: {
            permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
        },
    },

    // PRODUCT CATALOG

    {
        path: 'pc/templates',
        name: RouteNames.TEMPLATES_LIST,
        component: TemplatesListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('TemplatesRead') || isUserAllowed('TemplatesWrite')),
        },
    },
    {
        path: 'pc/preset-templates',
        name: RouteNames.PRESET_TEMPLATES_LIST,
        component: PresetTemplateListPage,
        meta: {
            permissionFunc: () =>
                permissionsService.isUserLotusFlareAdmin() &&
                (isUserAllowed('PresetTemplatesRead') || isUserAllowed('PresetTemplatesWrite')),
        },
    },
    {
        path: 'pc/categories',
        name: RouteNames.CATEGORY_LIST,
        component: Categories,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogCategoriesEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('CategoriesRead') || isUserAllowed('CategoriesWrite')),
        },
    },
    {
        path: 'pc/offers',
        name: RouteNames.OFFERS_LIST_NEW,
        component: OfferListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogOffersEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('OffersRead') || isUserAllowed('OffersWrite')),
        },
        props: {
            entityType: ENTITY_TYPES.OFFER,
        },
    },
    {
        path: 'pc/offer-prices',
        name: RouteNames.ATIOT_OFFER_PRICE_LIST,
        component: AtiotOfferPriceListPage,
        meta: {
            permissionFunc: () => getOperatorConfigValue('isAtiotDemo'),
        },
    },
    {
        path: 'pc/products',
        name: RouteNames.PRODUCT_LIST_NEW,
        component: ProductListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogProductsEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('ProductsRead') || isUserAllowed('ProductsWrite')),
        },
        props: {
            entityType: ENTITY_TYPES.PRODUCT,
        },
    },
    {
        path: 'pc/services',
        name: RouteNames.SERVICES_LIST_NEW,
        component: ServiceListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogServicesEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('ServicesRead') || isUserAllowed('ServicesWrite')),
        },
        props: {
            entityType: ENTITY_TYPES.SERVICE,
        },
    },
    {
        path: 'pc/resources',
        name: RouteNames.RESOURCE_LIST_NEW,
        component: ResourceListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogResourcesEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('ResourcesRead') || isUserAllowed('ResourcesWrite')),
        },
        props: {
            entityType: ENTITY_TYPES.RESOURCE,
        },
    },
    {
        path: 'pc/facets',
        name: RouteNames.FACET_LIST,
        component: FacetsListPage,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogFacetsEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('FacetsRead') || isUserAllowed('FacetsWrite')),
        },
    },
    {
        path: 'pc/template/:id?/:mode?',
        name: RouteNames.TEMPLATE_EDITOR,
        component: TemplateEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('TemplatesRead') || isUserAllowed('TemplatesWrite')),
        },
    },
    {
        path: 'pc/preset-template/:id?/:mode?',
        name: RouteNames.PRESET_TEMPLATES_EDITOR,
        component: PresetTemplateEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('PresetTemplatesRead') || isUserAllowed('PresetTemplatesWrite')),
        },
    },
    {
        path: 'pc/entity/:entityType/:id?/:mode?',
        name: RouteNames.ENTITY_EDITOR,
        component: EntityEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                isViewEnabled('ProductCatalog'),
        },
    },
    {
        path: 'pc/categories/:id?/:mode?',
        name: RouteNames.CATEGORY_EDITOR,
        component: CategoryEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogCategoriesEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('CategoriesRead') || isUserAllowed('CategoriesWrite')),
        },
    },
    {
        path: 'pc/facet/:id?/:mode?',
        name: RouteNames.FACET_EDITOR,
        component: FacetEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogFacetsEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                (isUserAllowed('FacetsRead') || isUserAllowed('FacetsWrite')),
        },
    },
    {
        path: 'pc/bulkedit/:entityType',
        name: RouteNames.BULK_EDIT_ENTITIES,
        component: BulkEntityEditor,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                isViewEnabled('ProductCatalog'),
        },
    },
    {
        path: 'pc/bulkeditfeedback/:entityType',
        name: RouteNames.BULK_EDIT_FEEDBACK,
        component: BulkEntityFeedback,
        meta: {
            permissionFunc: () =>
                (permissionsService.productCatalogEnabled() || permissionsService.isUserLotusFlareAdmin()) &&
                isViewEnabled('ProductCatalog'),
        },
    },
    {
        path: 'pc/validation-rules',
        name: RouteNames.VALIDATION_RULES_LIST,
        component: ValidationRulesListPage,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('validation-rules'),
        },
    },
    {
        path: 'pc/validation-rules/rule/:id?',
        name: RouteNames.VALIDATIONS_RULES_EDITOR,
        component: ValidaitonRulesEditor,
        meta: {
            permissionFunc: () => permissionsService.isFeatureFlagEnabled('validation-rules'),
        },
    },

    // REWARDS

    {
        name: RouteNames.REWARDS_VIEW,
        path: 'rewards',
        component: Rewards,
        meta: {
            permissionFunc: () => permissionsService.rewardsEnabled() && isViewEnabled('Rewards'),
        },
    },
    {
        name: RouteNames.REWARD_EDITOR,
        path: 'reward/:id?',
        component: RewardEditor,
        meta: {
            permissionFunc: () => permissionsService.rewardsEnabled() && isViewEnabled('Rewards'),
        },
    },
    {
        name: RouteNames.PRIZE_VIEW,
        path: 'prizes',
        component: Prizes,
        meta: {
            permissionFunc: () =>
                permissionsService.prizeEnabled() && (isUserAllowed('PrizesRead') || isUserAllowed('PrizesWrite')),
        },
    },
    {
        name: RouteNames.PRIZE_EDITOR,
        path: 'prize/:id?',
        component: PrizeEditor,
        meta: {
            permissionFunc: () => permissionsService.prizeEnabled() && isUserAllowed('PrizesWrite'),
        },
    },
    {
        name: RouteNames.PRIZE_ENGINE_VIEW,
        path: 'prize-engines',
        component: PrizeEngine,
        meta: {
            permissionFunc: () =>
                permissionsService.prizeEngineEnabled() &&
                (isUserAllowed('PrizeEngineRead') || isUserAllowed('PrizeEngineWrite')),
        },
    },
    {
        name: RouteNames.PRIZE_ENGINE_EDITOR,
        path: 'prize-engine/:id?',
        component: PrizeEngineEditor,
        meta: {
            permissionFunc: () => permissionsService.prizeEngineEnabled() && isUserAllowed('PrizeEngineWrite'),
        },
    },
    {
        name: RouteNames.LOTTERIES,
        path: 'lotteries',
        component: Lotteries,
        meta: {
            permissionFunc: () =>
                permissionsService.lotteryEnabled() &&
                (isUserAllowed('LotteriesRead') || isUserAllowed('LotteriesWrite')),
        },
    },
    {
        name: RouteNames.LOTTERY_EDITOR,
        path: 'lottery/:id?',
        component: LotteryEditor,
        meta: {
            permissionFunc: () => permissionsService.lotteryEnabled() && isUserAllowed('LotteriesWrite'),
        },
    },
    {
        name: RouteNames.VOUCHER_SET_VIEW,
        path: 'vouchers',
        component: VoucherSets,
        meta: {
            permissionFunc: () =>
                permissionsService.voucherEnabled() &&
                (isUserAllowed('VouchersSetsRead') || isUserAllowed('VouchersSetsWrite')),
        },
    },
    {
        name: RouteNames.VOUCHER_SET_EDITOR,
        path: 'voucher/:id?',
        component: VoucherSetEditor,
        meta: {
            permissionFunc: () => permissionsService.voucherEnabled() && isUserAllowed('VouchersSetsWrite'),
        },
    },
    {
        name: RouteNames.PAYOUTS,
        path: 'payouts',
        component: Payouts,
        meta: {
            permissionFunc: () =>
                permissionsService.payoutEnabled() && (isUserAllowed('PayoutsRead') || isUserAllowed('PayoutsWrite')),
        },
    },
    {
        name: RouteNames.REWARD_PAYOUT_EDITOR,
        path: 'payout/:id?',
        component: PayoutEditor,
        meta: {
            permissionFunc: () => permissionsService.payoutEnabled() && isUserAllowed('PayoutsWrite'),
        },
    },

    // PRICING RULES - KUIPER DEMO

    {
        name: RouteNames.PRICING_RULES_KUIPER_DEMO,
        path: 'pricing-rules-kuiper-demo',
        component: PricingRulesListPageKuiperDemo,
        meta: {
            permissionFunc: () => getOperatorConfigValue('isKuiperDemo'),
        },
    },
    {
        name: RouteNames.PRICING_RULES_EDITOR_KUIPER_DEMO,
        path: 'pricing-rule-kuiper-demo/:id?',
        component: PricingRulesEditorKuiperDemo,
        meta: {
            permissionFunc: () => getOperatorConfigValue('isKuiperDemo'),
        },
    },

    // CUSTOMER CARE aka USER MANAGEMENT

    {
        name: RouteNames.CUSTOMER_CARE_VIEW,
        path: 'customer-care',
        component: CustomerCare,
        meta: {
            permissionFunc: () => permissionsService.userManagementEnabled() && isViewEnabled('UserManager'),
        },
        children: [
            {
                name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                path: 'user-management',
                component: UserManagement,
                props: true,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementEnabled() &&
                        isViewEnabled('UserManager') &&
                        isUserAllowed('UMSearch'),
                },
            },
            {
                name: RouteNames.USER_MANAGEMENT_USER_TABLE,
                path: 'user-table/:field?/:operator?/:query?',
                component: UserTable,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementSearchMultipleResultsEnabled() &&
                        isViewEnabled('UMSearchMultipleResults') &&
                        isUserAllowed('UMUserRead'),
                },
            },
            {
                name: RouteNames.USER_MANAGEMENT_SUBSCRIBER_V2,
                path: 'subscriber/:id?',
                component: SubscriberDetails,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementEnabled() &&
                        isViewEnabled('UMSubscriber') &&
                        isUserAllowed('UMSubscriberRead'),
                },
            },
            {
                name: RouteNames.USER_MANAGEMENT_USER_V2,
                path: 'user/:id?',
                component: UserProfile,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementEnabled() &&
                        isViewEnabled('UMUser') &&
                        isUserAllowed('UMUserRead'),
                },
            },
            {
                name: RouteNames.USER_MANAGEMENT_ACCOUNT_V2,
                path: 'account/:id?',
                component: CustomerCareAccount,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementEnabled() &&
                        isViewEnabled('UMAccount') &&
                        isUserAllowed('UMAccountRead'),
                },
            },
            {
                name: RouteNames.USER_MANAGEMENT_ORGANIZATION,
                path: 'organization/:id?',
                component: Organization,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagerOrgsEnabled() &&
                        isViewEnabled('UMOrganization') &&
                        isUserAllowed('UMOrganizationRead'),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.MESSAGE_HISTORY,
                path: 'message-history',
                component: MessageHistory,
                meta: {
                    permissionFunc: () =>
                        permissionsService.cepCampaignsEnabled() &&
                        isUserAllowed(
                            'ServiceCampaignsRead',
                            'ServiceCampaignsWrite',
                            'MarketingCampaignsRead',
                            'MarketingCampaignsWrite',
                        ),
                },
            },
            {
                name: RouteNames.ESIM,
                path: 'esim',
                component: ESIMSearch,
                meta: {
                    permissionFunc: () => permissionsService.eSIMEnabled() && isUserAllowed('UMEsimRead'),
                },
            },
            {
                name: RouteNames.ESIM_DETAILS,
                path: 'esim-details/:iccid',
                component: ESIMDetails,
                meta: {
                    permissionFunc: () => permissionsService.eSIMEnabled() && isUserAllowed('UMEsimRead'),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.USER_MANAGEMENT_PHONES_TABLE,
                path: 'phone-table/:msisdn?',
                component: PhoneTable,
                meta: {
                    permissionFunc: () => permissionsService.accountAppointmentsEnabled(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.AGENT_NOTES_NEW_NOTE,
                path: 'new-note',
                component: NewNote,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementCRMEnabled() &&
                        isUserAllowed('UMUserAgentNotesAdd', 'UMAccountAgentNotesAdd'),
                },
            },
            // viasat demo
            {
                name: RouteNames.VIASAT_LEADS,
                path: 'leads',
                component: ViasatLeads,
                meta: {
                    permissionFunc: () => getOperatorConfigValue('isViasatDemo'),
                },
            },
            {
                name: RouteNames.VIASAT_PARTNER_MANAGEMENT,
                path: 'partner-management',
                component: ViasatPartnerManagement,
                meta: {
                    permissionFunc: () => getOperatorConfigValue('isViasatDemo'),
                },
            },
            {
                name: RouteNames.GOR_PAYMENT_USER_SEARCH,
                path: 'gor-payment-usersearch',
                component: GorPaymentUserSearch,
                meta: {
                    permissionFunc: () => isViewEnabled('GORPaymentStatus'),
                },
            },
            {
                name: RouteNames.GOR_PAYMENT_INFO,
                path: 'gor-payment-info/:msisdn',
                component: GorPaymentInfo,
                meta: {
                    permissionFunc: () => isViewEnabled('GORPaymentStatus'),
                },
            },
            {
                name: RouteNames.QR_CODE_RESEND_USER_SEARCH,
                path: 'resend-qrcode/user/resend/:email',
                component: ResendQRCode,
                permissionFunc: () => isViewEnabled('GuestOrdersResendQRCode'),
            },
            {
                name: RouteNames.QR_CODE_RESEND_PAGE,
                path: 'resend-qrcode/user/search',
                component: SearchGuestUser,
                meta: {
                    permissionFunc: () => isViewEnabled('GuestOrdersResendQRCode'),
                },
            },
        ],
    },

    // OPERATION

    {
        name: RouteNames.OPS_VIEW,
        path: 'ops',
        component: RouteWrapper,
        children: [
            {
                name: RouteNames.OPERATOR_NUMBER_MANAGEMENT_VIEW,
                path: 'number-management',
                component: NumberManagement,
                meta: {
                    permissionFunc: () =>
                        permissionsService.numberManagementEnabled() &&
                        (isUserAllowed('ViewNumberCounts') ||
                            isUserAllowed('UploadNumbers') ||
                            isUserAllowed('SearchNumbers')),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.SIM_PROFILE_MANAGEMENT,
                path: 'sim-profile-management',
                component: SimProfileManagement,
                meta: {
                    permissionFunc: () =>
                        permissionsService.simUploadEnabled() &&
                        (isUserAllowed('ViewNumberCounts') ||
                            isUserAllowed('UploadNumbers') ||
                            isUserAllowed('SearchNumbers')),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.OPS_GRAPHS,
                path: 'graphs',
                component: OperationsGraphs,
                meta: {
                    permissionFunc: () => permissionsService.opsGraphsEnabled(),
                },
            },
            {
                name: RouteNames.DEVELOPER_LINE_AUTHORIZATION,
                path: 'developer-line-authorization',
                component: DeveloperLineAuthorization,
                meta: {
                    permissionFunc: () =>
                        isViewEnabled('DeveloperLineAuthorization') && isUserAllowed(...ALL_LINE_AUTH_PERMISSIONS),
                },
            },
            {
                name: RouteNames.UPDATE_WPS,
                path: 'upload-wps',
                component: UpdateWps,
                meta: {
                    permissionFunc: () => isUserAllowed('UploadWpsRead', 'UploadWpsWrite'),
                },
            },
            {
                name: RouteNames.DEVEDGE_APPLICATION,
                path: 'application',
                component: DevedgeApplication,
                meta: {
                    permissionFunc: () => isUserAllowed('DevedgeApplicationsRead'),
                },
            },
        ],
    },

    // REPORTS

    {
        name: RouteNames.REPORTS,
        path: 'reports',
        component: Reports,
        meta: {
            permissionFunc: () => permissionsService.analyticsReportsEnabled() && isUserAllowed('ReportsRead'),
        },
    },

    // CEP & CDP

    {
        name: RouteNames.DATA_HUB_VIEW,
        path: 'data-hub',
        component: RouteWrapper,
        meta: {
            permissionFunc: () =>
                isUserAllowed('EventsRead', 'JsonPathTesterRead') ||
                (isUserInternal() &&
                    isUserAllowed(
                        'ReportConfigurationsRead',
                        'TableConfigurationsRead',
                        'KafkaConfigurationsRead',
                        'APIDestinationsRead',
                        'SharedFileLocationsRead',
                        'DataflowWrite',
                        'DlqDashboardRead',
                    )),
        },
        children: [
            {
                name: RouteNames.EVENTS_DASHBOARD,
                path: 'events',
                component: EventsDashboard,
                meta: {
                    permissionFunc: () => isUserAllowed('EventsRead'),
                },
            },
            {
                name: RouteNames.EVENTS_EDITOR,
                path: 'event/:id?',
                component: EventsEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('EventsWrite'),
                },
            },
            {
                name: RouteNames.REDSHIFT_CONFIG_DASHBOARD,
                path: 'table-configuration',
                component: RedshiftConfigDashboard,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('TableConfigurationsRead'),
                },
            },
            {
                name: RouteNames.REDSHIFT_CONFIG_EDITOR,
                path: 'redshift-config/:id?',
                component: RedshiftConfigEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('TableConfigurationsWrite'),
                },
            },
            {
                name: RouteNames.ORD_CONFIG_DASHBOARD,
                path: 'reports-configuration',
                component: ORDConfigDashboard,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('ReportConfigurationsRead'),
                },
            },
            {
                name: RouteNames.ORD_CONFIG_EDITOR,
                path: 'report-config/:id?',
                component: ORDConfigEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('ReportConfigurationsWrite'),
                },
            },
            {
                name: RouteNames.EVENT_COMPOSITION_DASHBOARD,
                path: 'event-composition-dashboard',
                component: EventComposition,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('EventCompositionConfigurationsRead'),
                },
            },
            {
                name: RouteNames.EVENT_COMPOSITION_EDITOR,
                path: 'event-composition/:id?',
                component: EventCompositionEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('EventCompositionConfigurationsWrite'),
                },
            },
            {
                name: RouteNames.KAFKA_SINK_CONFIG_DASHBOARD,
                path: 'kafka-configuration',
                component: KafkaConfigDashboard,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('KafkaConfigurationsRead'),
                },
            },
            {
                name: RouteNames.KAFKA_SINK_CONFIG_EDITOR,
                path: 'kafka-config/:id?',
                component: KafkaConfigEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('KafkaConfigurationsWrite'),
                },
            },
            {
                name: RouteNames.API_INVOKER_CONFIG_DASHBOARD,
                path: 'api-invoker-configuration',
                component: ApiInvokerConfigDashboard,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('APIDestinationsRead'),
                },
            },
            {
                name: RouteNames.API_INVOKER_CONFIG_EDITOR,
                path: 'api-invoker-configuration/:id?',
                component: ApiInvokerConfigEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('APIDestinationsWrite'),
                },
            },
            {
                name: RouteNames.SHARED_FILE_LOCATIONS_DASHBOARD,
                path: 'shared-file-locations',
                component: SharedFileLocations,
                meta: {
                    permissionFunc: () => isUserInternal() && isViewEnabled('SharedFileLocationsRead'),
                },
            },
            {
                name: RouteNames.JSONPATH_TESTER,
                path: 'jsonpath-tester',
                component: JSONPathTester,
                meta: {
                    permissionFunc: () => isUserAllowed('JsonPathTesterRead'),
                },
            },
            {
                name: RouteNames.DLQ_DASHBOARD,
                path: 'redshift-ingestion-recovery',
                component: DLQDashboard,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('DlqDashboardRead'),
                },
            },
            {
                name: RouteNames.DATAFLOWS,
                path: 'dataflows',
                component: Dataflows,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('DataflowRead'),
                },
            },
            {
                name: RouteNames.DATAFLOWS_EDITOR,
                path: 'dataflow/:id?',
                component: DataflowsEditor,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('DataflowWrite'),
                },
            },
        ],
    },

    // Segments related routes
    {
        name: RouteNames.SEGMENTS_VIEW,
        path: 'segments',
        component: Segment,
        children: [
            {
                name: RouteNames.SEGMENTS_LIST,
                path: 'manage-segments',
                component: Segments,
                meta: {
                    permissionFunc: () =>
                        permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite'),
                },
            },
            {
                name: RouteNames.SEGMENTS_ADD,
                path: 'create/:generationType?',
                component: NewSegment,
                meta: {
                    permissionFunc: () => permissionsService.segmentsEnabled() && isUserAllowed('SegmentsWrite'),
                },
            },
            {
                name: RouteNames.SEGMENTS_EDIT,
                path: 'edit/:id',
                component: EditSegment,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.segmentsEnabled() && isUserAllowed('SegmentsWrite'),
                },
            },
            {
                name: RouteNames.SEGMENTS_STATIC_FILTERS_ADD,
                path: 'static-filters/create',
                component: StaticSegmentEditor,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userGroupsEnabled() && isUserAllowed('StaticSegmentsWrite'),
                },
            },
            {
                name: RouteNames.SEGMENTS_STATIC_FILTERS_EDIT,
                path: 'static-filters/edit/:id',
                component: StaticSegmentEditor,
                props: true,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userGroupsEnabled() && isUserAllowed('StaticSegmentsWrite'),
                },
            },
        ],
    },

    // CAMPAIGNS

    {
        name: RouteNames.MESSAGES_VIEW,
        path: 'messages',
        component: Messages,
        children: [
            {
                name: RouteNames.CAMPAIGNS_VIEW,
                path: 'campaigns',
                component: Campaigns,
                meta: {
                    permissionFunc: () =>
                        permissionsService.cepCampaignsEnabled() &&
                        isUserAllowed(
                            'ServiceCampaignsRead',
                            'ServiceCampaignsWrite',
                            'MarketingCampaignsRead',
                            'MarketingCampaignsWrite',
                        ),
                },
            },
            {
                name: RouteNames.MESSAGE_TEMPLATE_TESTER,
                path: 'message_template_tester',
                component: MessageTemplateTester,
                props: true,
                meta: {
                    permissionFunc: () =>
                        permissionsService.cepCampaignsEnabled() &&
                        isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite', 'BackofficeCampaignsWrite'),
                },
            },
            {
                name: RouteNames.CAMPAIGNS_ADD,
                path: 'campaigns/create',
                component: CampaignEditor,
                meta: {
                    permissionFunc: () =>
                        permissionsService.cepCampaignsEnabled() &&
                        isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite'),
                },
            },
            {
                name: RouteNames.CAMPAIGNS_EDIT,
                path: 'campaigns/:id',
                component: CampaignEditor,
                props: true,
                meta: {
                    permissionFunc: () =>
                        permissionsService.cepCampaignsEnabled() &&
                        isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite'),
                },
            },
        ],
    },

    // SETTINGS

    {
        name: RouteNames.SETTINGS,
        path: 'settings',
        component: Settings,
        children: [
            {
                name: RouteNames.OPERATOR_LOCALIZATION,
                path: 'tenant-localization',
                component: TenantLocalization,
            },
            {
                name: RouteNames.USER_SETTINGS,
                path: 'user-settings',
                component: UserSettings,
            },
            {
                name: RouteNames.REMOTE_CONFIG,
                path: 'remote-config',
                component: RemoteConfig,
            },
            {
                name: RouteNames.QUIET_HOURS,
                path: 'quietHours',
                component: QuietHours,
            },
            // No equivalent permission
            {
                name: RouteNames.QUIET_HOURS_EDITOR,
                path: 'quiet-hour/:id?',
                component: QuietHoursEditor,
                meta: {
                    permissionFunc: () => permissionsService.quietHoursEnabled(),
                },
            },
            {
                name: RouteNames.CAMPAIGN_CATEGORIES,
                path: 'campaign-categories',
                component: CampaignCategories,
                meta: {
                    permissionFunc: () => isUserAllowed('ServiceCampaignsRead', 'MarketingCampaignsRead'),
                },
            },
            {
                name: RouteNames.CAMPAIGN_CATEGORIES_EDITOR,
                path: 'campaign-category/:id?',
                component: CampaignCategoriesEditor,
                meta: {
                    permissionFunc: () => isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite'),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.USER,
                path: 'users',
                component: Users,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.USER_EDITOR,
                path: 'user-editor/:id?',
                component: UserEditor,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.TENANTS,
                path: 'tenants',
                component: Tenants,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.TENANT_EDITOR,
                path: 'tenant-editor/:id?',
                component: TenantEditor,
                props: true,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.ROLES,
                path: 'roles',
                component: Roles,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            // No equivalent permission
            {
                name: RouteNames.ROLE_EDITOR,
                path: 'role/edit/:id?',
                component: RoleEditor,
                meta: {
                    permissionFunc: () => permissionsService.isUserAdmin(),
                },
            },
            {
                name: RouteNames.APPLICATION_MANAGER,
                path: 'application-management',
                component: ApplicationManager,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('ApplicationManager'),
                },
            },
            {
                name: RouteNames.MANUAL_PROXY_REQUEST,
                path: 'manualProxy',
                component: ManualProxyRequest,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('ManualRequestProxy'),
                },
            },
            {
                name: RouteNames.HOMEPAGE_CONFIG,
                path: 'homepage',
                component: HomepageConfig,
                meta: {
                    permissionFunc: () => permissionsService.isUserLotusFlareAdmin(),
                },
            },
            {
                name: RouteNames.THIRD_PARTY_APPLICATIONS,
                path: 'applications',
                component: ThirdPartyApplications,
                meta: {
                    permissionFunc: () => isUserInternal() && isUserAllowed('ThirdPartyApplications'),
                },
            },
        ],
    },

    // PROMOTIONS

    {
        name: RouteNames.PROMOTIONS,
        path: 'promotions',
        component: Promotions,
        meta: {
            permissionFunc: () =>
                permissionsService.promotionsEnabled() && isUserAllowed('PromotionRuleRead', 'PromotionRulesWrite'),
        },
    },
    {
        name: RouteNames.PROMOTIONS_EDITOR,
        path: 'promotion/:id?',
        component: PromotionsEditor,
        meta: {
            permissionFunc: () => permissionsService.promotionsEnabled() && isUserAllowed('PromotionRulesWrite'),
        },
    },

    // ACCOUNT

    {
        name: RouteNames.ACCOUNT_VIEW,
        path: 'account',
        component: AccountPage,
    },

    // DOCUMENTS

    {
        path: 'document/templates',
        name: RouteNames.DOCUMENT_TEMPLATES,
        component: DocumentTemplates,
        meta: {
            permissionFunc: () =>
                isUserInternal() && isUserAllowed('DocumentTemplatesReadOnly', 'DocumentTemplatesReadWrite'),
        },
    },
    {
        name: RouteNames.DOCUMENT_TEMPLATES_EDITOR,
        path: 'document/template/:id?',
        component: DocumentTemplateEditor,
        props: true,
        meta: {
            permissionFunc: () => isUserInternal() && isUserAllowed('DocumentTemplatesReadWrite'),
        },
    },
    {
        name: RouteNames.DOCUMENT_ASSETS,
        path: 'document/assets',
        component: DocumentAssets,
        props: true,
        meta: {
            permissionFunc: () => isUserInternal() && isUserAllowed('DocumentAssetsRead', 'DocumentAssetsReadWrite'),
        },
    },
    {
        name: RouteNames.DOCUMENT_ASSETS_EDITOR,
        path: 'document/asset/:id?',
        component: DocumentAssetsEditor,
        props: true,
        meta: {
            permissionFunc: () => isUserInternal() && isUserAllowed('DocumentAssetsReadWrite'),
        },
    },

    // AUTHENTICATION

    {
        name: RouteNames.AUTHENTICATION,
        path: 'authentication',
        component: AuthenticationMain,
        props: true,
        meta: {
            permissionFunc: () => isUserAllowed('IDPConfiguration'),
        },
    },
    {
        name: RouteNames.AUTHENTICATION_CONFIGURATOR,
        path: 'authentication/configuration',
        component: AuthenticationConfigurator,
        props: true,
        meta: {
            permissionFunc: () => isUserAllowed('IDPConfiguration'),
        },
    },
    {
        name: RouteNames.AUTHENTICATION_ROLE_MAPPER,
        path: 'authentication/grouprolemapping',
        component: AuthRoleMapper,
        props: true,
        meta: {
            permissionFunc: () => isUserAllowed('IDPGroupRoleMapping'),
        },
    },
    {
        name: RouteNames.AUTHENTICATION_USER_MAPPER,
        path: 'authentication/userrolemapping',
        component: ExternalUserManagement,
        props: true,
        meta: {
            permissionFunc: () => isUserAllowed('IDPUserRoleMapping'),
        },
    },

    // CUSTOMER CARE SUITE

    {
        name: RouteNames.CCS_VIEW,
        path: 'customer-care-suite',
        component: CustomerCareSuite,
        meta: {
            permissionFunc: () => true,
        },
        children: [
            {
                name: RouteNames.CCS_SEARCH,
                path: '/',
                component: CustomerCareSuiteSearch,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_USER_RESULTS_TABLE,
                path: 'user-table/:field?/:operator?/:query?',
                component: CustomerCareUserSearchResults,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_USER_PHONES_TABLE,
                path: 'phone-table/:msisdn?',
                component: CustomerCareUserPhoneSearchResults,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_USER_PAGE,
                path: 'user/:id?',
                component: UserPageView,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_ACCOUNT_PAGE,
                path: 'account/:id?',
                component: AccountPageView,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_SUBSCRIBER_PAGE,
                path: 'subscriber/:id?',
                component: SubscriberPageView,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_SUBSCRIBER_EDIT_PAGE,
                path: 'subscriber/edit/:id?',
                component: SubscriberDetailsEditor,
                meta: {
                    permissionFunc: () =>
                        permissionsService.userManagementEnabled() &&
                        permissionsService.editSubscriberDetailsPageEnabled() &&
                        isUserAllowed(
                            'UMSubscriberEditSubscriberUpdateFields',
                            'UMSubscriberEditSubscriberTerminateUndoTermination',
                            'UMSubscriberEditSubscriberSuspendResume',
                            'UMSubscriberEditSubscriberToggleFlag',
                        ),
                },
            },
            {
                name: RouteNames.CCS_ORGANIZATION_PAGE,
                path: 'organization/:id?',
                component: OrganizationPageView,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.END_USER_AUTHORIZATION,
                path: 'user-authorization/:targetType/:targetId/:operationType?/:operationTargetType?/:operationTargetId?',
                component: EndUserAuthorization,
                props: true,
                meta: {
                    permissionFunc: () => true,
                },
            },
            {
                name: RouteNames.CCS_DELIVERY_RESHIPMENT,
                path: 'reshipment/:targetType/:targetId/:deliveryId',
                component: DeliveryReshipment,
                props: true,
                meta: {
                    permissionFunc: () => isUserAllowed('UMAccountRequestReshipment'),
                },
            },
        ],
    },
];

export default routes;
