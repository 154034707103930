<template>
    <div>
        <!-- Push -->
        <div
            v-if="selectedType === MessageTypes.PUSH"
            class="details"
        >
            <div class="header">Create Message</div>

            <div class="section">
                <div class="title">*Push Notification Title</div>
                <CustomInput
                    :value="messageTitle"
                    :invalid="messageTitleMissing"
                    class="input"
                    @input="inputMessageTitle"
                />
            </div>

            <div class="section">
                <div class="title">Message</div>
                <MustacheAutocomplete
                    :value="message"
                    :eventsIDs="triggerEventsIDs"
                    class="input"
                    @input="inputMessage"
                />
            </div>

            <div class="section">
                <div class="title">
                    {{ $i18n.t('campaigns.pushOpenAction') }}
                </div>
                <Dropdown
                    v-if="pushNotificationOpenActionLabels.length"
                    :items="pushNotificationOpenActionLabels"
                    :selected="[NotificationButtonActionsToUI[pushOpenAction]]"
                    class="w-50 input"
                    caption="Select Open Action"
                    @selected="selectPushOpenAction"
                />
                <CustomInput
                    v-show="
                        pushOpenAction === NotificationButtonActions.OpenLink ||
                        pushOpenAction === NotificationButtonActions.OpenApp
                    "
                    :value="deepLink"
                    class="input"
                    placeholder="app://myapp/offers/youtube2G"
                    @input="inputDeepLink"
                />
            </div>

            <div class="section">
                <AppCheckbox
                    :value="isInboxViewChecked"
                    class="input font-weight-bold"
                    :labelRight="$i18n.t('campaigns.showInboxVersion')"
                    @input="checkInboxView"
                />
            </div>

            <div
                v-if="isInboxViewChecked"
                class="section"
            >
                <div class="title">{{ $i18n.t('campaigns.inboxTitle') }}*</div>
                <CustomInput
                    :value="enhancedViewTitle"
                    class="input"
                    @input="inputEnhancedViewTitle"
                />
                <div class="my-2">
                    <div class="title">{{ $i18n.t('campaigns.inboxShortMessage') }}</div>
                    <CustomInput
                        :value="enhancedViewMessagePushSummary"
                        class="input"
                        @input="inputEnhancedViewMessagePushSummary"
                    />
                </div>
            </div>

            <div
                v-if="isInboxViewChecked"
                class="section"
            >
                <div class="title">{{ $i18n.t('campaigns.inboxMessage') }}*</div>
                <MustacheAutocomplete
                    :value="enhancedViewMessage"
                    :eventsIDs="triggerEventsIDs"
                    language="mustache-html"
                    class="input"
                    @input="inputEnhancedViewMessage"
                />
            </div>
        </div>

        <!-- SMS -->
        <div
            v-if="selectedType === MessageTypes.SMS"
            class="details"
        >
            <div class="header">Create Message</div>

            <div
                v-if="campaignFeatures.backofficeTargetsEnabled"
                class="section"
            >
                <div class="title text-capitalize">
                    {{ $i18n.t('generic.to') }}
                </div>
                <TagsInput
                    class="input"
                    :value="backofficeTargetsInput"
                    placeholder="18001234567"
                    :invalid="$v.backOfficeTargets.$error"
                    @input="inputBackofficeTargets"
                />
            </div>

            <div class="section">
                <div class="title">{{ $i18n.t('campaigns.smsShowSenderAs') }}:</div>

                <div class="d-flex vertical-align-center mt-2">
                    <CustomInput
                        :value="smsSenderText"
                        :invalid="$v.smsSenderText.$error"
                        placeholder="Name"
                        class="input mt-0"
                        @input="inputSMSSenderText"
                    />
                </div>

                <div
                    v-show="$v.smsSenderText.$error"
                    class="display-flex mt-1 mb-2 pb-1"
                >
                    <span class="col-4" />
                    <span class="invalid-text">
                        {{ messageValidation.smsSenderText }}
                    </span>
                </div>
            </div>

            <div class="section mt-3">
                <div class="title">*Message</div>
                <MustacheAutocomplete
                    :value="message"
                    :eventsIDs="triggerEventsIDs"
                    :invalid="messageTextMissing"
                    class="input"
                    @input="inputMessage"
                />
            </div>
        </div>

        <!-- Email -->
        <div
            v-if="selectedType === MessageTypes.EMAIL"
            class="details"
        >
            <div class="d-flex justify-content-between header">Create Message</div>

            <div class="section">
                <div class="title">Email Subject</div>
                <CustomInput
                    :value="messageTitle"
                    :invalid="messageTitleMissing"
                    class="input"
                    @input="inputMessageTitle"
                />
            </div>

            <div
                v-if="campaignFeatures.backofficeTargetsEnabled"
                class="section"
            >
                <div class="title text-capitalize">
                    {{ $i18n.t('generic.to') }}
                </div>
                <TagsInput
                    class="input"
                    :value="backofficeTargetsInput"
                    placeholder="email1@example.com"
                    :invalid="$v.backOfficeTargets.$error"
                    @input="inputBackofficeTargets"
                />
            </div>

            <div class="section">
                <div class="title">
                    {{ $i18n.t('campaigns.sender') }}
                </div>
                <Dropdown
                    :items="emailSenderItems"
                    :selected="selectedEmailSender"
                    displayProperty="email_sender_address"
                    displaySubLabelProperty="email_sender_name"
                    isShowFullLabel
                    class="input"
                    caption="Select email sender"
                    @selected="selectEmailSender"
                />
            </div>

            <div class="section">
                <div class="title">
                    {{ $i18n.t('campaigns.BCC') }}
                </div>
                <TagsInput
                    class="input"
                    :value="bccEmailsInput"
                    placeholder="email1@example.com"
                    :invalid="$v.bccEmails.$error"
                    @input="inputBccEmail"
                />
            </div>

            <div class="section">
                <div class="title">Message</div>
                <MustacheAutocomplete
                    :value="message"
                    :eventsIDs="triggerEventsIDs"
                    language="mustache-html"
                    class="input"
                    @input="inputMessage"
                />
            </div>
            <div
                v-if="isActionBasedDelivery"
                class="my-4"
            >
                <AppToggle
                    :value="shouldShowAttachmentConfig"
                    :label="$t('generic.includeFormattedContent')"
                    small
                    @input="toggleEmailQRSection"
                />
                <div
                    v-if="shouldShowAttachmentConfig"
                    class="mt-2"
                >
                    <AppMultiselectV3
                        v-model="emailQROption"
                        :options="emailQROptions"
                        :allowEmpty="false"
                        :disabled="emailQROptions.length === 1"
                        small
                    />
                    <AppMultiselectV3
                        :value="emailQRProperty"
                        class="mt-2"
                        :additionalLabel="$t('events.eventProperty')"
                        :options="QREventProperties"
                        :allowEmpty="false"
                        small
                        @input="inputMessageQRData"
                    />
                    <p class="mt-2">
                        {{ $t('campaigns.messages.emailQRHint') }}
                        <span class="highlighted">src="cid:image_content_id"</span>
                        <IconButton
                            class="d-inline-block align-middle ml-1"
                            :icon="ICON_TYPES.CLONE"
                            @iconClick="copyToClipboard('src=&quot;cid:image_content_id&quot;')"
                        />
                    </p>
                </div>
            </div>
        </div>

        <!-- Ott -->
        <div
            v-if="selectedType === MessageTypes.OTT"
            class="details"
        >
            <div class="header">Create Message</div>

            <div class="section">
                <div class="title">Title</div>
                <CustomInput
                    :value="messageTitle"
                    class="input"
                    @input="inputMessageTitle"
                />
            </div>

            <div class="section">
                <div class="title">Message</div>
                <MustacheAutocomplete
                    :value="message"
                    :eventsIDs="triggerEventsIDs"
                    :invalid="messageTextMissing"
                    class="input"
                    @input="inputMessage"
                />
            </div>
            <div class="section">
                <AppCheckbox
                    :value="isInboxViewCheckedOtt"
                    class="input font-weight-bold"
                    :labelRight="$i18n.t('campaigns.showInboxVersion')"
                    @input="checkInboxViewOtt"
                />
            </div>
            <div
                v-if="isInboxViewCheckedOtt"
                class="section"
            >
                <div class="title">{{ $i18n.t('campaigns.inboxMessage') }}*</div>
                <MustacheAutocomplete
                    :value="enhancedViewMessageOtt"
                    :eventsIDs="triggerEventsIDs"
                    :placeholder="$i18n.t('campaigns.inboxMessage')"
                    language="mustache-html"
                    class="input"
                    @input="inputEnhancedViewMessageOtt"
                />
            </div>
            <div
                v-if="isInboxViewCheckedOtt"
                class="section"
            >
                <div class="title mb-2">{{ $i18n.t('campaigns.inboxMessageExpiry') }}*</div>
                <div class="mb-3">
                    <AppRadioButton
                        v-model="messageExpiry"
                        :inputValue="ExpiryLabel.Relative"
                        name="group"
                        :labelRight="ExpiryLabel.Relative"
                        class="mr-2"
                        @input="inputOttExpiryTimeDays"
                    />
                    <AppRadioButton
                        v-model="messageExpiry"
                        :inputValue="ExpiryLabel.NoExpiry"
                        name="group"
                        :labelRight="ExpiryLabel.NoExpiry"
                        @input="inputOttExpiryTimeDays"
                    />
                </div>
            </div>
            <div
                v-show="isInboxViewCheckedOtt && messageExpiry === ExpiryLabel.Relative"
                class="section"
            >
                <div class="d-flex">
                    <AppInputV3
                        v-model="expiryDay"
                        type="number"
                        class="col-sm-3 mr-3"
                        @input="inputOttExpiryTimeDays"
                    />
                    <AppMultiselectV3
                        id="expiryOptions"
                        v-model="expiryOptions"
                        :options="pushExpiryOptionsLabels"
                        :placeholder="$i18n.t('generic.days')"
                        class="col-sm-6"
                        :allowEmpty="false"
                        small
                    />
                </div>
            </div>
        </div>

        <div
            v-show="!MessageTypeLabels[selectedType]"
            class="reminder text-center"
        >
            Start designing your message by choosing one of the options above
        </div>
    </div>
</template>

<script>
import { values } from 'lodash';
import { validationMixin } from 'vuelidate';
import { MessageTypes, MessageTypeLabels } from '@/common/CampaignMessage';
import Message, {
    ExpiryLabel,
    ExpiryOptions,
    EMAIL_SENDER_OPTIONS_MAPPING,
    NotificationButtonActions,
    NotificationButtonActionsToUI,
    NotificationButtonActionsFromUI,
} from '@/__new__/services/dno/campaigns/models/Message';
import CustomInput from '@/__new__/features/campaigns/CustomInput.vue';
import MustacheAutocomplete from '@/components/partials/inputs/mustacheAutocomplete/MustacheAutocomplete.vue';
import Dropdown from '@/components/partials/Dropdown.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import TagsInput from '@/components/partials/inputs/TagsInput.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { ICON_TYPES } from '@/common/iconHelper';
import { copyToClipboard } from '@/common/utils';
import IconButton from '@/components/partials/IconButton.vue';
import { isViewEnabled } from '@/services/permissions/permissions.service';

export default {
    name: 'MessageDetails',
    components: {
        Dropdown,
        MustacheAutocomplete,
        CustomInput,
        AppCheckbox,
        AppRadioButton,
        AppInputV3,
        AppMultiselectV3,
        TagsInput,
        AppToggle,
        IconButton,
    },
    mixins: [validationMixin],
    props: {
        messageTitleMissing: { type: Boolean, default: false },
        messageTextMissing: { type: Boolean, default: false },
        selectedType: { type: String, default: '' },
        pushOpenAction: { type: String, default: '' },
        messageTitle: { type: String, default: '' },
        emailSenderItems: { type: Array, default: () => [] },
        sender: { type: String, default: '' },
        emailSenderName: { type: String, default: null },
        bccEmails: { type: Array, default: () => [] },
        message: { type: String, default: '' },
        enhancedViewMessagePushSummary: { type: String, default: '' },
        deepLink: { type: String, default: '' },
        smsSenderText: { type: String, default: null },
        enhancedViewTitle: { type: String, default: null },
        enhancedViewMessage: { type: String, default: null },
        enhancedViewMessageOtt: { type: String, default: null },
        enhancedOttExpiryTimeDays: { type: [Number, String], default: null },
        backOfficeTargets: { type: Array, default: () => [] },
        triggerEventsIDs: { type: Array, default: () => [] },
        campaignFeatures: { type: Object, default: () => undefined },
        campaignType: { type: Number, default: null },
        isActionBasedDelivery: { type: Boolean, default: false },
        emailQRProperty: { type: String, default: null },
    },
    data() {
        return {
            ICON_TYPES,
            MessageTypes,
            MessageTypeLabels,
            NotificationButtonActionsToUI,
            NotificationButtonActions,
            ExpiryOptions,
            ExpiryLabel,
            inboxViewChecked: null,
            inboxViewCheckedOtt: null,
            messageExpiry: null,
            expiryDay: null,
            expiryOptions: 'Days',
            shouldShowAttachmentConfig: false,
            emailQROption: this.$t('campaigns.messages.imageB64Attachment'),
        };
    },
    computed: {
        ...mapGetters(Modules.events, { getEventById: Getters.GET_EVENT_BY_ID }),
        emailQROptions() {
            return [this.$t('campaigns.messages.imageB64Attachment')];
        },
        QREventProperties() {
            const relatedEvent = this.getEventById(this.triggerEventsIDs[0]);
            if (relatedEvent) {
                return relatedEvent.properties.filter(prop => prop.type === 'string').map(prop => prop.name);
            }
            return [];
        },
        isInboxViewChecked() {
            return !!(this.inboxViewChecked ?? (this.enhancedViewTitle || this.enhancedViewMessage));
        },
        isInboxViewCheckedOtt() {
            return !!(this.inboxViewCheckedOtt ?? this.enhancedViewMessageOtt);
        },
        pushNotificationOpenActionLabels() {
            return Object.values(NotificationButtonActions)
                .filter(action => isViewEnabled(`CampaignPush_${action}`))
                .map(enabledAction => NotificationButtonActionsToUI[enabledAction]);
        },
        selectedEmailSender() {
            return this.emailSenderItems?.length && this.sender
                ? [
                      this.emailSenderItems.find(item =>
                          item[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_NAME]
                              ? item[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_ADDRESS] === this.sender &&
                                item[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_NAME] === this.emailSenderName
                              : item[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_ADDRESS] === this.sender,
                      ),
                  ].filter(item => item !== undefined)
                : [];
        },
        selectedEmailSenderName() {
            return this.selectedEmailSender?.length
                ? this.selectedEmailSender[0]?.[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_NAME] || null
                : null;
        },
        isDifferenceEmailSenderName() {
            return this.selectedEmailSender?.length && this.selectedEmailSenderName !== this.emailSenderName;
        },
        messageValidation() {
            return Message.validateMessage(
                {
                    type: this.selectedType,
                    smsSenderText: this.smsSenderText,
                    bccEmails: this.bccEmails,
                    backOfficeTargets: this.backOfficeTargets,
                },
                this.campaignType,
            );
        },
        pushExpiryOptionsLabels() {
            return values(ExpiryOptions);
        },
        backofficeTargetsInput() {
            return (this.backOfficeTargets || []).map(text => ({ text }));
        },
        bccEmailsInput() {
            return (this.bccEmails || []).map(email => ({ text: email }));
        },
    },
    validations() {
        return {
            smsSenderText: {
                validation: function validation() {
                    return !this.messageValidation.smsSenderText;
                },
            },
            bccEmails: {
                validation: function validation() {
                    return !this.messageValidation.bccEmails;
                },
            },
            backOfficeTargets: {
                validation: function validation() {
                    return !this.messageValidation.backOfficeTargets;
                },
            },
        };
    },
    watch: {
        enhancedOttExpiryTimeDays: {
            immediate: true,
            handler() {
                if (this.enhancedOttExpiryTimeDays) {
                    this.expiryDay = this.enhancedOttExpiryTimeDays;
                    this.messageExpiry = ExpiryLabel.Relative;
                } else {
                    this.messageExpiry = ExpiryLabel.NoExpiry;
                }
            },
        },
        sender: {
            immediate: true,
            handler() {
                if (!this.sender && this.emailSenderItems?.length) {
                    this.selectEmailSender(this.emailSenderItems[0]);
                }
            },
        },
        isDifferenceEmailSenderName: {
            handler() {
                if (this.isDifferenceEmailSenderName && this.selectedEmailSender.length) {
                    this.selectEmailSender(this.selectedEmailSender[0]);
                }
            },
        },
        emailQRProperty: {
            immediate: true,
            handler(newVal) {
                if (!this.shouldShowAttachmentConfig && newVal) {
                    this.shouldShowAttachmentConfig = true;
                }
            },
        },
    },
    methods: {
        copyToClipboard,
        checkInboxView(isChecked) {
            this.inboxViewChecked = isChecked;
            if (!isChecked) {
                this.inputEnhancedViewTitle(null);
                this.inputEnhancedViewMessage(null);
            } else {
                this.inputEnhancedViewTitle('');
                this.inputEnhancedViewMessage('');
            }
        },
        checkInboxViewOtt(isChecked) {
            this.inboxViewCheckedOtt = isChecked;
            if (!isChecked) {
                this.inputEnhancedViewMessageOtt(null);
                this.inputOttExpiryTimeDays(null);
                this.messageExpiry = true;
            } else {
                this.inputEnhancedViewMessageOtt('');
            }
        },
        selectPushOpenAction(openActionLabel) {
            this.$emit('selectPushOpenAction', NotificationButtonActionsFromUI[openActionLabel]);
        },
        inputMessageTitle(messageTitle) {
            this.$emit('inputMessageTitle', messageTitle);
        },
        inputMessageQRData(fieldName) {
            this.$emit('inputMessageQRData', fieldName);
        },
        toggleEmailQRSection() {
            this.shouldShowAttachmentConfig = !this.shouldShowAttachmentConfig;
            if (this.shouldShowAttachmentConfig) {
                this.inputMessageQRData(this.QREventProperties[0]);
            } else {
                this.inputMessageQRData(null);
            }
        },
        selectEmailSender(sender) {
            this.$emit('selectEmailSender', sender);
        },
        inputBccEmail(bccEmailsInput) {
            this.$v.bccEmails.$touch();
            const bccEmails = bccEmailsInput.map(emailData => emailData.text);
            this.$emit('inputBccEmail', bccEmails);
        },
        inputMessage(message) {
            this.$emit('inputMessage', message);
        },
        inputEnhancedViewMessagePushSummary(message) {
            this.$emit('inputEnhancedViewMessagePushSummary', message);
        },
        inputDeepLink(deepLink) {
            this.$emit('inputDeepLink', deepLink);
        },
        inputSMSSenderText(smsSenderText) {
            this.$v.smsSenderText.$touch();
            this.$emit('inputSMSSenderText', smsSenderText);
        },
        inputEnhancedViewTitle(input) {
            this.$emit('inputEnhancedViewTitle', input);
        },
        inputEnhancedViewMessage(input) {
            this.$emit('inputEnhancedViewMessage', input);
        },
        inputEnhancedViewMessageOtt(input) {
            this.$emit('inputEnhancedViewMessageOtt', input);
        },
        inputOttExpiryTimeDays(input) {
            if (input === ExpiryLabel.NoExpiry) {
                this.$emit('inputOttExpiryTimeDays', null);
            } else {
                this.$emit('inputOttExpiryTimeDays', this.expiryDay == null ? undefined : input);
            }
        },
        inputBackofficeTargets(input) {
            this.$v.backOfficeTargets.$touch();
            const backOfficeTargets = input.map(targetData => targetData.text);
            this.$emit('inputBackofficeTargets', backOfficeTargets);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_consistency';

$icon-path: '~@/assets/icons/';

.gray-text {
    font-size: 12px;
    color: $gray30;
    font-weight: normal;
    text-transform: initial;
}

.reminder {
    font-size: 14px;
    color: $gray30;
}

.pop-up.details .section textarea {
    margin-top: 0;
}

.details {
    .header {
        height: 22px;
        margin: 16px 0;

        color: $gray30;
        font-size: 14px;
        font-weight: bold;

        text-transform: uppercase;
    }

    .content-type-selector {
        padding-top: 4px;
        padding-bottom: 4px;

        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border: 1px solid $gray5;
        border-bottom: 0;

        div {
            padding: 5px;

            font-size: 10px;
            color: $navy;
            font-weight: 800;

            &.selected,
            &:hover {
                color: $orange;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .section {
        margin-top: 8px;
        font-size: 14px;

        &.disabled .title {
            color: $gray30;
        }

        .title {
            margin-top: 8px;

            font-weight: bold;
            color: $gray60;
        }

        .input {
            margin-top: 8px;
            width: 100%;

            ::v-deep div {
                border-radius: 0;

                input::placeholder {
                    font-style: normal;
                }
            }
        }
    }

    .checkbox {
        margin-top: 24px;
    }

    .multi-column {
        .section {
            flex-grow: 1;
        }
    }
}

.placeholder-panel {
    opacity: 1;
    line-height: 29px;

    padding: 0 29px;
    margin-left: -19px;
    margin-right: -19px;

    color: $navy;
    font-size: 14px;
    background-color: $gray5;

    &:before {
        margin-top: 7px;
        margin-right: 10px;
        content: url($icon-path + $pie-chart-icon);
    }
}

.disabled {
    div {
        pointer-events: none;
        opacity: 0.75;
    }

    ::v-deep input.eye {
        &,
        & + span.icon {
            pointer-events: all;
        }
    }
}

.invalid-text {
    color: $red;
    font-size: 10px;
}
.highlighted {
    background: $blue-200;
}
</style>
