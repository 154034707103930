<template>
    <div class="page-wrap">
        <SinkConfigEditor
            v-if="shouldShowContent"
            :entityType="ENTITY_TYPES.API_INVOKER_SINK_CONFIG"
            :uiSettings="configsSettings"
            :createFunction="upsertApiInvokerConfig"
            :updateFunction="upsertApiInvokerConfig"
            :typeCasters="typeCasters"
            :dashboardPageName="RouteNames.API_INVOKER_CONFIG_DASHBOARD"
            :editableConfig="editableConfig"
            :triggerDefinitions="triggerDefinitions"
            :formatters="getSinkFormatters"
            :events="events"
            :validate="true"
            @export="onExport"
        >
            <template #modal>
                <SinkConfigDialog
                    v-model="isDetailsModalOpen"
                    :jsonData="jsonData"
                    :yamlData="yamlData"
                />
            </template>
        </SinkConfigEditor>
    </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// components
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import SinkConfigDialog from '@/__new__/features/sinkConfigs/SinkConfigDialog.vue';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { isEmpty, omit } from 'lodash';
import { isDocumentTemplateJobId } from '@/common/documentsHelper';

// http
import { upsertApiInvokerConfig } from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';
import { getJobStaticExport } from '@/__new__/services/dno/sinkConfigs/http/apiInvokerConfig';

export default {
    name: 'ApiInvokerConfigEditor',
    components: { SinkConfigDialog },
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            upsertApiInvokerConfig,
            isDetailsModalOpen: false,
            jsonData: {},
            yamlData: '',
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            configsSettings: Getters.GET_API_INVOKER_SINK_CONFIGS_SETTINGS,
            allConfigs: Getters.GET_API_INVOKER_SINK_CONFIGS,
            getConfigById: Getters.GET_API_INVOKER_SINK_CONFIG_BY_ID,
            getSinkFormatters: Getters.GET_SINK_FORMATTERS,
            typeCasters: Getters.GET_SINK_TYPE_CASTERS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        editableConfig() {
            return this.getConfigById(this.$route.params.id);
        },
        shouldShowContent() {
            if (this.$route.params.id) {
                return !!this.editableConfig && this.events.length;
            }
            return !isEmpty(this.configsSettings) && this.triggerDefinitions && this.events;
        },
    },
    beforeCreate() {
        if (isDocumentTemplateJobId(this.$route.params.id)) {
            this.$router.go(-1);
        }
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this[Actions.LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS](),
                    this.fetchTriggers(),
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchSegments(),
                    this.fetchSinkTypeCasters(),
                ];

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                if (this.$route.params.id && this.allConfigs.length === 0) {
                    await this[Actions.LOAD_API_INVOKER_SINK_CONFIGS]();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.failedToLoadApiInvokerConfig'),
                        type: ALERT_TYPES.alert,
                    });
                },
            },
        );
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS,
            Actions.LOAD_API_INVOKER_SINK_CONFIGS,
        ]),
        getJobAsYaml(config) {
            return this.$withProgressBar(
                async () => {
                    const data = omit(config, ['created_at', 'last_updated']);
                    const { data: yamlData } = await getJobStaticExport(data);

                    this.yamlData = yamlData?.data?.toString();
                },
                {
                    errorHandler: () => {
                        this.yamlData = this.$t('generic.error');
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
        onExport(data) {
            this.getJobAsYaml(data);
            this.jsonData = data;
            this.isDetailsModalOpen = true;
        },
    },
};
</script>
