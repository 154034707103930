
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { padEnd } from 'lodash';

export default {
    name: 'ResendQRCode',
    components: {
        AppTable,
        TableFiltersRenderless,
    },
    data() {
        return {
            notes: [],
            isDataLoading: true,
            searchQueryHasAccessTable: '',
            enableRowStateControls: true,
        };
    },
    computed: {
        tableOrderColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.crmUserId'),
                    key: 'crmUserId',
                    field: 'crmUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'timestamp',
                    field: 'timestamp',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.note'),
                    key: 'note',
                    field: 'note',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    created() {
        this.fetchResendNotes();
    },
    methods: {
        async fetchResendNotes(): void {
            const { email } = this.$route.params;
            const noteId = padEnd(email, 32, '0');
            const result = await notesHTTP.getNotes(noteId, USER_MANAGER_HIERARCHY.USER);
            this.isDataLoading = false;
            if (Array.isArray(result.data)) {
                this.notes = result.data.flat(2).map(note => ({
                    crmUserId: note.crm_user_id,
                    timestamp: note.epoch,
                    note: this.$i18n.t('customerCare.guestOrder.resendCode'),
                }));
            }
        },
    },
};
