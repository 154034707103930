import http, { type DnoResponseBase } from '@/http/index';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { RECORD_CATEGORY } from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';
import type { AxiosPromise } from 'axios';
import type { DailyUsageApiResponse } from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';
import type { SignedUrlForDownloadEntity } from '@/common/fileUploadHelper';
import type { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';

import { RESOURCE_TYPE, SIM_TYPE } from '@/__new__/features/resources/common/sim';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

interface GetAvailableWPSResponse extends DnoResponseBase {
    wps: string[];
}

interface GetEsimProfileResponse extends DnoResponseBase {
    profiles: {
        profileState: string;
    }[];
}

export function getAvailableWPS(target_id: string): AxiosPromise<GetAvailableWPSResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getavailablewps',
        data: {
            target_id,
            target_type: TARGET_TYPE.SUBSCRIBER,
        },
    });
}

export function updateWPS(
    target_id: string,
    msisdn: string,
    iccid: string,
    wps: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/updatewps',
        data: {
            target_type: TARGET_TYPE.SUBSCRIBER,
            target_id,
            msisdn,
            iccid,
            wps,
        },
    });
}

export function suspendLine(target_id: string, target_type: number, reason: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/suspendline',
        data: {
            target_id,
            target_type,
            reason,
        },
    });
}

export function resumeSuspendLine(
    target_id: string,
    target_type: number,
    should_unblock_imei: boolean,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/resumesuspendline',
        data: {
            target_id,
            target_type,
            should_unblock_imei,
        },
    });
}

export function requestCancelLine(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/requestcancelline',
        data: {
            target_id,
            target_type,
        },
    });
}

export function cancelLineCancellationRequest(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/cancellinecancellationrequest',
        data: {
            target_id,
            target_type,
        },
    });
}

export function referrenceNGPInquiry(market_zip: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/referrencengpinquiry',
        data: {
            market_zip,
        },
    });
}

export function cancelDeviceLocation(
    target_id: string,
    target_type: number,
    msisdn: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/cancelDeviceLocation',
        data: {
            target_id,
            target_type,
            msisdn,
        },
    });
}

export function changeMSISDN(
    target_id: string,
    target_type: number,
    market_zip: string,
    msisdn: string,
    iccid: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/changemsisdn',
        data: {
            target_id,
            target_type,
            market_zip,
            msisdn,
            iccid,
        },
    });
}

export function getUsage(target_id: string, target_type: USER_MANAGER_HIERARCHY): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getusage',
        data: {
            target_id,
            target_type,
        },
    });
}

export function getDailyUsage(
    msisdn: string,
    recordCategory = RECORD_CATEGORY.VOICE_AND_SMS,
    nextPageToken?: string,
): AxiosPromise<DailyUsageApiResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getdailyusage',
        data: {
            msisdn,
            next_page_token: nextPageToken,
            filter: {
                record_category: recordCategory,
            },
        },
    });
}

export function getSignedURLForDownload(
    dataflow: DATAFLOW_API_TYPE,
    filename: string,
): AxiosPromise<SignedUrlForDownloadEntity> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            dataflow,
            file_name: filename,
        },
    });
}

export function getEsimProfile(iccid: string): AxiosPromise<GetEsimProfileResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getesimprofile',
        data: { iccid },
    });
}

export function resetVoiceMailPwd(
    target_id: string,
    target_type: number,
    msisdn: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/resetvoicemailpassword',
        data: {
            target_id,
            target_type,
            msisdn,
        },
    });
}

export function validateICCID(
    target_id: string,
    target_type: number,
    iccid: string,
    sim_type: number = RESOURCE_TYPE[SIM_TYPE.PSIM],
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/validateiccid',
        data: {
            target_id,
            target_type,
            iccid,
            sim_type,
        },
    });
}

export function requestReshipment(
    target_id: string,
    target_type: number,
    order_id: string,
    reason: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/reshipment',
        data: {
            target_id,
            target_type,
            order_id,
            reason,
        },
    });
}

export interface NetworkSpecItem {
    networkSpecificationDataName: string;
    networkSpecificationDataValue: string;
    networkSpecificationData: string;
}
interface SubscriberNetworkSpecificationResponse extends DnoResponseBase {
    networkSpecificationDetails: NetworkSpecItem[];
}

export function subscriberNetworkSpecification(data: {
    target_id: string;
    target_type: number;
    msisdn: string;
}): AxiosPromise<SubscriberNetworkSpecificationResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/subscriberNetworkSpecification',
        data,
    });
}

export interface NetworkCoverageInfo extends DnoResponseBase {
    latitude: string;
    longitude: string;
    roamingStatus: string;
    status: Record<string, string>;
    bands: Record<string, string>;
}

export function subscriberNetworkCoverage(data: {
    target_id: string;
    target_type: number;
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    zip4?: string;
}): AxiosPromise<NetworkCoverageInfo> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/subscriberNetworkCoverage',
        data,
    });
}

export interface OutageNotification {
    incident_id: number;
    cim_start_time: string;
    cim_end_time: string;
    executive_summary: string;
    cim_id: number;
    outage_type: string;
    etr: string;
    timestamp: number;
    status: string;
    operator_name: string;
}

export interface GetOutageNotificationsResponse extends DnoResponseBase {
    data: OutageNotification[];
}

export function getOutageNotifications(data: {
    target_id: string;
    target_type: number;
}): AxiosPromise<GetOutageNotificationsResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getOutageNotifications',
        data,
    });
}

export interface ImeiHistoryItem {
    imei: string;
    make: string;
    lastUpdateDateTime: string;
    blocked: string;
    model: string;
}

export interface SubscriberImeiHistoryResponse extends DnoResponseBase {
    imeiInfo: ImeiHistoryItem[];
}

export function subscriberImeiHistory(data: {
    target_id: string;
    target_type: number;
    msisdn: string;
}): AxiosPromise<SubscriberImeiHistoryResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/subscriberImeiHistory',
        data,
    });
}

export const OSSMVNEService = {
    getOptions: getAvailableWPS,
    update: updateWPS,
};

export default {
    getAvailableWPS,
    updateWPS,
    suspendLine,
    resumeSuspendLine,
    requestCancelLine,
    cancelLineCancellationRequest,
    referrenceNGPInquiry,
    cancelDeviceLocation,
    changeMSISDN,
    getUsage,
    getDailyUsage,
    getEsimProfile,
    resetVoiceMailPwd,
    validateICCID,
    requestReshipment,
    subscriberNetworkSpecification,
    subscriberNetworkCoverage,
    getOutageNotifications,
    subscriberImeiHistory,
};
