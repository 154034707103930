<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('charging.entities.plural.chargingSpecifications')"
            :isOverviewEnabled="isOverviewEnabled"
            :entitiesCount="chargingSpecifications.length"
            @searchQueryChanged="setSearchQuery"
        >
            <template slot="filterTable">
                <FilterTable
                    :columns="filterData"
                    @filterAdded="onFilterAdd"
                />
            </template>
            <template slot="button">
                <div class="d-flex align-items-center">
                    <ResponseModalButton
                        :response="payoutsApiResponse"
                        :title="$i18n.t('charging.entities.plural.chargingSpecifications')"
                        class="mr-3"
                        data-test-id="response-modal-button"
                    />
                    <IconButton
                        :label="$i18n.t('productCatalog.downloadDetails')"
                        :icon="ICON_TYPES.DOWNLOAD"
                        data-test-id="btn-download-csv"
                        @iconClick="triggerDownloadingCSV"
                    />
                </div>
            </template>
            <template slot="headerButtons">
                <AppButton
                    v-if="isEditEnabled"
                    data-test-id="newChargingSpec"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('home.createNew')"
                    @click="newChargingSpec"
                />
            </template>
            <template slot="allFilters">
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="index => removeFilter(index, filtersLocalStorageKey)"
                    @removeAllFilters="removeAllFilters(filtersLocalStorageKey)"
                />
            </template>
            <template slot="table">
                <AppTable
                    :entities="sortedFilteredEntities"
                    :selectedEntityId="selectedEntityId"
                    :isSearchEnabled="true"
                    :innerSearchQuery="searchQueryForTable"
                    :columnsData="tableColumnsData"
                    :isDataLoading="isDataLoading"
                    :requestEntities="requestEntities"
                    :entityType="ENTITY_TYPES.CHARGING_SPECIFICATION"
                    :enableRowStateControls="isEditEnabled"
                    :multiselectEnabled="isPushVersionEnabled"
                    :multiselectActions="multiselectActions"
                    :emitEntityActions="true"
                    idColumn="id"
                    tableKey="charging/charging-specifications"
                    data-test-id="cs-table"
                    @selectEntity="onEntitySelected"
                    @returnCleanEntities="downloadOffersCSV"
                    @input="ent => setNumberOfItemsAfterSearch(ent.length, chargingSpecifications.length)"
                    @multiselectActionClick="onMultiselectActionClick"
                    @edit="onEdit"
                    @clone="onClone"
                    @delete="onDelete"
                    @readonly="onReadOnly"
                >
                    <template #entityVersion="{ entity }">
                        <EntityStatusIndicator :status="entity.entityVersion" />
                    </template>
                    <template
                        v-if="isPushVersionEnabled"
                        #customRowButtons="{ entity }"
                    >
                        <IconButton
                            v-if="entity.isVersionPushed"
                            :icon="ICON_TYPES.PUBLISH"
                            :label="$i18n.t('generic.chargingSpecificationTypes.push')"
                            class="mr-2"
                            data-test-id="entity-push-version-action"
                            @iconClick="showEntityPushChargingVersionModal(entity)"
                        />
                    </template>
                    <template #pushedVersion="{ entity }">
                        <AppLabel
                            v-if="entity.pushedVersion"
                            :title="entity.pushedVersion"
                            :color="getChargingVersionColor(entity)"
                        />
                    </template>
                </AppTable>
            </template>
            <template slot="overview">
                <MutationDialog
                    v-model="isMutationModalVisible"
                    :entities="getAffectedEntities(mutationDialogDataType)"
                    @input="clearMutationDialogDataType"
                />
                <EntityOverview
                    :entityType="ENTITY_TYPES.CHARGING_SPECIFICATION"
                    :entity="selectedEntity"
                    @closeOverview="isOverviewEnabled = false"
                >
                    <div :slot="`section-1-header`">
                        <OverviewHeader
                            v-if="selectedEntityId"
                            :entityName="getMultiLangFieldValueByLocale(selectedEntity.data.name, selectedLanguage)"
                            :entityType="ENTITY_TYPES.CHARGING_SPECIFICATION"
                        />
                        <LanguageSwitcher v-model="selectedLanguage" />
                    </div>

                    <div :slot="`section-1-content`">
                        <OverviewList
                            :lists="detailsSections"
                            @showDialog="showMutationDialog"
                        />
                        <OverviewList
                            v-if="getCSsurchargeDetails"
                            :lists="surchargeDetailsSections"
                            @showDialog="showMutationDialog"
                        />
                        <ListDropdownSection
                            v-if="eventCountersSection"
                            :section="eventCountersSection"
                            :capitalizedTitles="false"
                            :alwaysExpanded="true"
                            :useBigRowGaps="true"
                            class="pl-0 section"
                        >
                            <template #nameTooltip>
                                <AppTooltip
                                    :offset="10"
                                    :tooltipPosition="TOOLTIP_POSITION.top"
                                    contentWidth="15rem"
                                    class="ml-2"
                                >
                                    <template slot="label">
                                        <div class="info-icon" />
                                    </template>
                                    <template slot="content">
                                        <div
                                            v-t="'charging.chargingSpecifications.lastTimePushetVersionInfo'"
                                            class="tooltip-explanation-content"
                                        />
                                    </template>
                                </AppTooltip>
                            </template>
                        </ListDropdownSection>
                    </div>
                </EntityOverview>
            </template>
            <template slot="modal">
                <ChargingSpecPushVersionModal
                    :visible="showEntitiesPushVersionModal"
                    :entites="selectedRows"
                    @close="onClosePushVersionModal"
                />
            </template>
        </AbstractListPageWrapper>
    </div>
</template>

<script>
// Global
import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';

// Store
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import ChargingSpecPushVersionModal from '@/__new__/features/charging/chargingSpecification/ChargingSpecPushVersionModal.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ListDropdownSection from '@/components/partials/entityOverview/ListDropdownSection.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Http
import {
    chargingSpecPushVersion,
    getChargingSpecCount,
    deleteChargingSpecificationDraft,
    updateChargingSpecificationsState,
} from '@/__new__/services/dno/charging/http/chargingSpecifications';
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { entityStateReverseMap, StateActionsMapping } from '@/common/entityStateMapper';
import {
    getChargeText,
    chargeTypeToTextOverview,
    PERIOD_TYPES,
    MAP_CHARGING_SPEC_TYPES,
    CHARGING_TYPE_MAP_VALUES,
    ROW_STATE_ACTIONS_CS_TYPES,
    isPushedVersionAtCurrentVersion,
    sortChargingSpecVersionsCount,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { getFormattedAmount } from '@/common/formatting';
import {
    getMultiLangFieldValueByLocale,
    getProperlyFormattedMultilangFieldValue,
    getEntityConsumers,
    getMutationDialogTitleKey,
    formatMutationDialogEntities,
} from '@/common/entities/entityHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import tableColumnType from '@/common/filterTable';
import { STATUS_CODE_TO_STATUS_NAME_MAP, STATUS_CODES } from '@/common/commonHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import { EntityActions } from '@/common/baseStatesHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import { LABEL_COLOR } from '@/common/labelsHelper';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

export default {
    name: 'ChargingSpecifications',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        MutationDialog,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
        ChargingSpecPushVersionModal,
        IconButton,
        AppLabel,
        ListDropdownSection,
        AppTooltip,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            selectedEntityId: null,
            selectedEntity: null,
            entityEventCounters: {},
            isEventCountersSectionLoading: false,
            confirmationBox: false,
            selectedLanguage: '',
            permissionsService,
            entityType: ENTITY_TYPES.CHARGING_SPECIFICATION,
            editorRoute: RouteNames.CHARGING_CHARGING_SPECIFICATION_EDITOR,
            ENTITY_TYPES,
            TOOLTIP_POSITION,
            searchQueryForTable: '',
            sectionsList: [
                {
                    id: 1,
                    name: 'Details',
                    icon: 'details',
                },
            ],
            isDataLoading: false,
            isMutationModalVisible: false,
            mutationDialogDataType: '',
            isOverviewEnabled: false,
            ICON_TYPES,
            BUTTON_TYPES,
            isPushVersionEnabled: isUserAllowed('ChargingSpecificationsPushVersion'),
            allowDeleteEntities: isUserAllowed('ChargingDeleteEntities'),
            ROW_STATE_ACTIONS_CS_TYPES,
            selectedRows: [],
            showEntitiesPushVersionModal: false,
            confirmEntityVersionPush: new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            }),
            partyRoleOptions: {},
            filtersLocalStorageKey: 'charging-spec',
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [
            Getters.GET_CHARGING_SPECIFICATIONS,
            Getters.GET_CHARGING_SPECIFICATIONS_API_RESPONSE,
            Getters.GET_CHARGING_SPECIFICATION_BY_ID,
        ]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_CONTENT_TYPE_BY_ID,
            Getters.GET_POLICY_COUNTER_BY_IDS,
            Getters.GET_USAGE_COUNTERS_BY_IDS,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        isEditEnabled() {
            return (
                this.permissionsService.productCatalogWrite() && this.isUserAllowed('ChargingSpecificationsReadWrite')
            );
        },
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        sortedFilteredEntities() {
            return this.filteredEntitiesMixin(this.chargingSpecifications);
        },
        payoutsApiResponse() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_API_RESPONSE];
        },
        getChargingSpecificationsData() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS] || [];
        },
        chargingSpecifications() {
            return this.getChargingSpecificationsData
                .map(e => this.createCsDataObj(e))
                .sort((entity1, entity2) => entity1?.name?.localeCompare(entity2?.name));
        },
        allowedActions() {
            return StateActionsMapping[this.selectedEntity.state];
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            const selectedEntityData = this.selectedEntity.data;
            const isInUseByCsg = this.upperEntities()?.[ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP];

            const detailsSections = [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: 'ID',
                        },
                        {
                            value:
                                selectedEntityData.name[this.selectedLanguage] ||
                                selectedEntityData.name ||
                                this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('productCatalog.products.editor.serviceName'),
                        },
                        {
                            value: selectedEntityData?.description
                                ? selectedEntityData.description[this.selectedLanguage]
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: getChargeText(this.selectedEntity.data) || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.chargeType'),
                        },
                        {
                            value: this.selectedEntity.data.should_group_balance || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.groupBalance'),
                            formatter: 'boolean',
                        },
                        {
                            value: this.selectedEntity.chargingVersion,
                            label: this.$i18n.t('generic.version'),
                        },
                        ...(this.isPushVersionEnabled
                            ? [
                                  {
                                      value: this.selectedEntity.pushedVersion || this.$i18n.t('generic.N/A'),
                                      label: this.$i18n.t('charging.chargingSpecifications.lastPushetVersion'),
                                  },
                                  {
                                      value: this.selectedEntity.pushed_timestamp
                                          ? this.$localeLibrary.getFormattedDate(this.selectedEntity.pushed_timestamp)
                                          : this.$i18n.t('generic.N/A'),
                                      label: this.$i18n.t('charging.chargingSpecifications.lastTimePushetVersion'),
                                  },
                              ]
                            : []),
                    ],
                },
                {
                    name: this.$i18n.t('charging.charging'),
                    properties: [
                        {
                            value: this.selectedEntity.data.rate || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.rate'),
                        },
                        {
                            value:
                                this.getPeriodTypeLabel(this.selectedEntity.data.period_type) ||
                                this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.periodType'),
                        },
                        {
                            value: this.selectedEntity.data.rate_period
                                ? getFormattedAmount(
                                      // eslint-disable-next-line camelcase
                                      this.selectedEntity.data?.service_types?.[0],
                                      this.selectedEntity.data.rate_period,
                                  )
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.ratePeriod'),
                        },
                        {
                            value:
                                getFormattedAmount(
                                    // eslint-disable-next-line camelcase
                                    this.selectedEntity.data?.service_types?.[0],
                                    this.selectedEntity.data.unit_amount,
                                ) || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.unitAmount'),
                        },
                    ],
                },
            ];

            // Charging Spec used by:
            if (isInUseByCsg?.length) {
                detailsSections.push({
                    name: `${_upperFirst(this.entityType)} ${this.$i18n.t('generic.usedBy')}`,
                    properties: [
                        {
                            label: `${isInUseByCsg.length} ${this.$i18n.t(
                                'charging.entities.plural.chargingSpecificationsGroups',
                            )}`,
                            value: isInUseByCsg.length,
                            linkLabel: this.$i18n.t('generic.seeDetails'),
                            formatter: 'link',
                            type: ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP,
                        },
                    ],
                });
            }

            if (
                selectedEntityData.policy_counter_ids &&
                permissionsService.chargingPolicyCountersEnabled() &&
                this.isUserAllowed('PolicyCountersReadOnly')
            ) {
                detailsSections.push({
                    name: this.$i18n.t('charging.entities.plural.policyCounters'),
                    properties: [],
                });

                const policyCounters = this[Getters.GET_POLICY_COUNTER_BY_IDS](selectedEntityData.policy_counter_ids);
                policyCounters.forEach(pc => {
                    detailsSections[detailsSections.length - 1].properties.push({
                        label: this.$i18n.t('generic.name'),
                        value: pc?.data ? getMultiLangFieldValueByLocale(pc.data.name) : this.$i18n.t('generic.N/A'),
                    });
                });
            }

            if (
                selectedEntityData.usage_counters &&
                permissionsService.chargingUsageCountersEnabled() &&
                this.isUserAllowed('UsageCountersReadOnly')
            ) {
                detailsSections.push({
                    name: this.$i18n.t('charging.entities.plural.usageCounters'),
                    properties: [],
                });

                const usageCounters = this[Getters.GET_USAGE_COUNTERS_BY_IDS](selectedEntityData.usage_counters);
                usageCounters.forEach(uc => {
                    detailsSections[detailsSections.length - 1].properties.push({
                        label: this.$i18n.t('generic.name'),
                        value: uc ? getMultiLangFieldValueByLocale(uc.name) : this.$i18n.t('generic.N/A'),
                    });
                });
            }

            if (
                selectedEntityData.content_type_id &&
                permissionsService.chargingContentTypesEnabled() &&
                this.isUserAllowed('ContentTypesReadOnly')
            ) {
                const usageCounters = this[Getters.GET_CONTENT_TYPE_BY_ID](selectedEntityData.content_type_id);
                if (usageCounters) {
                    detailsSections.push({
                        name: this.$i18n.t('charging.contentTypes.name'),
                        properties: [
                            {
                                label: this.$i18n.t('generic.name'),
                                value:
                                    getMultiLangFieldValueByLocale(usageCounters.name) || this.$i18n.t('generic.N/A'),
                            },
                        ],
                    });
                }
            }

            return detailsSections;
        },
        eventCountersSection() {
            if (this.entityEventCounters) {
                const entityEventCountersArr = Object.keys(this.entityEventCounters) || [];
                return {
                    name: this.$i18n.t('charging.versionUsageCount'),
                    properties: entityEventCountersArr.length
                        ? entityEventCountersArr.map(key => ({
                              label: key.replace('version_', ''),
                              value: this.entityEventCounters[key] || 0,
                          }))
                        : [
                              {
                                  label: this.isEventCountersSectionLoading
                                      ? `${this.$i18n.t('generic.loading')}...`
                                      : this.$i18n.t('category.notInUse'),
                                  value: '',
                              },
                          ],
                };
            }
            return {};
        },
        tableColumnsData() {
            const baseColumns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'serviceTypes',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'serviceTypes',
                    limitedOptions: Array.from(new Set(this.sortedFilteredEntities.map(e => e.serviceTypes))),
                },
                {
                    name: this.$i18n.t('productCatalog.entities.charge'),
                    key: 'charge',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'charge',
                    limitedOptions: Array.from(new Set(this.sortedFilteredEntities.map(e => e.charge))),
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.update_time),
                    sortBy: entity => entity.update_time,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sortedFilteredEntities.map(e => e.entityVersionLabel))],
                },
            ];

            if (this.isPushVersionEnabled) {
                baseColumns.push({
                    name: this.$i18n.t('charging.chargingSpecifications.lastPushetVersion'),
                    key: 'pushedVersion',
                    field: 'pushedVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if (this.areChannelPartnersEnabled) {
                baseColumns.push({
                    name: this.$i18n.t('operateAPIs.channelPartner'),
                    key: 'channelPartnerLabel',
                    field: 'channelPartnerLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sortedFilteredEntities.map(e => e.channelPartnerLabel))].filter(
                        Boolean,
                    ),
                });
            }

            return baseColumns;
        },
        filterData() {
            return this.isPushVersionEnabled
                ? [
                      ...this.tableColumnsData,
                      {
                          name: this.$i18n.t('charging.chargingSpecifications.versionPushedStatus'),
                          key: 'versionPushedStatus',
                          field: 'versionPushedStatus',
                          filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                          limitedOptions: [...new Set(this.sortedFilteredEntities.map(e => e.versionPushedStatus))],
                      },
                  ]
                : this.tableColumnsData;
        },
        getCSsurchargeDetails() {
            return this.selectedEntity?.data?.surcharges ?? null;
        },
        surchargeDetailsSections() {
            if (!this.getCSsurchargeDetails) {
                return [];
            }

            const entityData = {
                ...this.selectedEntity.data,
                ...this.getCSsurchargeDetails,
                charge_type: 'true',
                unit_amount: null,
            };

            const detailsSections = [
                {
                    name: this.$i18n.t('charging.chargingSpecifications.surcharge'),
                    properties: [
                        {
                            value: getChargeText(entityData) || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.chargeType'),
                        },
                    ],
                },
            ];

            return detailsSections;
        },
        multiselectActions() {
            return [
                {
                    label: this.$i18n.t('charging.chargingSpecifications.pushCsVersion'),
                    buttonIcon: ICON_TYPES.PUBLISH,
                    refName: ROW_STATE_ACTIONS_CS_TYPES.PUSH_CS_VERSION,
                },
            ];
        },
        areChannelPartnersEnabled() {
            return isUserAllowed('OperateAPIsChannelPartnersRead');
        },
    },
    created() {
        this.initData();
    },
    methods: {
        isUserAllowed,
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS,
        ]),
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_CONTENT_TYPES,
            Actions.REQUEST_POLICY_COUNTERS,
            Actions.REQUEST_USAGE_COUNTERS,
        ]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;
                    const promises = [
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS](),
                    ];

                    if (
                        permissionsService.chargingPolicyCountersEnabled() &&
                        this.isUserAllowed('PolicyCountersReadOnly')
                    ) {
                        promises.push(this[Actions.REQUEST_POLICY_COUNTERS]());
                    }

                    if (
                        permissionsService.chargingUsageCountersEnabled() &&
                        this.isUserAllowed('UsageCountersReadOnly')
                    ) {
                        promises.push(this[Actions.REQUEST_USAGE_COUNTERS]());
                    }

                    if (
                        permissionsService.chargingContentTypesEnabled() &&
                        this.isUserAllowed('ContentTypesReadOnly')
                    ) {
                        promises.push(this[Actions.REQUEST_CONTENT_TYPES]());
                    }

                    if (this.areChannelPartnersEnabled) {
                        promises.push(this.getChannelPartners());
                    }

                    this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
                    await Promise.all(promises);
                    this.selectedLanguage = this[Getters.languageDefault].toString();
                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        this.isDataLoading = false;
                        this.$Progress.fail();
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        sortChargingSpecsList(list) {
            return list.sort((entity1, entity2) => entity1.data?.name?.localeCompare(entity2.data?.name));
        },
        getPeriodTypeLabel(periodType) {
            const periodTypeLabel = PERIOD_TYPES.find(t => t.id === periodType);
            return periodTypeLabel ? _upperFirst(_toLower(periodTypeLabel.label)) : this.$i18n.t('generic.N/A');
        },
        newChargingSpec() {
            this.$router.push({
                name: this.editorRoute,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onEntitySelected(entityId) {
            this.entityEventCounters = {};
            this.selectedEntity = this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](entityId);
            this.selectedEntityId = entityId;
            this.isOverviewEnabled = true;
            this.getChargingSpecCountData();
        },
        entityState(s) {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        showMutationDialog(type) {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value) {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getAffectedEntities(mutationDialogDataType) {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        getMutationDialogTitleKey,
        createCsDataObj(e) {
            const isVersionPushed = isPushedVersionAtCurrentVersion(e);
            return {
                ...e,
                id: e.id,
                name: getProperlyFormattedMultilangFieldValue(e.data.name).en,
                charge: getChargeText(e.data),
                version: e.version,
                updatedTime: new Date(e.update_time * 1000).toLocaleDateString(),
                stateLabel: this.entityState(e.state),
                chargeType: chargeTypeToTextOverview(CHARGING_TYPE_MAP_VALUES.get(e.data.charge_type)),
                serviceTypes: MAP_CHARGING_SPEC_TYPES?.[e.data.service_types]
                    ? onlyFirstLetterUppercase(MAP_CHARGING_SPEC_TYPES?.[e.data.service_types]?.toString())
                    : '',
                entityVersionLabel: STATUS_CODE_TO_STATUS_NAME_MAP.get(e.entityVersion),
                pushedVersion: e.pushedVersion || '',
                allowedActionsExternal:
                    e.state !== STATUS_CODES.NA
                        ? [
                              EntityActions.CLONE,
                              ...(this.allowDeleteEntities ? [EntityActions.DELETE] : []),
                              EntityActions.EDIT,
                              EntityActions.READ_ONLY,
                          ]
                        : [EntityActions.DELETE, EntityActions.EDIT],
                isVersionPushed,
                versionPushedStatus:
                    isVersionPushed || e.state === STATUS_CODES.NA
                        ? this.$i18n.t('charging.chargingSpecifications.unpushed')
                        : this.$i18n.t('charging.chargingSpecifications.pushed'),
                channelPartnerId: e.data.channel_partner_id,
                channelPartnerLabel: this.partyRoleOptions[e.data.channel_partner_id] || undefined,
            };
        },
        onMultiselectActionClick({ selectedRows }) {
            this.selectedRows = selectedRows.map(csId => {
                const csData = this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](csId);
                return this.createCsDataObj(csData);
            });
            this.showEntitiesPushVersionModal = true;
        },
        onClosePushVersionModal() {
            this.showEntitiesPushVersionModal = false;
            this.selectedRows = [];
        },
        showEntityPushChargingVersionModal(data) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.chargingSpecifications.confirmEntityVersionPush'),
                type: ALERT_TYPES.warning,
                buttons: [this.confirmEntityVersionPush],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.confirmEntityVersionPush.id) {
                    this.entityPushVersionConfirm(data);
                }
            });
        },
        entityPushVersionConfirm(data) {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();

                    await chargingSpecPushVersion([
                        {
                            id: data.id,
                            charging_version: data.chargingVersion,
                        },
                    ]);

                    this.$Progress.finish();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('charging.updatedReloadEntities', {
                            entity: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                        }),
                        type: ALERT_TYPES.success,
                    });

                    setTimeout(() => {
                        const { companyId } = this.$route.params;
                        this.$router
                            .push({
                                name: RouteNames.HOME_VIEW,
                                params: { companyId },
                            })
                            .then(() => {
                                this.$router.push({
                                    name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                                    params: {
                                        companyId,
                                    },
                                });
                            });
                    }, 1000);
                },
                {
                    errorHandler: e => {
                        this.$Progress.fail();
                        Sentry.captureException(e);

                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        getChargingVersionColor({ isVersionPushed }) {
            return isVersionPushed ? LABEL_COLOR.yellow : LABEL_COLOR.green;
        },
        async getChargingSpecCountData() {
            if (this.selectedEntity) {
                try {
                    this.isEventCountersSectionLoading = true;
                    const epochWeek = this.$localeLibrary.getCurrentEpochWeek();
                    const res = await getChargingSpecCount(`${this.selectedEntityId}_${epochWeek}`);
                    this.entityEventCounters = sortChargingSpecVersionsCount(res.data.data.event_category_counters);
                    this.isEventCountersSectionLoading = false;
                } catch {
                    this.entityEventCounters = {};
                    this.isEventCountersSectionLoading = false;
                }
            }
        },
        upperEntities(entityId = this.selectedEntityId) {
            return getEntityConsumers(entityId, ENTITY_TYPES.CHARGING_SPECIFICATION);
        },
        onEdit(entityId) {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId) {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId) {
            const { state, version } = this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](entityId);
            const { [ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP]: isInUseByCsg = [] } = this.upperEntities(entityId);

            this.showDeleteAlert(entityId, isInUseByCsg.length, state, version, [
                updateChargingSpecificationsState,
                deleteChargingSpecificationDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId) {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
        async getChannelPartners() {
            try {
                const response = await getPartyRoles('ChannelPartner');
                this.partyRoleOptions = response?.data?.reduce((acc, partyRole) => {
                    if (partyRole?.id) {
                        acc[partyRole.id] = partyRole?.name || '';
                    }
                    return acc;
                }, {});
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: 'channel partners',
                    }),
                });
            }
        },
        onFilterAdd(filter) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/colors';

.section {
    margin: 0rem 0rem $spacing-m $spacing-xs;
    padding-right: $spacing-xl;
}

.tooltip-explanation-content {
    text-transform: none;
    font-weight: 100;
}
</style>
