import { render, staticRenderFns } from "./ValidationRulesEditor.vue?vue&type=template&id=0c8b5096&scoped=true"
import script from "./ValidationRulesEditor.vue?vue&type=script&lang=ts"
export * from "./ValidationRulesEditor.vue?vue&type=script&lang=ts"
import style0 from "./ValidationRulesEditor.vue?vue&type=style&index=0&id=0c8b5096&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8b5096",
  null
  
)

export default component.exports