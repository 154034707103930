
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { ValidationRulesService } from '@/__new__/services/dno/pc/http/validationRules';

// Layout
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import EntityFetcherMixinVue from '@/__new__/features/pc/EntityFetcherMixin.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import tableColumnType from '@/common/filterTable';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ValidationRuleOverview from '@/__new__/features/pc/validationRules/ValidationRuleOverview.vue';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';

// Types
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    TargetTypeMapReverse,
    ValidationRuleState,
    ValidationRuleStateColorMap,
} from '@/__new__/features/pc/validationRules/common/validationRule';

// Misc
import { uniq } from 'lodash';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import localeLibrary from '@/common/locale/localeLibrary';
import { ValidationRule, getLabel } from '@/__new__/services/dno/pc/models/ValidationRule';

export default Vue.extend({
    name: 'ValidationRulesListPage',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        ValidationRuleOverview,
        EntityStatusIndicator,
    },
    mixins: [FilterTableMixin, EntityFetcherMixinVue],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            ENTITY_TYPES,
            validationRules: [] as ValidationRule[],
            selectedRule: null as ValidationRule | null,
            searchQueryForTable: '',
            isDataLoading: false,
            isOverviewEnabled: false,
            ValidationRuleState,
            ValidationRuleStateColorMap,
            filtersLocalStorageKey: `pc-validation-rules`,
        };
    },
    computed: {
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        offers(): ProductCatalogOfferModel[] {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        filteredValidationRules() {
            return this.filteredEntitiesMixin(this.validationRules);
        },
        ruleStates() {
            return new Map([
                [ValidationRuleState.UNAPPROVED, this.$t('generic.stateMap.unapproved')],
                [ValidationRuleState.APPROVED, this.$t('generic.stateMap.approved')],
                [ValidationRuleState.PAUSED, this.$t('generic.stateMap.paused')],
            ]);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.validationRules.map(e => e.type)),
                    formatter: (type: string) => getLabel(type),
                },
                {
                    name: this.$t('generic.state'),
                    key: 'rulestate',
                    field: 'state',
                    additionalFormatter: entity => this.ruleStates.get(entity.state),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.validationRules.map(e => e.state)),
                },
                {
                    name: this.$t('generic.startTime'),
                    key: 'start_time',
                    field: 'start_time',
                    filterType: tableColumnType.DATETIME,
                    formatter: localeLibrary.getFormattedDateAndTime,
                },
                {
                    name: this.$t('generic.endTime'),
                    key: 'end_time',
                    field: 'end_time',
                    filterType: tableColumnType.DATETIME,
                    formatter: localeLibrary.getFormattedDateAndTime,
                },
                {
                    name: this.$t('generic.updateTime'),
                    key: 'update_time',
                    field: 'update_time',
                    filterType: tableColumnType.DATETIME,
                    formatter: localeLibrary.getFormattedDateAndTime,
                },
                {
                    name: this.$t('generic.targetType'),
                    key: 'target_type',
                    field: 'target_type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.validationRules.map(e => e.target_type)),
                    formatter: (targetType: number) => getLabel(TargetTypeMapReverse[targetType]),
                },
                {
                    name: this.$t('productCatalog.validationRules.affects'),
                    key: 'affected_entities',
                    field: 'affected_entities',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: (rule: ValidationRule) => {
                        const offers = uniq([
                            ...rule.entity_ids,
                            ...rule.conflicting_entity_ids,
                            ...rule.requires_entity_ids,
                        ]).length;
                        return `${offers} ${this.$tc('productCatalog.entities.offer', offers)}`.toLocaleLowerCase();
                    },
                },
            ];
        },
    },
    created() {
        this.loadValidationRules();
        this.loadOffers();
        this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
    },
    methods: {
        loadValidationRules() {
            this.isDataLoading = true;
            ValidationRulesService.get()
                .then(({ data }) => {
                    this.validationRules = Object.values(data.validation_rule_by_id)
                        .map(ValidationRule.fromJson)
                        .filter(({ state }) => state !== ValidationRuleState.DELETED);
                })
                .catch(error => {
                    this.$alert(this.$t('productCatalog.validationRules.failedToGetValidationRules'));
                    Sentry.captureException(error);
                })
                .finally(() => {
                    this.isDataLoading = false;
                });
        },
        loadOffers() {
            try {
                this.fetchEntities(ENTITY_TYPES.OFFER);
            } catch (error) {
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
            }
        },
        confirmApprove(entity: ValidationRule) {
            this.$alert(this.$t('alerts.areYouSureApprove', { entityName: entity.name }), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.approve'),
                        alertType: this.$ALERT_TYPES.warning,
                        handler: () => this.onApprove(entity),
                    }),
                ],
            });
        },
        confirmPause(entity: ValidationRule) {
            this.$alert(this.$t('alerts.areYouSurePause', { entityName: entity.name }), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.pause'),
                        alertType: this.$ALERT_TYPES.warning,
                        handler: () => this.onPause(entity),
                    }),
                ],
            });
        },
        confirmDelete(entity: ValidationRule) {
            this.$alert(this.$t('alerts.areYouSureDeleteEntity', { entityName: entity.name }), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.delete'),
                        alertType: this.$ALERT_TYPES.warning,
                        handler: () => this.onDelete(entity),
                    }),
                ],
            });
        },
        onApprove(entity: ValidationRule) {
            this.$Progress.start();
            ValidationRulesService.approve(entity)
                .then(() => {
                    this.loadValidationRules();
                    this.$alert(this.$t('productCatalog.validationRules.approveSuccess', { name: entity.name }), {
                        type: this.$ALERT_TYPES.success,
                    });
                    this.$Progress.finish();
                })
                .catch(error => {
                    this.$Progress.fail();
                    this.$alert(this.$t('productCatalog.validationRules.approveFailed'));
                    Sentry.captureException(error);
                });
        },
        onPause(entity: ValidationRule) {
            this.$Progress.start();
            ValidationRulesService.pause(entity)
                .then(() => {
                    this.loadValidationRules();
                    this.$alert(this.$t('productCatalog.validationRules.pauseSuccess', { name: entity.name }), {
                        type: this.$ALERT_TYPES.success,
                    });
                    this.$Progress.finish();
                })
                .catch(error => {
                    this.$Progress.fail();
                    this.$alert(this.$t('productCatalog.validationRules.pauseFailed'));
                    Sentry.captureException(error);
                });
        },
        onDelete(entity: ValidationRule) {
            this.$Progress.start();
            ValidationRulesService.delete(entity)
                .then(() => {
                    this.validationRules = this.validationRules.filter(({ id }) => id !== entity.id);
                    this.$alert(this.$t('productCatalog.validationRules.deletedSuccessfully', { name: entity.name }), {
                        type: this.$ALERT_TYPES.success,
                    });
                    this.$Progress.finish();
                })
                .catch(error => {
                    this.$Progress.fail();
                    this.$alert(this.$t('productCatalog.validationRules.failedToDeleteValidationRule'));
                    Sentry.captureException(error);
                });
        },
        onEntitySelected({ id }: ValidationRule) {
            const selected = this.validationRules.find(rule => rule.id === id);
            if (selected) {
                this.selectedRule = selected;
                this.isOverviewEnabled = true;
            }
        },
        editorRoute(entity?: ValidationRule) {
            return {
                name: RouteNames.VALIDATIONS_RULES_EDITOR,
                params: {
                    id: entity?.id,
                    companyId: this.$route.params.companyId,
                },
            };
        },
        openEditor(entity?: ValidationRule) {
            this.$router.push(this.editorRoute(entity));
        },
        onFilterAdd(filter: any) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
    },
});
