import http, { type DnoResponseBase } from '@/http/index';
import type { AxiosPromise } from 'axios';
import type {
    ClusterResponse,
    JobResponse,
    JobPausedResponse,
    ClusterJob,
    Job,
} from '@/__new__/features/settings/common/applicationManagerHelper';
import type { GetJobStaticExportResponse } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

const objectDistributorJcmPathPrefix =
    '/apiinvoker/object-distributor/management/configs/operators/$operator_name/jobs';

export function getAllObjectDistributorConfigs(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'GET',
        url: `${objectDistributorJcmPathPrefix}?state=enabled&response-model=all`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export interface ApplicationClusterApiResponse extends DnoResponseBase {
    data: {
        data: ClusterResponse[];
    };
}

export function getApplicationCluster(appName: string): AxiosPromise<ApplicationClusterApiResponse> {
    return http({
        method: 'GET',
        url: `/apiinvoker/${appName}/management/configs/cluster/scheduler`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export function updateSchedulerState(appName: string, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `/apiinvoker/${appName}/management/configs/cluster/scheduler`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data,
    });
}

export interface ClusterJobsApiResponse extends DnoResponseBase {
    data: {
        total_pages: number;
        total_elements: number;
        page_number: number;
        size: number;
        items: JobResponse[] | Record<string, never>;
    };
}

export interface ClusterJobsPauseApiResponse extends DnoResponseBase {
    data: {
        data: JobPausedResponse[] | Record<string, never>;
    };
}

export function getClusterJobs(appName: string): AxiosPromise<ClusterJobsApiResponse> {
    return http({
        method: 'GET',
        url: `/apiinvoker/${appName}/management/configs/operators/$operator_name/jobs?state=enabled`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export function getClusterJobsPauseState(appName: string): AxiosPromise<ClusterJobsPauseApiResponse> {
    return http({
        method: 'GET',
        url: `/apiinvoker/${appName}/management/configs/scheduler/operators/$operator_name/jobs/pause`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export interface ClusterJobApiResponse extends DnoResponseBase {
    data: JobResponse;
}

export function getClusterJob(appName: string, jobId: string): AxiosPromise<ClusterJobApiResponse> {
    return http({
        method: 'GET',
        url: `/apiinvoker/${appName}/management/configs/operators/$operator_name/jobs/${jobId}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export function updateClusterJob(appName: string, data: any, godeMode = false): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `/apiinvoker/${appName}/management/configs/operators/$operator_name/jobs:apply${
            godeMode ? '?force=true' : ''
        }`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data,
    });
}

export function deleteClusterJob(appName: string, id: Job['id']): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'DELETE',
        url: `/apiinvoker/${appName}/management/configs/operators/$operator_name/jobs/${id}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}

export function updateJobsPausedState(appName: string, data: ClusterJob[]): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `/apiinvoker/${appName}/management/configs/scheduler/operators/$operator_name/jobs/pause`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
            data,
        },
    });
}

export function getJobStaticExportById(app: string, id: Job['id']): AxiosPromise<GetJobStaticExportResponse> {
    return http(`/apiinvoker/${app}/management/configs/operators/$operator_name/jobs/${id}/export`);
}

export default {
    getAllObjectDistributorConfigs,
    getApplicationCluster,
    updateSchedulerState,
    getClusterJob,
    getClusterJobs,
    getClusterJobsPauseState,
    updateClusterJob,
    deleteClusterJob,
    updateJobsPausedState,
    getJobStaticExportById,
};
