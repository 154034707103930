
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppTable from '@/components/partials/AppTable.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import ApplicationData from '@/__new__/services/dno/ossdevedge/models/ApplicationData';
import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

// HTTP
import ossdevedgeHTTP from '@/__new__/services/dno/ossdevedge/http/ossdevedge';

// eslint-disable-next-line no-shadow
enum APPLICATION_STATES {
    DELETED = 0,
    PENDING_REGISTRATION = 1,
    REGISTRATION_FAILED = 2,
    ACTIVE = 3,
    PENDING_DELETION = 4,
    DELETION_FAILED = 5,
    UPDATING = 6,
    UPDATE_FAILED = 7,
}

const APPLICATION_STATES_INDICATOR_MAP = new Map([
    [APPLICATION_STATES.DELETED, LABEL_COLOR.red],
    [APPLICATION_STATES.PENDING_REGISTRATION, LABEL_COLOR.yellow],
    [APPLICATION_STATES.REGISTRATION_FAILED, LABEL_COLOR.red],
    [APPLICATION_STATES.ACTIVE, LABEL_COLOR.green],
    [APPLICATION_STATES.PENDING_DELETION, LABEL_COLOR.yellow],
    [APPLICATION_STATES.DELETION_FAILED, LABEL_COLOR.red],
    [APPLICATION_STATES.UPDATING, LABEL_COLOR.yellow],
    [APPLICATION_STATES.UPDATE_FAILED, LABEL_COLOR.red],
]);

const APPLICATION_STATES_TO_STATUS_NAME_MAP = new Map([
    [APPLICATION_STATES.DELETED, i18n.t('generic.stateMap.deleted')],
    [APPLICATION_STATES.PENDING_REGISTRATION, i18n.t('generic.stateMap.pendingRegistration')],
    [APPLICATION_STATES.REGISTRATION_FAILED, i18n.t('generic.stateMap.registrationFailed')],
    [APPLICATION_STATES.ACTIVE, i18n.t('generic.stateMap.active')],
    [APPLICATION_STATES.PENDING_DELETION, i18n.t('generic.stateMap.pendingDeletion')],
    [APPLICATION_STATES.DELETION_FAILED, i18n.t('generic.stateMap.deletionFailed')],
    [APPLICATION_STATES.UPDATING, i18n.t('generic.stateMap.updating')],
]);

export default {
    name: 'ApplicationTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
        EntityStatusIndicator,
        AppTable,
    },
    data() {
        return {
            entities: [],
            apiResponse: {},

            selectedEntity: {},
            showAdditionalSidebar: false,

            APPLICATION_STATES_INDICATOR_MAP,
            APPLICATION_STATES_TO_STATUS_NAME_MAP,
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.applicationTile.applicationId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(s => s.status))],
                },
                {
                    name: this.$i18n.t('generic.updatedAt'),
                    key: 'updateTimestampFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.updateTimestamp),
                    sortBy: entity => entity.updateTimestamp,
                    field: 'updateTimestamp',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
        additionalSidebarColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.applicationTile.serviceId'),
                    key: 'naasServiceId',
                    field: 'naasServiceId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.applicationTile.purpose'),
                    key: 'purpose',
                    field: 'purpose',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        apiRedirectedUrisJoined() {
            if (this?.selectedEntity?.apiRedirectUris) {
                return this.selectedEntity.apiRedirectUris.join(', ');
            }
            return '';
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await ossdevedgeHTTP.getApplications(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    this.apiResponse = response;

                    if (response?.data?.apps) {
                        this.entities = response.data.apps.map(el => {
                            return new ApplicationData(ApplicationData.mapResourceFromBE(el));
                        });
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.dataTransferFetchError'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectEntity(id) {
            this.selectedEntity = this.entities.find(el => el.id === id);
            this.showAdditionalSidebar = true;
        },
    },
};
