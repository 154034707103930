
import Vue, { PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Helpers
import type { ClusterResponse, ClusterJob } from '@/__new__/features/settings/common/applicationManagerHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'ApplicationDiffModal',
    components: {
        AppDialogV2,
        AppIcon,
        EntityStatusIndicator,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        app: {
            type: String,
            required: true,
        },
        clusters: {
            type: Array as PropType<ClusterResponse[]>,
            default: () => [],
        },
        jobs: {
            type: Array as PropType<ClusterJob[]>,
            default: () => [],
        },
    },
    data() {
        return {
            ICON_TYPES,
            stateMap: new Map([
                [Number(true), this.$t('generic.stateMap.active')],
                [Number(false), this.$t('generic.stateMap.paused')],
            ]),
            stateMapColor: new Map([
                [Number(true), LABEL_COLOR.green],
                [Number(false), LABEL_COLOR.red],
            ]),
        };
    },
    computed: {
        title(): string {
            return `${this.$t('generic.application')}: ${this.app}`;
        },
    },
    methods: {
        onCloseModal(): void {
            this.$emit('input', false);
        },
        onSave(): void {
            this.$emit('save');
        },
    },
});
