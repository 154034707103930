
import Vue from 'vue';

// Components
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDropdownMenu, { type MenuAction } from '@/components/partials/AppDropdownMenu.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import ApplicationDiffModal from '@/__new__/features/settings/applicationManager/ApplicationDiffModal.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import JobEditModal from '@/__new__/features/settings/applicationManager/JobEditModal.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import SinkConfigDialog from '@/__new__/features/sinkConfigs/SinkConfigDialog.vue';

// Mixins
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import entityEditorMixin from '@/common/entityEditorMixin';

// Http
import pipelinesHTTP from '@/__new__/services/dno/pipelines/http/pipelines';
import remoteConfigHTTP from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

// Helpers
import { isEqual, sortBy, difference } from 'lodash';
import Button from '@/common/button/Button';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { uuidV4 } from '@/common/utils';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import {
    type AplicationObject,
    type ClusterResponse,
    type JobResponse,
    type JobPausedResponse,
    type JobDefinition,
    type ClusterJob,
    Job,
    CLUSTER_JOB_ACTION,
    PRESELECTED_CLUSTER,
} from '@/__new__/features/settings/common/applicationManagerHelper';

export default Vue.extend({
    name: 'ApplicationManager',
    components: {
        AbstractSubSidebarPageWrapper,
        AppToggleV2,
        AppTable,
        AppButton,
        AppDropdownMenu,
        AppIcon,
        AppToggle,
        ApplicationDiffModal,
        JobEditModal,
        SubSidebar,
        SinkConfigDialog,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin, CountControlMixin, supportButtonMixin, entityEditorMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            ICON_COLORS,
            entries: {},
            // app
            aplications: [] as string[],
            clusterNotToShow: ['api-config'] as string[],
            selectedAplication: '' as string,
            // cluster
            aplicationsClusters: [] as ClusterResponse[],
            originalAplicationsClusters: [] as ClusterResponse[],
            selectedCluster: '' as string,
            // job
            clusterJobs: [] as ClusterJob[],
            originalClusterJobs: [] as ClusterJob[],
            useGodeMode: false as boolean,
            selectedJob: new Job(),
            selectedJobYaml: '',
            isEditModalOpen: false as boolean,
            isExportModalOpen: false as boolean,
            isLoadingApps: false as boolean,
            isLoadingClusters: false as boolean,
            isLoadingJobs: false as boolean,
            isDiffModalOpen: false as boolean,
            clusterJobDeleteBtn: new Button({
                label: this.$t('generic.delete'),
                handler: this.deleteJob.bind(this),
            }),
        };
    },
    computed: {
        applicationsData(): AplicationObject[] {
            return sortBy(
                this.aplications.map(el => ({
                    id: uuidV4(),
                    name: el.startsWith('lf-') ? el.substring(3) : el,
                })),
                'name',
            );
        },
        aplicationsClustersColumns(): TableColumn[] {
            return [
                {
                    name: this.$t('pipelines.applicationManager.clusters'),
                    key: 'cluster_name',
                    field: 'cluster_name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        clusterJobsColumns(): TableColumn[] {
            return [
                {
                    name: this.$t('pipelines.applicationManager.jobs'),
                    key: 'name',
                    field: 'name',
                    mapper: e => e?.job?.name || e?.id,
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        aplicationsColumns(): TableColumn[] {
            return [
                {
                    name: this.$t('pipelines.applicationManager.applications'),
                    key: 'name',
                    field: 'name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        clustersDiff(): ClusterResponse[] {
            return difference(this.aplicationsClusters, this.originalAplicationsClusters);
        },
        jobsDiff(): ClusterJob[] {
            return difference(this.clusterJobs, this.originalClusterJobs);
        },
        anyAnyClustersOrJobsChanges(): boolean {
            return !!this.clustersDiff.length || !!this.jobsDiff.length;
        },
    },
    created() {
        this.getApplications();
    },
    methods: {
        clearClusterData(): void {
            this.aplicationsClusters = [];
            this.originalAplicationsClusters = [];
            this.selectedCluster = '';
        },
        clearJobData(): void {
            this.clusterJobs = [];
            this.originalClusterJobs = [];
            this.selectedJob = new Job();
        },
        getApplications(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isLoadingApps = true;
                    const { data: { data = [] } = {} } = await remoteConfigHTTP.getServices({
                        labels: { 'lotusflare.com/jcm': 'true' },
                    });

                    this.aplications = Array.isArray(data) ? data : [];
                    this.isLoadingApps = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingApps = false;
                        this.$alert(
                            this.$t('pipelines.applicationManager.somethingWentWrongWhileFetchingApplications'),
                        );
                    },
                },
            );
        },
        onSelectedAplication(appName: string): void {
            if (appName === this.selectedAplication) {
                return;
            }

            if (this.selectedAplication && this.anyAnyClustersOrJobsChanges) {
                this.$alert(this.$t('pipelines.applicationManager.confirmAplicationChange'), {
                    type: this.$ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$t('generic.change'),
                            handler: this.confirmAplicationChange.bind(this, appName),
                        }),
                    ],
                });
            } else {
                this.confirmAplicationChange(appName);
            }
        },
        async confirmAplicationChange(appName: string): Promise<void> {
            this.clearClusterData();
            this.clearJobData();
            this.selectedAplication = appName;

            await this.getClusterData();
            this.selectDefaultCluster();
        },
        getClusterData(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isLoadingClusters = true;
                    const { data: { data = [] } = {} } = await pipelinesHTTP.getApplicationCluster(
                        this.selectedAplication,
                    );
                    this.aplicationsClusters = (data as ClusterResponse[]).filter(
                        ({ cluster_name: clusterName }) => !this.clusterNotToShow.includes(clusterName),
                    );

                    this.originalAplicationsClusters = [...this.aplicationsClusters];
                    this.isLoadingClusters = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingClusters = false;
                        this.selectedAplication = '';
                        this.$alert(this.$t('pipelines.errors.fetchSchedulerState'));
                    },
                },
            );
        },
        async getClusterJobsData(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.clearJobData();
                    this.isLoadingJobs = true;
                    const [
                        { data: { data: jobsWithPauseState = [] } = {} },
                        { data: { data: { items: jobsData = [] } = {} } = {} },
                    ] = await Promise.all([
                        pipelinesHTTP.getClusterJobsPauseState(this.selectedAplication),
                        pipelinesHTTP.getClusterJobs(this.selectedAplication),
                    ]);

                    if (![jobsWithPauseState, jobsData].every(Array.isArray)) {
                        throw new Error('BE: invalid data format');
                    }

                    const getJobData = (id: JobDefinition['id']) => {
                        const job = (jobsData as JobResponse[]).find(j => j.job_definition?.id === id);
                        return Job.mapFromBE(job as JobResponse);
                    };

                    const formatted = (jobsWithPauseState as JobPausedResponse[])
                        .filter(({ cluster_name: jobClusterName }) => jobClusterName === this.selectedCluster)
                        .map(j => ({
                            ...j,
                            id: j.job_id,
                            is_paused: !j.is_paused,
                            job: getJobData(j.job_id),
                        }));

                    this.clusterJobs = sortBy(formatted, [j => j.job.name.toLowerCase()]);
                    this.originalClusterJobs = [...this.clusterJobs];
                    this.isLoadingJobs = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingJobs = false;
                        this.$alert(this.$t('pipelines.errors.fetchClusterJobs'));
                    },
                },
            );
        },
        onSelectedCluster({ cluster_name: clusterName, enabled }: ClusterResponse): void {
            if (enabled === false) {
                this.clearJobData();
                this.selectedCluster = clusterName;
                return;
            }

            if (clusterName === this.selectedCluster || !enabled) {
                return;
            }

            if (this.selectedCluster && this.jobsDiff.length) {
                this.$alert(this.$t('pipelines.applicationManager.confirmClusterChange'), {
                    type: this.$ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$t('generic.change'),
                            handler: this.confirmClusterChange.bind(this, clusterName),
                        }),
                    ],
                });
            } else {
                this.confirmClusterChange(clusterName);
            }
        },

        async confirmClusterChange(clusterName: string): Promise<void> {
            this.selectedCluster = clusterName;
            await this.getClusterJobsData();
        },
        onUpdateCluster(entity: ClusterResponse, enabled: boolean): void {
            const index = this.aplicationsClusters.findIndex(el => el.cluster_name === entity.cluster_name);

            if (!enabled) {
                this.clearJobData();
            } else if (entity.cluster_name === this.selectedCluster) {
                this.confirmClusterChange(this.selectedCluster);
            }

            this.$set(this.aplicationsClusters, index, {
                ...entity,
                enabled,
            });
        },
        onUpdateJob(entity: ClusterJob, val: boolean): void {
            const index = this.clusterJobs.findIndex(el => el.id === entity.id);
            this.$set(this.clusterJobs, index, {
                ...entity,
                is_paused: val,
            });
        },
        onSave(): void {
            this.$alert(this.$t('pipelines.applicationManager.saveChanges'), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.save'),
                        handler: async () => {
                            if (this.clustersDiff.length) {
                                await this.confirmSaveClusters();
                            }

                            if (this.jobsDiff.length) {
                                await this.confirmSaveJobs();
                            }

                            this.clearClusterData();
                            this.clearJobData();
                            this.selectedAplication = '';
                            this.isDiffModalOpen = false;

                            this.$showSuccessAlert({
                                message: this.$t('pipelines.applicationManager.saveChangesSuccess'),
                            });
                        },
                    }),
                ],
            });
        },
        async confirmSaveClusters(): Promise<void> {
            await this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    await pipelinesHTTP.updateSchedulerState(this.selectedAplication, this.aplicationsClusters);
                    this.$Progress.finish();
                },
                {
                    errorHandler: (e: any) => {
                        this.$Progress.fail();
                        this.$alert(e?.message);
                    },
                },
            );
        },
        async confirmSaveJobs(): Promise<void> {
            await this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();

                    const clusterJobs = this.clusterJobs
                        .filter((job, i) => !isEqual(job, this.originalClusterJobs[i]))
                        .map(job => ({
                            ...job,
                            is_paused: !job.is_paused,
                        }));

                    await pipelinesHTTP.updateJobsPausedState(this.selectedAplication, clusterJobs);
                    this.$Progress.finish();
                },
                {
                    errorHandler: (e: any) => {
                        this.$Progress.fail();
                        this.$alert(e?.message);
                    },
                },
            );
        },
        confirmDeleteJob(jobData: Job): void {
            this.selectedJob = jobData;
            this.$alert(this.$t('pipelines.applicationManager.confirmJobDelete', jobData), {
                type: this.$ALERT_TYPES.warning,
                buttons: [this.clusterJobDeleteBtn],
            });
        },
        deleteJob(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    await pipelinesHTTP.deleteClusterJob(this.selectedAplication, this.selectedJob.id);
                    await this.getClusterJobsData();
                    this.selectedJob = new Job();
                },
                {
                    errorHandler: () =>
                        this.$alert(
                            this.$t(
                                'pipelines.applicationManager.somethingWentWrongWhileDeletingJob',
                                this.selectedJob,
                            ),
                        ),
                },
            );
        },
        editJob(jobData: Job): void {
            this.selectedJob = jobData;
            this.isEditModalOpen = true;
        },
        onCloseJobEditModal(): void {
            this.selectedJob = new Job();
            this.isEditModalOpen = false;
        },
        exportJob(job: Job): void {
            this.getJobAsYaml(job.id);
            this.selectedJob = job;
            this.isExportModalOpen = true;
        },
        onCloseExportModal() {
            this.selectedJobYaml = '';
            this.selectedJob = new Job();
        },
        async selectDefaultCluster(): Promise<void> {
            if (
                this.aplicationsClusters.find(
                    ({ cluster_name: name, enabled }) => name === PRESELECTED_CLUSTER && enabled,
                )
            ) {
                await this.confirmClusterChange(PRESELECTED_CLUSTER);
            }
        },
        getEntityActions(entity: ClusterJob): MenuAction[] {
            const actions = [
                {
                    id: CLUSTER_JOB_ACTION.EDIT,
                    icon: ICON_TYPES.EDIT,
                    label: this.$t('generic.edit'),
                    handler: this.editJob.bind(this, entity.job),
                },
                {
                    id: CLUSTER_JOB_ACTION.EXPORT,
                    icon: ICON_TYPES.DOWNLOAD,
                    label: this.$t('generic.export'),
                    handler: this.exportJob.bind(this, entity.job),
                },
            ];

            if (!entity.job.isGlobal) {
                actions.push({
                    id: CLUSTER_JOB_ACTION.DELETE,
                    icon: ICON_TYPES.DELETE,
                    label: this.$t('generic.delete'),
                    handler: this.confirmDeleteJob.bind(this, entity.job),
                });
            }

            return actions;
        },
        getJobAsYaml(id: string): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    if (this.selectedJobYaml) {
                        return;
                    }
                    const { data: yamlData } = await pipelinesHTTP.getJobStaticExportById(this.selectedAplication, id);
                    this.selectedJobYaml = yamlData?.data?.toString();
                },
                {
                    errorHandler: () => {
                        this.selectedJobYaml = this.$t('generic.error');
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
    },
});
