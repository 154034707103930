
import Vue from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default Vue.extend({
    name: 'Dataflows',
    components: {
        AbstractListPageWrapper,
        AppButton,
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            searchQueryForTable: '',
            selectedEntityId: null,
            isOverviewEnabled: false,
            isCreateNewEnabled: isUserAllowed('DataflowWrite'),
        };
    },
    methods: {
        goToEditPage(): void {
            this.$router.push({
                name: RouteNames.DATAFLOWS_EDITOR,
                params: { companyId: this.$route.params?.companyId },
            });
        },
    },
});
