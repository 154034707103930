import axios from '@/http/index';

export function getExternalUsers() {
    const options = {
        method: 'GET',
        url: '/admin/externalaccount',
        headers: {
            'content-type': 'application/json',
        },
    };
    return axios(options);
}

export function getUsers() {
    const options = {
        method: 'GET',
        url: '/admin/account',
        headers: {
            'content-type': 'application/json',
        },
    };
    return axios(options);
}

export function addUser(data) {
    const options = {
        method: 'POST',
        url: '/admin/account',
        headers: {
            'content-type': 'application/json',
        },
        data,
    };
    return axios(options);
}

export function updateUser(data) {
    const options = {
        method: 'PUT',
        url: '/admin/account',
        headers: {
            'content-type': 'application/json',
        },
        data,
    };
    return axios(options);
}

export function deleteUser(data) {
    const options = {
        method: 'DELETE',
        url: '/admin/account',
        headers: {
            'content-type': 'application/json',
        },
        data,
    };
    return axios(options);
}

export function resetPassword(data) {
    const options = {
        method: 'POST',
        url: '/admin/resetpassword',
        headers: {
            'content-type': 'application/json',
        },
        data,
    };
    return axios(options);
}

export default {};
