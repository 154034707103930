
import Vue from 'vue';
import AppTable from '@/components/partials/AppTable.vue';
import { type TableColumn } from '@/common/filterTable';
import remoteConfigHttp from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import { getEsimInventory } from '@/__new__/services/dno/sim/http/inventory';

export default Vue.extend({
    name: 'EsimRemainingOffer',

    components: {
        AppTable,
    },
    props: {},
    data() {
        return {
            enableRowStateControls: true,
            isDataLoading: true,
            searchQueryHasAccessTable: '',
            esimOfferInventorys: [],
        };
    },
    computed: {
        esimInventoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('operator.offerName'),
                    key: 'offerName',
                    field: 'offerName',
                },
                {
                    name: this.$i18n.t('operator.remainingProfiles'),
                    key: 'remainingCount',
                    field: 'remainingCount',
                },
            ];
        },
    },
    created() {
        this.getEsimInventoryData();
    },
    methods: {
        async getEsimInventoryData() {
            await this.$withProgressBar(
                async () => {
                    const {
                        data: {
                            data: { whitelist_offer_info: whitelistOfferInfo },
                        },
                    } = await remoteConfigHttp.getRemoteConfig();

                    if (!whitelistOfferInfo?.value) {
                        return;
                    }

                    const whiteListOfferObj = JSON.parse(whitelistOfferInfo.value);
                    const endWithEsim = Object.keys(whiteListOfferObj.short_codes_tb || {}).filter(shortCode =>
                        shortCode.endsWith('_eSIM'),
                    );
                    const {
                        data: { data: offerInventory },
                    } = await getEsimInventory({
                        esim_indexes: endWithEsim,
                    });
                    this.isDataLoading = false;
                    this.esimOfferInventorys = Object.keys(offerInventory).map(offerName => ({
                        offerName,
                        remainingCount: offerInventory[offerName],
                    }));
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                    },
                },
            );
        },
    },
});
