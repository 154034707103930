/* eslint-disable camelcase */
import invert from 'lodash/invert';
import moment from 'moment';
import localeLibrary, { NO_END_TIME_EPOCH } from '@/common/locale/localeLibrary';
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';
import { isoToEpoch } from '@/common/timeHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { offerReservedKeys } from '@/__new__/features/pc/common/entitiesReservedKeys';
import CompatibilityRuleModel from './CompatibilityRuleModel';
import { STATUS_CODES_OPERATIONS, STATUS_CODES } from '@/common/commonHelper';
import { formatDataAmount, unitTypes } from '@/common/formatting';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';

export const PRICE_TYPES_MAP = {
    FLAT: 'flat',
    TIERED: 'tiered',
    VOLUME: 'volume',
};

export const PRICE_TYPES_TO_ENUM = {
    [PRICE_TYPES_MAP.FLAT]: 1,
    [PRICE_TYPES_MAP.TIERED]: 2,
    [PRICE_TYPES_MAP.VOLUME]: 3,
};

export const ENUM_TO_PRICE_TYPE = invert(PRICE_TYPES_TO_ENUM);

export const defaultDataPriceMultiplier = 1024 * 1024;

class ProductCatalogOfferModel extends ProductCatalogEntityBase {
    startTime;

    startTimeFormatted;

    endTime;

    endTimeFormatted;

    price;

    priceType;

    priceTypeString;

    currencyType;

    amountRange;

    categories;

    categoriesDisplay;

    childEntities;

    compatibilityRules;

    operation;

    properties;

    restrictedOffer;

    shortCode;

    constructor(entityJson) {
        super(entityJson);
        const entityData = entityJson.data;

        // Offer specific data to add on top of ProductCatalogEntityBase constructur data
        // required ones

        // for some offers we don't have start time
        // to avoid some bug, 0 is set
        if (!entityData.start_time) {
            this.startTime = 0;
        } else {
            this.startTime = entityData.start_time;
        }

        this.startTimeFormatted = localeLibrary.getFormattedDateAndTime(entityData.start_time || null);

        // for some offers we don't have end time
        // to avoid some bug, maximum date is set
        if (!entityData.end_time || entityData.end_time === 0) {
            this.endTime = NO_END_TIME_EPOCH;
        } else {
            this.endTime = entityData.end_time;
        }

        this.endTimeFormatted = localeLibrary.getFormattedDateAndTime(
            entityData.end_time === NO_END_TIME_EPOCH ? null : entityData.end_time || null,
        );

        if (entityData.amounts?.amount_range) {
            this.amountRange = entityData.amounts.amount_range;

            // adding max field based on min field of next object from array,
            // under condition that next object exists
            for (let i = 0; i < this.amountRange.length; i += 1) {
                if (this.amountRange[i + 1]) {
                    this.amountRange[i] = {
                        ...this.amountRange[i],
                        max: (Number(this.amountRange[i + 1].range_start) - 1).toString(),
                    };
                }
            }
        }

        this.shortCode = entityData?.short_code || '';
        this.priceType = entityData.amounts?.amount_type || 1;
        this.priceTypeString = ENUM_TO_PRICE_TYPE[this.priceType];
        this.currencyType = entityData?.amounts?.currency_type || null;
        this.price = Number(entityData.amounts?.primary);
        this.priceFormatted =
            this.currencyType === currencyTypes.DATA
                ? formatDataAmount(Number(this.price))
                : localeLibrary.getFormattedAmount(this.price);
        this.categories = (entityData.relationships ?? [])
            .filter(relation => relation.type === ENTITY_TYPES.CATEGORY)
            .map(r => r.id);
        this.categoriesDisplay = entityData.categoriesDisplay;
        // overwriting childEntities from ProductCatalogEntityBaseModel since
        // offers categories are put into relationships field now
        this.childEntities = entityData.relationships?.length
            ? entityData.relationships.filter(relation => !this.categories.includes(relation.id))
            : [];

        // channel partner parsing for filtering
        this.channelPartnerId = entityJson.data.channel_partner_id || '';

        // seting template engine properties data here
        this.properties = Object.keys(entityJson.data)
            .filter(key => !offerReservedKeys.includes(key))
            .reduce((result, key) => {
                result[key] = entityJson.data[key];
                return result;
            }, {});

        this.compatibilityRules =
            entityJson.compatibility_rules && entityJson.compatibility_rules.length
                ? entityJson.compatibility_rules.map(r => CompatibilityRuleModel.fromRaw(r))
                : [];

        this.restrictedOffer = entityData.restrictedOffer || 'false';

        // operation handling
        // default value (for unapproved offer as well)
        this.operation = STATUS_CODES_OPERATIONS.NA;
        // setting another values based on the conditions below
        if (entityJson.state === STATUS_CODES.APPROVED || entityJson.state === STATUS_CODES.PAUSED) {
            const currentUnix = moment().unix();
            if (currentUnix < entityData.start_time) {
                this.operation = STATUS_CODES_OPERATIONS.PENDING;
            } else if (
                !entityData.end_time ||
                currentUnix < entityData.end_time ||
                entityData.end_time === NO_END_TIME_EPOCH
            ) {
                this.operation = STATUS_CODES_OPERATIONS.RUNNING;
            } else {
                this.operation = STATUS_CODES_OPERATIONS.EXPIRED;
            }
        }
    }

    // to parse data for the API call
    static toJson(entityData, isForBulkEdit = false) {
        const genericDataFromatted = super.toJson(entityData);

        // basic setup
        let result = {
            ...genericDataFromatted,
            // entity type specific required
        };

        if (entityData.startTime) {
            result.start_time = isoToEpoch(entityData.startTime);
        }

        if (entityData.endTime || entityData.noEndTime) {
            result.end_time = entityData.noEndTime ? NO_END_TIME_EPOCH : isoToEpoch(entityData.endTime);
        }

        if (entityData.selectedCurrency || entityData.price || entityData.selectedPriceType) {
            result.amounts = {};
        }

        // pricing relate stuff
        if (entityData.selectedCurrency === unitTypes.DATA.toUpperCase() && entityData?.price) {
            result.amounts.primary = this.getAmountInBString(entityData.price, entityData.dataTypeDefinition);
        } else if (entityData.price || entityData.price === 0) {
            result.amounts.primary = entityData.price.toString();
        }

        if (entityData.selectedCurrency) {
            result.amounts.currency_type = entityData.selectedCurrency;
        }

        if (entityData.selectedPriceType) {
            const typeName = entityData.selectedPriceType.type;
            result.amounts.amount_type = PRICE_TYPES_TO_ENUM[typeName];
        }

        if (entityData?.selectedPriceType && entityData.selectedPriceType?.type !== PRICE_TYPES_MAP.FLAT) {
            delete result.amounts.primary;

            let amountRange = [];

            entityData.optionsArray?.map(option => {
                const optionForSave = {
                    range_start: Number(option.range_start),
                    amount:
                        entityData.selectedCurrency === unitTypes.DATA.toUpperCase()
                            ? this.getAmountInBString(option.amount, entityData.dataTypeDefinition)
                            : option.amount,
                };

                amountRange = [...amountRange, optionForSave];
                return amountRange;
            });

            result.amounts.amount_range = amountRange;
        }

        // optional properties
        if (entityData.categories?.length) {
            const categoriesFormatted = entityData.categories.map(category => ({
                id: category.id,
                type: ENTITY_TYPES.CATEGORY,
            }));

            if (isForBulkEdit) {
                // in case of bulk edit we're using 'categories' key since we don't have child entity update capability
                result.categories = categoriesFormatted;
            } else {
                if (!result.relationships) {
                    result.relationships = [];
                }
                categoriesFormatted.forEach(catFormatted => result.relationships.push(catFormatted));
            }
        }

        return result;
    }

    static getAmountInBString(amount, dataTypeDefinition) {
        const multiplier = dataTypeDefinition?.definition?.multiplier || defaultDataPriceMultiplier;

        return Math.round(multiplier * amount).toString();
    }
}

export default ProductCatalogOfferModel;
